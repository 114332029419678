export const OutlinedSuitcaseIcon = ({ ...props }) => {
  return (
    <svg {...props} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18.251 4.75H1.75098C1.33676 4.75 1.00098 5.08579 1.00098 5.5V17.5C1.00098 17.9142 1.33676 18.25 1.75098 18.25H18.251C18.6652 18.25 19.001 17.9142 19.001 17.5V5.5C19.001 5.08579 18.6652 4.75 18.251 4.75Z"
        stroke="#C7C7D2"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.75 4.75V3.25C13.75 2.85218 13.592 2.47064 13.3107 2.18934C13.0294 1.90804 12.6478 1.75 12.25 1.75H7.75C7.35218 1.75 6.97064 1.90804 6.68934 2.18934C6.40804 2.47064 6.25 2.85218 6.25 3.25V4.75"
        stroke="#C7C7D2"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.0011 9.84155C16.2658 11.4241 13.1605 12.255 10.0004 12.2501C6.84075 12.255 3.736 11.4244 1.00098 9.84235"
        stroke="#C7C7D2"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.875 9.25H11.125"
        stroke="#C7C7D2"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
