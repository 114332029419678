import React, { useEffect, useState } from "react";
import { ButtonUI } from "../../../components/ui/button";
import { API_types } from "../../../helper/enum";
import Api from "../../../helper/Api";
import { toast } from "react-toastify";
import { validations } from "../../../helper/validations";
import Loader from "../../../components/common/Loader";
import { userAsCompany } from "../../../helper/constants";
import { CircularProgress } from "@material-ui/core";

export const UserProfile = (props) => {
  const userId = localStorage.getItem("user");
  const [formErrors, setFormErrors] = useState({});
  const [results, setResults] = useState([]);
  const [formValues, setFormValues] = useState({});
  const [isLoader, setisLoader] = useState(true);
  const errors = {};

  useEffect(() => {
    setisLoader(true);
    if (userAsCompany(window.location.hostname)) {
      getUserInfo();
    }
    getSubAdminInfo();

  }, []);

  const handleChange = (e) => {
    setFormValues({
      ...formValues,
      [e.target.name]: e.target.value,
    });
  };

  const getSubAdminInfo = () => {
    Api("GET", `Admin/GetSubAdminById?userId=${userId}`)
      .then((response) => {
        if (response.data) {
          setResults(response.data);
          props.getUserName(
            response.data.firstName,
            response.data.lastName,
            response.data.isMasterDesktopNotification,
            response.data.isMasterEmailNotification
          );
          setFormValues({
            ["firstName"]: response.data.firstName,
            ["lastName"]: response.data.lastName,
            ["email"]: response.data.emails[0],
            ["phone"]: response.data.phoneNumbers[0],
          });
        } else {
          setFormValues({});
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setisLoader(false);
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    setFormErrors(validate(formValues));
    if (Object.keys(validate(formValues)).length > 0) {
      return;
    }

    const params = {
      companyId: null,
      userId: userId,
      firstName: formValues.firstName,
      lastName: formValues.lastName,
      email: formValues.email,
      phone: formValues.phone,
    };

    Api(API_types.PUT, `Users/SaveUserProfileInformation`, params)
      .then((res) => {
        if (res.data.isSuccess) {
          if (userAsCompany(window.location.hostname)) {
            getUserInfo();
          } else {
            getSubAdminInfo();
          }
          props.getUserName(formValues.firstName, formValues.lastName);
          toast.success(res.data.message);
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setisLoader(false);
      });
  };

  const getUserInfo = () => {
    Api(API_types.GET, `Users/GetUserById?companyId=null&userId=${userId}`)
      .then((response) => {
        if (response.data) {
          setResults(response.data);
          props.getUserName(
            response.data.firstName,
            response.data.lastName,
            response.data.isMasterDesktopNotification,
            response.data.isMasterEmailNotification
          );
          setFormValues({
            ["firstName"]: response.data.firstName,
            ["lastName"]: response.data.lastName,
            ["email"]: response.data.emails[0],
            ["phone"]: response.data.phoneNumbers[0],
          });
        } else {
          setFormValues({});
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setisLoader(false);
      });
  };

  // Validations
  const validate = (values) => {
    if (!values.firstName) {
      errors.firstName = validations?.fname?.req;
    }

    if (!values.lastName) {
      errors.lastName = validations?.lname?.req;
    }

    if (!values.email) {
      errors.email = validations?.email?.req;
    } else if (!validations?.email?.pattern?.value.test(values.email)) {
      errors.email = validations?.email?.pattern?.message;
    }

    if (!values.phone) {
      errors.phone = validations?.phoneNumber?.req;
    } else if (!validations?.mobile?.pattern?.value.test(values.phone)) {
      errors.phone = validations?.mobile?.pattern?.message;
    }

    return errors;
  };

  return (
    <div className="personal-settings password-tab">
      {isLoader ? (
        <div className="dflex">
          <CircularProgress />
        </div>
      ) : (
        <>
          <div className="personal-settings-title">
            <span className="td_name"> Profile Information </span>
            <p className="title_2"> Here you can manage your account. </p>
          </div>

          <form className="inner-container" onSubmit={handleSubmit}>
            <div className="forms-input">
              <label htmlFor="firstName">
                First Name<span className="error_msg">*</span>
              </label>
              <input
                type="text"
                name="firstName"
                id="firstName"
                placeholder="First Name"
                onChange={handleChange}
                value={formValues.firstName}
              />
              {formErrors.firstName ? (
                <span className="error_msg">{formErrors.firstName}</span>
              ) : null}
            </div>
            <div className="forms-input">
              <label htmlFor="lastName">
                Last Name<span className="error_msg">*</span>
              </label>
              <input
                type="text"
                name="lastName"
                id="lastName"
                placeholder="Last Name"
                onChange={handleChange}
                value={formValues.lastName}
              />
              {formErrors.lastName ? (
                <span className="error_msg">{formErrors.lastName}</span>
              ) : null}
            </div>
            <div className="forms-input">
              <label htmlFor="email">
                Email<span className="error_msg">*</span>
              </label>
              <input
                type="text"
                name="email"
                id="email"
                placeholder="Email"
                onChange={handleChange}
                value={formValues.email}
              />
              {formErrors.email ? (
                <span className="error_msg">{formErrors.email}</span>
              ) : null}
            </div>
            <div className="forms-input">
              <label htmlFor="phone">
                Phone<span className="error_msg">*</span>
              </label>
              <input
                type="text"
                inputmode="numeric"
                pattern="[0-9]*"
                name="phone"
                id="phone"
                placeholder="Phone"
                onChange={handleChange}
                value={formValues.phone}
              />
              {formErrors.phone ? (
                <span className="error_msg">{formErrors.phone}</span>
              ) : null}
            </div>
            <div className="forms-input align-items-center">
              <ButtonUI
                color="#ffffff"
                variant="contained"
                p="12px"
                width="300px"
                fontSize="16px"
                mt="30px"
              >
                Save
              </ButtonUI>
            </div>
          </form>
        </>
      )}
    </div>
  );
};
