import {
  FormControl,
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
} from "@mui/material";
import { indigo } from "@mui/material/colors";
import { useRef, useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import Api from "../../../../../../../helper/Api";
import { MultiStepCol } from "../../../../../addEditPopup/multiStepCol";
import { CheckboxList } from "../../../../CheckboxList";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import moment from "moment";
import { validations } from "../../../../../../../helper/validations";
import { sortAtoZ } from "../../../../../../../helper/constants";

export const CreateAptForm = ({
  openForm,
  toggleForm,
  activeStep,
  setActiveStep,
  formValues,
  setFormValues,
  applicantId,
  jobId,
  savePreHireForm,
  formErrors,
  setFormErrors,
  isSubmitting,
}) => {
  const ref = useRef(null);
  const participantRef = useRef(null);
  const { companyData } = useSelector((state) => state.companyDataReducer);
  const [appoiontment, setAppointment] = useState({});
  const [facilitiesList, setFacilitiesList] = useState([]);
  const [allParticipants, setAllParticipants] = useState([]);
  const [allTimeSlots, setAllTimeSlots] = useState([]);
  const [participantsObj, setParticipantsObj] = useState([]);
  const [selectedSlots, setSelectedSlots] = useState([]);
  const date = new Date();
  const tomorrow = new Date().setDate(date.getDate() + 1);
  const dayAfterTomorrow = new Date().setDate(date.getDate() + 2);
  const [locationName, setLocationName] = useState([]);
  const [participantNames, setParticipantNames] = useState([]);
  const errors = {};
  const [tmr, setTmr] = useState([]);
  const [tday, setTday] = useState([]);
  const [daftmr, setDaftmr] = useState([]);
  const [openLocationBox, setOpenLocationBox] = useState(false);
  const [locationList, setLocationList] = useState({});

  useEffect(() => {
    if (activeStep === 2 && allParticipants?.length > 10) {
      ref?.current?.scrollIntoView({ behavior: "smooth" });
    }
  }, [activeStep]);

  useEffect(() => {
    Api(
      "GET",
      `Appointments/GetAppointmentsList?companyId=${companyData.companyId}&companyHirerchy=${companyData.companyHirerchy}`
    ).then((res) => {
      const data = [...res.data.data];
      const apt = data.find((rec) => rec.keyName === "Physical_PPD");
      setAppointment(apt);
    });

    Api(
      "GET",
      `Facilities/GetAllFacilities?companyId=${companyData.companyId}`
    ).then((res) => {
      setFacilitiesList(sortAtoZ(res.data.data, "address"));
    });

    Api(
      "GET",
      `Job/GetTypeWiseUserList?companyId=${companyData.companyId}&type=3`
    ).then((res) => {
      const data = [...res.data];
      data.map((rec) => {
        rec.id = rec.userId;
        rec.checked = false;
        rec.name = rec.userFirstName + " " + rec.userLastName;
      });
      setAllParticipants(data);
    });

    const params = {
      companyId: companyData.companyId,
      jobId: jobId,
      applicantId: applicantId,
      offset: new Date().getTimezoneOffset(),
    };
    Api("POST", `Job/GetPreHireSlot`, params).then((res) => {
      setAllTimeSlots(res.data.data);
    });
  }, []);

  const handleNext = () => {
    if (activeStep === 0) {
      setFormErrors(firstScreen());
      if (Object.keys(firstScreen()).length > 0) {
        return false;
      }
      return true;
    }

    if (activeStep === 1) {
      setFormErrors(secondScreen());
      if (Object.keys(secondScreen()).length > 0) {
        return false;
      }
      return true;
    }

    if (activeStep === 2) {
      setFormErrors(thirdScreen());
      if (Object.keys(thirdScreen()).length > 0) {
        return false;
      }
      return true;
    }
  };

  const firstScreen = () => {
    if (!formValues.appoinmentType) {
      errors.interviewType = validations.appointmentType.req;
    }

    if (!formValues.duration) {
      errors.duration = validations.duration.req;
    }

    if ((!formValues.facilities || formValues.facilities.length === 0) &&
      (!formValues.addLocation || Object.values(formValues.addLocation).length === 0)) {
      errors.facilities = "Please add or select a location";
    }

    if (formValues.addLocation && (!formValues.addLocation.address1 || formValues.addLocation.address1 === "")) {
      errors.facilities = "Please add address field";
    }

    if (!formValues.notes) {
      errors.notes = validations.note.req;
    }

    return errors;
  };

  const secondScreen = () => {
    if (
      (!formValues.participants || formValues.participants.length === 0) &&
      (!formValues.participantList || formValues.participantList.length === 0)
    ) {
      errors.participant = "Please select at least one participant!";
      participantRef.current.scrollIntoView({ behavior: "smooth" });
    }

    return errors;
  };

  const thirdScreen = () => {
    if (!formValues.suggestedSlots || formValues.suggestedSlots.length === 0) {
      errors.selectedSlots = validations.selectedSlots.req;
    }

    return errors;
  };

  const handleSubmit = () => {
    savePreHireForm();
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
    setFormErrors({ ...formErrors, [name]: null });
  };

  const handleAptChange = (e) => {
    setFormValues({
      ["duration"]: appoiontment.duration,
      ["notes"]: appoiontment.note,
      ["appoinmentType"]: e.target.value,
    });
    setFormErrors({
      ...formErrors,
      duration: null,
      notes: null,
      appoinmentType: null,
      interviewType: null,
    });
  };

  // On selecting the facility (location)
  const handleRadioChange = (e) => {
    const facility = facilitiesList.find(
      (rec) => rec.facilitiesId === e.target.value
    );
    setLocationName(facility.address);
    setFormValues({ ...formValues, ["facilities"]: [e.target.value] });
    setFormErrors({ ...formErrors, facilities: null });
  };

  // On selecting the participants
  const handleCheckboxChange = (checkName, isChecked) => {
    const partiName = [];
    const data = [...allParticipants];
    data.map((rec) => {
      if (rec.id === checkName) {
        rec.checked = isChecked;
      }
    });
    setAllParticipants(data);
    const users = [];
    data.filter((d) => {
      if (d.checked === true) {
        users.push(d.id);
        partiName.push(d.name);
      }
    });
    setParticipantNames(partiName);
    if (users.length) {
      setFormErrors({ ...formErrors, participant: null });
    }
    setFormValues({ ...formValues, ["participants"]: users });
  };
  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const data = [...participantsObj];
    data.at(index)[name] = value;
    setParticipantsObj(data);
    setFormValues({ ...formValues, ["participantList"]: data });
  };

  const handleAddBox = (e) => {
    e.preventDefault();
    setParticipantsObj([
      ...participantsObj,
      {
        firstName: "",
        lastName: "",
        title: "",
        emailAddress: "",
      },
    ]);
  };

  // handle click event of the Remove button
  const handleRemoveClick = (index) => {
    const list = [...participantsObj];
    list.splice(index, 1);
    setParticipantsObj(list);
    setFormValues({ ...formValues, ["participantList"]: list });
  };

  function selectSlots(slots, day) {
    const data = [...selectedSlots];
    if (data.includes(slots)) {
      data.splice(data.indexOf(slots), 1);
    } else {
      data.push(slots);
    }

    if (day === "tday") {
      tday.includes(slots)
        ? tday.splice(tday.indexOf(slots), 1)
        : setTday([...tday, slots]);
    } else if (day === "tmr") {
      tmr.includes(slots)
        ? tmr.splice(tmr.indexOf(slots), 1)
        : setTmr([...tmr, slots]);
    } else {
      daftmr.includes(slots)
        ? daftmr.splice(daftmr.indexOf(slots), 1)
        : setDaftmr([...daftmr, slots]);
    }

    setSelectedSlots(data);
    setFormValues({ ...formValues, ["suggestedSlots"]: data });
  }

  const handleAddLocation = () => {
    setOpenLocationBox(true);
    setLocationList({
      name: "",
      address1: "",
      address2: "",
      city: "",
      country: "",
      postalCode: ""
    });
  }

  const handleRemoveLocation = () => {
    setLocationList({});
    setOpenLocationBox(false);
    setFormValues({ ...formValues, ["addLocation"]: {} });
  };

  const handleLocationInputChange = (e) => {
    const { name, value } = e.target;
    const data = { ...locationList };
    data[name] = value;
    setLocationList(data);
    setFormValues({ ...formValues, ["addLocation"]: data });
  };

  return (
    <MultiStepCol
      openForm={openForm}
      toggleForm={toggleForm}
      activeStep={activeStep}
      setActiveStep={setActiveStep}
      handleSubmit={handleSubmit}
      handleNext={handleNext}
      applicantId={applicantId}
      isSubmitting={isSubmitting}
      title="PreHire Form"
      steps="4"
    >
      {activeStep === 0 && (
        <>
          <div className="forms-input">
            <label>Appointment Type:</label>
            <select
              onChange={handleAptChange}
              name="appoinmentType"
              value={formValues.appointment}
            >
              <option value="">Select</option>
              <option value={appoiontment.appointmentId}>
                {appoiontment.name}
              </option>
            </select>
            <span className="error_msg">{formErrors?.interviewType}</span>
          </div>

          <div className="forms-input">
            <label>Duration(minutes) :</label>
            <input
              type="text"
              name="duration"
              placeholder="Duartion"
              onChange={handleChange}
              value={formValues.duration}
            />
            <span className="error_msg">{formErrors?.duration}</span>
          </div>

          <div className="forms-input">
            <label>Select Location:</label>
            <FormControl>
              <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={formValues.location}
                onChange={handleRadioChange}
              >
                {facilitiesList.map((facility, index) => (
                  <>
                    {facility.address !== "" && (
                      <FormControlLabel
                        value={facility.facilitiesId}
                        key={index}
                        control={
                          <Radio
                            sx={{
                              color: indigo[800],
                              "&.Mui-checked": {
                                color: indigo[600],
                              },
                            }}
                          />
                        }
                        label={facility.address}
                      />
                    )}
                  </>
                ))}
              </RadioGroup>
            </FormControl>
            {openLocationBox ?
              <div className="forms-input" key="locationBox">
                <div className="add-upload-container" key={1}>
                  <div className="text-right">
                    <IconButton
                      size="small"
                      onClick={() => handleRemoveLocation()}
                    >
                      <CloseIcon color="primary" fontSize="small" />
                    </IconButton>
                  </div>
                  <div className="forms-input">
                    <input
                      type="text"
                      placeholder="Location Name (Optional)"
                      name="name"
                      id="Name"
                      value={locationList.name}
                      onChange={(e) => handleLocationInputChange(e)}
                    />
                  </div>

                  <div className="forms-input ">
                    <input
                      type="text"
                      placeholder="Address"
                      name="address1"
                      id="address1"
                      value={locationList.address1}
                      onChange={(e) => handleLocationInputChange(e)}
                    />
                  </div>

                  <div className="forms-input">
                    <input
                      type="text"
                      placeholder="Landmark"
                      name="address2"
                      id="address2"
                      value={locationList.address2}
                      onChange={(e) => handleLocationInputChange(e)}
                    />
                  </div>

                  <div className="forms-input">
                    <input
                      type="text"
                      placeholder="City"
                      name="city"
                      id="city"
                      value={locationList.city}
                      onChange={(e) => handleLocationInputChange(e)}
                    />
                  </div>

                  <div className="forms-input sections">
                    <div className="col-2">
                      <input
                        type="text"
                        placeholder="Country"
                        name="country"
                        id="country"
                        value={locationList.country}
                        onChange={(e) => handleLocationInputChange(e)}
                      />
                    </div>

                    <div className="col-2">
                      <input
                        type="text"
                        placeholder="Postal Code"
                        name="postalCode"
                        id="postalCode"
                        value={locationList.postalCode}
                        onChange={(e) => handleLocationInputChange(e)}
                      />
                    </div>
                  </div>
                </div>
              </div>
              :
              <span className="participants pointer" onClick={handleAddLocation}>
                <AddIcon fontSize="small" /> Add Location
              </span>
            }
            <span className="error_msg">{formErrors?.facilities}</span>
          </div>

          <div className="forms-input">
            <label>Notes/Instructions :</label>
            <textarea
              placeholder="Notes"
              onChange={handleChange}
              value={formValues.notes}
              name="instructions"
            />
          </div>
        </>
      )}

      {activeStep === 1 && (
        <>
          <h3 ref={participantRef}>Select Participants</h3>
          <span className="error_msg">{formErrors?.participant}</span>
          <CheckboxList
            allData={allParticipants}
            setAllData={setAllParticipants}
            showSaveContainer={false}
            handleChange={handleCheckboxChange}
          />

          {participantsObj.map((participant, index) => (
            <div className="forms-input" key={index}>
              <div className="add-upload-container" key={1}>
                <div className="text-right">
                  <IconButton
                    size="small"
                    onClick={() => handleRemoveClick(index)}
                  >
                    <CloseIcon color="primary" fontSize="small" />
                  </IconButton>
                </div>
                <div className="forms-input sections">
                  <div className="col-2">
                    <label>First Name:</label>
                    <input
                      type="text"
                      placeholder="First Name"
                      name="firstName"
                      id="firstName"
                      value={participant.firstName}
                      onChange={(e) => handleInputChange(e, index)}
                    />
                  </div>

                  <div className="col-2">
                    <label>Last Name:</label>
                    <input
                      type="text"
                      placeholder="Last Name"
                      name="lastName"
                      id="lastName"
                      value={participant.lastName}
                      onChange={(e) => handleInputChange(e, index)}
                    />
                  </div>
                </div>

                <div className="forms-input sections">
                  <div className="col-2">
                    <label>Title:</label>
                    <input
                      type="text"
                      placeholder="Title"
                      name="title"
                      id="title"
                      value={participant.title}
                      onChange={(e) => handleInputChange(e, index)}
                    />
                  </div>

                  <div className="col-2">
                    <label>Email Address:</label>
                    <input
                      type="text"
                      placeholder="Email Address"
                      name="emailAddress"
                      id="emailAddress"
                      value={participant.email}
                      onChange={(e) => handleInputChange(e, index)}
                    />
                  </div>
                </div>
              </div>
            </div>
          ))}
          <span className="participants pointer" onClick={handleAddBox}>
            <AddIcon fontSize="small" /> Add Participant
          </span>
        </>
      )}

      {activeStep === 2 && (
        <>
          {allTimeSlots?.timeSlotsCurrent?.length > 0 &&
            allTimeSlots?.timeSlotTomorrow?.length > 0 &&
            allTimeSlots?.timeSlotDayAfterTomorrow?.length > 0 ? (
            <>
              <div className="time-slots" ref={ref}>
                <span>Date: {moment(date).format("dddd, MMMM DD")}</span>
                <span>Available slots :</span>
                <div className="slot-container">
                  {allTimeSlots?.timeSlotsCurrent?.map((slot) => (
                    <span
                      className={
                        slot.isDisabled === false
                          ? selectedSlots.includes(slot.timeSlot)
                            ? "slot pointer selected"
                            : "slot pointer"
                          : "slot disabled"
                      }
                      onClick={() => selectSlots(slot.timeSlot, "tday")}
                    >
                      {slot.isDisabled === false && (
                        <>{moment(slot.timeSlot).format("h:mm a")}</>
                      )}
                    </span>
                  ))}
                </div>
              </div>

              <div className="time-slots mt-20">
                <span>Date: {moment(tomorrow).format("dddd, MMMM DD")}</span>
                <span>Available slots :</span>
                <div className="slot-container">
                  {allTimeSlots?.timeSlotTomorrow?.map((slot) => (
                    <span
                      className={
                        slot.isDisabled === false
                          ? selectedSlots.includes(slot.timeSlot)
                            ? "slot pointer selected"
                            : "slot pointer"
                          : "slot disabled"
                      }
                      onClick={() => selectSlots(slot.timeSlot, "tmr")}
                    >
                      {moment(slot.timeSlot).format("h:mm a")}
                    </span>
                  ))}
                </div>
              </div>

              <div className="time-slots mt-20">
                <span>
                  Date: {moment(dayAfterTomorrow).format("dddd, MMMM DD")}
                </span>
                <span>Available slots :</span>
                <div className="slot-container">
                  {allTimeSlots?.timeSlotDayAfterTomorrow?.map((slot) => (
                    <span
                      className={
                        slot.isDisabled === false
                          ? selectedSlots.includes(slot.timeSlot)
                            ? "slot pointer selected"
                            : "slot pointer"
                          : "slot disabled"
                      }
                      onClick={() => selectSlots(slot.timeSlot, "daftmr")}
                    >
                      {moment(slot.timeSlot).format("h:mm a")}
                    </span>
                  ))}
                </div>
              </div>

              <span className="error_msg">{formErrors?.selectedSlots}</span>
            </>
          ) : (
            <span>
              No slots found ! Please check the system settings for slots
              availability !
            </span>
          )}
        </>
      )}

      {activeStep === 3 && (
        <>
          <div className="forms-inputfill">
            <label className="note-label">
              Review the following details and submit.
            </label>
          </div>
          <div className="forms-inputfill">
            <label className="company_label">
              {localStorage.getItem("companyName")}
            </label>
          </div>
          <div className="forms-inputfill">
            <label>Appointment Type:</label>
            <span>Physical / PPD</span>
          </div>
          <div className="forms-inputfill">
            <label>Duration:</label>
            <span>{formValues?.duration} minutes</span>
          </div>
          <div className="forms-inputfill">
            <label>Location:</label>
            <span>{locationName.length > 0 ? locationName : formValues.addLocation.address1}</span>
          </div>
          <div className="forms-inputfill">
            <label>Notes/Instructions:</label>
            <span>{formValues?.notes}</span>
          </div>
          <div className="forms-inputfill">
            <label>Participants:</label>
            <div className="time-slots mt-5">
              <div className="slot-container">
                {(participantNames.length > 0)
                  ?
                  participantNames.map((intName) => (
                    <span className="inter-name">
                      <div className="sm-dot-div"></div>
                      {intName}
                    </span>
                  ))
                  :
                  formValues.participantList.map((participant) => {
                    <span className="inter-name">
                      <div className="sm-dot-div"></div>
                      {participant.firstName + " " + participant.lastName}
                    </span>
                  })
                }
              </div>
            </div>
          </div>
          <div className="forms-inputfill">
            <label>Selected dates and Times:</label>
            {tday.length > 0 && (
              <>
                <span>{moment(date).format("ddd, MMM DD")} :</span>
                <div className="time-slots mt-5">
                  <div className="slot-container">
                    {tday.map((t) => (
                      <span className="b-slot">
                        {moment(t).format("h:mm a")}
                      </span>
                    ))}
                  </div>
                </div>
              </>
            )}

            {tmr.length > 0 && (
              <>
                <span>{moment(tomorrow).format("ddd, MMM DD")} :</span>
                <div className="time-slots mt-5">
                  <div className="slot-container">
                    {tmr.map((t) => (
                      <span className="b-slot">
                        {moment(t).format("h:mm a")}
                      </span>
                    ))}
                  </div>
                </div>
              </>
            )}

            {daftmr.length > 0 && (
              <>
                <span>{moment(dayAfterTomorrow).format("ddd, MMM DD")} :</span>
                <div className="time-slots mt-5">
                  <div className="slot-container">
                    {daftmr.map((t) => (
                      <span className="b-slot">
                        {moment(t).format("h:mm a")}
                      </span>
                    ))}
                  </div>
                </div>
              </>
            )}
          </div>
        </>
      )}
    </MultiStepCol>
  );
};
