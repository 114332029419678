import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "@fontsource/inter";
import "../../../assets/css/components/login/_login.css";
import { present_year, userAsCompany } from "../../../helper/constants";
import Api from "../../../helper/Api";
import { toast } from "react-toastify";
import { validations } from "../../../helper/validations";
import { useDispatch } from "react-redux";
import { COMPANY_DATA_ACTIONS } from "../../../redux/companyData/actions";
import { ButtonUI } from "../../../components/ui/button";

export function Login() {
  const [isLoader, setIsLoader] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [isRemember, setIsRemember] = useState(
    JSON.parse(localStorage.getItem("rememberMe"))
  );
  const initialValues = { userName: "", password: "" };
  const [formValues, setFormValues] = useState(initialValues);
  const errors = {};
  let navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (
      localStorage.getItem("email") !== " " &&
      localStorage.getItem("password") !== " "
    ) {
      setFormValues({
        userName: localStorage.getItem("email"),
        password: localStorage.getItem("password"),
      });
    }
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setFormErrors(validate(formValues));
    if (Object.keys(validate(formValues)).length === 0) {
      setIsLoader(true);
      if (userAsCompany(window.location.hostname)) {
        formValues.loginType = "company";
      } else {
        formValues.loginType = "admin";
      }

      // Login API call and set local storage Values
      Api("POST", "account/login", formValues)
        .then((response) => {
          if (response.data.isSuccess === true) {
            if (isRemember === true) {
              localStorage.setItem("email", formValues.userName);
              localStorage.setItem("password", formValues.password);
            } else {
              localStorage.removeItem("email", formValues.userName);
              localStorage.removeItem("password", formValues.password);
            }

            // Admin portal login
            if (response.data.data.companyAccessLists.length === 0) {
              setTimeout(() => {
                navigate("/dashboard", { replace: true });
              }, 600);
            }

            // Direct company login
            if (response.data.data.companyAccessLists.length === 1) {
              const params = {
                companyId: response.data.data.userDetail.companyId,
                companyHirerchy: response.data.data.userDetail.companyHirerchy,
              };

              dispatch({
                type: COMPANY_DATA_ACTIONS.SET_COMP_DATA,
                payload: params,
              });

              setTimeout(() => {
                navigate("/dashboard_new", { replace: true });
              }, 600);
            }

            // Select a company to login
            if (response.data.data.companyAccessLists.length > 1) {
              const dataParams = {
                companyId: response.data.data.userDetail.companyId,
                companyHirerchy: response.data.data.userDetail.companyHirerchy,
              };

              dispatch({
                type: COMPANY_DATA_ACTIONS.SET_COMP_DATA,
                payload: {
                  companyId: response.data.data.userDetail.companyId,
                  companyHirerchy:
                    response.data.data.userDetail.companyHirerchy,
                },
              });

              navigate("/select-company", {
                state: { companyList: response.data.data.companyAccessLists },
              });
            }

            localStorage.setItem("token", response?.data?.data?.token);
            localStorage.setItem(
              "roles",
              response?.data?.data?.rolePermissions
            );
            localStorage.setItem(
              "user",
              response?.data?.data?.userDetail?.userId
            );
            localStorage.setItem(
              "sessionId",
              response?.data?.data?.userDetail?.userSessionID
            );
            localStorage.setItem("rememberMe", isRemember);
            localStorage.setItem(
              "userName",
              response?.data?.data?.userDetail?.firstName +
                " " +
                response?.data?.data?.userDetail?.lastName
            );
          } else {
            toast.error(response.data.message);
          }
        })
        .finally(() => {
          setIsLoader(false);
        });
    }
  };

  // Validations
  const validate = (values) => {
    if (!values.password) {
      errors.password = validations?.password?.req;
    }
    if (!values.userName) {
      errors.userName = validations?.email?.req;
    }
    return errors;
  };

  const handleCheckChange = (e) => {
    setIsRemember(e.target.checked);
    localStorage.setItem("rememberMe", e.target.checked);
  };

  return (
    <div className="main-container">
      <div className="inner-container">
        <div className="rec4">
          <div className="left_form">
            <div className="left_logo w-100">
              <img
                src={require("../../../assets/images/logo.png")}
                alt="left-img"
                className="logo_img"
              />
            </div>
            <div className="login_title">
              <h2 className="title_1">Sign In</h2>
              <span className="title_2">
                Enter your email and password for signing in
              </span>
            </div>
            <div className="login_form">
              <form method="POST" onSubmit={handleSubmit}>
                <div className="email_box">
                  <label className="email_label" htmlFor="email">
                    Login ID
                  </label>
                  <input
                    className="email_input"
                    type="text"
                    name="userName"
                    id="userName"
                    placeholder="Email"
                    onChange={handleChange}
                    value={formValues.userName}
                  />
                  {formErrors.userName ? (
                    <span className="error_msg">{formErrors.userName}</span>
                  ) : null}
                </div>
                <div className="pswd_box">
                  <label className="email_label" htmlFor="password">
                    Password
                  </label>
                  <input
                    className="pswd_input"
                    type="password"
                    name="password"
                    id="password"
                    placeholder="Password"
                    onChange={handleChange}
                    value={formValues.password}
                  />
                  {formErrors.password ? (
                    <span className="error_msg">{formErrors.password}</span>
                  ) : null}
                </div>
                <div className="forgot_box">
                  <input
                    type="checkbox"
                    className="login-chekbox"
                    id="rememberMe"
                    name="rememberMe"
                    checked={isRemember}
                    onChange={(e) => handleCheckChange(e)}
                  />
                  <label className="login-remembar" htmlFor="rememberMe">
                    Remember Me
                  </label>
                  <Link to="/forgot-password" className="login-forgot">
                    Forgot password?
                  </Link>
                </div>
                <div className="submit_box">
                  <ButtonUI
                    fontWeight={600}
                    fontSize="16px"
                    height="44px"
                    width="100%"
                    p="6px"
                    onClick={handleSubmit}
                  >
                    {isLoader ? <div className="dot-pulse"></div> : "Sign In"}
                  </ButtonUI>
                </div>
              </form>
            </div>
            <div className="login_copyright">
              <span className="copyright_text">
                @{present_year} All Rights Reserved. Talentats Cookie
                Preferences, Privacy and Terms
              </span>
            </div>
          </div>
        </div>
        <div className="rec5"></div>
      </div>
    </div>
  );
}
