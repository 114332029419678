import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { AddEditPopup } from "../../../addEditPopup";
import { useState } from "react";
import Api from "../../../../../helper/Api";
import { job_access } from "../../../../../helper/constants";
import { useEffect } from "react";
import { useSelector } from "react-redux";

export const AddUser = ({
  openSidebar,
  toggleDrawer,
  formValues,
  formErrors,
  setFormValues,
  allTimeZone,
  usersRoles,
  setFormErrors,
  handleFormSubmit,
  isSubmitting,
}) => {
  const [roles, setRoles] = useState([]);
  const [accessData, setAccessData] = useState([]);
  const [message, setMessage] = useState("Please select an option to proceed!");
  const [jobAccessCheck, setJobAccessCheck] = useState([]);
  const [showWelcomeMessage, setShowWelcomeMessage] = useState(false);

  const { companyData } = useSelector((state) => state.companyDataReducer);

  const [defaultTimeZones, setDefaultTimeZones] = useState(localStorage.getItem("timeZone"));

  const [selectedCompanyId, setSelectedCompanyId] = useState(null);
  const [defaultCompanyId, setDefaultCompanyId] = useState(null);


  useEffect(() => {
    const generateLoginId = () => {
      const { firstName, lastName } = formValues;
      const trimmedFirstName = firstName?.trim().toLowerCase();
      const trimmedLastName = lastName?.trim().toLowerCase();

      if (trimmedFirstName && trimmedLastName) {
        const firstChar = trimmedFirstName.charAt(0);
        const lastChar = trimmedLastName.slice(-1); // Get the last character of the last name
        const newLoginId = firstChar + trimmedLastName;
        setFormValues((prevValues) => ({
          ...prevValues,
          login_id: newLoginId
        }));
      }
    };

    generateLoginId();
  }, [formValues.firstName, formValues.lastName]);

  useEffect(() => {
    // Set default company selection only if the selected option is "company"
    if (formValues.jobAccess === "company" && accessData.length > 0) {
      setDefaultCompanyId(accessData[0].id);
    } else {
      setSelectedCompanyId(null);
      setDefaultCompanyId(null); // Reset default selection if the option is changed

    }
  }, [formValues.jobAccess, accessData]);

  useEffect(() => {
    setAccessData([]);

    // Set default timezone when the component mounts
    setFormValues((prevValues) => ({
      ...prevValues,
      timeZone: defaultTimeZones || allTimeZone[0]?.timeZone || 'none',
    }));
  }, [openSidebar]);

  const handleSelectChange = (event) => {
    const {
      target: { value },
    } = event;
    setRoles(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  useEffect(() => {
    setShowWelcomeMessage(!!formValues.email_id);
  }, [formValues.email_id]);

  const handleJobAccessCheck = (id, name) => {
    if (selectedCompanyId === id) {
      setSelectedCompanyId(null);
    } else {
      setSelectedCompanyId(id);
      setDefaultCompanyId(null);
    }
  };


  const handleJobAccess = (e) => {
    let url, id;
    formValues.jobAccess = e.target.value;
    setFormErrors({});

    switch (e.target.value) {
      case "department":
        url = `Department/GetDepartmentList?companyId=${companyData.companyId}`;
        id = "departmentId";
        break;
      case "company":
        url = `companies/GetListOfCompany?companyId=${companyData.companyId}`;
        id = "companyId";
        break;
      case "facility":
        url = `Facilities/GetFacilitiesList?companyId=${companyData.companyId}`;
        id = "facilitiesId";
        break;
      case "job":
        url = `Job/GetJobList?companyId=${companyData.companyId}`;
        id = "jobId";
        break;
    }

    if (formValues.jobAccess === "none" || formValues.jobAccess === "Select") {
      setAccessData([]);
      setMessage("No Data Found!");
      return;
    }

    Api("GET", url + `&companyHirerchy=${companyData.companyHirerchy}`)
      .then((res) => {
        formValues.jobAccess === "department" ||
          formValues.jobAccess === "company"
          ? setAccessData(res.data.map((res) => ({ ["id"]: res[id], ...res })))
          : setAccessData(
            res.data.data.map((res) => ({ ["id"]: res[id], ...res }))
          );
        // This condition is for temporary basis untill the job module APIs get prepared
        // The condition for department module is due to the response variables
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // const handleChange = (e) => {
  //   setFormValues({ ...formValues, [e.target.name]: e.target.value });
  // };

  const handleWelcomeMessageChange = (e) => {
    setFormValues({ ...formValues, isSendWelcomeMessage: e.target.checked });
  };

  const generateLoginId = (firstName, lastName) => {
    const trimmedFirstName = (firstName || '').trim().toLowerCase();
    const trimmedLastName = (lastName || '').trim().toLowerCase();

    if (!trimmedFirstName || !trimmedLastName) {
      return ''; // If either first name or last name is empty, return empty login ID
    }

    const firstChar = trimmedFirstName.charAt(0);
    const newLoginId = firstChar + trimmedLastName;

    return newLoginId;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value
    }));

    if (name === 'email_id') {
      const isSendWelcomeMessage = !!value;
      setFormValues((prevValues) => ({
        ...prevValues,
        isSendWelcomeMessage: isSendWelcomeMessage,
      }));
    }
    if (name === 'timezone') {
      setDefaultTimeZones(value);
      setFormValues((prevValues) => ({
        ...prevValues,
        timezone: defaultTimeZones,
      }));
    }
  };

  // const handleChange = (e) => {
  //   const { name, value } = e.target;

  //   // Check if the changed field is login_id and if it's not empty
  //   // If so, use the entered value for login_id; otherwise, generate it
  //   const newLoginId = name === 'login_id' && value.trim() !== '' ? value : formValues.login_id;

  //   setFormValues((prevValues) => ({
  //     ...prevValues,
  //     [name]: value, // Update other form values as usual
  //     login_id: newLoginId, // Update login_id conditionally
  //   }));

  //   if (name === 'email_id') {
  //     const isSendWelcomeMessage = !!value;
  //     setFormValues((prevValues) => ({
  //       ...prevValues,
  //       isSendWelcomeMessage: isSendWelcomeMessage,
  //     }));
  //   }
  //   if (name === 'timezone') {
  //     setDefaultTimeZones(value);
  //     setFormValues((prevValues) => ({
  //       ...prevValues,
  //       timezone: defaultTimeZones,
  //     }));
  //   }
  // };

  const handleLastNameBlur = () => {
    const firstName = formValues.firstName || '';
    const trimmedFirstName = firstName.trim().toLowerCase();
    const trimmedLastName = formValues.lastName.trim().toLowerCase();

    const firstChar = trimmedFirstName.charAt(0);
    const newLoginId = firstChar + trimmedLastName;

    setFormValues((prevValues) => ({
      ...prevValues,
      login_id: newLoginId
    }));
  };
  const handleTimezoneChange = (e) => {
    const { value } = e.target;

    setFormValues((prevValues) => ({
      ...prevValues,
      timeZone: value
    }));

    // Update defaultTimeZone if it's not set
    if (!defaultTimeZones) {
      setDefaultTimeZones(value);
    }
  };
  const handleSubmit = (e) => {
    e.preventDefault();

    const firstName = formValues.firstName || '';
    const lastName = formValues.lastName || '';

    const trimmedFirstName = firstName.trim().toLowerCase();
    const trimmedLastName = lastName.trim().toLowerCase();

    const firstChar = trimmedFirstName.charAt(0);
    const newLoginId = firstChar + trimmedLastName;

    const isCustomLoginId = formValues.login_id && formValues.login_id.trim() !== '';
    const finalLoginId = isCustomLoginId ? formValues.login_id : newLoginId;

    setFormValues((prevValues) => ({
      ...prevValues,
      login_id: finalLoginId
    }));

    formValues.roles = [...roles];

    const entityData = roles
      .map((role) =>
        jobAccessCheck.map((job) => ({ ["roleId"]: role, ...job }))
      )
      .flat(1);
    entityData.map((m) =>
      usersRoles.find((u) =>
        u.roleId === m.roleId ? (m.roleName = u.roleName) : null
      )
    );

    formValues.jobAccessCheck = entityData;
    formValues.jobAccessData = accessData.filter((col) => {
      return formValues.jobAccessCheck.find(
        (checked) => checked.entId === col.id
      );
    });

    handleFormSubmit();
  };

  return (
    <AddEditPopup
      openSidebar={openSidebar}
      toggleDrawer={toggleDrawer}
      title="Add New User"
      type="jobTemplate"
      handleSubmit={handleSubmit}
      isSubmitting={isSubmitting}
    >
      <div className="forms-input sections">
        <div className="col-2">
          <label> First Name</label>
          <input
            type="text"
            placeholder="First Name"
            name="firstName"
            id="firstName"
            value={formValues.firstName}
            onChange={handleChange}
          />
          <span className="error_msg">{formErrors.firstName}</span>
        </div>
        <div className="col-2">
          <label>Middle Name</label>
          <input
            type="text"
            placeholder="Middle Name"
            name="middleName"
            id="middleName"
            value={formValues.middleName}
            onChange={handleChange}
          />
          <span className="error_msg">{formErrors.middleName}</span>
        </div>
      </div>

      <div className="forms-input sections">
        <div className="col-2">
          <label>Last Name</label>
          <input
            type="text"
            placeholder="Last Name"
            name="lastName"
            id="lastName"
            value={formValues.lastName}
            onChange={handleChange}
          //onBlur={handleLastNameBlur}
          />
          <span className="error_msg">{formErrors.lastName}</span>
        </div>
        <div className="col-2">
          <label>Nick Name</label>
          <input
            type="text"
            placeholder="Nick Name"
            name="nickName"
            id="nickName"
            value={formValues.nickName}
            onChange={handleChange}
          />
          <span className="error_msg">{formErrors.nickName}</span>
        </div>
      </div>

      <div className="forms-input sections">
        <div className="col-2">
          <label>Title</label>
          <input
            type="text"
            placeholder="Title"
            name="user_title"
            id="user_title"
            value={formValues.user_title}
            onChange={handleChange}
          />
          <span className="error_msg">{formErrors.user_title}</span>
        </div>
        <div className="col-2">
          <label>Login ID</label>
          <input
            type="text"
            placeholder="Login ID"
            name="login_id"
            id="login_id"
            value={formValues.login_id}
            onChange={handleChange}
          />
          <span className="error_msg">{formErrors.login_id}</span>
        </div>
      </div>

      <div className="forms-input sections">
        <div className="col-2">
          <label>Email Address</label>
          <input
            type="text"
            placeholder="Email Address"
            name="email_id"
            id="email_id"
            value={formValues.email_id}
            onChange={handleChange}
          />
          <span className="error_msg">{formErrors.email_id}</span>
        </div>
        <div className="col-2">
          <label>Cell Phone</label>
          <input
            type="text"
            placeholder="Cell Phone"
            name="cell_phone"
            id="cell_phone"
            value={formValues.cell_phone}
            onChange={handleChange}
          />
          <span className="error_msg">{formErrors.cell_phone}</span>
        </div>
      </div>

      <div className="forms-input sections">
        <div className="col-2">
          <label>Timezone</label>
          <select
            name="timeZone"
            id="timeZone"
            value={formValues.timeZone || defaultTimeZones}
            onChange={handleTimezoneChange}
          >
            <option value="none">Select</option>
            {allTimeZone.map((time) => (
              <option key={time.id} value={time.timeZone}>
                {time.timeZone}
              </option>
            ))}
          </select>
          <span className="error_msg">{formErrors.timeZone}</span>
        </div>
        <div className="col-2">
          <label>Select User Role</label>
          <select name="roles" onChange={handleSelectChange} value={roles}>
            <option>Select</option>
            {usersRoles?.map((role) => (
              <option value={role.roleId}>{role.roleName}</option>
            ))}
          </select>
          <span className="error_msg">{formErrors.roleName}</span>
        </div>
      </div>

      <div className="forms-input sections">
        <div className="col-2">
          <label>Job access level:</label>
          <select
            name="jobAccess"
            id="jobAccess"
            value={formValues.jobAccess}
            onChange={handleJobAccess}
          >
            <option>Select</option>
            {job_access?.map((roles) => (
              <option value={roles.name.toLowerCase()} key={roles.id}>
                {roles.name}
              </option>
            ))}
          </select>
          <span className="error_msg">{formErrors.jobAccess}</span>
        </div>
      </div>

      <div className="forms-input">
        <label>Select one or more facilities</label>
        <div className="user-roles-pop-up">
          {accessData.length > 0 ? (
            accessData.map((data) => (
              <div className="d-flex flex-column border-bottom" key={data.id}>
                <FormControlLabel
                  control={
                    <Checkbox
                      disableRipple
                      value={data.name}
                      onChange={() => handleJobAccessCheck(data.id, data.name)}
                      checked={selectedCompanyId === data.id || defaultCompanyId === data.id}
                    />

                  }
                  label={data.name}
                />
                {data.address ? (
                  <span className="sub-desc updated-design">
                    {data.address}
                  </span>
                ) : null}
              </div>
            ))
          ) : (
            <span className="message">{message}</span>
          )}
        </div>
        <span className="error_msg">{formErrors.jobAccessLevel}</span>
        {showWelcomeMessage && (
          <div className="forms-input">
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  checked={formValues.isSendWelcomeMessage || false}
                  onChange={handleWelcomeMessageChange}
                  defaultChecked
                />
              }
              label="Send Welcome Message"
            />
          </div>
        )}
      </div>
    </AddEditPopup>
  );
};
