export const SendIcon = () => {
  return (
    <svg
      width="12"
      height="14"
      viewBox="0 0 12 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.3519 6.04178L2.16109 0.79725C1.96718 0.688618 1.74478 0.641642 1.5235 0.662579C1.30222 0.683515 1.09258 0.77137 0.922481 0.914443C0.752386 1.05751 0.629916 1.24901 0.571384 1.46343C0.512852 1.67785 0.521035 1.90502 0.594844 2.11467L2.26883 6.99334L0.594844 11.8851C0.536506 12.0501 0.518573 12.2266 0.54255 12.3998C0.566527 12.5731 0.631714 12.7381 0.732641 12.881C0.833568 13.0238 0.967291 13.1404 1.12259 13.2209C1.27789 13.3014 1.45023 13.3435 1.62516 13.3437C1.81311 13.3433 1.99782 13.2947 2.16164 13.2026L2.16656 13.1993L11.3541 7.94545C11.5225 7.8501 11.6627 7.71174 11.7601 7.54452C11.8576 7.37729 11.909 7.18719 11.909 6.99362C11.909 6.80005 11.8576 6.60994 11.7601 6.44272C11.6627 6.27549 11.5225 6.13714 11.3541 6.04178H11.3519ZM2.02109 11.7703L3.42984 7.65616H6.43766C6.6117 7.65616 6.77862 7.58702 6.90169 7.46395C7.02477 7.34087 7.09391 7.17396 7.09391 6.99991C7.09391 6.82586 7.02477 6.65894 6.90169 6.53587C6.77862 6.4128 6.6117 6.34366 6.43766 6.34366H3.43367L2.02164 2.22897L10.3719 6.99389L2.02109 11.7703Z"
        fill="#E6492D"
      />
    </svg>
  );
};
