import axios from "axios";
import { baseUrl } from "./constants";
import { toast } from "react-toastify";

const Api = async (method, urlData, params, contentType) => {
  const url = `${baseUrl}${urlData}`;
  const token = localStorage.getItem("token");
  const headers = {};

  if (token) {
    headers["Authorization"] = `Bearer ${token}`;
  }

  if (contentType === "multipart") {
    headers["Content-Type"] = "multipart/form-data";
  }

  let axiosObj = {
    method: method,
    url: url,
    data: params,
    headers: headers,
  };

  try {
    return await axios(axiosObj);
  } catch (error) {
    if (error && error.response) {
      toast.error(error.response.data.message);
      localStorage.clear();
      window.location.replace(process.env.REACT_APP_PUBLIC_URL + 'login');
    } else {
      toast.error(error);
      window.location.replace(process.env.REACT_APP_PUBLIC_URL + 'login');
    }
  }
};

export default Api;
