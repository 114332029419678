import { useEffect } from "react";
import { AddEditCol } from "../../../../addEditPopup/AddEditCol";
import Api from "../../../../../../helper/Api";
import { useState } from "react";

export const StopApplicantForm = ({
  toggleForm,
  openForm,
  applicantId,
  companyId,
  isSubmitting,
  formValues,
  setFormValues,
  handleSubmit,
  formErrors,
}) => {
  const [loading, setLoading] = useState(true);
  const [stopReasonsList, setStopReasonsList] = useState([]);

  useEffect(() => {
    Api("GET", `Job/GetStopReasonsList?companyId=${companyId}`)
      .then((res) => {
        setStopReasonsList(res?.data);
      })
      .finally(() => setLoading(false));
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleStopReason = (e) => {
    const reason = stopReasonsList.find(
      (r) => r.stopReasonsId === e.target.value
    );
    setFormValues({
      ...formValues,
      ["stopReasonsId"]: e.target.value,
      ["stopReasonsChatMessage"]: reason.defaultChatMessage,
    });
  };

  return (
    <AddEditCol
      applicantId={applicantId}
      openForm={openForm}
      title="Are you sure you want to Stop this application?"
      toggleForm={toggleForm}
      handleSubmit={handleSubmit}
      isLoading={loading}
      isSubmitting={isSubmitting}
    >
      <div className="forms-input">
        <label>Reason :</label>
        <select onChange={handleStopReason} name="stopReasonsId">
          <option value="">Select</option>
          {stopReasonsList.map((reason) => (
            <option value={reason.stopReasonsId}>{reason.name}</option>
          ))}
        </select>
        {formErrors.stopReasonsId ? (
          <span className="error_msg">{formErrors.stopReasonsId}</span>
        ) : null}
      </div>

      <div className="forms-input">
        <label>Chat Message :</label>
        <textarea
          name="stopReasonsChatMessage"
          value={formValues.stopReasonsChatMessage}
          onChange={handleChange}
          rows="6"
        />
      </div>

      <div className="forms-input">
        <label>Notes :</label>
        <textarea
          name="stopReasonsNotes"
          value={formValues.stopReasonsNotes}
          onChange={handleChange}
        />
      </div>
    </AddEditCol>
  );
};
