import { useEffect, useState } from "react";
import { ButtonUI } from "../../../../../../ui/button";
import { OfferForm } from "./offerForm";
import { toast } from "react-toastify";
import Api from "../../../../../../../helper/Api";
import moment from "moment";
import { ResendOfferingForm } from "./resendNotificationForm";
import { EditOfferForm } from "./editOfferForm";
import { AcceptButton } from "../../../../../../ui/button/accept";
import { SendButton } from "../../../../../../ui/button/send";
import { DeleteButton } from "../../../../../../ui/button/delete";
import { EditButton } from "../../../../../../ui/button/edit";
import { AlertBox, showSuccess } from "../../../../../../../helper/utils";
import { HubConnectionBuilder, LogLevel } from "@microsoft/signalr";
import { baseUrl } from "../../../../../../../helper/constants";

const OFFER_STATUS = {
  Draft: "orange-sign",
  Pending: "red-delete",
  Declined: "red-delete",
  Accepted: "green-active",
  Rescinded: "",
};

export const OfferingStatus = ({ selectedSuitCase, applicantId }) => {
  const [openOfferingForm, setOfferingForm] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [formValues, setFormValues] = useState([]);
  const [offeringList, setOfferingList] = useState([]);
  const [openResendForm, setResendForm] = useState(false);
  const [flag, setFlag] = useState("");
  const [offerScheduleId, setOfferScheduleId] = useState("");
  const [openEditForm, setEditOfferForm] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmitting, setSubmitting] = useState(false);
  const [editMode, setEditMode] = useState(false);

  useEffect(() => {
    getOfferingList();
  }, []);

  useEffect(async () => {
    var the_arr = baseUrl.split("/");
    the_arr.pop();
    the_arr.pop();
    var apiUrl = the_arr.join("/");

    const connect = new HubConnectionBuilder()
      .withUrl(`${apiUrl}/liveUpdateHub`)
      .configureLogging(LogLevel.Information)
      .build();

    connect.on("OfferingList", (res) => {
      res.map((r) => {
        if (r.applicantId === applicantId) {
          setOfferingList(res);
        }
      });
    });

    await connect.start();

  }, [selectedSuitCase.jobId]);

  const getOfferingList = () => {
    Api(
      "GET",
      `Job/GetOfferingList?companyId=${selectedSuitCase.companyId}&applicantId=${applicantId}&jobId=${selectedSuitCase.jobId}`
    )
      .then((res) => {
        setOfferingList(res.data);
      })
      .catch((err) => console.log(err));
  };

  const toggleOfferForm = (open) => {
    setOfferingForm(open);
  };

  const toggleResendForm = (open) => {
    setResendForm(open);
  };

  const toggleEditOffer = (open) => {
    setEditOfferForm(open);
  };

  const acceptOffer = (id) => {
    AlertBox().then((res) => {
      if (!res.isConfirmed) return;

      const params = {
        applicantOfferingId: id,
      };

      setSubmitting(true);
      Api("POST", `Job/AcceptOfferLetter`, params)
        .then((res) => {
          if (res.data.isSuccess === true) {
            showSuccess("Accepted!", res.data.message);
            toggleOfferForm(false);
          } else {
            toast.error(res.data.message);
          }
        })
        .catch((err) => console.log(err))
        .finally(() => setSubmitting(false));
    });
  };

  const handleSaveForm = () => {
    const params = { ...formValues };
    params.companyId = selectedSuitCase.companyId;
    params.jobId = selectedSuitCase.jobId;
    params.applicantId = applicantId;

    if (editMode && params.supervisors[0]["sFullName"] !== undefined) {
      const data = [...params.supervisors];
      const recs = [];
      data.map((rec) => {
        recs.push(rec.sId);
      })
      params.supervisors = [...recs];
    }

    setSubmitting(true);
    Api("POST", "Job/SaveOfferingForm", params)
      .then((res) => {
        if (res.data.isSuccess === true) {
          toast.success(res.data.message);
          toggleOfferForm(false);
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setSubmitting(false));
  };

  const handleNotification = (description, id) => {
    setSubmitting(true);
    const params = {
      companyId: selectedSuitCase.companyId,
      jobId: selectedSuitCase.jobId,
      applicantOfferingId: id,
      applicantId: applicantId,
      description: description,
    };

    Api("PUT", `Job/ResendNotificationForOffering`, params)
      .then((res) => {
        if (res.data.isSuccess === true) {
          toast.success(res.data.message);
          toggleResendForm(false);
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setSubmitting(false));
  };

  const handleCancelInterview = (description, id) => {
    const params = {
      applicantOfferingId: id,
      applicantMessage: description,
    };

    setSubmitting(true);
    Api("POST", `Job/RescindedJobOffer`, params)
      .then((res) => {
        if (res.data.isSuccess === true) {
          toggleResendForm(false);
          toast.success(res.data.message);
        } else {
          toggleResendForm(true);
          toast.error(res.data.message);
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setSubmitting(false));
  };

  const editAction = (offering) => {
    setActiveStep(0);
    setFormValues(offering);
    if (offering.offerStatus === "Accepted") {
      toggleEditOffer(true);
    } else {
      setEditMode(true);
      toggleOfferForm(true);
    }
  };

  const handleEditOfferDate = () => {
    const params = {
      startDate: formValues.startDate,
      applicantId: applicantId,
      jobId: selectedSuitCase.jobId,
      companyId: selectedSuitCase.companyId,
      applicantOfferingId: formValues.applicantOfferingId,
    };

    setSubmitting(true);
    Api("PUT", `Job/EditOfferingForm`, params)
      .then((res) => {
        if (res.data.isSuccess === true) {
          toast.success(res.data.message);
          toggleEditOffer(false);
          getOfferingList();
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setSubmitting(false));
  };

  const deleteOffering = (offering) => {
    AlertBox().then((res) => {
      if (!res.isConfirmed) return;

      Api(
        "DELETE",
        `Job/DeleteOffering?applicantOfferingId=${offering.applicantOfferingId}`
      ).then((res) => {
        if (res.data.isSuccess) {
          toast.success(res.data.message);
          getOfferingList();
        } else {
          toast.error(res.data.message);
        }
      });
    });
  };

  return (
    <div>
      {openOfferingForm && (
        <OfferForm
          openForm={openOfferingForm}
          toggleForm={toggleOfferForm}
          activeStep={activeStep}
          setActiveStep={setActiveStep}
          formValues={formValues}
          setFormValues={setFormValues}
          handleSaveForm={handleSaveForm}
          formErrors={formErrors}
          setFormErrors={setFormErrors}
          isSubmitting={isSubmitting}
          selectedSuitCase={selectedSuitCase}
          editMode={editMode}
        />
      )}

      {openResendForm && (
        <ResendOfferingForm
          handleSubmit={handleNotification}
          toggleForm={toggleResendForm}
          openForm={openResendForm}
          flag={flag}
          handleCancelInterview={handleCancelInterview}
          offerScheduleId={offerScheduleId}
          applicantId={applicantId}
          isSubmitting={isSubmitting}
        />
      )}

      {openEditForm && (
        <EditOfferForm
          openForm={openEditForm}
          handleSubmit={handleEditOfferDate}
          toggleForm={toggleEditOffer}
          applicantId={applicantId}
          formValues={formValues}
          setFormValues={setFormValues}
          isSubmitting={isSubmitting}
        />
      )}

      {(offeringList[0]?.offerStatus === "Rescinded" ||
        offeringList[0]?.offerStatus === "Declined" ||
        offeringList.length === 0) && (
          <ButtonUI
            p="8px"
            variant="outlined"
            ml="96px"
            onClick={() => (
              toggleOfferForm(true),
              setFormValues({}),
              setFormErrors({}),
              setActiveStep(0),
              setEditMode(false)
            )}
          >
            Extend Offer
          </ButtonUI>
        )}

      <div className="profile-data mt-10">
        <div className="inter-container">
          {offeringList?.map((offering, index) => (
            <fieldset className="status-border mt-5">
              <legend
                className={"status " + OFFER_STATUS[offering?.offerStatus]}
              >
                {offering?.offerStatus}
              </legend>
              <div className="section" key={index}>
                <div>
                  <strong>Offering</strong>
                </div>
                <div className="mt-5">
                  <div className="flex-column">
                    <div className="j-field">
                      <label>Rate:</label>
                      <strong>
                        {offering?.rate + " (" + offering?.rateType + ")"}
                      </strong>
                    </div>

                    <div className="j-field">
                      <label>Shift Type:</label>
                      <strong>{offering?.shiftType}</strong>
                    </div>

                    <div className="j-field">
                      <label>Created:</label>
                      <strong>
                        {moment(offering?.created).format("MM/DD/Y")}
                      </strong>
                    </div>

                    <div className="j-field">
                      <label>Start Date:</label>
                      <strong>
                        {moment(offering?.startDate).format("MM/DD/Y")}
                      </strong>
                    </div>

                    <div className="j-field">
                      <label>Expires:</label>
                      <strong>
                        {moment(offering?.expireDate).format("MM/DD/Y")}
                      </strong>
                    </div>

                    <div className="j-field">
                      <label>Employment Type:</label>
                      <strong>{offering?.employeementType}</strong>
                    </div>

                    <div className="j-field">
                      <label>Exempt from overtime:</label>
                      <strong>
                        {offering?.isExemptfromovertime == true ? "Yes" : "No"}
                      </strong>
                    </div>

                    <div className="j-field">
                      <label>Schedule Type:</label>
                      <strong>{offering?.scheduleType}</strong>
                    </div>

                    <div className="j-field">
                      <label>Last viewed:</label>
                      <strong>{offering?.lastViewed}</strong>
                    </div>
                  </div>
                </div>
                <span className="d-flex flex-row mt-5">
                  {offering?.offerStatus === "Draft" && (
                    <>
                      <EditButton onClick={() => editAction(offering)} />
                      <DeleteButton
                        title="Delete"
                        width="70px"
                        onClick={() => (
                          setFlag("delete"),
                          setOfferScheduleId(offering.applicantOfferingId),
                          deleteOffering(offering)
                        )}
                      />
                    </>
                  )}

                  {offering?.offerStatus === "Pending" && (
                    <>
                      <AcceptButton
                        onClick={() =>
                          acceptOffer(offering.applicantOfferingId)
                        }
                      />
                      <SendButton
                        onClick={() => (
                          toggleResendForm(true),
                          setFlag("send"),
                          setOfferScheduleId(offering.applicantOfferingId)
                        )}
                      />
                      <DeleteButton
                        title="Rescind"
                        width="70px"
                        onClick={() => (
                          toggleResendForm(true),
                          setFlag("rescinded"),
                          setOfferScheduleId(offering.applicantOfferingId)
                        )}
                      />
                    </>
                  )}

                  {offering?.offerStatus === "Accepted" && (
                    <>
                      <SendButton
                        onClick={() => (
                          toggleResendForm(true),
                          setFlag("send"),
                          setOfferScheduleId(offering.applicantOfferingId)
                        )}
                      />
                      <DeleteButton
                        title="Rescind"
                        width="70px"
                        onClick={() => (
                          toggleResendForm(true),
                          setFlag("rescinded"),
                          setOfferScheduleId(offering.applicantOfferingId)
                        )}
                      />
                    </>
                  )}

                  {offering?.offerStatus === "Declined" && (
                    <AcceptButton
                      onClick={() => acceptOffer(offering.applicantOfferingId)}
                    />
                  )}
                </span>
              </div>
            </fieldset>
          ))}
        </div>
      </div>
    </div>
  );
};
