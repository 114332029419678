import { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import NoRecordsFound from "../../../components/common/NoRecordsFound";
import Api from "../../../helper/Api";
import { validations } from "../../../helper/validations";
import { EclipseLoader } from "../../../components/common/eclipseLoader";
import { ButtonUI } from "../../../components/ui/button";
import AddIcon from "@mui/icons-material/Add";
import { AddAdminUser } from "./addAdminUser";
import { toast } from "react-toastify";
import { FormControlLabel } from "@mui/material";
import { Android12Switch } from "../../../components/ui/android/android2switch";
import { EditDeleteMenu } from "../../../components/ui/editDeleteMenu";
import { CellPhoneIcon, OutlinedEmailIcon } from "../../../icons";
import QueryBuilderIcon from "@mui/icons-material/QueryBuilder";
import Loader from "../../../components/common/Loader";
import { indigo } from "@mui/material/colors";

export const UserDetails = () => {
  const [isLoader, setLoader] = useState(false);
  const [infLoader, setInfLoader] = useState(false);
  const [pageNumber, setPage] = useState(1);
  const [usersData, setUsersData] = useState([]);
  const [isActive, setIsActive] = useState(false);
  const [showSideBar, setSideBar] = useState(false);
  const [label, setLabel] = useState("Add New User");
  const [formErrors, setFormErrors] = useState({});
  const [formValues, setFormValues] = useState({});
  const [openUserAddForm, setUserAddForm] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [allTimeZone, setAllTimeZone] = useState([]);
  const [usersDataIndi, setUsersDataIndi] = useState([]);
  const errors = {};

  useEffect(() => {
    setLoader(true);
    getUserList(true);
    getTimeZone();
  }, []);

  useEffect(() => {
    getUserList();
  }, [pageNumber]);

  const getTimeZone = () => {
    Api("GET", "TimeZone/GetTimeZone?isActive=true")
      .then((response) => {
        if (response.data) {
          setAllTimeZone(response.data);
        } else {
          setAllTimeZone([]);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getUserList = (firstSelectDefault = false) => {
    Api("GET", `Admin/GetSubAdminList?pageLimit=12&pageNumber=${pageNumber}`)
      .then((res) => {
        const allData = [...usersData, ...res.data.data];
        setUsersData(allData);
        if (firstSelectDefault) {
          getData(res.data.data[0].userId);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setInfLoader(false);
      });
  };

  const getData = (userId) => {
    try {
      Api("GET", `Admin/GetSubAdminById?userId=${userId}`)
        .then((res) => {
          setUsersDataIndi(res.data);
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setLoader(false);
        });
    } catch (e) {
      console.log(e);
    }
  };

  const fetchMore = () => {
    setInfLoader(true);
    setPage((prev) => prev + 1);
  };

  const showItems = (userId) => {
    getData(userId);
  };

  const toggleUserAdd = (anchor, open) => {
    setUserAddForm({ ...openUserAddForm, [anchor]: open });
  };

  const handleSubmit = () => {
    const params = {
      firstName: formValues.firstName,
      lastName: formValues.lastName,
      middleName: formValues.middleName,
      nickName: formValues.nickName,
      //emails: formValues.emails,
      //phoneNumbers: formValues.phoneNumbers,
      loginID: formValues.loginId,
      title: formValues.user_title,
      timeZone: formValues.timeZone,
    };

    params.userId = usersDataIndi?.userId;

    setFormErrors(validate(formValues));
    if (Object.values(validate(formValues)).length > 0) {
      return;
    }

    if (editMode) {
      Api("PUT", `Admin/EditSubAdmin`, params)
        .then((res) => {
          if (res.data.isSuccess === true) {
            toast.success(res.data.message);
            toggleUserAdd("right", false);
            getUserList();
          } else {
            toast.error(res.data.message);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      Api("POST", `Admin/AddSubAdmin`, params)
        .then((res) => {
          if (res.data.isSuccess === true) {
            toast.success(res.data.message);
            toggleUserAdd("right", false);
            getUserList();
          } else {
            toast.error(res.data.message);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const validate = (values) => {
    if (!values.firstName) {
      errors.firstName = validations?.fname?.req;
    }

    if (!values.middleName) {
      errors.middleName = validations?.mname?.req;
    }

    if (!values.lastName) {
      errors.lastName = validations?.lname?.req;
    }

    if (!values.nickName) {
      errors.nickName = validations?.nname?.req;
    }

    if (!values.user_title) {
      errors.user_title = validations?.title?.req;
    }

    if (!values.loginId) {
      errors.loginId = validations?.loginid?.req;
    } else if (values.loginId.length < 4 || values.loginId.length > 5) {
      errors.loginId = validations?.loginid?.custom;
    }

    if (!values.emails) {
      errors.emails = validations?.email?.req;
    }

    if (!values.phoneNumbers) {
      errors.phoneNumbers = validations?.mobile?.req;
    } else if (isNaN(values.phoneNumbers)) {
      errors.phoneNumbers = validations?.mobile?.pattern.message;
    }

    if (!values.timeZone) {
      errors.timeZone = validations?.timezone?.req;
    }

    return errors;
  };

  return (
    <div>
      <AddAdminUser
        openSidebar={openUserAddForm}
        formValues={formValues}
        setFormValues={setFormValues}
        toggleDrawer={toggleUserAdd}
        handleSubmit={handleSubmit}
        formErrors={formErrors}
        allTimeZone={allTimeZone}
        editMode={editMode}
      />

      <div className="company_content help-container applicants">
        <div className="common-container users company">
          <div className="inner-container company">
            <div className="user-container company">
              {isLoader === true ? (
                <Loader inShow={true} />
              ) : usersData.length > 0 ? (
                <div className="w-100 h-100 position-relative d-flex gap-20">
                  {/* Column section start */}
                  <div className="user-list user-lists">
                    <div className="inner-h user-inf-list">
                      <InfiniteScroll
                        height="100%"
                        dataLength={usersData?.length || 0}
                        next={fetchMore}
                        hasMore={usersData?.length !== usersData?.totalRecords}
                        loader={
                          infLoader && (
                            <h4 style={{ textAlign: "center" }}>
                              <EclipseLoader />
                            </h4>
                          )
                        }
                        endMessage={
                          <p style={{ textAlign: "center" }}>
                            <b>Yay! You have seen it all</b>
                          </p>
                        }
                      >
                        <div>
                          {usersData.length > 0 &&
                            usersData.map((users) => (
                              <div
                                className={
                                  isActive === users.userId
                                    ? "user-info active"
                                    : "user-info"
                                }
                                key={users.userId}
                                onClick={() => {
                                  showItems(users.userId);
                                }}
                              >
                                <div className="user-header">
                                  {users.active ? (
                                    <img
                                      src={
                                        require("../../../assets/images/user-info-selected.svg")
                                          .default
                                      }
                                      alt="nav-icon"
                                      className="icon profile-icon"
                                    />
                                  ) : (
                                    <img
                                      src={
                                        require("../../../assets/images/user-info-default.svg")
                                          .default
                                      }
                                      alt="nav-icon"
                                      className="icon profile-icon"
                                    />
                                  )}
                                  <span>
                                    {users.firstName + " " + users.lastName}
                                  </span>
                                </div>
                                <p>
                                  {users.roleDetails?.map((roles, i, row) => {
                                    if (i + 1 === row.length) {
                                      return roles.roleName;
                                    } else {
                                      return roles.roleName + ", ";
                                    }
                                  })}
                                </p>
                              </div>
                            ))}
                        </div>
                      </InfiniteScroll>
                    </div>
                    <div className="bottom-btn">
                      <ButtonUI
                        p="12px"
                        mt="20px"
                        ml="8px"
                        width="300px"
                        leftIcon={<AddIcon style={{ height: 16, width: 16 }} />}
                        onClick={() => (
                          setSideBar(true),
                          setLabel("Add New User"),
                          setFormErrors({}),
                          setFormValues({}),
                          toggleUserAdd("right", true),
                          setEditMode(false)
                        )}
                      >
                        Add New User
                      </ButtonUI>
                    </div>
                  </div>
                  {/* Column section end */}

                  {/* Info section start */}
                  <div className="user-detail position-relative">
                    <div className="top-header-user">
                      <span className="main-name">
                        {usersDataIndi.firstName + " " + usersDataIndi.lastName}
                      </span>
                      <span className="check-status">Active</span>
                      <FormControlLabel
                        control={
                          <Android12Switch
                            checked={usersDataIndi.isActive ?? false}
                            // onChange={() =>
                            //   isActiveUser(
                            //     usersDataIndi.userId,
                            //     usersDataIndi.isActive
                            //   )
                            // }
                          />
                        }
                      />
                    </div>
                    <div className="personal-info">
                      <div className="d-flex justify-content-between mb-15">
                        <span className="main-head">Personal Information</span>
                        <div className="position-relative">
                          <EditDeleteMenu
                            id={usersDataIndi.userId}
                            onEdit={() => (
                              setEditMode(true),
                              setFormValues(usersDataIndi),
                              toggleUserAdd("right", true)
                            )}
                            deleteOption={false}
                          />
                        </div>
                      </div>
                      <div className="personal-data">
                        <div className="p-entity">
                          <OutlinedEmailIcon
                            width="20"
                            height="16"
                            className="p-icon"
                          />
                          <div>
                            <p className="p-label">Email Address</p>
                            <p className="p-data">
                              {usersDataIndi?.emails?.join(", ")}
                            </p>
                          </div>
                        </div>
                        <div className="p-entity">
                          <CellPhoneIcon
                            width="30"
                            height="22"
                            style={{ width: "400px !important" }}
                          />
                          <div>
                            <p className="p-label">Cell Phone</p>
                            <p className="p-data">
                              {usersDataIndi?.phoneNumbers?.join(", ")}
                            </p>
                          </div>
                        </div>
                        <div className="p-entity">
                          <QueryBuilderIcon sx={{ color: indigo[500] }} />
                          <div>
                            <p className="p-label"></p>
                            <p className="p-data">{usersDataIndi.timeZone}</p>
                          </div>
                        </div>
                        <div className="p-entity">
                          <img
                            src={
                              require("../../../assets/images/User.svg").default
                            }
                            alt="nav-icon"
                            className="p-icons"
                          />
                          <div>
                            <p
                              className="p-label"
                              style={{ paddingLeft: "6px" }}
                            >
                              Title
                            </p>
                            <p className="p-data">{usersDataIndi.title}</p>
                          </div>
                        </div>
                        <div className="p-entity">
                          <img
                            src={
                              require("../../../assets/images/User.svg").default
                            }
                            alt="nav-icon"
                            className="p-icons"
                          />
                          <div>
                            <p className="p-label">Login ID</p>
                            <p className="p-data">{usersDataIndi.loginID}</p>
                          </div>
                        </div>
                        <div className="p-entity">
                          <img
                            src={
                              require("../../../assets/images/User.svg").default
                            }
                            alt="nav-icon"
                            className="p-icons"
                          />
                          <div>
                            <p
                              className="p-label"
                              style={{ paddingLeft: "6px" }}
                            >
                              Nick Name
                            </p>
                            <p className="p-data">{usersDataIndi.nickName}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="user-roles">
                      <div className="d-flex justify-content-between mb-15">
                        <span className="main-head">User Role</span>
                      </div>
                      <div className="add-roles-container">
                        {usersDataIndi.roleDetails?.map((items) => (
                          <div className="role-tabs" key={items.roleId}>
                            <span>{items.roleName}</span>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                  {/* Info section end */}
                </div>
              ) : (
                <NoRecordsFound />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
