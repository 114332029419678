import { useEffect, useState } from "react";
import Api from "../../../helper/Api";
import { AddEditPopup } from "../../common/addEditPopup";

export const AddEditDoc = ({
  openSidebar,
  toggleDrawer,
  formValues,
  setFormValues,
  handleSubmit,
  isSubmitting,
  formErrors,
  showAddEditLabel,
}) => {
  const [allDocs, setAllDocs] = useState([]);

  useEffect(() => {
    getDocs();
  }, []);

  // get all docs dropdown api
  const getDocs = () => {
    Api("GET", "Document/GetDocumentType?type=doctype")
      .then((res) => {
        if (res.data) {
          setAllDocs(res.data);
        } else {
          setAllDocs([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleDocFiles = (files) => {
    setFormValues({ ...formValues, docFile: files[0] });
  };

  return (
    <AddEditPopup
      toggleDrawer={toggleDrawer}
      openSidebar={openSidebar}
      title={showAddEditLabel}
      handleSubmit={handleSubmit}
      isSubmitting={isSubmitting}
    >
      <div>
        <div className="in-forms">
          <div className="forms-input">
            <label>Document Name</label>
            <input
              type="text"
              placeholder="Name here"
              name="docName"
              id="docName"
              value={formValues.docName}
              onChange={handleChange}
            />
            {formErrors.docName ? (
              <span className="error_msg">{formErrors.docName}</span>
            ) : null}
          </div>
          <div className="forms-input">
            <label>Select Document Type</label>
            <select
              name="doc_type"
              id="doc_type"
              value={formValues.doc_type}
              onChange={handleChange}
            >
              <option value="none">Select</option>
              {allDocs.map((docs) => (
                <option key={docs.documentTypeId} value={docs.documentTypeId}>
                  {docs.name}
                </option>
              ))}
            </select>
            {formErrors.doc_type ? (
              <span className="error_msg">{formErrors.doc_type}</span>
            ) : null}
          </div>
          <div className="forms-input">
            <label>Notes</label>
            <textarea
              placeholder="Enter a description..."
              name="notesName"
              id="notesName"
              cols="20"
              rows="4"
              value={formValues.notesName}
              onChange={handleChange}
            ></textarea>
            {formErrors.notesName ? (
              <span className="error_msg">{formErrors.notesName}</span>
            ) : null}
          </div>
          <input
            type="text"
            hidden
            placeholder="notesDate"
            name="notesDate"
            id="date"
            value={formValues.notesDate}
            onChange={handleChange}
          />
        </div>
      </div>
    </AddEditPopup>
  );
};
