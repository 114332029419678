import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import "@fontsource/inter";
import "../../../assets/css/components/login/_login.css";
import Api from "../../../helper/Api";
import Loader from "../../../components/common/Loader";
import {
  ToastAlert,
  present_year,
  userAsCompany,
} from "../../../helper/constants";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { validations } from "../../../helper/validations";

export function ResetPassword() {
  const search = useLocation().search;
  const userId = new URLSearchParams(search).get("userId");
  const tokenMatch = search.match(/(?:^|\b)token=([^&]*)/);
  const token = tokenMatch ? tokenMatch[1] : null;


  const [errorMessages, setErrorMessages] = useState({});
  const initialValues = {
    NewPassword: "",
    ConfirmPassword: "",
    userId: userId,
    token: token,
  };
  const [formValues, setFormValues] = useState(initialValues);
  const [isLoader, setIsLoader] = useState(false);
  const [openToastBar, setOpenToastBar] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const errors = {};
  let navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setFormErrors(validate(formValues));
    if (Object.keys(validate(formValues)).length == 0) {
      setIsLoader(true);
      //Reset Password API call
      Api("POST", "account/resetpassword", formValues)
        .then((response) => {
          if (response.data.isSuccess) {
            toast.success(response.data.message);
            setTimeout(() => {
              navigate("/login");
            }, 2000);
          } else {
            toast.error(response.data.message);
            setTimeout(() => {
              navigate("/forgot-password", { replace: true });
            }, 2000);
          }
        })
        .catch((error) => {
          toast.error(error.response.data.message);
          setIsLoader(false);
        });
      setOpenToastBar(true);
    }
  };

  // Validations
  const validate = (values) => {
    if (!values.ConfirmPassword) {
      errors.ConfirmPassword = "Confirm " + validations?.password?.req;
    } else if (values.NewPassword !== values.ConfirmPassword) {
      errors.ConfirmPassword = validations?.password?.match?.message;
    }
    if (!values.NewPassword) {
      errors.NewPassword = "New " + validations?.password?.req;
    } else if (!validations?.password?.custom?.isValid(values.NewPassword)) {
      errors.NewPassword = validations?.password?.custom?.message;
    }
    return errors;
  };

  return (
    <div className="main-container">
      <Loader inShow={isLoader} />
      {openToastBar ? <ToastAlert /> : null}
      <div className="inner-container">
        <div className="rec4">
          <div className="left_form">
            <div className="left_logo active">
              <img
                src={require("../../../assets/images/logo.png")}
                alt="left-img"
                className="logo_img"
              />
            </div>
            <div className="reset_form">
              <form onSubmit={handleSubmit}>
                <div className="pswd_box">
                  <label className="email_label" htmlFor="NewPassword">
                    New Password
                  </label>
                  <input
                    className="pswd_input"
                    type="password"
                    name="NewPassword"
                    id="NewPassword"
                    placeholder="New Password"
                    onChange={handleChange}
                  />
                  {formErrors.NewPassword ? (
                    <span className="error_msg">{formErrors.NewPassword}</span>
                  ) : null}
                </div>
                <div className="pswd_box active">
                  <label className="email_label" htmlFor="ConfirmPassword">
                    Confirm Password
                  </label>
                  <input
                    className="pswd_input"
                    type="password"
                    name="ConfirmPassword"
                    id="ConfirmPassword"
                    placeholder="Confirm Password"
                    onChange={handleChange}
                  />
                  {formErrors.ConfirmPassword ? (
                    <span className="error_msg">
                      {formErrors.ConfirmPassword}
                    </span>
                  ) : null}
                </div>
                <div className="submit_box">
                  <input
                    type="submit"
                    className="login-submit-btn"
                    value="Submit"
                  />
                </div>
              </form>
            </div>
            <div className="reset_copyright">
              <span className="copyright_text">
                @{present_year} All Rights Reserved. Talentats Cookie
                Preferences, Privacy and Terms
              </span>
            </div>
          </div>
        </div>
        <div
          className={
            userAsCompany(window.location.hostname) ? "rec5 active" : "rec5"
          }
        ></div>
      </div>
    </div>
  );
}
