import { Sources } from "../../components/companyDetail/sources/index";
import { Facilities } from "../../components/companyDetail/facilities/index";
import { QuickNotes } from "../../components/companyDetail/quickNotes/index";
import { SystemSettings } from "../../components/companyDetail/systemSettings/index";
import { SubCompanies } from "../../components/companyDetail/subCompanies/index";
import { Messages } from "../../components/companyDetail/messages/index";
import { StopReasons } from "../../components/companyDetail/stopReasons";
import { Documents } from "../../components/companyDetail/documents";
import { Appointments } from "../../components/companyDetail/appointments";
import { JobBoards } from "../../components/companyDetail/jobBoards";
import { JobTemplates } from "../../components/companyDetail/jobTemplates/index";
import { JobTemplateDetails } from "../../components/companyDetail/jobTemplates/details/index";
import { VideoInterviews } from "../../components/companyDetail/videoInterviews/index";
import { Jobs } from "../../components/companyDetail/jobs";
import { AdminMorePositions } from "../../components/companyDetail/jobs/details/morePositions";
import { CompanyUsersFromAdmin } from "../../components/companyDetail/users";
import { CompanyManageJobsForAdmin } from "../../components/companyDetail/users/manageJobs";
import { CompanyManageNotificationsForAdmin } from "../../components/companyDetail/users/manageNotifications";

const adminCompanyRoutes = [
  { path: "/sources", element: <Sources /> },
  { path: "/facilities", element: <Facilities /> },
  { path: "/quick-notes", element: <QuickNotes /> },
  { path: "/system-settings", element: <SystemSettings /> },
  { path: "/sub-companies", element: <SubCompanies /> },
  { path: "/messages", element: <Messages /> },
  { path: "/stop-reasons", element: <StopReasons /> },
  { path: "/documents", element: <Documents /> },
  { path: "/appointments", element: <Appointments /> },
  { path: "/job-boards", element: <JobBoards /> },
  { path: "/video-interviews", element: <VideoInterviews /> },

  { path: "/job-templates", element: <JobTemplates /> },
  { path: "/job-templates/details", element: <JobTemplateDetails /> },

  { path: "/users", element: <CompanyUsersFromAdmin /> },
  { path: "/users/managejobs", element: <CompanyManageJobsForAdmin /> },
  { path: "/users/notifications",element: <CompanyManageNotificationsForAdmin />},

  { path: "/jobs", element: <Jobs /> },
  { path: "/jobs/more-positions", element: <AdminMorePositions /> },
];

export default adminCompanyRoutes;
