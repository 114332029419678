import { Route, Routes, Outlet } from "react-router-dom";
import { PageNotFound } from "./views/pages/error/PageNotFound";
import { Provider } from "react-redux";
import { store, persistor } from "./redux/store";
import { PersistGate } from "redux-persist/integration/react";
import { CompanyDetailLayout } from "./components/companyDetail/layout";
import { AdminLayout } from "./views/admin/layout";
import { CompanyLayout } from "./views/company/layout";
import { publicRoutes } from "./routes/public/routes";
import { companyRoutes } from "./routes/company/routes";
import { ToastAlert } from "./helper/constants";
import { NotificationAlert } from "./components/ui/notificationAlert";
import RequireAuth from "./views/pages/authentication/RequireAuth";
import adminCompanyRoutes from "./routes/admin/companyRoutes";
import adminRoutes from "./routes/admin/routes";
import { useEffect, useState } from "react";
import { HubConnectionBuilder, LogLevel } from "@microsoft/signalr";
import "./App.css";
import "./assets/css/components/ui/_toast.css";

function App() {
  const [message, setMessage] = useState("");
  const [isOpen, setOpen] = useState(false);
  const BASEURL = process.env.REACT_APP_BASE_URL;

  useEffect(async () => {
    var the_arr = BASEURL.split("/");
    the_arr.pop();
    the_arr.pop();
    var apiUrl = the_arr.join("/");

    const connect = new HubConnectionBuilder()
      .withUrl(`${apiUrl}/notifications`)
      .configureLogging(LogLevel.Information)
      .build();

    connect.on("ReceiveNotification", (msg, username, email) => {
      if (username) {
        setMessage(msg.slice(0, -1) + " by " + username);
      }
      setMessage(msg.slice(0, -1));
      setOpen(true);
    });

    await connect.start();
  }, []);

  return (
    <>
      <div className="App">
        <Provider store={store}>
          <PersistGate persistor={persistor}>
            <Routes>
              {publicRoutes.map((route) => {
                return (
                  <Route
                    key={route.path}
                    path={route.path}
                    element={route.element}
                  />
                );
              })}
              <Route element={<RequireAuth />}>
                <Route
                  element={
                    <CompanyLayout>
                      <Outlet />
                    </CompanyLayout>
                  }
                >
                  {companyRoutes.map((route) => {
                    return (
                      <Route
                        key={route.path}
                        path={route.path}
                        element={route.element}
                      />
                    );
                  })}
                </Route>
                <Route
                  element={
                    <CompanyDetailLayout>
                      <Outlet />
                    </CompanyDetailLayout>
                  }
                >
                  {adminCompanyRoutes.map((route) => {
                    return (
                      <Route
                        key={route.path}
                        path={route.path}
                        element={route.element}
                      />
                    );
                  })}
                </Route>
                <Route
                  element={
                    <AdminLayout>
                      <Outlet />
                    </AdminLayout>
                  }
                >
                  {adminRoutes.map((route) => {
                    return (
                      <Route
                        key={route.path}
                        path={route.path}
                        element={route.element}
                      />
                    );
                  })}
                </Route>
              </Route>
              <Route path="*" element={<PageNotFound />} />
            </Routes>
          </PersistGate>
        </Provider>
      </div>
      <div>
        <ToastAlert />
        <NotificationAlert
          message={message}
          isOpen={isOpen}
          setOpen={setOpen}
        />
      </div>
    </>
  );
}

export default App;
