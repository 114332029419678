import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Api from "../../../../../../../helper/Api";
import { ButtonUI } from "../../../../../../ui/button";
import Loader from "../../../../../Loader";
import { PublicNavigationBar } from "../../../../../navigationBar/public";
import NoRecordsFound from "../../../../../NoRecordsFound";

export const ViewOffer = () => {
  const [content, setContent] = useState({});
  const [isLoader, setLoader] = useState(true);
  const [isSubmitting, setSubmitting] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const offerId = location.pathname.substring(
    location.pathname.lastIndexOf("/") + 1
  );

  useEffect(() => {
    getOfferingDetails();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      getOfferingDetails();
    }, 4500);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const getOfferingDetails = () => {
    Api("GET", `Job/GetOfferingData?id=${offerId}`)
      .then((res) => {
        if (res.data.isSuccess === true) {
          setContent(res.data.data);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoader(false);
      });
  };

  const openChat = () => {
    window.open(content.chatrepresentative, "_blank");
  };

  const acceptOrDecline = (flag) => {
    setSubmitting(true);
    const params = {
      applicantOfferingId: content.applicantOfferingId,
      isAccept: flag,
    };

    Api("POST", `Job/AcceptDeclineOffer`, params)
      .then((res) => {
        if (res.data.isSuccess === true) {
          toast.success(res.data.message);
          getOfferingDetails();
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => setSubmitting(false));
  };

  return (
    <div>
      <PublicNavigationBar />
      {isLoader ? (
        <Loader inShow={true} />
      ) : Object.values(content).length > 0 ? (
        <div className="white-container">
          <div className="mb-10">
            <img
              src={require("../../../../../../../assets/images/logo.png")}
              width="125px"
            />
          </div>
          <span>{content.heading}</span>

          <div>
            <div className="physical-request-container">
              <div className="doc-section"></div>
              <div className="apt-container">
                <span>
                  <strong>Rate : </strong>
                  {content.rate}
                </span>
                <span>
                  <strong>Shift Type : </strong>
                  {content.shiftType}
                </span>
                <span>
                  <strong>Schedule Type : </strong>
                  {content.scheduleType}
                </span>
                <span>
                  <strong>Employment Type : </strong>
                  {content.employmentType}
                </span>
                <span>
                  <strong>Exempt from Overtime : </strong>
                  {content.exemptfromOvertime}
                </span>
                <span>
                  <strong>Supervisor(s) : </strong>
                  {content?.supervisors?.join(", ")}
                </span>
                <span>
                  <strong>Start Date : </strong>
                  {content.startDate}
                </span>
                <span>
                  <strong>Notes : </strong>
                  {content.notes}
                </span>
                {content.applicantOfferingId !== "" && (
                  <>
                    {isSubmitting ? (
                      <div className="d-flex justify-content-center">
                        <ButtonUI p="8px" mt="0px">
                          <div className="dot-pulse"></div>
                        </ButtonUI>
                      </div>
                    ) : (
                      <div className="d-flex justify-content-center">
                        <ButtonUI
                          p="8px"
                          mt="0px"
                          onClick={() => acceptOrDecline(true)}
                        >
                          Accept
                        </ButtonUI>
                        <ButtonUI
                          p="8px"
                          ml="10px"
                          mt="0px"
                          variant="outlined"
                          onClick={() => acceptOrDecline(false)}
                        >
                          Decline
                        </ButtonUI>
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
          <div className="doc-section"></div>

          <strong>
            If you have any questions about this notification please{" "}
            <u
              className="pointer"
              onClick={() => {
                openChat();
              }}
            >
              chat with a representative
            </u>
          </strong>
        </div>
      ) : (
        <NoRecordsFound />
      )}
    </div>
  );
};
