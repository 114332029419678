import { validations } from "../../../helper/validations";
import { AddEditPopup } from "../../common/addEditPopup";

export const EditStopReasons = ({
  toggleDrawer,
  openSidebar,
  label,
  formValues,
  setFormValues,
  formErrors,
  setFormErrors,
  handleSubmit,
  isSubmitting,
}) => {
  const errors = {};

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleSave = (e) => {
    e.preventDefault();
    setFormErrors(validate(formValues));
    if (Object.keys(validate(formValues)).length > 0) {
      return;
    }

    handleSubmit();
  };

  const validate = (values) => {
    if (!values.name || values.name === "") {
      errors.name = validations.name.req;
    } else if (!isNaN(values.name)) {
      errors.name = validations.name.pattern.message;
    }

    return errors;
  };

  return (
    <AddEditPopup
      toggleDrawer={toggleDrawer}
      openSidebar={openSidebar}
      handleSubmit={handleSave}
      title={label}
      isSubmitting={isSubmitting}
    >
      <div className="forms-input">
        <label>Reason Name</label>
        <input
          type="text"
          placeholder="Reason Name here"
          name="name"
          id="name"
          value={formValues.name}
          onChange={handleChange}
        />
        <span className="error_msg">{formErrors?.name}</span>
      </div>

      <div className="forms-input">
        <label>Default Chat Message</label>
        <textarea
          placeholder="Enter a message..."
          name="defaultChatMessage"
          id="defaultChatMessage"
          value={formValues.defaultChatMessage}
          onChange={handleChange}
          rows="8"
        ></textarea>
      </div>
    </AddEditPopup>
  );
};
