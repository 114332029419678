import React, { useEffect, useState } from "react";
import { API_types } from "../../../helper/enum";
import Api from "../../../helper/Api";
import { ButtonUI } from "../../../components/ui/button";
import Loader from "../../../components/common/Loader";
import { StyledPagination } from "../../../components/ui/pagination/pagination";
import { toast } from "react-toastify";
import moment from "moment";
import { Logout } from "@mui/icons-material";
import { Tooltip } from "@mui/material";

export const ManageSession = ({}) => {
  const userId = localStorage.getItem("user");
  const [sessionList, setSessionList] = useState([]);
  const [isLoader, setisLoader] = useState(false);
  const [totalPage, setTotalPage] = useState(1);
  const [page, setPage] = useState(1);

  // pagination
  const handlePageChange = (event, value) => {
    setPage(value);
  };

  useEffect(() => {
    getAllSession();
  }, [page]);

  const getAllSession = () => {
    Api(
      API_types.GET,
      `Users/GetUserSessionList?companyId=null&userId=${userId}&pageNumber=${page}&pageLimit=8`
    )
      .then((response) => {
        if (response.data) {
          // setisLoader(false);
          setSessionList(response.data.data);
          setTotalPage(response.data.totalPage);
        } else {
          setSessionList([]);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // handle click event of the Logout from all devices button
  const handleLogoutFromAllDevicesClick = () => {
    Api(API_types.GET, `Account/LogOut?userId=${userId}`)
      .then((res) => {
        if (res.data.isSuccess) {
          setSessionList([]);
          toast.success(res.data.message);
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // handle click event of the Logout button
  const handleLogoutClick = (session) => {
    let sessionID = session.sessionId;
    Api(API_types.GET, `Account/LogOut?userId=${userId}&sessionId=${sessionID}`)
      .then((res) => {
        if (res.data.isSuccess) {
          let index = sessionList.indexOf(session);
          let data = [...sessionList];
          data.splice(index, 1);
          setSessionList(data);
          toast.success(res.data.message);
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div className="personal-settings password-tab">
      <div className="personal-settings-title d-flex">
        <div className="td_name">Manage Sessions</div>
        <ButtonUI
          color="#ffffff"
          variant="contained"
          p="8px"
          radius="6px"
          fontSize="12px"
          onClick={handleLogoutFromAllDevicesClick}
        >
          Logout All
        </ButtonUI>
      </div>
      <Loader inShow={isLoader} />
      {sessionList.length > 0 ? (
        <div className="company_table session_table">
          <table>
            <thead>
              <tr>
                <th className="text-left">Device Details</th>
                <th className="">Last Usage</th>
                <th className=""></th>
              </tr>
            </thead>
            <tbody className="">
              {sessionList.map((session) => (
                <tr key={session.sessionID}>
                  {session.device ? (
                    <td className="text-left">
                      {session?.device} <br />
                      <span>{session?.location}</span>
                    </td>
                  ) : (
                    <td className="text-left">No device found</td>
                  )}
                  <td className="">
                    {moment(session.lastusage).format("MMM Do, YY hh:mm:ss")}
                  </td>
                  <td className="">
                    <Tooltip title="Logout">
                      <Logout
                        fontSize="small"
                        onClick={() => handleLogoutClick(session)}
                      />
                    </Tooltip>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          <StyledPagination
            count={totalPage}
            page={page}
            variant="outlined"
            shape="rounded"
            onChange={handlePageChange}
          />
        </div>
      ) : (
        <p className="text-center">No session Found !</p>
      )}
    </div>
  );
};
