import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { HelpIcon, SearchIcon } from "../../../icons";
import "../../../assets/css/components/navigation-bar/_navigation-bar.css";
import ProfileMenu from "../../ui/profileMenu";
import { userAsCompany } from "../../../helper/constants";
import { IconButton, Tooltip } from "@mui/material";
import { NotificationMenu } from "../../ui/notificationMenu";
import { useDispatch } from "react-redux";
import { SEARCH_ACTIONS } from "../../../redux/search/actions";

export const NavigationBar = () => {
  const authToken = localStorage.getItem("token");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleLogoClick = () => {
    navigate("/dashboard");
  };

  const linkStyle = {
    anchor: {
      width: "52px",
      height: "52px",
      textAlign: "center",
    },
    button: {
      width: "100%",
      height: "100%",
    },
  };

  const openSearchCol = () => {
    dispatch({
      type: SEARCH_ACTIONS.SET_SEARCH_FLAG,
      payload: true,
    });
  };

  return (
    <div
      className={
        userAsCompany(window.location.hostname)
          ? "navbar company-active"
          : "navbar"
      }
    >
      {userAsCompany(window.location.hostname) ? (
        <div className="w-100 d-flex align-items-center"></div>
      ) : (
        <img
          src={require("../../../assets/images/logo_white.png")}
          alt="nav-logo"
          className="whitelogo_img pointer"
          onClick={() => handleLogoClick()}
        />
      )}
      {authToken ? (
        <div className="navbar_options">
          <div className="user_options">
            <span style={linkStyle.anchor}>
              <Tooltip title="Search">
                <IconButton
                  size="small"
                  style={linkStyle.button}
                  onClick={openSearchCol}
                >
                  <SearchIcon
                    fill={
                      userAsCompany(window.location.hostname)
                        ? "#3954A5"
                        : "#fff"
                    }
                    width="22"
                    height="22"
                    className="icon search-icon"
                  />
                </IconButton>
              </Tooltip>
            </span>

            <NotificationMenu />

            <Link to="/help" style={linkStyle.anchor} className="me-2">
              <Tooltip title="Help">
                <IconButton size="small" style={linkStyle.button}>
                  <HelpIcon
                    fill={
                      userAsCompany(window.location.hostname)
                        ? "#3954A5"
                        : "#fff"
                    }
                    width="22"
                    height="22"
                    className="icon search-icon"
                  />
                </IconButton>
              </Tooltip>
            </Link>

            <div
              className={
                userAsCompany(window.location.hostname)
                  ? "divider-line company-active"
                  : "divider-line"
              }
            ></div>
            <div className="profile">
              <ProfileMenu />
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default NavigationBar;
