import Checkbox from "@material-ui/core/Checkbox";
import { withStyles } from "@material-ui/core/styles";

export const CheckboxWithBlueCheck = withStyles({
  root: {
    "& .MuiSvgIcon-root": {
      fill: "white",
      fontSize: "1.1rem",
      "&:hover": {
        backgroundColor: "white",
      },
    },
    "&$checked": {
      "& .MuiIconButton-label": {
        position: "relative",
        zIndex: 0,
        border: "2px solid #3954A5",
        borderRadius: 3,
        left: 2,
      },
      "& .MuiIconButton-label:after": {
        content: '""',
        left: 3,
        top: 3,
        height: 10,
        width: 10,
        position: "absolute",
        backgroundColor: "#3954A5",
        zIndex: -1,
        borderColor: "transparent",
        "&:hover": {
          backgroundColor: "white",
        },
      },
    },
    "&:not($checked) .MuiIconButton-label": {
      position: "relative",
      zIndex: 0,
      border: "2px solid #9EA0A5",
      borderRadius: 3,
      left: 2,
    },
    "&:not($checked) .MuiIconButton-label:after": {
      content: '""',
      left: 3,
      top: 3,
      height: 10,
      width: 10,
      position: "absolute",
      backgroundColor: "white",
      zIndex: -1,
      borderColor: "transparent",
    },
  },
  checked: {},
})(Checkbox);
