import { createTheme } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import Loader from "../../../components/common/Loader";
import NoRecordsFound from "../../../components/common/NoRecordsFound";
import Api from "../../../helper/Api";
import { useSelector } from "react-redux";
import { ButtonUI } from "../../../components/ui/button";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import * as FileSaver from "file-saver";
import XLSX from "sheetjs-style";
import { ReportSortFilter } from "./reportSortFilter";
import { Link } from "react-router-dom";
import { IconButton, Tooltip } from "@mui/material";
import { indigo } from "@mui/material/colors";
import LocalPrintshopOutlinedIcon from "@mui/icons-material/LocalPrintshopOutlined";
import { ReportRangePicker } from "./reportRangePicker";
import { StyledEngineProvider } from "@mui/material/styles";
import jsPDF from "jspdf";

export const ApplicantSourceReport = () => {
  const [isLoader, setIsLoader] = useState(false);
  const [rows, setRows] = useState([]);
  const { companyData } = useSelector((state) => state.companyDataReducer);
  const [locationOptions, setLocationOptions] = useState([]);
  const [deptOptions, setDeptOptions] = useState([]);
  const [companyOptions, setCompanyOptions] = useState([]);
  const [totalPage, setTotalPage] = useState(1);
  const [pageNumber, setPage] = useState(1);

  const theme = createTheme({
    palette: {
      action: {
        hover: "#fff",
      },
    },
  });

  useEffect(() => {
    getAppSourceReport(0);
    getLocationOptions();
    getDepartmentOptions();
    getSubCompanies();
  }, []);

  const getLocationOptions = () => {
    Api("GET", `Facilities/GetAllFacilities?companyId=${companyData.companyId}`)
      .then((res) => {
        setLocationOptions(res.data.data);
      })
      .catch((err) => console.log(err));
  };

  const getSubCompanies = () => {
    Api(
      "GET",
      `companies/getcompany?pageLimit=${15}&parentCompanyId=${
        companyData.companyId
      }&companyHirerchy=${companyData.companyHirerchy}`
    )
      .then((response) => {
        response.data.data.length
          ? setCompanyOptions(response.data.data)
          : setCompanyOptions([]);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsLoader(false);
      });
  };

  // get 1 month data by default
  const getAppSourceReport = (
    flag = "",
    elemId = "",
    dateFrom = new Date().toISOString(),
    dateTo = new Date().toISOString(),
    dateDisplay = ""
  ) => {
    setIsLoader(true);
    const query = `companyId=${companyData.companyId}&flag=${flag}&searchBy=${elemId}&dateTo=${dateTo}&dateFrom=${dateFrom}&dateDisplay=${dateDisplay}`;
    Api("GET", `Report/ApplicantSourceReport?${query}`)
      .then((res) => {
        setRows(res.data);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsLoader(false);
      });
  };

  // flag : (1 for Date, 2 for Company, 3 for Facilities, 4 for Department)
  const handleDateFilters = (dateFrom, dateTo, dateDisplay) => {
    getAppSourceReport(1, "", dateFrom, dateTo, dateDisplay);
  };

  const getDepartmentOptions = () => {
    Api(
      "GET",
      `Department/GetDepartmentList?companyId=${companyData.companyId}&companyHirerchy=${companyData.companyHirerchy}`
    )
      .then((res) => {
        setDeptOptions(res.data);
      })
      .catch((err) => console.log(err));
  };

  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const exportToCSV = (apiData, fileName) => {
    const ws = XLSX.utils.json_to_sheet(apiData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  const exportPDF = () => {
    var doc = new jsPDF();

    doc.setFontSize(10);

    const columns = [
      { title: "Source", dataKey: "Source" },
      { title: "Applicants", dataKey: "Applicants" },
      { title: "Videos", dataKey: "Videos" },
      { title: "Interviews", dataKey: "Interviews" },
      { title: "Offers", dataKey: "Offers" },
      { title: "Hires", dataKey: "Hires" },
    ];

    doc.autoTable(columns, rows);
    doc.save("Applicant_Source_Report.pdf");
  };

  // pagination
  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const Row = rows?.map((val, key) => {
    return (
      <tr key={key}>
        <td className="td_name">{val['Source']}</td>
        <td>{val.Applicants}</td>
        <td>{val.Videos}</td>
        <td>{val.Interviews}</td>
        <td>{val.Offers}</td>
        <td>{val.Hires}</td>
      </tr>
    );
  });

  const handleSortFilter = (flag, elemId, dateTo, dateFrom, dateDisplay) => {
    if (flag === 1) {
      getAppSourceReport(flag, elemId, dateFrom, dateTo, dateDisplay);
    } else {
      getAppSourceReport(flag, elemId);
    }
  };

  const handleSort = () => {};

  return (
    <div>
      <div className="company_content">
        <div className="d-flex flex-column border-bottom">
          <div className="d-flex report-header">
            <div className="company_text">Applicant Source Report</div>
            {rows?.length > 0 && (
              <div className="d-flex align-items-center">
                <ReportSortFilter
                  locationOptions={locationOptions}
                  deptOptions={deptOptions}
                  companyOptions={companyOptions}
                  handleSortFilter={handleSortFilter}
                  handleDateFilters={handleDateFilters}
                />
                <Tooltip title="Download">
                  <IconButton
                    onClick={() => exportToCSV(rows, "Applicant_Source_Report")}
                  >
                    <FileDownloadOutlinedIcon sx={{ color: indigo[500] }} />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Print PDF">
                  <IconButton onClick={() => exportPDF()}>
                    <LocalPrintshopOutlinedIcon sx={{ color: indigo[500] }} />
                  </IconButton>
                </Tooltip>
              </div>
            )}
          </div>
          <div className="top-url">
            <p>
              <Link to="/reports">Reports &gt; </Link>
              <Link to="/reports/applicant-source">
                <strong>Applicant Source Report</strong>
              </Link>
            </p>
          </div>
        </div>

        {isLoader ? (
          <Loader inShow={true} />
        ) : rows?.length > 0 ? (
          <>
            <div className="company_table" style={{ marginTop: 0 }}>
              <table>
                <thead>
                  <tr className="report-titles">
                    <td className="th_name">
                      Source
                      <button
                        onClick={() => handleSort("source")}
                        className="icon icon_button"
                      >
                        <img
                          src={
                            require("../../../assets/images/tabsort.svg")
                              .default
                          }
                          alt="sort-icon"
                          className="icon sort_icon"
                        />
                      </button>
                    </td>
                    <td>Applicants</td>
                    <td>Videos</td>
                    <td>Interviews</td>
                    <td>Offers</td>
                    <td>Hires</td>
                  </tr>
                </thead>
                <tbody>{Row}</tbody>
              </table>
            </div>
            {/* <StyledPagination
              count={totalPage}
              page={pageNumber}
              variant="outlined"
              shape="rounded"
              onChange={handlePageChange}
            /> */}
          </>
        ) : (
          <NoRecordsFound />
        )}
      </div>
    </div>
  );
};
