import "../../../assets/css/components/notification/_notification.css";
import "../../../assets/css/components/applicants/_applicants.css";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useRef } from "react";
import { useState } from "react";
import Api from "../../../helper/Api";
import { useDispatch } from "react-redux";
import { APPLICANT_DATA_ACTIONS } from "../../../redux/applicantData/actions";
import { AddApplicantForm } from "../../../components/common/layouts/jobInfo/content/addApplicant";
import { toast } from "react-toastify";
import { ColoredLinearProgress } from "../../../components/common/coloredProgressBar";
import { ProfileCard } from "../../../components/common/cards/ProfileCard";
import { NoColDataFound } from "../../../components/common/NoColDataFound";
import { useSelector } from "react-redux";
import { ApplicantContent } from "../../../components/common/layouts/applicantInfo/content";
import InfiniteScroll from "react-infinite-scroll-component";
import { ListItemText } from "@mui/material";
import { EclipseLoader } from "../../../components/common/eclipseLoader";
import { Search } from "../../pages/search/search";
import { JobSidebar } from "../../../components/common/layouts/jobInfo/sidebar";

export function HotList() {
  const location = useLocation();
  const [hotList, setHotList] = useState([]);
  const [pageNumber, setPage] = useState(1);
  const [isLoader, setLoader] = useState(false);
  const [openSidebar, setSidebar] = useState(false);
  const [formValues, setFormValues] = useState({});
  const [ids, setIds] = useState({});
  const dispatch = useDispatch();
  const formData = new FormData();
  const heightRef = useRef(null);
  const [infLoader, setInfLoader] = useState(false);
  const { isSearchColOpen } = useSelector((state) => state.globalSearchReducer);
  const { jobsArray } = useSelector((state) => state.jobDataReducer);
  const { applicantsArray } = useSelector(
    (state) => state.applicantDataReducer
  );

  useEffect(() => {
    console.log(window.innerHeight);
    setLoader(true);
    getHotApplicants("updatedAt", "desc");
  }, []);

  useEffect(() => {
    getHotApplicants("updatedAt", "DESC");
  }, [pageNumber]);

  const getHotApplicants = (columnName, direction) => {
    Api(
      "GET",
      `Job/GetHotandHireApplicantList?companyId=${location.state.companyId}&Type=HotList&pageLimit=10&pageNumber=${pageNumber}`
    )
      .then((res) => {
        const allData = [...hotList, ...res.data.data];
        setHotList(allData);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setInfLoader(false);
        setLoader(false);
      });
  };

  const toggleDrawer = (anchor, open) => {
    setSidebar({ ...openSidebar, [anchor]: open });
  };

  const editApplicant = () => {
    formData.append("firstName", formValues?.firstName ?? "");
    formData.append("middleName", formValues?.middleName ?? "");
    formData.append("lastName", formValues?.lastName ?? "");
    formData.append("nickName", formValues?.nickName ?? "");
    formData.append("title", formValues?.title ?? "");
    formData.append("emailAddress", formValues?.emailAddress ?? "");
    formData.append("phoneNumber", formValues?.phoneNumber ?? "");
    formData.append("resume", formValues?.resume ?? "");
    formData.append("viewResume", formValues?.viewResume ?? "");
    formData.append("companyId", ids.companyId);
    formData.append("jobId", ids.jobId);
    formData.append("applicantId", ids.applicantId);
    formData.append(
      "address.addressLineOne",
      formValues?.address?.addressLineOne ?? ""
    );
    formData.append(
      "address.addressLineTwo",
      formValues?.address?.addressLineTwo ?? ""
    );
    formData.append("address.country", "");
    formData.append("address.city", formValues?.address?.city ?? "");
    formData.append("address.state", formValues?.address?.state ?? "");
    formData.append("address.zipCode", formValues?.address?.zipCode ?? "");
    formData.append("source", formValues?.source ?? "");

    Api("PUT", `Job/EditApplicant`, formData, "multipart")
      .then((res) => {
        if (res.data.isSuccess === true) {
          toast.success(res.data.message);
          toggleDrawer("right", false);
          getHotApplicants("updatedAt", "desc");
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const openApplicantDetails = (applicantData) => {
    if (applicantData.status === "NEW_APPLICATION") {
      Api(
        "PUT",
        `Job/UpdateCurrentStatus?companyId=${applicantData.companyId}&jobId=${applicantData.applicantList[0].jobId}&applicantId=${applicantData.applicantId}&status=REVIEWING`
      )
        .then((res) => {
          if (res.data.isSuccess === true) {
            applicantData.applicantList[0].jobCycleStatus = "REVIEWING";
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }

    const record = { ...applicantData };

    const isPresent = applicantsArray.some(
      (d) => d.applicantId === record.applicantId
    );

    if (isPresent === false) {
      const data = [record, ...applicantsArray];
      dispatch({
        type: APPLICANT_DATA_ACTIONS.SET_APPLICANTS_ARRAY,
        payload: data,
      });
    }
  };

  const fetchMore = () => {
    setInfLoader(true);
    setPage((prevState) => prevState + 1);
  };

  return (
    <div>
      {/* Edit Applicant form */}
      <AddApplicantForm
        openAddForm={openSidebar}
        toggleDrawer={toggleDrawer}
        handleSubmit={editApplicant}
        formValues={formValues}
        setFormValues={setFormValues}
      />

      <div className="company_content help-container applicants">
        <div className="common-container users company">
          <div className="inner-container company">
            <div className="user-container company">
              <div className="user-list">
                <div className="applicant-profile">
                  <div className="applicant-data">
                    <strong>Hot list</strong>
                  </div>
                </div>
                {isLoader === true ? (
                  <ColoredLinearProgress />
                ) : hotList.length > 0 ? (
                  <div className="inner-h applicant-list full">
                    <div>
                      <ul
                        className="job_template_option_menu pointer"
                        ref={heightRef}
                      >
                        <InfiniteScroll
                          height={
                            window.innerHeight <= 678
                              ? window.innerHeight * 0.666
                              : window.innerHeight * 0.722
                          }
                          dataLength={hotList?.length || 0}
                          next={fetchMore}
                          hasMore={hotList?.length !== hotList?.totalRecords}
                          loader={
                            infLoader && (
                              <h4 style={{ textAlign: "center" }}>
                                <EclipseLoader />
                              </h4>
                            )
                          }
                          endMessage={
                            <p style={{ textAlign: "center" }}>
                              <b>Yay! You have seen it all</b>
                            </p>
                          }
                        >
                          {hotList?.length === 0 && (
                            <ListItemText primary={"No Available Resource"} />
                          )}
                          {
                            <div>
                              {hotList?.length > 0 &&
                                hotList.map((applicant, index) => (
                                  <div key={index}>
                                    <ProfileCard
                                      applicantId={applicant.applicantId}
                                      applicantList={applicant.applicantJobList}
                                      name={
                                        applicant.firstName +
                                        " " +
                                        applicant.lastName
                                      }
                                      jobName={
                                        applicant.applicantJobList[0].jobName
                                      }
                                      jobId={
                                        applicant.applicantJobList[0].jobId
                                      }
                                      companyId={applicant.companyId}
                                      jobCode={
                                        applicant.applicantJobList[0].jobNo
                                      }
                                      isHotList={
                                        applicant.applicantJobList[0].isHotList
                                      }
                                      morePositions={applicant.morePositions}
                                      createdAt={applicant.createdAt}
                                      openApplicantDetails={
                                        openApplicantDetails
                                      }
                                      checked={applicant.checked}
                                      setAllChecked={true}
                                      status={
                                        applicant.applicantJobList[0]
                                          .jobHiredStatus
                                      }
                                      applicant={applicant}
                                    />
                                  </div>
                                ))}
                            </div>
                          }
                        </InfiniteScroll>
                      </ul>
                    </div>
                  </div>
                ) : (
                  <NoColDataFound />
                )}
              </div>

              {/* Global search column start */}
              {isSearchColOpen && <Search />}
              {/* Global search column end */}

              {jobsArray?.map((data) => (
                <JobSidebar key={data.jobId} jobData={data} />
              ))}

              {applicantsArray?.map((data, index) => (
                <ApplicantContent
                  data={data}
                  index={index}
                  key={data.applicantId}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
