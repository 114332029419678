export const ReportsIcon = ({...props}) => {
    return (
        <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path 
                d="M12 12V3" 
                stroke="#C7C7D2" 
                strokeWidth="2" 
                strokeLinecap="round" 
                strokeLinejoin="round"
            />
            <path 
                d="M19.794 7.5L4.20557 16.5" 
                stroke="#C7C7D2" 
                strokeWidth="2" 
                strokeLinecap="round" 
                strokeLinejoin="round"
            />
            <path 
                d="M3.15008 13.6449C2.76041 11.5361 3.13532 9.35748 4.20749 7.50024C5.27966 5.643 6.97883 4.22884 8.99988 3.51172V10.2675L3.15008 13.6449Z" 
                stroke="#C7C7D2" 
                strokeWidth="2" 
                strokeLinecap="round" 
                strokeLinejoin="round"
            />
            <path 
                d="M12.0002 3C13.577 3.00029 15.1261 3.41485 16.4923 4.20216C17.8585 4.98947 18.9938 6.1219 19.7847 7.48605C20.5755 8.8502 20.9941 10.3982 20.9985 11.975C21.0029 13.5518 20.5929 15.1021 19.8097 16.4706C19.0264 17.8391 17.8973 18.9779 16.5355 19.7728C15.1737 20.5676 13.627 20.9908 12.0502 20.9999C10.4734 21.0089 8.92191 20.6035 7.55108 19.8243C6.18024 19.0451 5.03818 17.9194 4.23926 16.56" 
                stroke="#C7C7D2" 
                strokeWidth="2" 
                strokeLinecap="round" 
                strokeLinejoin="round"
            />
        </svg>
    );
}
