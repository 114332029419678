import { useEffect, useState } from "react";
import Api from "../../../../../helper/Api";
import { sortAtoZ } from "../../../../../helper/constants";
import { AddEditCol } from "../../../addEditPopup/AddEditCol";
import { CheckboxList } from "../../CheckboxList";

export const SendApplicantForm = ({
  openSidebar,
  toggleDrawer,
  handleSendApp,
  applicantId,
  companyId,
  isSubmitting,
  formErrors,
}) => {
  const hasSelectAll = false;
  const [allData, setAllData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    Api("GET", `Job/GetSendApplicanttoUsersList?companyId=${companyId}`)
      .then((res) => {
        const response = res.data.map((d) => {
          return {
            ...d,
            name: d.userFirstName + " " + d.userLastName,
            id: d.userId,
          };
        });

        setAllData(sortAtoZ(response));
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    handleSendApp(allData);
  };

  const handleChange = (checkName, isChecked) => {
    const checkList = allData.map((data) => {
      if (data.id === checkName) {
        data.checked = isChecked;
      }
      return data;
    });

    setAllData(checkList);
  };

  return (
    <AddEditCol
      applicantId={applicantId}
      openForm={openSidebar}
      title="Send Applicant Form"
      toggleForm={toggleDrawer}
      handleSubmit={handleSubmit}
      isSubmitting={isSubmitting}
      isLoading={loading}
    >
      <div className="forms-input">
        <span className="error_msg">{formErrors?.users}</span>
      </div>
      <CheckboxList
        allData={allData}
        setAllData={setAllData}
        handleSubmit={handleSubmit}
        hasSelectAll={hasSelectAll}
        showSaveContainer={false}
        handleChange={handleChange}
      />
    </AddEditCol>
  );
};
