export const NoColDataFound = () => {
  return (
    <div className="common-container">
      <div className="inner-h job-sidebar no-data">
        <img
          src={require("../../assets/images/no-data.png")}
        />
        <span>No data found!</span>
      </div>
    </div>
  );
};
