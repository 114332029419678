import { useEffect, useState, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import "../../../assets/css/components/company-detail/_job.css";
import { AddEditJob } from "../../common/addEditPopup/AddEditJob";
import Api from "../../../helper/Api";
import { JOB_DATA_ACTIONS } from "../../../redux/jobData/actions";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { IconButton, Menu, MenuItem, Tooltip } from "@mui/material";
import { JCCard } from "../../common/cards/JCCard";
import SyncIcon from "@mui/icons-material/Sync";
import { indigo } from "@mui/material/colors";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import SearchInCol from "../../ui/searchInCol";
import { NoColDataFound } from "../../common/NoColDataFound";
import { useSelector } from "react-redux";
import { ApplicantContent } from "../../common/layouts/applicantInfo/content";
import {
  initialJobsFilters,
  skeletonBaseColor,
  skeletonBorderRadius,
  skeletonHighLightColor,
  sortAtoZ,
} from "../../../helper/constants";
import { JobSidebar } from "../../common/layouts/jobInfo/sidebar";
import { JobsSortFilter } from "../../common/jobsSortFilter";
import { ViewportList } from "react-viewport-list";
import Repeat, { getDays, getFilteredByStatus } from "../../../helper/utils";
import { useDebounce } from "../../../hooks/useDebounce";
import { Search } from "../../../views/pages/search/search";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { VideoInterviewColumn } from "../../common/layouts/applicantInfo/content/applicantJobData/VideoInterview/videoInterviewColumn";
import { ConnectSequence } from "../../common/layouts/applicantInfo/content/connectSequence";
import { EditJobTemplate } from "../jobTemplates/editJobTemplate";
import { validations } from "../../../helper/validations";

export const Jobs = ({ type = "DEFAULT" }) => {
  const ref = useRef(null);
  const [searchInput, setSearchInput] = useState("");
  const [formValues, setFormValues] = useState({});
  const [openSidebar, setSidebar] = useState(false);
  const [openJobTemplateSidebar, setJobTemplateSidebar] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [statusOptions, setStatusOptions] = useState([]);
  const [priorityOptions, setPriorityOptions] = useState([]);
  const [facilityOptions, setFacilityOptions] = useState([]);
  const [companyOptions, setCompanyOptions] = useState([]);
  const [deptOptions, setDeptOptions] = useState([]);
  const [jobFormLabel, setLabel] = useState("Add Job");
  const [editMode, setEditMode] = useState(false);
  const [isLoader, setIsLoader] = useState(false);
  const location = useLocation();
  const dispatch = useDispatch();
  const [searchOpen, setSearchOpen] = useState(false);
  const [isSubmitting, setSubmitting] = useState(false);
  const [appliedFilters, setAppliedFilters] = useState(initialJobsFilters);
  const debouncedSearchTerm = useDebounce(searchInput, 500);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const { jobsArray, isRefresh, allJobs } = useSelector(
    (state) => state.jobDataReducer
  );
  const { applicantsArray } = useSelector(
    (state) => state.applicantDataReducer
  );
  const { videosArray } = useSelector(
    (state) => state.videoInterviewDataReducer
  );
  const { seqArray } = useSelector((state) => state.sequenceReducer);
  const { isSearchColOpen } = useSelector((state) => state.globalSearchReducer);
  const { companyData } = useSelector((state) => state.companyDataReducer);
  const searchJobs = [];
  const [allJobsFlat, setAllJobsFlat] = useState([]);
  const displayJobs = {
    NOT_STARTED: [],
    IN_PROGRESS: [],
    COMPLETE: [],
    BLANK: [],
  };
  const errors = {};

  const {
    filledJobs,
    jobsList,
    allJobsList,
    cancelledJobs,
    activeJobs,
    hiringJobs,
    holdJobs,
    pendingJobs,
  } = allJobs;

  useEffect(() => {
    if (isRefresh) {
      getActiveJobs();
      dispatch({ type: JOB_DATA_ACTIONS.SET_REFRESH });
    }
  }, [isRefresh]);

  useEffect(() => {
    getJobStatus();
    getFacilities();
    getDepartments();
    getActiveJobs();
    getSubCompanyList();
  }, []);

  useEffect(() => {
    if (!searchOpen && debouncedSearchTerm) {
      setSearchInput("");
    }
    handleSortFilter(appliedFilters);
  }, [searchOpen, debouncedSearchTerm]);

  useEffect(() => {
    if (appliedFilters !== initialJobsFilters || debouncedSearchTerm) {
      handleSortFilter(appliedFilters);
    }
  }, [allJobsList]);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const getActiveJobs = async () => {
    setIsLoader(true);
    await Api(
      "GET",
      `Job/GetJobColumnList?companyId=${
        type === "COMPANY" ? companyData?.companyId : location?.state?.companyId
      }&jobStatus=ACTIVE`
    )
      .then((res) => {
        const activeJob = [...res.data.data];
        searchJobs.push(activeJob);

        dispatch({
          type: JOB_DATA_ACTIONS.SET_JOBS,
          payload: { name: "activeJobs", value: activeJob },
        });

        displayJobs.NOT_STARTED.push(
          sortAtoZ(
            activeJob.filter((job) => job?.jobProgressStatus === "NOT_STARTED")
          )
        );
        displayJobs.IN_PROGRESS.push(
          sortAtoZ(
            activeJob.filter((job) => job?.jobProgressStatus === "IN_PROGRESS")
          )
        );
        displayJobs.COMPLETE.push(
          sortAtoZ(
            activeJob.filter((job) => job?.jobProgressStatus === "COMPLETE")
          )
        );
        displayJobs.BLANK.push(
          sortAtoZ(activeJob.filter((job) => job?.jobProgressStatus === ""))
        );

        dispatch({
          type: JOB_DATA_ACTIONS.SET_ALL_JOBS,
          payload: Object.values(displayJobs).flat(2),
        });
        getHiringJobs();
      })
      .finally(() => {
        setIsLoader(false);
      });
  };

  const getHiringJobs = async () => {
    await Api(
      "GET",
      `Job/GetJobColumnList?companyId=${
        type === "COMPANY" ? companyData?.companyId : location?.state?.companyId
      }&jobStatus=HIRING`
    )
      .then((res) => {
        const hiringJob = [...res.data.data];
        searchJobs.push(hiringJob);

        dispatch({
          type: JOB_DATA_ACTIONS.SET_JOBS,
          payload: { name: "hiringJobs", value: hiringJob },
        });

        displayJobs.NOT_STARTED.push(
          sortAtoZ(
            hiringJob.filter((job) => job?.jobProgressStatus === "NOT_STARTED")
          )
        );
        displayJobs.IN_PROGRESS.push(
          sortAtoZ(
            hiringJob.filter((job) => job?.jobProgressStatus === "IN_PROGRESS")
          )
        );
        displayJobs.COMPLETE.push(
          sortAtoZ(
            hiringJob.filter((job) => job?.jobProgressStatus === "COMPLETE")
          )
        );
        displayJobs.BLANK.push(
          sortAtoZ(hiringJob.filter((job) => job?.jobProgressStatus === ""))
        );

        dispatch({
          type: JOB_DATA_ACTIONS.SET_ALL_JOBS,
          payload: Object.values(displayJobs).flat(2),
        });
        getHoldJobs();
      })
      .finally(() => {
        setIsLoader(false);
      });
  };

  const getPendingJobs = async () => {
    await Api(
      "GET",
      `Job/GetJobColumnList?companyId=${
        type === "COMPANY" ? companyData?.companyId : location?.state?.companyId
      }&jobStatus=PENDING`
    )
      .then((res) => {
        const pendingJob = [...res.data.data];
        searchJobs.push(pendingJob);

        dispatch({
          type: JOB_DATA_ACTIONS.SET_JOBS,
          payload: { name: "pendingJobs", value: pendingJob },
        });

        getFilledJobs();
      })
      .finally(() => {
        setIsLoader(false);
      });
  };

  const getFilledJobs = async () => {
    await Api(
      "GET",
      `Job/GetJobColumnList?companyId=${
        type === "COMPANY" ? companyData?.companyId : location?.state?.companyId
      }&jobStatus=FILLED`
    )
      .then((res) => {
        const fillJobs = [...res.data.data];
        searchJobs.push(fillJobs);

        dispatch({
          type: JOB_DATA_ACTIONS.SET_JOBS,
          payload: {
            name: "filledJobs",
            value: [...fillJobs],
          },
        });

        dispatch({
          type: JOB_DATA_ACTIONS.SET_ALL_JOBS,
          payload: Object.values(displayJobs).flat(2),
        });
        getCancelledJobs();
      })
      .finally(() => {
        setIsLoader(false);
      });
  };

  const getDepartments = () => {
    Api(
      "GET",
      `Department/GetDepartmentList?companyId=${
        type === "COMPANY" ? companyData?.companyId : location?.state?.companyId
      }&companyHirerchy=${
        type === "COMPANY"
          ? companyData?.companyHirerchy
          : location?.state?.companyHirerchy
      }`
    ).then((res) => {
      setDeptOptions(sortAtoZ(res?.data));
    });
  };

  const getSubCompanyList = () => {
    Api(
      "GET",
      `companies/getcompany?companyId=${
        type === "COMPANY" ? companyData?.companyId : location?.state?.companyId
      }&OrderbyDirection=desc`
    ).then((res) => {
      setCompanyOptions(res.data.data);
    });
  };

  const getHoldJobs = async () => {
    await Api(
      "GET",
      `Job/GetJobColumnList?companyId=${
        type === "COMPANY" ? companyData?.companyId : location?.state?.companyId
      }&jobStatus=ON_HOLD`
    )
      .then((res) => {
        const onHoldJob = [...res.data.data];
        searchJobs.push(onHoldJob);

        dispatch({
          type: JOB_DATA_ACTIONS.SET_JOBS,
          payload: { name: "holdJobs", value: onHoldJob },
        });

        dispatch({
          type: JOB_DATA_ACTIONS.SET_ALL_JOBS,
          payload: Object.values(displayJobs).flat(2),
        });
        getPendingJobs();
      })
      .finally(() => {
        setIsLoader(false);
      });
  };

  const getCancelledJobs = async () => {
    await Api(
      "GET",
      `Job/GetJobColumnList?companyId=${
        type === "COMPANY" ? companyData?.companyId : location?.state?.companyId
      }&jobStatus=CANCELLED`
    )
      .then((res) => {
        const cancelJobs = [...res.data.data];
        const updatedCancelJobs = getFilteredByStatus(cancelJobs);

        searchJobs.push(cancelJobs);

        setAllJobsFlat(searchJobs.flat(1));
        dispatch({
          type: JOB_DATA_ACTIONS.SET_JOBS,
          payload: {
            name: "cancelledJobs",
            value: [...cancelJobs],
          },
        });

        const data = [];
        data.push(
          displayJobs["NOT_STARTED"]
            .flat(2)
            .sort((a, b) => {
              const priorityOrder = { HIGH: 3, MEDIUM: 2, NORMAL: 1 };
              return priorityOrder[b.priority] - priorityOrder[a.priority];
            })
            .sort((a, b) => {
              return new Date(b.createdAt) - new Date(a.createdAt);
            })
        );

        data.push(
          displayJobs["IN_PROGRESS"]
            .flat(2)
            .sort((a, b) => {
              const priorityOrder = { HIGH: 3, MEDIUM: 2, NORMAL: 1 };
              return priorityOrder[b.priority] - priorityOrder[a.priority];
            })
            .sort((a, b) => {
              return new Date(b.createdAt) - new Date(a.createdAt);
            })
        );

        data.push(
          displayJobs["COMPLETE"]
            .flat(2)
            .sort((a, b) => {
              const priorityOrder = { HIGH: 3, MEDIUM: 2, NORMAL: 1 };
              return priorityOrder[b.priority] - priorityOrder[a.priority];
            })
            .sort((a, b) => {
              return new Date(b.createdAt) - new Date(a.createdAt);
            })
        );

        data.push(
          displayJobs["BLANK"]
            .flat(2)
            .sort((a, b) => {
              const priorityOrder = { HIGH: 3, MEDIUM: 2, NORMAL: 1 };
              return priorityOrder[b.priority] - priorityOrder[a.priority];
            })
            .sort((a, b) => {
              return new Date(b.createdAt) - new Date(a.createdAt);
            })
        );

        dispatch({
          type: JOB_DATA_ACTIONS.SET_ALL_JOBS,
          payload: data.flat(1),
        });
      })
      .finally(() => {
        setIsLoader(false);
      });
  };

  const getJobStatus = () => {
    Api("GET", `Job/GetPriorityAndStatus`).then((res) => {
      const groupByCategory = res.data.reduce((group, status) => {
        const { type } = status;
        group[type] = group[type] ?? [];
        group[type].push(status);
        return group;
      }, {});

      const sData = sortAtoZ(groupByCategory.STATUS);
      setStatusOptions(sData);
      groupByCategory.STATUS = [...sData];

      const pData = sortAtoZ(groupByCategory.PRIORITY);
      setPriorityOptions(pData);

      dispatch({
        type: JOB_DATA_ACTIONS.SET_ALL_JOB_STATUS,
        payload: groupByCategory,
      });
    });
  };

  const getFacilities = () => {
    Api(
      "GET",
      `Facilities/GetFacilitiesList?companyId=${
        type === "COMPANY" ? companyData?.companyId : location?.state?.companyId
      }&companyHirerchy=${
        type === "COMPANY"
          ? companyData?.companyHirerchy
          : location?.state?.companyHirerchy
      }`
    ).then((res) => {
      const fData = sortAtoZ(res.data.data, "address");
      setFacilityOptions(fData);
    });
  };

  const toggleDrawer = (anchor, open) => {
    setSidebar({ ...openSidebar, [anchor]: open });
  };

  const handleSubmit = (editor) => {
    setSubmitting(true);
    formValues.positions = formValues.positions ? formValues.positions : 0;
    formValues.description = editor;
    if (editMode === true) {
      // Edit job
      Api("PUT", "Job/EditJob", formValues)
        .then((res) => {
          if (res.data.isSuccess === true) {
            toast.success(res.data.message);
            toggleDrawer("right", false);
            setEditMode(false);
            getActiveJobs();
          } else {
            toast.error(res.data.message);
          }
        })
        .finally(() => {
          setSubmitting(false);
        });
    } else {
      // Add job
      Api("POST", `Job/AddJob`, formValues)
        .then((res) => {
          if (res.data.isSuccess === true) {
            toast.success(res.data.message);
            toggleDrawer("right", false);
            setIsLoader(true);
            getActiveJobs();
          } else {
            toast.error(res.data.message);
          }
        })
        .finally(() => {
          setSubmitting(false);
        });
    }
  };

  const cardClickEvent = (job) => {
    const isPresent = jobsArray.some((a) => {
      return a.jobId === job.jobId;
    });

    if (!isPresent) {
      const data = [job, ...jobsArray];
      dispatch({
        type: JOB_DATA_ACTIONS.SET_JOBS_ARRAY,
        payload: data,
      });
    }
  };

  const handleSortFilter = async ({
    status = null,
    facility = null,
    priorityId = null,
    depId = null,
    sort = null,
    companyId = null,
  }) => {
    let filteredJobs = [...allJobsList];
    const JOBSTYPE = {
      ACTIVE: activeJobs,
      HIRING: hiringJobs,
      PENDING: pendingJobs,
      ON_HOLD: holdJobs,
      FILLED: filledJobs,
      CANCELLED: cancelledJobs,
    };

    if (status) {
      filteredJobs = [...JOBSTYPE[status]];
    }

    if (facility) {
      const facilityFiltered = filteredJobs.filter((el) => {
        return el.facilityId === facility;
      });
      filteredJobs = [...facilityFiltered];
    }

    if (priorityId) {
      const priorityFiltered = filteredJobs.filter((el) => {
        return el.priority === priorityId;
      });
      filteredJobs = [...priorityFiltered];
    }

    if (depId) {
      const deptFitered = filteredJobs.filter((el) => {
        return el.departmentId === depId;
      });
      filteredJobs = [...deptFitered];
    }

    if (companyId) {
      const companyFiltered = filteredJobs.filter((el) => {
        return el.companyId === companyId;
      });
      filteredJobs = [...companyFiltered];
    }

    if (!!debouncedSearchTerm) {
      const data = [...allJobsFlat];
      const searchedFiltered = data?.filter((d) => {
        return (
          d.name.toLowerCase().includes(debouncedSearchTerm.toLowerCase()) ||
          d.jobNo.toLowerCase().includes(debouncedSearchTerm.toLowerCase())
        );
      });
      filteredJobs = [...searchedFiltered];
    }

    if (sort === "days") {
      const jobsDays = filteredJobs.sort(
        (a, b) => getDays(a.createdAt) - getDays(b.createdAt)
      );
      filteredJobs = [...jobsDays];
    }
    if (sort === "date") {
      const jobsDate = filteredJobs.sort(
        (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
      );
      filteredJobs = [...jobsDate];
    }
    dispatch({
      type: JOB_DATA_ACTIONS.SET_JOBS,
      payload: { name: "jobsList", value: [...filteredJobs] },
    });
  };

  const handleRefresh = () => {
    setAppliedFilters(initialJobsFilters);
    getActiveJobs();
  };

  const editAction = (jobId) => {
    setEditMode(true);
    Api("GET", `JOb/GetJobById?jobId=${jobId}`).then((res) => {
      setFormValues(res.data);
      setLabel("Edit Job");
      toggleDrawer("right", true);
    });
  };

  const openAddJob = () => {
    toggleDrawer("right", true);
    setFormValues({
      positions: 1,
      priority: "HIGH",
      jobStatus: "ACTIVE",
    });
    setEditMode(false);
  };

  const JobCardSkeleton = () => {
    return (
      <Repeat numTimes={6}>
        {(index) => (
          <div className="col-job" key={index}>
            <div className="jc-card-header">
              <span>
                <Skeleton count={1} height={18} width={70} />
              </span>
              <div className="w-50 justify-content-between">
                <Skeleton count={1} height={18} width={60} />
                <Skeleton count={1} height={18} width={60} />
                <Skeleton count={1} height={18} width={30} className="mr-10" />
              </div>
            </div>
            <div className="jc-card-content">
              <div className="show-more">
                <Skeleton count={1} height={50} width={300} />
              </div>
              <div className="job-posted">
                <Skeleton count={1} height={18} width={70} />
                <Skeleton count={1} height={18} width={70} />
              </div>
            </div>
          </div>
        )}
      </Repeat>
    );
  };

  const handleAddButton = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const openAddJobTemplate = () => {
    setFormValues([]);
    toggleTemplateDrawer("right", true);
    setFormErrors({});
    setEditMode(false);
  };

  const toggleTemplateDrawer = (anchor, open) => {
    setJobTemplateSidebar({ ...openJobTemplateSidebar, [anchor]: open });
  };

  const validateTemplate = (values) => {
    if (!values.companyId) {
      errors.companyId = validations?.companyId?.req;
    }

    if (!values.name) {
      errors.name = validations?.name?.req;
    }

    if (!values.description) {
      errors.description = validations?.description?.req;
    }

    return errors;
  };

  const saveJobTemplate = (e) => {
    e.preventDefault();
    setFormErrors(validateTemplate(formValues));
    if (Object.keys(validateTemplate(formValues)).length > 0) {
      return;
    }
    var params = {};

    setSubmitting(true);
    params = { ...formValues };
    params.rate = formValues.rate ? +formValues.rate : 0;

    Api("POST", `JobTemplate/AddJobTemplate`, params)
      .then((res) => {
        if (res.data.isSuccess === true) {
          toast.success(res.data.message);
          toggleTemplateDrawer("right", false);
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error(err);
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <div>
      <EditJobTemplate
        openSidebar={openJobTemplateSidebar}
        toggleDrawer={toggleTemplateDrawer}
        formValues={formValues}
        setFormValues={setFormValues}
        label={"Add Job Template"}
        isSubmitting={isSubmitting}
        formErrors={formErrors}
        setFormErrors={setFormErrors}
        saveJobTemplate={saveJobTemplate}
      />

      {openSidebar && (
        <AddEditJob
          title={jobFormLabel}
          openSidebar={openSidebar}
          toggleDrawer={toggleDrawer}
          formValues={formValues}
          setFormValues={setFormValues}
          handleSubmit={handleSubmit}
          isSubmitting={isSubmitting}
        />
      )}

      <div
        className={
          type === "COMPANY" && "company_content help-container applicants"
        }
      >
        {type === "COMPANY" && (
          <div className="top-url">
            <p>
              <strong>Jobs</strong>
            </p>
          </div>
        )}

        <div
          className={
            type === "DEFAULT"
              ? "common-container users"
              : "common-container users company"
          }
        >
          <div
            className={
              type === "DEFAULT" ? "inner-container" : "inner-container company"
            }
          >
            {type === "DEFAULT" && (
              <div className="top-url">
                <div>
                  <p>
                    <Link to="/companylist"> Companies &gt; </Link>
                    <strong>Jobs</strong>
                  </p>
                </div>
              </div>
            )}

            <div
              className={
                type === "COMPANY" ? "user-container company" : "user-container"
              }
            >
              <div className="user-list job-h">
                <div className="job-header">
                  <strong
                    style={{
                      paddingRight: searchOpen && 2,
                    }}
                  >
                    Jobs
                  </strong>
                  <JobsSortFilter
                    statusOptions={statusOptions}
                    facilityOptions={facilityOptions}
                    priorityOptions={priorityOptions}
                    deptOptions={deptOptions}
                    companyOptions={companyOptions}
                    handleSortFilter={handleSortFilter}
                    appliedFilters={appliedFilters}
                    setAppliedFilters={setAppliedFilters}
                  />
                  {searchOpen === false && (
                    <Tooltip placement="top" title="Refresh">
                      <IconButton
                        size="small"
                        style={{ minWidth: "20px" }}
                        onClick={handleRefresh}
                      >
                        <SyncIcon sx={{ color: indigo[500] }} />
                      </IconButton>
                    </Tooltip>
                  )}

                  <SearchInCol
                    searchOpen={searchOpen}
                    searchInput={searchInput}
                    setSearchInput={setSearchInput}
                    setSearchOpen={setSearchOpen}
                  />

                  <Tooltip placement="top" title="Add Job">
                    <IconButton
                      aria-label="more"
                      id="long-button"
                      aria-controls={open ? "long-menu" : undefined}
                      aria-expanded={open ? "true" : undefined}
                      aria-haspopup="true"
                      size="small"
                      style={{ minWidth: "20px" }}
                      onClick={handleAddButton}
                    >
                      <AddCircleOutlineOutlinedIcon
                        sx={{ color: indigo[500] }}
                      />
                    </IconButton>
                  </Tooltip>
                  <Menu
                    id="long-menu"
                    MenuListProps={{
                      "aria-labelledby": "long-button",
                    }}
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    PaperProps={{
                      style: {
                        width: "20ch",
                      },
                    }}
                  >
                    <MenuItem
                      key="new_job"
                      selected={"add_job" === "Pyxis"}
                      onClick={() => {
                        openAddJob();
                        handleClose();
                      }}
                    >
                      New Job
                    </MenuItem>
                    <MenuItem
                      key="new_job_template"
                      selected={"new_job" === "Pyxis"}
                      onClick={() => {
                        openAddJobTemplate();
                        handleClose();
                      }}
                    >
                      New Job Template
                    </MenuItem>
                  </Menu>
                </div>
                {isLoader ? (
                  <div className="job_template_option_menu pointer">
                    <SkeletonTheme
                      borderRadius={skeletonBorderRadius}
                      baseColor={skeletonBaseColor}
                      highlightColor={skeletonHighLightColor}
                    >
                      <JobCardSkeleton />
                    </SkeletonTheme>
                  </div>
                ) : jobsList && jobsList.length > 0 ? (
                  <div ref={ref} className="job_template_option_menu pointer">
                    <ViewportList
                      viewportRef={ref}
                      items={jobsList}
                      itemMinSize={40}
                    >
                      {(item) => (
                        <div key={item.jobId}>
                          <JCCard
                            jobCode={item.jobNo}
                            name={item.name}
                            description={item.description}
                            jobStatus={item.jobStatus}
                            priority={item.priority}
                            positions={item.positions}
                            jobId={item.jobId}
                            createdAt={item.createdAt}
                            jobProgressStatus={item?.jobProgressStatus}
                            jobProgressStatusDescription={
                              item?.jobProgressStatusDescription
                            }
                            companyId={
                              type === "COMAPNY"
                                ? companyData?.companyId
                                : location?.state?.companyId
                            }
                            cardClickEvent={() => cardClickEvent(item)}
                            editAction={editAction}
                            countData={item.countData}
                          />
                        </div>
                      )}
                    </ViewportList>
                  </div>
                ) : (
                  <NoColDataFound />
                )}
              </div>
              {isSearchColOpen && <Search />}

              {(jobsArray ?? []).map((data) => (
                <JobSidebar key={data.jobId} jobData={data} />
              ))}

              {(applicantsArray ?? []).map((data) => (
                <ApplicantContent key={data.applicantId} data={data} />
              ))}

              {videosArray?.map((interview) => (
                <VideoInterviewColumn
                  key={interview.jobApplicantVideoInterviewId}
                  interview={interview}
                />
              ))}

              {(seqArray ?? []).map((data) => (
                <ConnectSequence key={data.applicantId} data={data} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
