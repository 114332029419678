import * as React from "react";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";

export const DateSelector = (props) => {
  const [open, setOpen] = React.useState(false);
  const handleChange = (val) => {
    if (!!val && !isNaN(val.getTime())) {
      props.handleDateChange(val);
      setOpen(false);
    }
  };

  const onKeyDown = (e) => {
    e.preventDefault();
    setOpen(true);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Stack spacing={3}>
        <DesktopDatePicker
          inputFormat="MM/dd/yyyy"
          value={props.value}
          minDate={props.minDate}
          onChange={handleChange}
          renderInput={(params) => (
            <TextField
              {...params}
              error={false}
              onClick={() => setOpen(true)}
              onKeyDown={onKeyDown}
              size="small"
            />
          )}
          disablePast={props.disablePast}
          open={open}
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
          {...props}
        />
      </Stack>
    </LocalizationProvider>
  );
};
