import { AddEditPopup } from "../../../addEditPopup";
import { ApplyForJob } from "../../ApplyForJob";
import { useEffect, useState } from "react";
import { validations } from "../../../../../helper/validations";
import { ButtonUI } from "../../../../ui/button";
import { toast } from "react-toastify";
import Api from "../../../../../helper/Api";
import { APPLICANT_DATA_ACTIONS } from "../../../../../redux/applicantData/actions";
import { useDispatch } from "react-redux";

export const AddApplicantForm = ({
  toggleDrawer,
  formValues,
  setFormValues,
  handleAddApplicant,
  label,
  openAddForm,
  isSubmitting,
  askForConfirmation,
  confirmData,
  name,
  jobId,
}) => {
  const [formErrors, setFormErrors] = useState({});
  const errors = {};
  const dispatch = useDispatch();

  useEffect(() => {
    setFormErrors({});
  }, [openAddForm]);

  const validate = (values) => {
    // if (values.phoneNumber && values.phoneNumber.length != 11) {
    //   errors.phoneNumber = validations?.phoneNumber.invalid;
    // }

    if (values?.applicantPhoneList && values?.applicantPhoneList.length > 0) {
      let data = [...values.applicantPhoneList];
      data.map((rec) => {
        if (!rec.phone) {
          errors.phoneNumber = validations?.phoneNumber.req;
        } else if (rec.phone.length != 11) {
          errors.phoneNumber = validations?.phoneNumber.invalid;
        }
      });
    }

    if (!values.firstName) {
      errors.firstName = validations?.fname?.req;
    }

    if (!values.emailAddress && !values.phoneNumber) {
      errors.emailAddress = "Please add at least one contact detail!";
    }

    if (
      values.emailAddress &&
      !values.emailAddress.match(validations?.email?.pattern.value)
    ) {
      errors.emailAddress = validations?.email?.pattern?.message;
    }

    if (!values.lastName) {
      errors.lastName = validations?.lname?.req;
    }

    return errors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    setFormErrors(validate(formValues));
    if (Object.keys(validate(formValues)).length > 0) {
      return;
    }

    handleAddApplicant();
  };

  const selectApplicant = (applicant) => {
    const params = {
      companyId: applicant.companyId,
      applicantId: applicant.applicantId,
      jobId: jobId,
    };

    Api("POST", "Job/ApplyNewJob", params)
      .then((res) => {
        if (res.data.isSuccess) {
          dispatch({ type: APPLICANT_DATA_ACTIONS.SET_APPLICANTS_REFRESH });
        }
        toast.success(res.data.message);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <AddEditPopup
      title={label ? label : "New Applicant"}
      toggleDrawer={toggleDrawer}
      handleSubmit={handleSubmit}
      openSidebar={openAddForm}
      type="jobTemplate"
      isSubmitting={isSubmitting}
    >
      {askForConfirmation == 0 ? (
        <ApplyForJob
          formValues={formValues}
          setFormValues={setFormValues}
          formErrors={formErrors}
          setFormErrors={setFormErrors}
          isEdit={label ? true : false}
        />
      ) : (
        <div className="confirm-div">
          <span>
            <strong>{name} </strong>
            May already exist!
          </span>
          <span>Contacts</span>

          {confirmData?.map((applicant) => (
            <div>
              <div className="contact-section">
                <div className="d-flex justify-content-between w-100 align-items-center">
                  <strong>{applicant?.emailAddress}</strong>
                  <ButtonUI
                    p="6px"
                    onClick={() => (
                      selectApplicant(applicant), toggleDrawer("right", false)
                    )}
                  >
                    Select
                  </ButtonUI>
                </div>
                <span>{applicant?.phoneNumber}</span>
                <span>
                  {applicant?.firstName} {applicant?.lastName}
                </span>
                <span>
                  {applicant?.name} {"(" + applicant?.jobNo + ")"}
                </span>
              </div>
            </div>
          ))}
          <span className="mt-10">
            If the correct <strong>{name}</strong> is not listed above, Create
            New.
          </span>
        </div>
      )}
    </AddEditPopup>
  );
};
