import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Api from "../../../../../../../helper/Api";
import "../../../../../../../assets/css/public/_docPack.css";
import { ButtonUI } from "../../../../../../ui/button";
import { IconButton, Tooltip } from "@mui/material";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import { green, indigo } from "@mui/material/colors";
import fileDownload from "js-file-download";
import axios from "axios";
import { useRef } from "react";
import { toast } from "react-toastify";

const imageExt = ["jpg", "jpeg", "png", "svg"];

export const ViewDocPack = () => {
  const location = useLocation();
  const [message, setMessage] = useState("");
  const [formValues, setFormValues] = useState({});
  const [documentList, setDocumentList] = useState([]);
  const [isLoggedIn, setLoggedIn] = useState(false);
  const [uploadDocs, setUploadDocs] = useState([]);
  const [downloadDocs, setDownloadDocs] = useState([]);
  const [isLoader, setLoader] = useState(false);
  const [isPreHireDocList, setIsPreHireDocList] = useState(false);
  const [isOnboardingDocList, setIsOnboardingDocList] = useState(false);
  const docRef = useRef(null);
  const formData = new FormData();
  const preHireDocumentPackId = location.pathname.substring(
    location.pathname.lastIndexOf("/") + 1
  );

  useEffect(() => {
    Api("GET", `Job/checkDocumentPasswordExists/${preHireDocumentPackId}`).then(
      (res) => {
        if (res.data.isSuccess === true) {
          setMessage(res.data.message);
        }
      }
    );
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      if (isLoggedIn === true) {
        getDocumentList();
      }
    }, 4500);

    return () => {
      clearInterval(interval);
    };
  }, [isLoggedIn]);

  const getDocumentList = () => {
    Api(
      "GET",
      `Job/GetUpdatedDocumentList?preHireOrOnboardingId=${preHireDocumentPackId}`
    ).then((res) => {
      setDocumentList(res.data.data.doc);
      setIsPreHireDocList(res.data.data.isPreHireDocList);
      setIsOnboardingDocList(res.data.data.isOnboardingDocList);
      const upload = [];
      const download = [];
      const documents = [...res.data.data.doc];
      documents.forEach((rec) => {
        if (rec.documentUrl === "") {
          upload.push(rec);
        } else {
          download.push(rec);
        }
      });
      setUploadDocs(upload);
      setDownloadDocs(download);
    });
  };

  // Create password if not saved
  const savePassword = () => {
    const params = {
      packId: preHireDocumentPackId,
      password: formValues.password,
      confirmPassword: formValues.confirmPassword,
      flag: message,
    };

    setLoader(true);
    Api("POST", `Job/setDocumentPackPassword`, params)
      .then((res) => {
        if (res.data.isSuccess === true) {
          setDocumentList(res.data.data.doc);
          res.data.data.doc.length > 0 ? setLoggedIn(true) : setLoggedIn(false);
          setIsPreHireDocList(res.data.data.isPreHireDocList);
          setIsOnboardingDocList(res.data.data.isOnboardingDocList);
          const upload = [];
          const download = [];
          const documents = [...res.data.data.doc];
          documents.forEach((rec) => {
            if (rec.documentUrl === "") {
              upload.push(rec);
            } else {
              download.push(rec);
            }
          });
          setUploadDocs(upload);
          setDownloadDocs(download);
        } else {
          toast.error(res.data.message);
        }
      })
      .finally(() => {
        setLoader(false);
      });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  // Download the document
  const downloadDocument = (url, id) => {
    axios
      .get(url, {
        responseType: "blob",
      })
      .then((res) => {
        fileDownload(res.data, url.split("/").at(-1));
      });
  };

  const openDocument = (doc) => {
    const docId = window.btoa(doc.documentId);
    const value = isPreHireDocList === undefined ? 0 : 1;

    if (doc.documentStatus === "SIGNED_PREHIRE") {
      window.open(doc.documentUrl, "_blank");
    } else {
      window.open(
        `/view-doc/${docId}_${preHireDocumentPackId}_${window.btoa(value)}`,
        "_blank"
      );
    }
  };

  // Upload the document
  const uploadDocument = (e, documentId) => {
    formData.append("documentId", documentId);
    formData.append("onboardingId", preHireDocumentPackId);
    formData.append("document", e.target.files[0]);

    Api("POST", `Job/UploadOnboardingDocument`, formData).then((res) => {
      if (res.data.isSuccess === true) {
        toast.success(res.data.message);
      }
    });
  };

  const openAttachment = () => {
    docRef.current.click();
  };

  return (
    <div>
      <div className="white-container">
        <div className="mb-10">
          <img
            src={require("../../../../../../../assets/images/logo.png")}
            width="125px"
          />
        </div>
        {documentList.length > 0 ? (
          <div className="doc-tab-container">
            {uploadDocs.length > 0 && (
              <span className="doc-type-title">
                Please upload the following document(s) :
              </span>
            )}
            {uploadDocs?.map((rec, index) => (
              <div className="border-bottom doc-tab" key={index}>
                <label>
                  {rec.documentName} (Upload) <br />
                </label>
                <input
                  type="file"
                  hidden
                  ref={docRef}
                  onChange={(e) => uploadDocument(e, rec.documentId)}
                />
                <Tooltip title="Upload">
                  <IconButton onClick={openAttachment}>
                    <FileUploadOutlinedIcon style={{ color: indigo[600] }} />
                  </IconButton>
                </Tooltip>
              </div>
            ))}

            <div className="doc-section"></div>
            <span className="doc-type-title">
              You may download the following document(s) :
            </span>
            {downloadDocs?.map((doc, dindex) => (
              <div className="border-bottom doc-tab" key={dindex}>
                <label
                  className="pointer doc-link"
                  onClick={() => openDocument(doc)}
                >
                  {doc?.displayFileName != null
                    ? doc.displayFileName
                    : doc?.documentName != null
                    ? doc.documentName
                    : "No name found"}
                  <br />
                </label>
                {(doc.documentStatus === "SIGNED_PREHIRE" ||
                  doc.documentStatus === "UPLOADED_PREHIRE" ||
                  doc.documentStatus === "DELIVERED_PREHIRE" ||
                  imageExt.includes(
                    doc?.displayFileName?.split(".").at(-1)
                  )) && (
                  <Tooltip title="Download">
                    <IconButton
                      onClick={() =>
                        downloadDocument(doc.documentUrl, doc.documentId)
                      }
                    >
                      <FileDownloadOutlinedIcon style={{ color: green[600] }} />
                    </IconButton>
                  </Tooltip>
                )}
              </div>
            ))}
          </div>
        ) : (
          <>
            <label>
              Please enter the password shared with you in the mail (you will use this password to access
              your documents in TalentATS).
            </label>

            <div className="input-container">
              <div className="txt_box">
                <input
                  className="email_input"
                  type="password"
                  name="password"
                  id="userName"
                  placeholder="Password"
                  onChange={handleChange}
                />
              </div>
              {message === "0" && (
                <div className="txt_box">
                  <input
                    className="email_input"
                    type="password"
                    name="confirmPassword"
                    id="userName"
                    placeholder="Confirm Password"
                    onChange={handleChange}
                  />
                </div>
              )}
              <ButtonUI
                p="10px"
                ml="120px"
                onClick={() => savePassword()}
                width="70px"
              >
                {isLoader ? <div className="dot-pulse"></div> : "Submit"}
              </ButtonUI>
            </div>
          </>
        )}
      </div>
    </div>
  );
};
