import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Api from "../../../../../../../helper/Api";
import { ButtonUI } from "../../../../../../ui/button";
import { toast } from "react-toastify";
import "../../../../../../../assets/css/public/doc.css";

export const ViewDoc = () => {
  const location = useLocation();
  const [content, setContent] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [showButton, setSignButton] = useState(false);

  const ids = location.pathname
    .substring(location.pathname.lastIndexOf("/") + 1)
    .split("_");

  useEffect(() => {
    setTimeout(() => {
      let scr = document.createElement("script");
      scr.src = window.location.origin + "/js/forms.js";
      document.body.append(scr);
    }, 2000);

    Api(
      "GET",
      `Job/getDynamicDocumentContent?documentId=${ids[0]}&preHireDocumentPackId=${ids[1]}`
    )
      .then((res) => {
        const parser = new DOMParser();
        const html = parser.parseFromString(
          res.data.data.HTMLContent,
          "text/html"
        );
        // if (html.getElementsByClassName("fr-fic fr-dib")) {
        //   html.getElementsByClassName("fr-fic fr-dib")[0].src =
        //     res.data?.data?.companyLogo;
        // }
        res.data.isSuccess && setSignButton(true);
        setContent(html.getElementsByTagName("body")[0].innerHTML);
      })
      .catch((err) => console.log(err));
  }, []);

  const saveData = (e) => {
    e.preventDefault();
    setLoading(true);
    // const inputs = [...Object.values(document.getElementsByTagName("input"))];

    // const tAreas = [
    //   ...Object.values(document.getElementsByTagName("textarea")),
    // ];

    // if (tAreas.length > 0) {
    //   tAreas.map((area) => {
    //     area.setAttribute(
    //       "value",
    //       document.getElementById(area?.id)?.value
    //         ? document.getElementById(area?.id)?.value
    //         : ""
    //     );
    //   });
    // }

    // if (inputs.length > 0) {
    //   inputs.map((i) => {
    //     const inputEl = document.getElementById(i.id);
    //     if (i.type === "text" && content.includes(i.id)) {
    //       inputEl.setAttribute("value", inputEl.value);
    //     }

    //     if (i.type === "radio") {
    //       const rchecked = i.value === "Yes" ? true : false;
    //       inputEl.setAttribute("checked", rchecked);
    //     }

    //     if (i.type === "checkbox") {
    //       const cchecked = i.value === "Yes" ? true : false;
    //       inputEl.setAttribute("checked", cchecked);
    //     }
    //   });
    // }

    var selects = document.getElementsByTagName("select");
    for (let i = 0; i < selects.length; i++) {
      if (selects[i].id) {
        const selectBox = document.getElementById(selects[i].id);
        selectBox.parentNode.replaceChild(
          document.createTextNode(selects[i].value),
          selectBox
        );
      }
    }

    const params = {
      onboardingORPreHireId: ids[1],
      documentId: ids[0],
      htmlContent: document.getElementById("ol-content").innerHTML,
      docFormType: +window.atob(ids[2]),
    };

    Api("POST", `Job/SignDocumentForm`, params)
      .then((res) => {
        if (res.data.isSuccess) {
          toast.success(res.data.message);
          window.close();
        } else {
          toast.error(res.data.message);
        }
      })
      .finally(() => setLoading(false));
  };

  return (
    <form id="aspnetForm" onSubmit={saveData}>
      <div
        id="docWrapper"
        className="fr-view doc_wrapper"
        style={{ opacity: 1 }}
      >
        <div style={{ padding: "10px 10px 50px 10px" }}>
          <div
            id="ol-content"
            className="view"
            style={{
              textAlign: "left",
              color: "#000",
              boxSizing: "border-box",
            }}
            dangerouslySetInnerHTML={{
              __html: content,
            }}
          />
          {showButton && (
            <div className="d-flex">
              <ButtonUI p="8px" m="auto" width="140px">
                {isLoading ? (
                  <div className="dot-pulse"></div>
                ) : (
                  "Sign & Submit"
                )}
              </ButtonUI>
              <ButtonUI
                p="8px"
                m="auto"
                ml="10px"
                onClick={window.close}
                width="140px"
                variant="outlined"
              >
                Finish Later
              </ButtonUI>
            </div>
          )}
        </div>
      </div>
    </form>
  );
};
