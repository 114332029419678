import { Tooltip } from "@mui/material";
import { ButtonUI } from ".";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";

export const DownloadButton = ({ onClick }) => {
  return (
    <Tooltip title="Download">
      <ButtonUI
        variant="outlined"
        fontSize="10px"
        color="#3954A5"
        mt="5px"
        width="84px"
        onClick={onClick}
        rightIcon={<FileDownloadOutlinedIcon fontSize="small" />}
      >
        Download
      </ButtonUI>
    </Tooltip>
  );
};
