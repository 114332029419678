import { TextareaAutosize } from "@material-ui/core";
import "../../../../../../../assets/css/components/company-detail/_applicantTabs.css";
import { ButtonUI } from "../../../../../../ui/button";
import Api from "../../../../../../../helper/Api";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { APPLICANT_DATA_ACTIONS } from "../../../../../../../redux/applicantData/actions";
import ThumbUpOutlinedIcon from '@mui/icons-material/ThumbUpOutlined';
import { grey, red } from "@material-ui/core/colors";
import { useState } from "react";

export const HotApplicantTab = ({
  applicantData,
  selectedSuitCase,
  applicantDetails,
  comment,
  setComment
}) => {
  const dispatch = useDispatch();
  const [isCommentDisplayed, setCommentDisplayed] = useState();
  const { applicantsArray } = useSelector(
    (state) => state.applicantDataReducer
  );

  function addToHotlist(flag = false) {
    Api(
      "PUT",
      `Job/AddToHotList?companyId=${applicantData.companyId}&applicantId=${applicantData.applicantId
      }&jobId=${selectedSuitCase.jobId}&isHotList=${!applicantDetails.isHotList}&hotListComment=${comment}`
    )
      .then((res) => {
        if (res.data.isSuccess) {
          if (applicantData.applicantList !== undefined && applicantData.applicantList.length > 0) {
            const applicantsList = [...applicantData?.applicantList];
            const applicantIndex = applicantsList.findIndex(
              (applicant) => applicant.applicantId === applicantData.applicantId
            );
            if (applicantIndex !== -1) {
              applicantsList[applicantIndex].isHotList = !applicantDetails.isHotList;
              applicantsList[applicantIndex].hotListComment = comment;
            }

            const data = [...applicantsArray];
            data.map((rec) => {
              if (rec.applicantId === applicantDetails.applicantId) {
                rec.applicantList = [...applicantsList];
              }
            });
            dispatch({
              type: APPLICANT_DATA_ACTIONS.SET_APPLICANTS_ARRAY,
              payload: data,
            });
            dispatch({ type: APPLICANT_DATA_ACTIONS.SET_APPLICANTS_REFRESH });
          } else {
            const data = [...applicantsArray];
            data.map((rec) => {
              if (rec.applicantId === applicantDetails.applicantId) {
                rec.isHotList = !applicantDetails.isHotList;
                rec.hotListComment = applicantDetails.hotListComment;
              }
            });
            dispatch({
              type: APPLICANT_DATA_ACTIONS.SET_APPLICANTS_ARRAY,
              payload: data,
            });
            dispatch({ type: APPLICANT_DATA_ACTIONS.SET_APPLICANTS_REFRESH });
          }
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const addHotlistComment = () => {
    Api(
      "PUT",
      `Job/AddToHotList?companyId=${applicantData.companyId}&applicantId=${applicantData.applicantId
      }&jobId=${selectedSuitCase.jobId}&isHotList=${applicantDetails.isHotList}&hotListComment=${comment}`
    )
      .then((res) => {
        if (res.data.isSuccess) {
          const applicantsList = [...applicantData?.applicantList];
          const applicantIndex = applicantsList.findIndex(
            (applicant) => applicant.applicantId === applicantData.applicantId
          );
          if (applicantIndex !== -1) {
            applicantsList[applicantIndex].isHotList = applicantDetails.isHotList;
            applicantsList[applicantIndex].hotListComment = comment;
          }

          const data = [...applicantsArray];
          data.map((rec) => {
            if (rec.applicantId === applicantDetails.applicantId) {
              rec.applicantList = [...applicantsList];
            }
          });
          dispatch({
            type: APPLICANT_DATA_ACTIONS.SET_APPLICANTS_ARRAY,
            payload: data,
          });
          dispatch({ type: APPLICANT_DATA_ACTIONS.SET_APPLICANTS_REFRESH });
          setCommentDisplayed(true);
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  return (
    <div className="tab-container p-0-16 mt-15">
      {isCommentDisplayed ? 
        <div className="font-12">{comment}</div> 
      : 
        <>
          <div>
            <div className="notes-header-container">
              <ThumbUpOutlinedIcon sx={{ color: applicantDetails.isHotList ? red[900] : grey[500] }} onClick={addToHotlist} />
              <span className={`hot-app-label ${applicantDetails.isHotList ? "red" : ""}`} onClick={addToHotlist}>Hot Applicant</span>
            </div>
          </div>
          <div className="hot-applicant-note-container">
            <div className="text-area-container">
              <TextareaAutosize
                aria-label="minimum height"
                minRows={4}
                maxRows={4}
                placeholder="Note Here"
                onChange={(e) => setComment(e.target.value)}
                value={comment}
                name="hotListComment"
                style={{
                  resize: "none",
                  width: "276px",
                  border: "none",
                  outline: "none",
                  fontFamily: "Inter",
                }}
              />
            </div>
            <div className="applicant-btn-container">
              <ButtonUI
                mr="16px"
                p="8px"
                width={"80px"}
                onClick={() => addHotlistComment()}
              >
                Save
              </ButtonUI>
              <ButtonUI
                variant="outlined"
                p="8px"
                width="80px"
              >
                Cancel
              </ButtonUI>
            </div>
          </div>
        </>
      }
    </div>
  );
};
