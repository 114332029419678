import { Fragment, useState } from "react";
import moment from "moment";
import BackgroundLetterAvatars from "../layouts/BackgroundLetterAvatars";
import { ButtonUI } from "../../ui/button";
import Api from "../../../helper/Api";

const applicantStatus = {
  ACTIVEHIRED: { status: "Active", color: "active" },
  DELETED: { status: "Deleted", color: "red" },
  HIRED: { status: "Hired", color: "purple" },
  INELIGIBLE: { status: "Ineligible", color: "blue" },
  POSITION_CANCELED: { status: "Canceled", color: "" },
  POSITION_FILLED: { status: "Filled", color: "yellow" },
  QUITE: { status: "Quite", color: "orange" },
  TERMINATED: { status: "Terminated", color: "dblue" },
  UNQUALIFIED: { status: "Unqualified", color: "grey" },
  WITHDRAWN: { status: "Withdrawn", color: "lblue" },
  FURLOUGH: "",
};

const hireStatus = {
  ACTIVEHIRED: "",
  DELETED: "",
  HIRED: { status: "Hired", color: "green-active" },
  INELIGIBLE: "",
  POSITION_CANCELED: "",
  POSITION_FILLED: "",
  QUITE: { status: "Quite", color: "red-delete" },
  TERMINATED: { status: "Terminated", color: "red-delete" },
  UNQUALIFIED: "",
  WITHDRAWN: "",
  "": "",
  FURLOUGH: { status: "Furloughed", color: "red-delete" }
};

export const ProfileCard = (props) => {
  const [arrIndex, setArrIndex] = useState([]);
  const [arrLoader, setArrLoader] = useState([]);
  const showActivity = (appId) => {
    let loaders = [...arrLoader];
    loaders.push(appId);
    setArrLoader(loaders);
    Api("GET", `Job/GetApplicantActivity?applicantId=${props.applicantId}&jobId=${props.jobId}&companyId=${props.companyId}`)
      .then((res) => {
        let arr = [...arrIndex];
        arr[props.applicantList.indexOf(props.applicant)] = res.data.latestActivityMessage;
        if (loaders.includes(appId)) {
          loaders.splice(loaders.indexOf(appId), 1);
          setArrLoader(loaders);
        }
        setArrIndex(arr);
      }).catch((err) => {
        console.log(err);
      }).finally(() => {
        if (loaders.includes(appId)) {
          loaders.splice(loaders.indexOf(appId), 1);
          setArrLoader(loaders);
        }
      })
  }

  return (
    <Fragment>
      <div className="g-card profile pointer" key={props.applicantId}>
        <BackgroundLetterAvatars
          name={props.name}
          width={34}
          height={34}
          fontSize="16px"
        />
        <div className="profile-content">
          <div
            className="profile-content"
            onClick={() => props.openApplicantDetails(props)}
          >
            <div className="g-card-dark-header">
              <span className="mt-5">{props.name}</span>
              <div className="date-arrow">
                <div>
                  {moment(props.createdAt).format("MM/DD/YYYY")}
                  <img src={require("../../../assets/images/arrow.png")} />
                </div>
                {props?.status && props.jobCycleStatus === undefined && (
                  <div className="d-flex justify-content-center">
                    <label
                      className={`status-label ${applicantStatus[props.status]["color"]
                        }`}
                    >
                      {applicantStatus[props.status]["status"]}
                    </label>
                  </div>
                )}
                {props?.status && props.jobCycleStatus === "HIRE" && (
                  <div className="d-flex justify-content-center">
                    {hireStatus[props.status] !== "" &&
                      <label
                        className={`status ${hireStatus[props.status]["color"]
                          }`}
                      >
                        {hireStatus[props.status]["status"]}
                      </label>
                    }
                  </div>
                )}
              </div>
            </div>
            <div className="g-card-header-2">
              {props.jobName} ({props.jobCode})
            </div>
            <div className="g-card-header-2 mt-10 p-content">
              <label>{arrIndex[props.applicantList?.indexOf(props.applicant)]}</label>
            </div>
          </div>
          <div className="g-card-header-2 p-content">
            <span className="blue-t-normal">
              {props?.morePositions > 1
                ? "+" + (props?.morePositions - 1) + " more positions"
                : null}
            </span>
            {arrLoader.includes(props.applicantId) === true ?
              <ButtonUI variant="outlined" fontSize="11px" radius="4px" width="119px"><div className="loader-spin"></div></ButtonUI>
              :
              <ButtonUI variant="outlined" fontSize="11px" radius="4px" onClick={() => showActivity(props.applicantId)}>Show latest activity</ButtonUI>
            }
          </div>
        </div>
      </div>
    </Fragment>
  );
};
