import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Api from "../../../../../../../helper/Api";

export const EditDoc = () => {
  const location = useLocation();
  const docId = location.pathname.substring(
    location.pathname.lastIndexOf("/") + 1
  );

  useEffect(() => {
    var hash = docId;
    var so = "saveOver";
    var wrapperID = "document_wrapper_" + hash;
    const customize =
      '{"items": [{"type":"field","label":"Applicant First Name","value":"[Applicant_First_Name]"},{"type":"field","label":"Applicant Full Name","value":"[Applicant_Full_Name]"},{"type":"field","label":"Applicant Last Name","value":"[Applicant_Last_Name]"},{"type":"image","label":"Company Logo","value":"/companylogoimage/69AE0470CA4838B33DE5078C3DD7149E"},{"type":"field","label":"Company Name","value":"[Company_Name]"},{"type":"field","label":"Current Date","value":"[Current_Date]"},{"type":"field","label":"Department Name","value":"[Department_Name]"},{"type":"field","label":"Facility Name","value":"[Facility_Name]"},{"type":"field","label":"Job Name","value":"[Job_Name]"},{"type":"field","label":"Pay Rate","value":"[Pay_Rate]"},{"type":"field","label":"Start Date","value":"[Start_Date]"},{"type":"field","label":"Supervisor Names","value":"[Supervisor_Names]"}]}';

    window.showEditor(hash, wrapperID, so, true, customize);

    Api(
      "GET",
      `Job/getOfferLetterDocumentContent?id=${docId}&isSystemLink=${true}`
    ).then((res) => {
      if (document.getElementsByClassName("fr-element fr-view")) {
        document.getElementsByClassName("fr-element fr-view")[0].innerHTML =
          res.data.data.htmlContent;
      }
    });
  }, []);

  return (
    <div>
      <div id={"document_wrapper_" + docId}></div>
    </div>
  );
};
