import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Api from "../../../../../../../helper/Api";

export const ViewOL = () => {
  const location = useLocation();
  const offerLetterId = location.pathname.substring(
    location.pathname.lastIndexOf("/") + 1
  );
  const [content, setContent] = useState("");

  useEffect(() => {
    Api(
      "GET",
      `Job/getOfferLetterDocumentContent?id=${offerLetterId}&isSystemLink=${true}`
    ).then((res) => {
      setContent(res.data.data.htmlContent);
    });
  }, []);

  return (
    <div>
      <div className="job-data-container">
        <div className="j-content">
          <div className="mt-20" id="fr-content">
            <div
              id="ol-content"
              dangerouslySetInnerHTML={{
                __html: content,
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
