import Swal from "sweetalert2";
import { sortAtoZ } from "./constants";
import React from "react";

export const getDays = (day) => {
  const date_1 = new Date(day);
  const date_2 = new Date();
  return Math.ceil((date_2.getTime() - date_1.getTime()) / (1000 * 3600 * 24));
};

export const getFilteredByStatus = (jobs) => {
  return [
    ...sortAtoZ(jobs.filter((job) => job?.jobProgressStatus === "NOT_STARTED")),
    ...sortAtoZ(jobs.filter((job) => job?.jobProgressStatus === "IN_PROGRESS")),
    ...sortAtoZ(jobs.filter((job) => job?.jobProgressStatus === "COMPLETE")),
    ...sortAtoZ(jobs.filter((job) => job?.jobProgressStatus === "")),
  ];
};

export const AlertBox = () => {
  return Swal.fire({
    title: "Are you sure?",
    text: "You won't be able to revert this!",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Yes",
    heightAuto: false
  });
};

export const showSuccess = (
  title = "Deleted!",
  html = "Deleted successfully!"
) => {
  let timerInterval;
  Swal.fire({
    title: title,
    icon: "success",
    html: html,
    timer: 1800,
    timerProgressBar: true,
    willClose: () => {
      clearInterval(timerInterval);
    },
  }).then((result) => {
    /* Read more about handling dismissals below */
    if (result.dismiss === Swal.DismissReason.timer) {
      console.log("I was closed by the timer");
    }
  });
};

const Repeat = (props) => {
  let items = [];
  for (let i = 0; i < props.numTimes; i++) {
    items.push(props.children(i));
  }
  return <React.Fragment>{items}</React.Fragment>;
};
export default Repeat;
