export const JOB_DATA_ACTIONS = {
  SET_JOB_DATA: "SET_JOB_DATA",
  SET_ALL_JOB_STATUS: "SET_ALL_JOB_STATUS",
  SET_JOB_CYCLE_STATUS: "SET_JOB_CYCLE_STATUS",
  SET_APPLICANT_COUNT: "SET_APPLICANT_COUNT",
  SET_JOBS_ARRAY: "SET_JOBS_ARRAY",
  UPDATE_JOBS_ARRAY: "UPDATE_JOBS_ARRAY",
  SET_REFRESH: "SET_REFRESH",
  SET_JOBS: "SET_JOBS",
  SET_ALL_JOBS: "SET_ALL_JOBS",
  DELETE_ALL_JOBS: "DELETE_ALL_JOBS",
};
