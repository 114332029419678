import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

import { styled } from "@mui/material/styles";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import PropTypes from "prop-types";

import { toast } from "react-toastify";
import Loader from "../../common/Loader";
import Api from "../../../helper/Api";

import NoRecordsFound from "../../common/NoRecordsFound";
import { EditDeleteMenu } from "../../ui/editDeleteMenu";
import "../../../assets/css/components/company-detail/_appointments.css";
import { ButtonUI } from "../../ui/button";
import AddIcon from "@mui/icons-material/Add";
import InfoDialogBox from "../../common/dialogBox/infoDialogBox";

let originalRows = [];

let chatQuickNotes = [];

export function QuickNotes() {
  const location = useLocation();

  const [rows, setRows] = useState(originalRows);
  const [chats, setChats] = useState(chatQuickNotes);
  const [tabQuickChats, setTabQuickChats] = useState(0);
  const [quickNoteId, setquickNoteId] = useState(0);

  const [isLoader, setIsLoader] = useState(false);

  const [showSideBar, setShowSideBar] = useState(false);
  const [showAddEditLabel, setAddEditLabel] = useState("");

  const initialValues = { note: "", displayDate: "" };
  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [editMode, setEditMode] = useState(false);

  const initialValuesChat = { notesNameChat: "", displayDateChat: "" };
  const [formValuesChat, setFormValuesChat] = useState(initialValuesChat);
  const [formErrorsChat, setFormErrorsChat] = useState({});
  const [isSubmitChat, setIsSubmitChat] = useState(false);
  const [openInfoDialog, setOpenInfoDialog] = React.useState(false);
  const [infoDialogContent, setInfoDialogContent] = React.useState("");

  const pageLimit = 500;
  const pageNumber = 0;

  const CustomTab = styled(Tab)`
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #333333;
    letter-spacing: normal;
    text-transform: capitalize;

    &.Mui-selected {
      font-weight: 700;
      color: #333;
    }
  `;

  // Tab Value
  const [value, setValue] = React.useState(0);

  useEffect(() => {
    getApplicantQuickNotesData();
  }, []);

  const getApplicantQuickNotesData = () => {
    setIsLoader(true);
    Api(
      "GET",
      `QuickNote/GetQuickNoteList?companyId=${location.state.companyId}&companyHirerchy=${location.state.companyHirerchy}&pageLimit=${pageLimit}&pageNumber=${pageNumber}&quickNoteType=applicant`
    )
      .then((response) => {
        if (response.data.data.length) {
          setRows(response.data.data);
        } else {
          setRows([]);
        }
        setIsLoader(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoader(false);
      });
  };

  const getChatQuickNotesData = () => {
    Api(
      "GET",
      `QuickNote/GetQuickNoteList?companyId=${location.state.companyId}&companyHirerchy=${location.state.companyHirerchy}&pageLimit=${pageLimit}&pageNumber=${pageNumber}&quickNoteType=chat`
    )
      .then((response) => {
        response.data.data.length ? setChats(response.data.data) : setChats([]);
      })
      .catch((error) => {
        console.log(error);
        setIsLoader(false);
      });
  };

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <div className="h-100">{children}</div>}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const handleChangeTab = (event, newValue) => {
    newValue === 1 ? setTabQuickChats(true) : setTabQuickChats(false);
    if (newValue == 1) {
      getChatQuickNotesData();
    }
    setValue(newValue);
  };

  function getCurrentDate(separator = "") {
    let newDate = new Date();
    let date = newDate.getDate();
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();

    return `${date}${separator}${month < 10 ? `0${month}` : `${month}`
      }${separator}${year}`;
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormErrors(validate(formValues));
    const params = {
      companyId: location.state.companyId,
      displayDate: formValues.displayDate,
      note: formValues.note,
      quickNoteType: "applicant",
    };

    if (Object.keys(validate(formValues)).length == 0) {
      setIsLoader(true);
      if (editMode) {
        setIsLoader(true);
        params.quickNoteId = quickNoteId;
        Api("PUT", "QuickNote/EditQuickNote", params)
          .then((res) => {
            if (res.data.isSuccess == true) {
              rows.map((row) => {
                if (row.quickNoteId == params.quickNoteId) {
                  rows[rows.indexOf(row)] = Object.assign(params);
                }
              });
              setRows(rows);
              toast.success(res.data.message);
            } else {
              toast.error(res.data.message);
            }
            getApplicantQuickNotesData();
            setIsLoader(false);
          })
          .catch((err) => {
            console.log(err);
            setIsLoader(false);
          });
      } else {
        setIsLoader(true);
        Api("POST", "QuickNote/AddQuickNote", params)
          .then((res) => {
            res.data.isSuccess == true
              ? toast.success(res.data.message)
              : toast.error(res.data.message);
            getApplicantQuickNotesData();
            setIsLoader(false);
          })
          .catch((err) => {
            console.log(err);
          });
      }
      setIsSubmit(true);
      setShowSideBar(false);
      setEditMode(false);
      setFormValues("");
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const addDataTable = (e) => {
    setAddEditLabel("Add Quick Note");
    setShowSideBar(true);
  };

  const errors = {};
  const validate = (values) => {
    if (!values.note) {
      errors.note = "Notes is required.";
    } else if (!isNaN(values.note)) {
      errors.note = "Notes must be a text!";
    }

    return errors;
  };

  const formErrorClear = () => {
    formErrors.note = "";
    formErrors.date = "";
  };

  const closeSideBar = () => {
    setFormValues("");
    formErrorClear();
    setShowSideBar(false);
  };

  const handleSubmitChat = (e) => {
    e.preventDefault();
    setFormErrorsChat(validate(formValuesChat));
    const params = {
      companyId: location.state.companyId,
      displayDate: getCurrentDate("/"),
      note: formValuesChat.notesNameChat,
      quickNoteType: "chat",
    };

    if (Object.keys(validateChat(formValuesChat)).length == 0) {
      setIsLoader(true);
      if (editMode) {
        setIsLoader(true);
        params.quickNoteId = quickNoteId;
        Api("PUT", "QuickNote/EditQuickNote", params)
          .then((res) => {
            if (res.data.isSuccess == true) {
              chats.map((row) => {
                if (row.quickNoteId == params.quickNoteId) {
                  rows[rows.indexOf(row)] = Object.assign(params);
                }
              });
              setChats(rows);
              toast.success(res.data.message);
            } else {
              toast.error(res.data.message);
            }
            getChatQuickNotesData();
            setIsLoader(false);
          })
          .catch((err) => {
            console.log(err);
            setIsLoader(false);
          });
      } else {
        setIsLoader(true);
        Api("POST", "QuickNote/AddQuickNote", params)
          .then((res) => {
            res.data.isSuccess == true
              ? toast.success(res.data.message)
              : toast.error(res.data.message);
            getChatQuickNotesData();
            setIsLoader(false);
          })
          .catch((err) => {
            console.log(err);
          });
      }
      setIsSubmitChat(true);
      setShowSideBar(false);
      setEditMode(false);
      setFormValuesChat("");
    }
  };

  const handleChangeChat = (e) => {
    const { name, value } = e.target;
    setFormValuesChat({ ...formValuesChat, [name]: value });
  };

  const addDataTableChat = (e) => {
    setAddEditLabel("Add Chat Quick Note");
    setShowSideBar(true);
  };

  const errorsChat = {};
  const validateChat = (values) => {
    if (!values.notesNameChat) {
      errorsChat.notesNameChat = "Notes is required.";
    } else if (!isNaN(values.notesNameChat)) {
      errorsChat.notesNameChat = "Notes must be a text!";
    }

    return errorsChat;
  };

  const formErrorClearChat = () => {
    formErrorsChat.notesNameChat = "";
  };

  const closeSideBarChat = () => {
    setFormValuesChat("");
    formErrorClearChat();
    setShowSideBar(false);
  };

  const handleEdit = (quickNoteId) => {
    // Edit button clicked in - popup
    setEditMode(true);
    setquickNoteId(quickNoteId);

    if (tabQuickChats) {
      setAddEditLabel("Edit Chat Quick Note");
      const listItemValueChat = chats.filter((items) => {
        return items.quickNoteId === quickNoteId ? items : null;
      });
      setFormValuesChat({
        ["notesNameChat"]: listItemValueChat[0].note,
        ["displayDateChat"]: listItemValueChat[0].displayDate,
      });
      setShowSideBar(quickNoteId);
    } else {
      setAddEditLabel("Edit Quick Note");

      const listItemValue = rows.filter((items) => {
        return items.quickNoteId === quickNoteId ? items : null;
      });
      setFormValues({
        ["note"]: listItemValue[0].note,
        ["displayDate"]: listItemValue[0].displayDate,
      });
      setShowSideBar(quickNoteId);
    }
  };

  const handleDelete = (quickNoteId) => {
    // Delete data of row table - popup clicked delte

    var answer = window.confirm("Are you sure you want to delete?");
    if (answer) {
      setIsLoader(true);
      // Delete facilities api call
      Api("DELETE", `QuickNote/DeleteQuickNote?quickNoteId=${quickNoteId}`)
        .then((res) => {
          if (res.data.isSuccess == true) {
            toast.success(res.data.message);
          } else {
            toast.error(res.data.message);
          }
          getApplicantQuickNotesData();
          getChatQuickNotesData();
        })
        .catch((err) => {
          console.log(err);
          setIsLoader(false);
        });
    }
  };

  const handleClickOpen = () => {
    setOpenInfoDialog(true);
  };

  const handleClose = () => {
    setOpenInfoDialog(false);
  };

  return (
    <div>
      <InfoDialogBox
        content={infoDialogContent}
        open={openInfoDialog}
        handleClose={handleClose}
      />
      {/* sidebar for add and edit data into the table */}
      {showSideBar ? <div className="back-shadow"></div> : null}
      {showSideBar ? (
        <div
          className={showSideBar ? "side-bar-parent active" : "side-bar-parent"}
        >
          <header>
            <span>{showAddEditLabel}</span>
            <img
              src={require("../../../assets/images/close.svg").default}
              alt="close-img"
              className="close-img"
              onClick={tabQuickChats ? closeSideBarChat : closeSideBar}
            />
          </header>

          {tabQuickChats ? (
            <form onSubmit={handleSubmitChat} className="inner-container">
              <div className="in-forms">
                <div className="forms-input">
                  <label>Note</label>
                  <textarea
                    name="notesNameChat"
                    id="notesNameChat"
                    cols="20"
                    rows="6"
                    value={formValuesChat.notesNameChat}
                    onChange={handleChangeChat}
                  ></textarea>
                  {formErrorsChat.notesNameChat ? (
                    <span className="error_msg">
                      {formErrorsChat.notesNameChat}
                    </span>
                  ) : null}
                </div>
                <input
                  type="text"
                  hidden
                  placeholder="date"
                  name="displayDateChat"
                  id="displayDateChat"
                  value={formValuesChat.displayDateChat}
                  onChange={handleChangeChat}
                />
              </div>
              <div className="btns-container">
                <input type="submit" className="add-btn" value="Submit" />
                <button
                  className="cancel-btn"
                  type="reset"
                  onClick={() => (
                    setShowSideBar(false), setFormValuesChat(initialValuesChat)
                  )}
                >
                  Cancel
                </button>
              </div>
            </form>
          ) : (
            <form onSubmit={handleSubmit} className="inner-container">
              <div className="in-forms">
                <div className="forms-input">
                  <label>Note</label>
                  <textarea
                    name="note"
                    id="note"
                    cols="20"
                    rows="4"
                    value={formValues.note}
                    onChange={handleChange}
                  ></textarea>
                  {formErrors.note ? (
                    <span className="error_msg">{formErrors.note}</span>
                  ) : null}
                </div>
                <input
                  type="text"
                  hidden
                  placeholder="displayDate"
                  name="displayDate"
                  id="date"
                  value={formValues.displayDate}
                  onChange={handleChange}
                />
              </div>
              <div className="btns-container">
                <input type="submit" className="add-btn" value="Submit" />
                <button
                  className="cancel-btn"
                  type="reset"
                  onClick={() => (
                    setShowSideBar(false), setFormValues(initialValues)
                  )}
                >
                  Cancel
                </button>
              </div>
            </form>
          )}
        </div>
      ) : null}
      <div className="common-container users">
        <div className="inner-container">
          <div className="top-url">
            <p>
              <Link to="/companylist"> Companies &gt; </Link>
              <strong>Quick Notes</strong>
            </p>
          </div>
          <div className="manage-container jobs quick">
            <div className="manage-bg active">
              <div className="parent-container-d">
                <div className="top-search">
                  <Tabs
                    value={value}
                    onChange={handleChangeTab}
                    aria-label="Quick notes tab"
                  >
                    <CustomTab
                      label="Applicant Quick Notes"
                      {...a11yProps(0)}
                    />
                    <CustomTab label="Chat Quick Notes" {...a11yProps(1)} />
                  </Tabs>
                  {tabQuickChats ? (
                    <ButtonUI
                      p="12px"
                      leftIcon={<AddIcon style={{ height: 16, width: 16 }} />}
                      onClick={addDataTableChat}
                    >
                      Add
                    </ButtonUI>
                  ) : (
                    <ButtonUI
                      p="12px"
                      leftIcon={<AddIcon style={{ height: 16, width: 16 }} />}
                      onClick={addDataTable}
                    >
                      Add
                    </ButtonUI>
                  )}
                </div>

                <div className="manage-checkboxes-none">
                  <Loader inShow={isLoader} />
                  <TabPanel value={value} index={0} className="h-100">
                    {rows.length > 0 ? (
                      <div className="cards-contain pointer">
                        {rows.map((row) => (
                          <div
                            className="cust-cards"
                            key={row.quickNoteId}
                            role="button"
                          >
                            <div className="date-sec">
                              <span
                                className="pointer"
                                onClick={() => {
                                  setInfoDialogContent(row.note);
                                  handleClickOpen();
                                }}
                              >
                                {row.displayDate}
                              </span>
                              {location.state.companyId === row.companyId &&
                                <EditDeleteMenu
                                  id={row.quickNoteId}
                                  onEdit={handleEdit}
                                  onDelete={handleDelete}
                                />
                              }
                            </div>
                            <p
                              className="pointer"
                              onClick={() => {
                                setInfoDialogContent(row.note);
                                handleClickOpen();
                              }}
                            >
                              {row.note.substring(0, 200)}
                            </p>
                          </div>
                        ))}
                      </div>
                    ) : (
                      <NoRecordsFound />
                    )}
                  </TabPanel>

                  <TabPanel value={value} index={1} className="h-100">
                    {chats.length > 0 ? (
                      <div className="cards-contain active">
                        {chats.map((row) => (
                          <div className="cust-cards" key={row.quickNoteId}>
                            <div className="date-sec">
                              <span
                                className="pointer"
                                onClick={() => {
                                  setInfoDialogContent(row.note);
                                  handleClickOpen();
                                }}
                              >
                                {row.displayDate}
                              </span>
                              {location.state.companyId === row.companyId &&
                                <div className="position-relative">
                                  <EditDeleteMenu
                                    id={row.quickNoteId}
                                    onEdit={handleEdit}
                                    onDelete={handleDelete}
                                  />
                                </div>
                              }
                            </div>
                            <p
                              className="pointer"
                              onClick={() => {
                                setInfoDialogContent(row.note);
                                handleClickOpen();
                              }}
                            >
                              {row.note.substring(0, 200)}
                            </p>
                          </div>
                        ))}
                      </div>
                    ) : (
                      <NoRecordsFound />
                    )}
                  </TabPanel>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
