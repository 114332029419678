import { useState } from "react";
import { DateSelector } from "../../../../../../ui/pickers/date";
import { AddEditCol } from "../../../../../addEditPopup/AddEditCol";

export const EditOfferForm = ({
  toggleForm,
  openForm,
  handleSubmit,
  applicantId,
  formValues,
  setFormValues,
  isSubmitting,
}) => {
  const [value, setValue] = useState(formValues.startDate);

  const handleDateChange = (newValue) => {
    setValue(newValue.toISOString());
    setFormValues({ ...formValues, ["startDate"]: newValue.toISOString() });
  };

  return (
    <AddEditCol
      openForm={openForm}
      toggleForm={toggleForm}
      handleSubmit={handleSubmit}
      applicantId={applicantId}
      title="Edit Offering Date"
      isSubmitting={isSubmitting}
    >
      <div className="forms-input mt-15">
        <label>Start Date: </label>
        <DateSelector
          value={value}
          setValue={setValue}
          disablePast={true}
          handleDateChange={handleDateChange}
        />
      </div>
    </AddEditCol>
  );
};
