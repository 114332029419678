export const ShowInActive = ({ width, height, fill, stroke }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 22 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.75 5.56152C2.75 5.37919 2.82243 5.20432 2.95136 5.07539C3.0803 4.94646 3.25516 4.87402 3.4375 4.87402H18.5625C18.7448 4.87402 18.9197 4.94646 19.0486 5.07539C19.1776 5.20432 19.25 5.37919 19.25 5.56152C19.25 5.74386 19.1776 5.91873 19.0486 6.04766C18.9197 6.17659 18.7448 6.24902 18.5625 6.24902H3.4375C3.25516 6.24902 3.0803 6.17659 2.95136 6.04766C2.82243 5.91873 2.75 5.74386 2.75 5.56152ZM3.4375 11.749H18.5625C18.7448 11.749 18.9197 11.6766 19.0486 11.5477C19.1776 11.4187 19.25 11.2439 19.25 11.0615C19.25 10.8792 19.1776 10.7043 19.0486 10.5754C18.9197 10.4465 18.7448 10.374 18.5625 10.374H3.4375C3.25516 10.374 3.0803 10.4465 2.95136 10.5754C2.82243 10.7043 2.75 10.8792 2.75 11.0615C2.75 11.2439 2.82243 11.4187 2.95136 11.5477C3.0803 11.6766 3.25516 11.749 3.4375 11.749Z"
        fill={fill}
        stroke={stroke}
        strokeWidth="0.4"
      />
      <path
        d="M3.4375 15.874H12.375C12.5573 15.874 12.7322 15.9465 12.8611 16.0754C12.9901 16.2043 13.0625 16.3792 13.0625 16.5615C13.0625 16.7439 12.9901 16.9187 12.8611 17.0477C12.7322 17.1766 12.5573 17.249 12.375 17.249H3.4375C3.25516 17.249 3.0803 17.1766 2.95136 17.0477C2.82243 16.9187 2.75 16.7439 2.75 16.5615C2.75 16.3792 2.82243 16.2043 2.95136 16.0754C3.0803 15.9465 3.25516 15.874 3.4375 15.874Z"
        fill={fill}
        stroke={stroke}
        strokeWidth="0.4"
      />
      <path
        d="M18.0772 16.5619L19.0494 17.5342C19.1784 17.6631 19.2508 17.838 19.2508 18.0203C19.2508 18.2027 19.1784 18.3775 19.0494 18.5065C18.9205 18.6354 18.7456 18.7078 18.5633 18.7078C18.381 18.7078 18.2061 18.6354 18.0772 18.5065L17.1049 17.5342L16.1326 18.5065C16.0037 18.6354 15.8288 18.7078 15.6465 18.7078C15.4641 18.7078 15.2893 18.6354 15.1603 18.5065C15.0314 18.3775 14.959 18.2027 14.959 18.0203C14.959 17.838 15.0314 17.6631 15.1603 17.5342L16.1326 16.5619L15.1603 15.5897C15.0314 15.4607 14.959 15.2859 14.959 15.1035C14.959 14.9212 15.0314 14.7463 15.1603 14.6174C15.2893 14.4884 15.4641 14.416 15.6465 14.416C15.8288 14.416 16.0037 14.4884 16.1326 14.6174L17.1049 15.5897L18.0772 14.6174C18.2061 14.4884 18.381 14.416 18.5633 14.416C18.7456 14.416 18.9205 14.4884 19.0494 14.6174C19.1784 14.7463 19.2508 14.9212 19.2508 15.1035C19.2508 15.2859 19.1784 15.4607 19.0494 15.5897L18.0772 16.5619Z"
        fill={fill}
        stroke={stroke}
        strokeWidth="0.4"
      />
    </svg>
  );
};
