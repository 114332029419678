import { CheckboxList } from "../../../../common/layouts/CheckboxList";
import { useState, useEffect } from "react";
import Api from "../../../../../helper/Api";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { ColoredLinearProgress } from "../../../../common/coloredProgressBar";

export const Benefits = ({ getJobTempById }) => {
  const [allData, setAllData] = useState([]);
  const { companyData } = useSelector((state) => state.companyDataReducer);
  const [isLoader, setIsLoader] = useState(false);
  const hasSelectAll = false;
  const selectedData = useSelector(
    (state) => state.jobTemplateReducer.jobTemplateData.benefits
  );

  useEffect(() => {
    setIsLoader(true);
    Api(
      "GET",
      `Sources/GetSourceList?companyId=${companyData.companyId}&type=benefits&companyHirerchy=${companyData.companyHirerchy}`
    )
      .then((res) => {
        res.data.map((d) => {
          d.checked =
            selectedData.length > 0 && selectedData.includes(d.sourceId)
              ? true
              : false;
          d.id = d.sourceId;
        });
        setAllData(res.data);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsLoader(false);
      });
  }, []);

  const handleSubmit = (data) => {
    let selected = [];
    data.filter(function (record) {
      if (record.checked === true) {
        selected.push(record.sourceId);
      }
    });

    const params = {
      companyId: companyData.companyId,
      jobTemplateId: companyData.data.jobTemplateId,
      ids: selected,
      enumSourceType: 1, // Benefits
    };

    Api("POST", "JobTemplate/SaveJobTemplateDetails", params)
      .then((res) => {
        if (res.data.isSuccess === true) {
          getJobTempById();
          toast.success(res.data.message);
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div>
      <div className="top-header-user">
        <span className="main-name">
          Benefits {"(" + Object.keys(allData).length + ")"}
        </span>
      </div>
      {isLoader === true ? (
        <ColoredLinearProgress />
      ) : (
        <div className="main_content">
          <CheckboxList
            allData={allData}
            setAllData={setAllData}
            handleSubmit={handleSubmit}
            hasSelectAll={hasSelectAll}
          />
        </div>
      )}
    </div>
  );
};
