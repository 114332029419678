import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "../../../assets/css/common/_company-side-bar.css";
import Api from "../../../helper/Api";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { JOB_DATA_ACTIONS } from "../../../redux/jobData/actions";
import { APPLICANT_DATA_ACTIONS } from "../../../redux/applicantData/actions";
import { EditCompanyForm } from "./editCompanyForm";
import EditIcon from "@mui/icons-material/Edit";
import { IconButton, Tooltip } from "@mui/material";
import { VIDEO_DATA_ACTIONS } from "../../../redux/videoInterviewData/actions";
import { SEARCH_ACTIONS } from "../../../redux/search/actions";

const menu_options = [
  { id: 1, key: "sources", name: "Sources" },
  { id: 2, key: "appointments", name: "Appointments" },
  { id: 3, key: "facilities", name: "Facilities" },
  { id: 4, key: "jobs", name: "Jobs" },
  { id: 5, key: "documents", name: "Documents" },
  // {id: 6, key: 'job-applications', name: 'Job Applications'},
  { id: 7, key: "job-boards", name: "Job Boards" },
  { id: 8, key: "job-templates", name: "Job Templates" },
  { id: 9, key: "messages", name: "Messages" },
  { id: 10, key: "quick-notes", name: "Quick Notes" },
  { id: 11, key: "stop-reasons", name: "Stop Reasons" },
  { id: 12, key: "sub-companies", name: "Sub Companies" },
  { id: 13, key: "system-settings", name: "System Settings" },
  { id: 14, key: "users", name: "Users" },
  { id: 15, key: "video-interviews", name: "Video Interviews" },
];

export function CompanySideBar(props) {
  const [showSideBar, setSideBar] = useState(false);
  const [showAddEditLabel, setAddEditLabel] = useState("Add New");
  const [formValues, setFormValues] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [isLoader, setIsLoader] = useState(false);
  const [companyData, setCompanyData] = useState([]);
  const [editImageShow, setEditImageShow] = useState(false);
  const [companyId, setCompanyId] = useState(props.params.companyId);
  const dispatch = useDispatch();
  const errors = {};
  const [companyHirerchy, setCompanyHirerchy] = useState(
    props.params.companyHirerchy
  );

  useEffect(() => {
    getCompanyData();
    setIsLoader(true);
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      Api("PUT", "companies/editcompany", formValues, "multipart")
        .then((response) => {
          if (response.data.isSuccess) {
            toast.success(response.data.message);
            setSideBar(false);
            getCompanyData();
          } else {
            toast.error(response.data.message);
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setIsLoader(false);
        });
    }
  }, [formErrors]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormErrors(validate(formValues));
    setIsSubmit(true);
  };

  const validate = (values) => {
    if (!values.name) {
      errors.name = "Name is required.";
    }
    if (!values.companyImage && !values.companyLogo) {
      errors.companyImage = "Company Logo is required!";
    }
    return errors;
  };

  const getCompanyData = () => {
    if (props.params.companyId) {
      Api("GET", `Companies/GetCompanyById?companyId=${props.params.companyId}`)
        .then((response) => {
          if (response.data) {
            setCompanyData(response.data);
            localStorage.setItem("companyLogoPath", response.data.companyImage);
            localStorage.setItem("companyName", response.data.name);
            localStorage.setItem("timeZone", response.data.timeZone);
          } else {
            setCompanyData([]);
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setIsLoader(false);
        });
    }
  };

  const toggleDrawer = (anchor, open) => {
    setSideBar({ ...showSideBar, [anchor]: open });
  };

  const clearData = (menu) => {
    if (menu.key === "jobs") {
      dispatch({
        type: JOB_DATA_ACTIONS.SET_JOBS_ARRAY,
        payload: [],
      });

      dispatch({
        type: APPLICANT_DATA_ACTIONS.SET_APPLICANTS_ARRAY,
        payload: [],
      });

      dispatch({
        type: VIDEO_DATA_ACTIONS.SET_VIDEOS_ARRAY,
        payload: [],
      });

      dispatch({
        type: SEARCH_ACTIONS.SET_SEARCH_FLAG,
        payload: false,
      });
    }
  };

  return (
    <div className="parent-outer-main">
      {/* side-bar html call */}

      <EditCompanyForm
        showAddEditLabel={showAddEditLabel}
        showSideBar={showSideBar}
        toggleDrawer={toggleDrawer}
        handleSubmit={handleSubmit}
        formValues={formValues}
        setFormValues={setFormValues}
        editImageShow={editImageShow}
        setEditImageShow={setEditImageShow}
        formErrors={formErrors}
      />

      <div className="sidebar-company">
        <div className="sidebar-header">
          <img
            src={
              companyData.companyImage?.split('/').at(-1) === ""
                ? require("../../../assets/images/dummy-img.png")
                : companyData.companyImage
            }
            alt="logo"
            className="company-logo"
          />
          <p className={`company-names ${companyData.name && companyData.name.length > 50 ? 'with-spaces' : ''}`} title={companyData.name}>
            {companyData.name}
          </p>
          <Tooltip title="Edit company details">
            <IconButton
              className="company-edit"
              onClick={() => (
                toggleDrawer("right", true),
                setAddEditLabel("Edit Company"),
                setFormValues(companyData),
                setEditImageShow(true)
              )}
            >
              <EditIcon color="action" />
            </IconButton>
          </Tooltip>
        </div>
        <div className="sidebar-content">
          {menu_options.map(function (menu) {
            if (
              !(
                companyData.parentCompanyId != null &&
                menu.name === "Sub Companies"
              )
            ) {
              return (
                <div
                  key={menu.id}
                  className={
                    props.params.componentName == menu.key
                      ? "dashboard active"
                      : "dashboard"
                  }
                >
                  <Link
                    to={"/" + menu.key}
                    state={{
                      companyId: companyId,
                      companyHirerchy: companyHirerchy,
                      componentName: menu.key,
                    }}
                    onClick={() => clearData(menu)}
                  >
                    <span className="dashboard_text icon">{menu.name}</span>
                  </Link>
                </div>
              );
            }
          })}
        </div>
      </div>
    </div>
  );
}
