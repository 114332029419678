import React, { useEffect, useRef, useState } from "react";
import { AddEditPopup } from "../common/addEditPopup";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import { FilesIcon, CloseXIcon } from "../../icons";
import { toast } from "react-toastify";

export const AddEditCompany = ({
  formValues,
  setFormValues,
  editImageShow,
  setEditImageShow,
  formErrors,
  editMode,
  handleSubmit,
  label,
  allTimeZone,
  toggleDrawer,
  openSidebar,
  isSubmitting,
  attachment,
  setAttachment,
  resetChildState,
}) => {
  const attachRef = useRef(null);
  //const [attachment, setAttachment] = useState("");
  const [imageErrorMsg, setImageErrorMsg] = useState("");

  const handleChange = (e) => {
    setFormValues({ ...formValues, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    if (attachment) {
      checkFileSize(attachment);
    }
  }, [attachment]);

  const checkFileSize = (file) => {
    if (file && file.size > 5 * 1024 * 1024) {
      // 5 MB in bytes
      setImageErrorMsg("Please upload an image smaller than 5 MB.");
      setAttachment("");
    } else {
      setImageErrorMsg("");
    }
  };

  const handleCloseDrawer = () => {
    resetChildState(); // Reset child state when closing the drawer
    toggleDrawer("right", false); // Close the drawer
  };

  const handleLogoUpload = () => {
    attachRef.current.click();
  };

  const uploadResume = (e) => {
    if (e.target.files[0].size <= 5 * 1000 * 1024) {
      setAttachment(e.target.files[0]);
      setFormValues({ ...formValues, ["companyLogo"]: e.target.files[0] });
    } else {
      toast.error("File size is too large !!!");
    }
  };

  return (
    <AddEditPopup
      toggleDrawer={toggleDrawer}
      openSidebar={openSidebar}
      handleSubmit={handleSubmit}
      title={label}
      isSubmitting={isSubmitting}
    >
      <input
        type="file"
        ref={attachRef}
        hidden
        onChange={uploadResume}
        key={attachment}
        accept="image/png, image/jpg, image/jpg"
      />

      <div className="forms-input">
        <label>Name</label>
        <input
          type="text"
          placeholder="Name"
          name="name"
          id="name"
          value={formValues.name}
          onChange={handleChange}
        />
        <span className="error_msg">{formErrors.name}</span>
      </div>
      <div className="forms-input">
        <label>Job Number Prefix</label>
        <input
          type="text"
          placeholder="Job Number Prefix here"
          name="jobPrefix"
          id="jobPrefix"
          value={formValues.jobPrefix}
          onChange={handleChange}
          readOnly={editMode}
          style={{ backgroundColor: editMode ? "#DCDCDC" : "" }}
        />
        <span className="error_msg">{formErrors.jobPrefix}</span>
      </div>
      <div className="forms-input">
        <label>Timezone</label>
        <select
          name="timeZone"
          id="timeZone"
          value={formValues.timeZone}
          onChange={handleChange}
        >
          <option value="none">Select</option>
          {allTimeZone.map((time) => (
            <option key={time.id} value={time.timeZone}>
              {time.timeZone}
            </option>
          ))}
        </select>
        <span className="error_msg">{formErrors.timeZone}</span>
      </div>
      <p className="max-applicant">
        Maximum Applicants Allowed per Job (leave 0 for unlimited)
      </p>
      <div className="forms-input">
        <label>Total</label>
        <input
          type="number"
          placeholder="Total Applicants"
          name="totalJob"
          id="totalJob"
          value={formValues.totalJob}
          onChange={handleChange}
          min="0"
        />
        <span className="error_msg">{formErrors.totalJob}</span>
      </div>
      <div className="forms-input">
        <label>Per Job Board</label>
        <input
          type="number"
          placeholder="Applicants per job board"
          name="perJobBoard"
          id="perJobBoard"
          value={formValues.perJobBoard}
          onChange={handleChange}
          min="0"
        />
        <span className="error_msg">{formErrors.perJobBoard}</span>
      </div>
      {editImageShow ? (
        <div className="forms-input">
          <label>Company logo:</label>
          <div className="upload-file-shower">
            <img
              src={formValues.companyLogo}
              alt="preview-img"
              className="show-img"
            />
            <button
              onClick={() => (
                setEditImageShow(false),
                (formValues.companyLogo = ""),
                setAttachment("")
              )}
              className="icon icon_button"
            >
              <img
                src={require("../../assets/images/close-upload.png")}
                alt="close"
                className="close-img"
              />
            </button>
          </div>
        </div>
      ) : attachment ? (
        <div className="forms-input">
          <label>Company logo:</label>
          <div className="doucments-card applicant">
            <div className="doc-detail">
              <FilesIcon
                stroke="#9EA0A5"
                line="#fff"
                width="32px"
                height="32px"
              />
              <div className="file-name-label">{attachment.name}</div>
            </div>
            <div
              className="doc-detail"
              role="button"
              onClick={() => setAttachment("")}
            >
              <div className="pointer">
                <CloseXIcon width="28px" height="28px" />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="forms-input">
          <label>Company logo:</label>
          <div
            role="button"
            className="mt-10 document applicant pointer"
            onClick={() => handleLogoUpload()}
          >
            <div className="documents-upload">
              <span>Upload Document</span>
              <CloudUploadOutlinedIcon width="32" height="32" />
            </div>
          </div>
          <span className="error_msg">{formErrors.companyImage}</span>
          {/* <span className="error_msg">{imageErrorMsg ? imageErrorMsg : ""}</span> */}
        </div>
      )}
    </AddEditPopup>
  );
};
