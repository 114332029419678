import React, { useEffect } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { useState } from "react";
import { styled } from "@mui/material/styles";
import { Box, Tooltip } from "@mui/material";
import {
  ActivityIcon,
  ChatWithBadgeIcon,
  CheckBoxIcon,
  DescriptionIcon,
  FilesIcon,
  NotePencilIcon,
  ThumbsUpIcon,
} from "../../../../../../icons";
import "../../../../../../assets/css/components/company-detail/_applicantTabs.css";
import { ResumeTab } from "./resume";
import { NotesTab } from "./notes";
import { DocumentsTab } from "./documents";
import { ActivityTab } from "./activity";
import { CheckListTab } from "./checklist";
import { HotApplicantTab } from "./hotApplicant";
import { ChatTab } from "./chat";
import { useSelector } from "react-redux";

const StyledTabs = styled((props) => (
  <Tabs
    {...props}
    TabIndicatorProps={{
      children: <span className="MuiTabs-indicatorSpan" />,
    }}
  />
))({
  display: "flex",
  flex: 1,
  "& .MuiTabs-flexContainer": {
    display: "flex",
    flex: 1,
  },
  "& .MuiTabs-indicator": {
    display: "flex",
    flex: 1,
    justifyContent: "center",
    backgroundColor: "transparent",
  },
  "& .MuiTabs-indicatorSpan": {
    maxWidth: 20,
    minWidth: 50,
    width: "100%",
    backgroundColor: "#3954A5",
  },
});

const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    textTransform: "none",
    color: "#3954A5",
    display: "flex",
    flex: 1,
    padding: 2,
    maxWidth: 20,
    minWidth: 50,
    "&.Mui-selected": {
      color: "#3954A5",
    },
    "&.Mui-focusVisible": {
      backgroundColor: "rgba(100, 95, 228, 0.32)",
    },
  })
);

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 0 }}>{children}</Box>}
    </div>
  );
}

export const ApplicantInfoTabs = ({
  applicantData,
  selectedSuitCase,
  cvUpdated,
  setCvUpdated,
}) => {
  const { applicantsArray } = useSelector(
    (state) => state.applicantDataReducer
  );
  const [value, setValue] = useState(0);
  const [comment, setComment] = useState("");
  const [applicantDetails, setApplicantDetails] = useState({});

  useEffect(() => {
    if (
      applicantData?.applicantList !== undefined &&
      applicantData?.applicantList.length > 0
    ) {
      const applicant = applicantData?.applicantList?.find((rec) => {
        if (
          rec !== null &&
          rec !== undefined &&
          rec.applicantId === applicantData.applicantId
        ) {
          return rec;
        }
      });
      setApplicantDetails(applicant);
      setComment(applicant.hotListComment);
    } else {
      setApplicantDetails(applicantData);
      setComment(applicantData.hotListComment);
    }
  }, [applicantsArray]);

  const handleChange = (newValue) => {
    (newValue !== value) ? setValue(newValue) : setValue(7);
  };

  return (
    <div className="user-details-tab">
      <div style={{ borderBottom: "1px solid rgba(57, 84, 165, 0.2)" }}>
        <StyledTabs
          value={value}
          aria-label="styled tabs example"
        >
          <StyledTab
            onClick={() => handleChange(0)}
            icon={
              <Tooltip title="Resume">
                <div
                  style={{
                    background: value === 0 && "#3954A5",
                  }}
                  className="tabs"
                >
                  <DescriptionIcon
                    fill={value === 0 ? "#fff" : "#8898C9"}
                    width="18px"
                    height="18px"
                  />
                </div>
              </Tooltip>
            }
            {...a11yProps(0)}
          />
          <StyledTab
            onClick={() => handleChange(1)}
            icon={
              <Tooltip title="Notes">
                <div
                  style={{
                    background: value === 1 && "#3954A5",
                  }}
                  className="tabs"
                >
                  <NotePencilIcon
                    fill={value === 1 ? "#fff" : "#8898C9"}
                    width="18px"
                    height="18px"
                  />
                </div>
              </Tooltip>
            }
            {...a11yProps(1)}
          />
          <StyledTab
            onClick={() => handleChange(2)}
            icon={
              <Tooltip title="Hot List">
                <div
                  style={{
                    background: value === 2 && "#3954A5",
                  }}
                  className="tabs"
                >
                  <ThumbsUpIcon
                    stroke={
                      applicantDetails.isHotList ? "#E6492D" : value === 2 ? "#FFF" : "#8898C9"
                    }
                    fill={
                      applicantDetails.isHotList ? "#FFDFDF" : value === 2 ? "#3954A5" : "#ffff"
                    }
                    width="18px"
                    height="18px"
                  />
                </div>
              </Tooltip>
            }
            {...a11yProps(2)}
          />
          <StyledTab
            onClick={() => handleChange(3)}
            icon={
              <Tooltip title="Chat">
                <div
                  style={{
                    background: value === 3 && "#3954A5",
                  }}
                  className="tabs"
                >
                  <ChatWithBadgeIcon
                    stroke={value === 3 ? "#fff" : "#8898C9"}
                    width="18px"
                    height="18px"
                  />
                </div>
              </Tooltip>
            }
            {...a11yProps(3)}
          />
          <StyledTab
            onClick={() => handleChange(4)}
            icon={
              <Tooltip title="Check list items">
                <div
                  style={{
                    background: value === 4 && "#3954A5",
                  }}
                  className="tabs"
                >
                  <CheckBoxIcon
                    stroke={value === 4 ? "#fff" : "#8898C9"}
                    width="18px"
                    height="18px"
                  />
                </div>
              </Tooltip>
            }
            {...a11yProps(4)}
          />
          <StyledTab
            onClick={() => handleChange(5)}
            icon={
              <Tooltip title="Documents">
                <div
                  style={{
                    background: value === 5 && "#3954A5",
                  }}
                  className="tabs"
                >
                  <FilesIcon
                    stroke={value === 5 ? "#fff" : "#8898C9"}
                    line={value === 5 ? "#8898C9" : "#fff"}
                    width="18px"
                    height="18px"
                  />
                </div>
              </Tooltip>
            }
            {...a11yProps(5)}
          />
          <StyledTab
            onClick={() => handleChange(6)}
            icon={
              <Tooltip title="Activities">
                <div
                  style={{
                    background: value === 6 && "#3954A5",
                  }}
                  className="tabs"
                >
                  <ActivityIcon
                    stroke={value === 6 ? "#fff" : "#8898C9"}
                    width="18px"
                    height="18px"
                  />
                </div>
              </Tooltip>
            }
            {...a11yProps(6)}
          />
        </StyledTabs>
      </div>
      <TabPanel value={value} index={0}>
        {value === 0 && (
          <ResumeTab
            applicantData={applicantData}
            selectedSuitCase={selectedSuitCase}
            cvUpdated={cvUpdated}
            setCvUpdated={setCvUpdated}
          />
        )}
      </TabPanel>
      <TabPanel value={value} index={1}>
        {value === 1 && (
          <NotesTab
            applicantData={applicantData}
            selectedSuitCase={selectedSuitCase}
          />
        )}
      </TabPanel>
      <TabPanel value={value} index={2}>
        {value === 2 && (
          <HotApplicantTab
            applicantDetails={applicantDetails}
            comment={comment}
            setComment={setComment}
            selectedSuitCase={selectedSuitCase}
            applicantData={applicantData}
          />
        )}
      </TabPanel>
      <TabPanel value={value} index={3}>
        {value === 3 && (
          <ChatTab
            applicantData={applicantData}
            selectedSuitCase={selectedSuitCase}
          />
        )}
      </TabPanel>
      <TabPanel value={value} index={4}>
        {value === 4 && (
          <CheckListTab
            applicantData={applicantData}
            selectedSuitCase={selectedSuitCase}
          />
        )}
      </TabPanel>
      <TabPanel value={value} index={5}>
        {value === 5 && (
          <DocumentsTab
            applicantData={applicantData}
            selectedSuitCase={selectedSuitCase}
          />
        )}
      </TabPanel>
      <TabPanel value={value} index={6}>
        {value === 6 && (
          <ActivityTab
            applicantData={applicantData}
            selectedSuitCase={selectedSuitCase}
          />
        )}
      </TabPanel>
      <TabPanel value={value} index={6}>
        {value === 7 && (
          <></>
        )}
      </TabPanel>
    </div>
  );
};
