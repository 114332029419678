import { SEQUENCE_DATA_ACTIONS } from "./actions";

const initialState = {
  seqArray: [],
};

export const sequenceReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SEQUENCE_DATA_ACTIONS.SET_SEQ_DATA:
      return { ...state, seqArray: payload };

    default:
      return state;
  }
};
