import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "@fontsource/inter";
import "../../../assets/css/components/login/_login.css";
import { ToastAlert, userAsCompany } from "../../../helper/constants";
import Api from "../../../helper/Api";
import Loader from "../../../components/common/Loader";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { validations } from "../../../helper/validations";

export function ForgotPassword() {
  const [errorMessages, setErrorMessages] = useState({});
  const initialValues = { Email: "" };
  const [formValues, setFormValues] = useState(initialValues);
  const [isLoader, setIsLoader] = useState(false);
  const [openToastBar, setOpenToastBar] = useState(false);
  const formValuesPhone = {};
  let navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!formValues.Email) {
      setErrorMessages({
        name: "Email",
        message: "Email or " + validations?.mobile?.req,
      });
    } else if (
      !validations?.email?.pattern?.value.test(formValues.Email) &
      !validations?.mobile?.pattern?.value.test(formValues.Email)
    ) {
      setErrorMessages({ name: "Email", message: validations?.mobile?.custom });
    } else if (validations?.mobile?.pattern?.value.test(formValues.Email)) {
      formValuesPhone["PhoneNumber"] = formValues.Email;
      setIsLoader(true);
      setErrorMessages({});
      Api("POST", "account/forgotpassword", formValuesPhone)
        .then((response) => {
          if (response.data.isSuccess) {
            const userId = response.data.id;
            setIsLoader(false);
            toast.success(response.data.message);
            setOpenToastBar(true);
            setTimeout(() => {
              navigate("/otp?userId=" + userId);
            }, 2000);
          } else {
            setIsLoader(false);
            toast.error(response.data.message);
            setOpenToastBar(true);
          }
        })
        .catch((error) => {
          if (!error?.response) {
            toast.error("Invalid Number");
            setOpenToastBar(true);
          }
          setIsLoader(false);
        });
    } else {
      setIsLoader(true);
      setErrorMessages({});
      Api("POST", "account/forgotpassword", formValues)
        .then((response) => {
          if (response.data.isSuccess) {
            setIsLoader(false);
            toast.success(response.data.message);
            setOpenToastBar(true);
            setTimeout(() => {
              navigate("/login");
            }, 2000);
          } else {
            setIsLoader(false);
            toast.error(response.data.message);
            setOpenToastBar(true);
          }
        })
        .catch((error) => {
          if (!error?.response) {
            toast.error("Invalid Email");
            setOpenToastBar(true);
          }
          setIsLoader(false);
        });
    }
  };

  // Generate JSX code for error message
  const renderErrorMessage = (name) =>
    name === errorMessages.name && (
      <span className="error_msg">{errorMessages.message}</span>
    );

  // JSX code for login form
  return (
    <div className="main-container">
      <Loader inShow={isLoader} />
      {openToastBar ? <ToastAlert /> : null}
      <div className="inner-container">
        <div className="rec4">
          <div className="left_form">
            <div className="forgotkey_img">
              <img
                src={require("../../../assets/images/Ellipse.png")}
                alt="left-img"
                className=""
              />
            </div>
            <div className="login_title active">
              <h2 className="title_1 center">Forgot Password</h2>
              <p className="title_2 center">
                No worries, we'll send you reset instructions.
              </p>
            </div>
            <div className="login_form">
              <form onSubmit={handleSubmit}>
                <div className="email_box">
                  <label className="email_label" htmlFor="Email">
                    Email / Mobile
                  </label>
                  <input
                    className="email_input"
                    type="text"
                    name="Email"
                    id="Email"
                    placeholder="Enter email or mobile"
                    onChange={handleChange}
                  />
                  {renderErrorMessage("Email")}
                </div>
                <div className="submit_box">
                  <input
                    type="submit"
                    className="login-submit-btn"
                    value="Submit"
                  />
                </div>
              </form>
            </div>
            <div className="back_to_login">
              <img
                src={require("../../../assets/images/Vector.png")}
                alt="vector"
                className="vector_img"
              />
              <Link to="/login" className="login_link">
                Back to log in
              </Link>
            </div>
          </div>
        </div>
        <div
          className={
            userAsCompany(window.location.hostname) ? "rec5 active" : "rec5"
          }
        ></div>
      </div>
    </div>
  );
}
