import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import "../../../assets/css/components/notification/_notification.css";
import "../../../assets/css/components/applicants/_applicants.css";
import { useState } from "react";
import Api from "../../../helper/Api";
import { useDispatch } from "react-redux";
import { APPLICANT_DATA_ACTIONS } from "../../../redux/applicantData/actions";
import { AddApplicantForm } from "../../../components/common/layouts/jobInfo/content/addApplicant";
import { toast } from "react-toastify";
import { NoColDataFound } from "../../../components/common/NoColDataFound";
import { ApplicantContent } from "../../../components/common/layouts/applicantInfo/content";
import { ProfileCard } from "../../../components/common/cards/ProfileCard";
import { ColoredLinearProgress } from "../../../components/common/coloredProgressBar";
import { useSelector } from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";
import { EclipseLoader } from "../../../components/common/eclipseLoader";
import { Search } from "../../pages/search/search";
import { JobSidebar } from "../../../components/common/layouts/jobInfo/sidebar";

export function Applicant() {
  const location = useLocation();
  const [pageNumber, setPage] = useState(1);
  const [applicantList, setApplicantList] = useState([]);
  const [isLoader, setLoader] = useState(true);
  const [openSidebar, setSidebar] = useState(false);
  const [formValues, setFormValues] = useState({});
  const [ids, setIds] = useState({});
  const dispatch = useDispatch();
  const formData = new FormData();
  const [infLoader, setInfLoader] = useState(true);
  const { isSearchColOpen } = useSelector((state) => state.globalSearchReducer);
  const { jobsArray } = useSelector((state) => state.jobDataReducer);
  const [selectedStatus, setSelectedStatus] = useState("");
  const [totalPage, setTotalPage] = useState(1); // Default to 1 or set the initial value accordingly
  const { applicantsArray } = useSelector(
    (state) => state.applicantDataReducer
  );
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    // Check if there's no need to make the API call
    if (pageNumber > totalPage) {
      // No need to call the API, update loaders and return
      setInfLoader(false);
      setLoader(false);
      return;
    }

    getAllApplicants();
  }, [pageNumber, selectedStatus, totalPage]); // Add totalPage to the dependency array

  const handleStatusChange = (status) => {
    setSelectedStatus(status);
    setApplicantList([]);
    setPage(1);
    // getAllApplicants(selectedStatus)
  };

  const getAllApplicants = (status = "") => {
    setLoading(true);
    Api(
      "GET",
      `Job/GetApplicantList?companyId=${location.state.companyId}&pageLimit=24&pageNumber=${pageNumber}&status=${selectedStatus}`
    )
      .then((res) => {
        const newData = res.data.data.filter(
          (newApplicant) =>
            !applicantList.some(
              (existingApplicant) =>
                existingApplicant.applicantId === newApplicant.applicantId
            )
        );
        // Concatenate new data with existing list
        const updatedList = [...applicantList, ...newData];
        setApplicantList(updatedList);
        setTotalPage(res.data.totalPages);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
        setInfLoader(false);
        setLoader(false);
      });
  };

  const toggleDrawer = (anchor, open) => {
    setSidebar({ ...openSidebar, [anchor]: open });
  };

  const editApplicant = () => {
    formValues.applicantPhoneList.map((phone, index) => {
      if (phone.phone !== "" && phone.isNotify !== "") {
        formData.append("applicantPhoneList[" + index + "].phone", phone.phone);
        formData.append("applicantPhoneList[" + index + "].applicantNotificationId", phone.applicantNotificationId);
        formData.append("applicantPhoneList[" + index + "].isNotify", phone.isNotify);
        formData.append("applicantPhoneList[" + index + "].isDelete", phone.isDelete);
      }
    });

    formValues.applicantEmailList.map((emailId, index) => {
      if (emailId.phone !== "" && emailId.isNotify !== "") {
        formData.append("applicantEmailList[" + index + "].email", emailId.email);
        formData.append("applicantEmailList[" + index + "].applicantNotificationId", emailId.applicantNotificationId);
        formData.append("applicantEmailList[" + index + "].isNotify", emailId.isNotify);
        formData.append("applicantEmailList[" + index + "].isDelete", emailId.isDelete);
      }
    });

    formData.append("firstName", formValues?.firstName ?? "");
    formData.append("middleName", formValues?.middleName ?? "");
    formData.append("lastName", formValues?.lastName ?? "");
    formData.append("nickName", formValues?.nickName ?? "");
    formData.append("title", formValues?.title ?? "");
    formData.append("emailAddress", formValues?.emailAddress ?? "");
    formData.append("phoneNumber", formValues?.phoneNumber ?? "");
    formData.append("resume", formValues?.resume ?? "");
    formData.append("viewResume", formValues?.viewResume ?? "");
    formData.append("companyId", ids.companyId);
    formData.append("jobId", ids.jobId);
    formData.append("applicantId", ids.applicantId);
    formData.append("address.addressLineOne", formValues?.address?.addressLineOne ?? "");
    formData.append(
      "address.addressLineTwo",
      formValues?.address?.addressLineTwo ?? ""
    );
    formData.append("address.country", "");
    formData.append("address.city", formValues?.address?.city ?? "");
    formData.append("address.state", formValues?.address?.state ?? "");
    formData.append("address.zipCode", formValues?.address?.zipCode ?? "");
    formData.append("source", formValues?.source ?? "");

    Api("PUT", `Job/EditApplicant`, formData, "multipart")
      .then((res) => {
        if (res.data.isSuccess === true) {
          toast.success(res.data.message);
          toggleDrawer("right", false);
          getAllApplicants();
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const openApplicantDetails = (applicantData) => {
    if (applicantData.applicantList[0].jobCycleStatus === "NEW_APPLICATION") {
      Api(
        "PUT",
        `Job/UpdateCurrentStatus?companyId=${applicantData.companyId}&jobId=${applicantData.applicantList[0].jobId}&applicantId=${applicantData.applicantId}&status=REVIEWING`
      )
        .then((res) => {
          if (res.data.isSuccess === true) {
            applicantData.applicantList[0].jobCycleStatus = "REVIEWING";
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }

    const record = {
      ...applicantData,
      applicantList: applicantData.applicantList.map((rec) => {
        if (rec.applicantId === applicantData.applicantId) {
          return {
            ...rec,
            isHotList: rec.isHotList,
          };
        }

        return rec;
      }),
    };

    const isPresent = applicantsArray.some(
      (d) => d.applicantId === record.applicantId
    );

    if (isPresent === false) {
      const data = [record, ...applicantsArray];
      dispatch({
        type: APPLICANT_DATA_ACTIONS.SET_APPLICANTS_ARRAY,
        payload: data,
      });
    }
  };

  const fetchMore = () => {
    setInfLoader(true);
    setPage((prevState) => prevState + 1);
  };

  return (
    <div>
      {/* Edit Applicant form */}
      {openSidebar && (
        <AddApplicantForm
          openAddForm={openSidebar}
          toggleDrawer={toggleDrawer}
          handleSubmit={editApplicant}
          formValues={formValues}
          setFormValues={setFormValues}
        />
      )}

      <div className="company_content help-container applicants">
        <div className="common-container users company">
          <div className="inner-container company">
            <div className="user-container company">
              <div className="user-list">
                <div className="applicant-profile">
                  <div className="applicants-data">
                    <strong>Applicants</strong>
                  </div>
                </div>

                <select onChange={(e) => handleStatusChange(e.target.value)}>
                  <option value="">Select Status</option>
                  <option value="NEW_APPLICATION">New Application</option>
                  <option value="REVIEWING">Reviewing</option>
                  <option value="VIDEO_INTERVIEW">Video Interview</option>
                  <option value="INTERVIEWING">Interviewing</option>
                  <option value="OFFERING">Offering</option>
                  <option value="OFFER_LETTER">Offer Letter</option>
                  <option value="PREHIRE">Prehire</option>
                  <option value="ONBOARDING">Onboarding</option>
                  <option value="HIRE">Hire</option>
                </select>
                {isLoading && applicantList.length <= 0 ? (
                  <div className="margin-top-10"><ColoredLinearProgress /></div>
                ) : applicantList?.length > 0 && selectedStatus !== null ? (
                  <div className="inner-h applicant-list full">
                    <div>
                      <ul className="job_template_option_menu pointer">
                        <InfiniteScroll
                          height={window.innerHeight <= 678 ? window.innerHeight * 0.666 : window.innerHeight * 0.722}
                          dataLength={applicantList?.length || 0}
                          next={fetchMore}
                          hasMore={applicantList?.length !== applicantList?.totalRecords}
                          loader={
                            infLoader && (
                              <h4 style={{ textAlign: "center" }}>
                                <EclipseLoader />
                              </h4>
                            )
                          }
                          endMessage={
                            <p style={{ textAlign: "center" }}>
                              <b>Yay! You have seen it all</b>
                            </p>
                          }
                        >
                          <div>
                            {applicantList?.length > 0 &&
                              applicantList?.map((applicant) => (
                                <div key={Math.random()}>
                                  <ProfileCard
                                    applicantId={applicant.applicantId}
                                    name={applicant.firstName + " " + applicant.lastName}
                                    jobName={applicant?.jobName}
                                    jobId={applicant.jobId}
                                    companyId={applicant.companyId}
                                    jobCode={applicant.jobNo}
                                    morePositions={applicant.morePositions}
                                    createdAt={applicant.createdAt}
                                    openApplicantDetails={openApplicantDetails}
                                    checked={applicant.checked}
                                    latestActivityMessage={applicant?.latestActivityMessage}
                                    applicantList={applicantList}
                                    jobCycleStatus={applicant.jobCycleStatus}
                                    setApplicantList={setApplicantList}
                                    setAllChecked={true}
                                    status={applicant.jobHiredStatus}
                                    applicant={applicant}
                                  />
                                </div>
                              ))}
                          </div>
                        </InfiniteScroll>
                      </ul>
                    </div>
                  </div>
                ) : (
                  selectedStatus !== "" && <NoColDataFound />
                )}

              </div>

              {/* Global search column start */}
              {isSearchColOpen && <Search />}
              {/* Global search column end */}

              {jobsArray?.map((data) => (
                <JobSidebar key={data.jobId} jobData={data} />
              ))}

              {applicantsArray?.map((data, index) => (
                <ApplicantContent
                  data={data}
                  index={index}
                  key={data.applicantId}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
