export const ImageIcon = ({ ...props }) => {
  return (
    <svg
      {...props}
      viewBox="0 0 18 17"
      fill={props.fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.4616 2.90039H3.26227C2.99224 2.90039 2.73328 3.00766 2.54234 3.19859C2.35141 3.38953 2.24414 3.64849 2.24414 3.91852V13.0816C2.24414 13.3517 2.35141 13.6106 2.54234 13.8016C2.73328 13.9925 2.99224 14.0998 3.26227 14.0998H14.4616C14.7317 14.0998 14.9906 13.9925 15.1816 13.8016C15.3725 13.6106 15.4798 13.3517 15.4798 13.0816V3.91852C15.4798 3.64849 15.3725 3.38953 15.1816 3.19859C14.9906 3.00766 14.7317 2.90039 14.4616 2.90039ZM10.6437 5.95477C10.7947 5.95477 10.9423 5.99955 11.0679 6.08345C11.1935 6.16736 11.2913 6.28662 11.3491 6.42614C11.4069 6.56567 11.4221 6.71921 11.3926 6.86733C11.3631 7.01545 11.2904 7.15151 11.1836 7.2583C11.0768 7.36509 10.9408 7.43782 10.7926 7.46728C10.6445 7.49674 10.491 7.48162 10.3515 7.42383C10.2119 7.36603 10.0927 7.26816 10.0088 7.14259C9.92486 7.01702 9.88008 6.86938 9.88008 6.71836C9.88008 6.51584 9.96053 6.32162 10.1037 6.17842C10.2469 6.03522 10.4412 5.95477 10.6437 5.95477ZM3.26227 13.0816V11.2999L6.57117 7.99102L11.6618 13.0816H3.26227ZM14.4616 13.0816H13.1018L10.811 10.7909L12.0837 9.5182L14.4616 11.8968V13.0816Z"
        fill={props.fill}
      />
    </svg>
  );
};
