import { useEffect } from "react";
import { useSelector } from "react-redux";
import Api from "../../../../../../../helper/Api";
import { useState } from "react";
import { validations } from "../../../../../../../helper/validations";
import { sortAtoZ } from "../../../../../../../helper/constants";
import { AddEditBox } from "../../../../../addEditPopup/AddEditBox";

export const OfferLetterForm = ({
  openForm,
  toggleForm,
  handleSubmit,
  applicantId,
  formErrors,
  setFormErrors,
  isSubmitting,
}) => {
  const { companyData } = useSelector((state) => state.companyDataReducer);
  const [offerLetters, setOfferLetters] = useState([]);
  const [documentId, setDocumentId] = useState("");
  const [isLoading, setLoading] = useState(true);
  const errors = {};

  useEffect(() => {
    Api(
      "GET",
      `Job/GetOfferLetterDocumentsDropdown?companyId=${companyData.companyId}`
    )
      .then((res) => {
        setOfferLetters(sortAtoZ(res.data.data.lstDdl, "internalName"));
      })
      .finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    if (documentId) setFormErrors(validate());
  }, [documentId]);

  const saveForm = (e) => {
    e.preventDefault();
    setFormErrors(validate());
    if (Object.keys(validate()).length > 0) {
      return;
    }

    handleSubmit(documentId);
  };

  const validate = () => {
    if (!documentId) {
      errors.offerLetter = validations.offerLetter.req;
    }

    return errors;
  };

  const handleChange = (e) => {
    setDocumentId(e.target.value);
  };

  return (
    <AddEditBox
      openForm={openForm}
      toggleForm={toggleForm}
      title="Create Offer Letter"
      handleSubmit={saveForm}
      applicantId={applicantId}
      isSubmitting={isSubmitting}
      isLoading={isLoading}
    >
      <div className="forms-input">
        <label>Offer letter</label>
        <select onChange={handleChange}>
          <option>Select</option>
          {offerLetters?.map((offer) => (
            <option value={offer.documentId}>{offer.internalName}</option>
          ))}
        </select>
        <span className="error_msg">{formErrors?.offerLetter}</span>
      </div>
    </AddEditBox>
  );
};
