import { Fragment, useState } from "react";
import { StyledTableCell } from "../../ui/table/table";
import { StyledTableRow } from "../../ui/table/table";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import DraggableList from "./DraggableList";
import { EditQuestion } from "../../companyDetail/videoInterviews/editQuestion";
import Api from "../../../helper/Api";
import { API_types } from "../../../helper/enum";
import { toast } from "react-toastify";
import { EditDeleteMenu } from "../../ui/editDeleteMenu";

export const AccordianList = (props) => {
  const [open, setOpen] = useState(false);
  const [openSidebar, setSidebar] = useState(false);
  const initialValues = {
    videoInterviewId: props.items.videoInterviewId,
    companyId: props.items.companyId,
    prepTime: 30,
    responseTime: 180
  };
  const [formValues, setFormValues] = useState(initialValues);
  const [questionLabel, setQuestionLabel] = useState("");
  const [editMode, setEditMode] = useState(false);

  const toggleDrawer = (anchor, open) => {
    setSidebar({ ...openSidebar, [anchor]: open });
  };

  const editQuestion = (data) => {
    setEditMode(true);
    data.videoInterviewId = props.items.videoInterviewId;
    data.companyId = props.items.companyId;
    setFormValues(data);
    toggleDrawer("right", true);
    setQuestionLabel("Edit Question");
  };

  const deleteQuestion = (data) => {
    var answer = window.confirm("Are you sure you want to delete this ?");
    if (answer) {
      // DELETE Question api call
      Api(
        API_types.DELETE,
        `VideoInterview/DeleteQuestion?videoInterviewId=${props.items.videoInterviewId}&companyId=${props.items.companyId}&questionsId=${data.questionsId}`
      )
        .then((res) => {
          if (res.data.isSuccess) {
            toast.success(res.data.message);
            props.getData();
          } else {
            toast.error(res.data.message);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const addQuestion = () => {
    toggleDrawer("right", true);
    setQuestionLabel("Add Question");
    setEditMode(false);
    setFormValues(initialValues);
  };

  return (
    <Fragment>
      <EditQuestion
        openSidebar={openSidebar}
        toggleDrawer={toggleDrawer}
        formValues={formValues}
        setFormValues={setFormValues}
        questionLabel={questionLabel}
        editMode={editMode}
        getData={props.getData}
      />

      <StyledTableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <StyledTableCell component="th" scope="row" align="left">
          {props.items.name}
        </StyledTableCell>
        <StyledTableCell scope="row" align="right">
          <span className="greyColour">Allow Redo Request: </span>
          <strong>{props.items.allowRadorequest ? "Yes" : "No"}</strong>
        </StyledTableCell>
        <StyledTableCell scope="row" align="center" width="70px">
          <span
            className={props.items.isActive ? "status green-active" : "status"}
          >
            {props.items.isActive ? "ACTIVE" : "INACTIVE"}
          </span>
        </StyledTableCell>
        <StyledTableCell scope="row" align="right" width="50px">
          {props.items.companyId === props.companyId && (
            <EditDeleteMenu
              id={props.items.videoInterviewId}
              onEdit={props.editInterview}
              onDelete={props.deleteInterview}
              addOption={true}
              onAdd={addQuestion}
            />
          )}
        </StyledTableCell>
        <StyledTableCell align="right" width="50px">
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? (
              <KeyboardArrowUpIcon color="primary" />
            ) : (
              <KeyboardArrowDownIcon color="action" />
            )}
          </IconButton>
        </StyledTableCell>
      </StyledTableRow>
      <StyledTableRow>
        <StyledTableCell
          style={{ paddingBottom: 0, paddingTop: 0 }}
          colSpan={6}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <DraggableList
                items={props.items.questions}
                formValues={formValues}
                handleEdit={editQuestion}
                handleDelete={deleteQuestion}
                companyId={props.companyId}
                companyHirerchy={props.companyHirerchy}
                qCompanyId={props.items.companyId}
              />
            </Box>
          </Collapse>
        </StyledTableCell>
      </StyledTableRow>
    </Fragment>
  );
};
