import { CheckboxWithBlueCheck } from "../../../../../ui/checkbox/checkboxWithBlueTick";
import { FormControlLabel } from "@mui/material";
import { useEffect, useState } from "react";
import Api from "../../../../../../helper/Api";
import { AddEditCol } from "../../../../addEditPopup/AddEditCol";
import { sortAtoZ } from "../../../../../../helper/constants";

export const JobSelectForm = ({
  applicantId,
  companyId,
  openForm,
  toggleForm,
  handleJobSelect,
  isSubmitting,
  jobId,
}) => {
  const [allData, setAllData] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [error, setError] = useState("");

  useEffect(() => {
    Api(
      "GET",
      `Job/GetNewApplicationJobList?companyId=${companyId}&applicantId=${applicantId}&jobId=${jobId}`
    )
      .then((res) => {
        let data = [...res.data];
        const responseData = data.map((d) => {
          return { ...d, checked: false, id: d.jobId, name: d.jobName };
        });
        setAllData(sortAtoZ(responseData));
      })
      .finally(() => {
        setLoading(false);
      });
  }, [openForm]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const data = [];
    allData.map((a) => {
      if (a.checked === true) {
        data.push(a.jobId);
      }
    });

    if (data.length > 0) {
      handleJobSelect(data);
    } else {
      setError("Please select at least one job to save!");
      return;
    }
  };

  const onCheckBoxChange = (checkName, isChecked) => {
    const checkList = allData.map((data) => {
      if (data.jobId === checkName) {
        data.checked = isChecked;
      }
      return data;
    });
    setAllData(checkList);
  };

  return (
    <AddEditCol
      isSubmitting={isSubmitting}
      applicantId={applicantId}
      openForm={openForm}
      title="Job Select Form"
      toggleForm={toggleForm}
      isLoading={isLoading}
      handleSubmit={handleSubmit}
    >
      {allData.map((rec) => (
        <div className="checkbox-label job-list" key={rec.id}>
          <div>
            <FormControlLabel
              control={
                <CheckboxWithBlueCheck
                  disableRipple
                  checked={rec.checked}
                  onChange={(e) => onCheckBoxChange(rec.id, e.target.checked)}
                  value={rec.id}
                  key={rec.id}
                />
              }
              label={rec.name + " (" + rec.jobNo + ")"}
            />
            <span className="chk-email">
              {rec?.companyName !== null && rec?.companyName}
            </span>
            <span className="chk-email">
              {rec?.facilityAddress !== null && rec?.facilityAddress}
            </span>
          </div>
          <label className="company-address">
            {rec?.description?.substring(0, 50)}
          </label>
        </div>
      ))}
      <div className="forms-input">
        <label className="error_msg">{error}</label>
      </div>
    </AddEditCol>
  );
};
