import { useState } from "react";
import { DateSelector } from "../../../../../../ui/pickers/date";
import { AddEditBox } from "../../../../../addEditPopup/AddEditBox";

export const EditVideoInterview = ({
  toggleDrawer,
  applicantId,
  openSidebar,
  handleSubmit,
  isSubmitting,
  expDate,
}) => {
  const [value, setValue] = useState(expDate);

  const handleDateChange = (newValue) => {
    setValue(newValue.toISOString());
  };

  const handleSave = () => {
    handleSubmit(value);
  };

  return (
    <AddEditBox
      title="Edit Video Interview"
      toggleForm={toggleDrawer}
      applicantId={applicantId}
      openForm={openSidebar}
      handleSubmit={handleSave}
      isSubmitting={isSubmitting}
    >
      <div className="forms-input">
        <label>Expires :</label>
        <DateSelector
          value={value}
          setValue={setValue}
          disablePast={true}
          handleDateChange={handleDateChange}
        />
      </div>
    </AddEditBox>
  );
};
