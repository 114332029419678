import { useState } from "react";
import { AddEditBox } from "../../../../../addEditPopup/AddEditBox";

export const ResendOfferingForm = ({
  toggleForm,
  handleSubmit,
  openForm,
  offerScheduleId,
  flag,
  handleCancelInterview,
  applicantId,
  isSubmitting,
}) => {
  const [description, setDescription] = useState("");

  const handleChange = (e) => {
    setDescription(e.target.value);
  };

  const handleSave = (e) => {
    e.preventDefault();
    flag === "send"
      ? handleSubmit(description, offerScheduleId)
      : handleCancelInterview(description, offerScheduleId);
  };

  return (
    <AddEditBox
      openForm={openForm}
      toggleForm={toggleForm}
      applicantId={applicantId}
      handleSubmit={handleSave}
      isSubmitting={isSubmitting}
      title={
        flag === "send" ? "Resend Offering Notification" : "Cancel this Offer ?"
      }
    >
      <div className="forms-input">
        <label>Message to Applicant:</label>
        <textarea
          placeholder="Message"
          value={description}
          name="description"
          onChange={handleChange}
        />
      </div>
    </AddEditBox>
  );
};
