import Api from "../../../../../helper/Api";
import React, { useState } from "react";
import { job_access } from "../../../../../helper/constants";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Android12Switch } from "../../../../ui/android/android2switch";
import { AddEditPopup } from "../../../addEditPopup";
import { useEffect } from "react";

export const UserAccess = ({
  setUserAccessForm,
  selectedUserRoles,
  toggleDrawer,
  companyData,
  handleUserAccess,
  formValues,
  setFormValues,
  label,
  editMode,
  accessRecordsData,
  formErrors,
  setFormErrors,
  openSidebar,
  validate,
}) => {
  const [accessData, setAccessData] = useState([]);


  useEffect(() => {
    if (!formValues.jobAccess && formValues.jobAccess) {
      console.log("first", formValues)
      setFormErrors(validate(formValues));
    }
  }, [formValues.jobAccess]);

  const handleChange = (e) => {
    if (e.target.name == "isNotify") {
      setFormValues({ ...formValues, [e.target.name]: !formValues.isNotify });
    } else {
      setFormValues({ ...formValues, [e.target.name]: e.target.value });
    }
  };

  const handleAccess = (e) => {
    let url, id;
    formValues.jobAccess = e.target.value;
    switch (e.target.value) {
      case "department":
        url = `Department/GetDepartmentList?companyId=${companyData.companyId}`;
        id = "departmentId";
        break;
      case "company":
        url = `Companies/GetCompany?parentCompanyId=${companyData.companyId}`;
        id = "companyId";
        break;
      case "facility":
        url = `Facilities/GetFacilitiesList?companyId=${companyData.companyId}`;
        id = "facilitiesId";
        break;
      case "job":
        url = `Job/GetJobList?companyId=${companyData.companyId}`;
        id = "jobId";
        break;
    }

    if (e.target.value === "none" || e.target.value === "Select") {
      setAccessData([]);
      return;
    }

    Api("GET", url + `&companyHirerchy=${companyData.companyHirerchy}`)
      .then((res) => {
        if (id === "departmentId") {
          const data = [...res.data];
          data.map((rec) => (rec.id = rec.departmentId));
          setAccessData(data);
        } else {
          setAccessData(
            res.data.data.map((res) => ({ ["id"]: res[id], ...res }))
          );
        }

        // This condition is for temporary basis untill the job module APIs get prepared
        // The condition for department module is due to the response variables
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <AddEditPopup
      openSidebar={openSidebar}
      toggleDrawer={toggleDrawer}
      handleSubmit={handleUserAccess}
      title="Job Access Management Form"
    >
      <div className="forms-input">
        <label>Role</label>
        <select
          name="userRole"
          id="userRole"
          value={formValues.userRole}
          onChange={handleChange}
        >
          <option>Select</option>
          {selectedUserRoles.map((role) => (
            <option value={role.roleId} key={role.roleId}>
              {role.roleName}
            </option>
          ))}
        </select>
        <span className="error_msg">{formErrors.userRole}</span>
      </div>
      <div className="forms-input">
        <label>Access Level</label>
        <select
          name="jobAccess"
          id="jobAccess"
          value={formValues.jobAccess}
          onChange={handleAccess}
          disabled={editMode}
          style={{ backgroundColor: editMode ? "#DCDCDC" : "" }}
        >
          <option>Select</option>
          {job_access.map((roles) => (
            <option value={roles.name.toLowerCase()} key={roles.id}>
              {roles.name}
            </option>
          ))}
        </select>
        <span className="error_msg">{formErrors.jobAccess !== "Job Access Level is required" ? "" : formErrors.jobAccess}</span>
      </div>
      <div className="forms-input">
        <label>Specific access module records</label>
        <select
          name="accessRecords"
          id="accessRecords"
          value={formValues.accessRecords}
          onChange={handleChange}
        >
          <option>Select</option>
          {accessData.map((record) => (
            <option value={record.id} key={record.id}>
              {record.name}
            </option>
          ))}
          {editMode
            ? accessRecordsData.map((record) => (
              <option value={record.id} key={record.id}>
                {record.name}
              </option>
            ))
            : null}
        </select>
        <span className="error_msg">{formErrors.accessRecords}</span>
      </div>
      {editMode ? (
        <div className="forms-input">
          <FormControlLabel
            control={<Android12Switch />}
            label="Notify"
            name="isNotify"
            checked={formValues.isNotify}
            onChange={handleChange}
          />
        </div>
      ) : null}
    </AddEditPopup>
  );
};
