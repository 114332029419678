import { CheckboxWithBlueCheck } from "../../../../../../ui/checkbox/checkboxWithBlueTick";
import { useEffect, useRef, useState } from "react";
import { CheckboxList } from "../../../../../layouts/CheckboxList";
import Api from "../../../../../../../helper/Api";
import { useSelector } from "react-redux";
import moment from "moment/moment";
import { MultiStepCol } from "../../../../../addEditPopup/multiStepCol";
import { validations } from "../../../../../../../helper/validations";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import { indigo } from "@material-ui/core/colors";
import { sortAtoZ } from "../../../../../../../helper/constants";
import {
  FormControl,
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
} from "@mui/material";
import { useLocation } from "react-router-dom";

export const InterviewForm = ({
  openForm,
  toggleForm,
  activeStep,
  setActiveStep,
  formValues,
  setFormValues,
  handleFormSubmit,
  applicantId,
  jobId,
  formErrors,
  setFormErrors,
  companyId,
  isSubmitting,
}) => {
  const ref = useRef(null);
  const interviewerRef = useRef(null);
  const [allFacilities, setFacilities] = useState();
  const [allInterviewers, setAllInterviewers] = useState([]);
  const [interviewType, setInterviewType] = useState("");
  const [allTimeSlots, setAllTimeSlots] = useState([]);
  const [interviewTypes, setInterviewTypes] = useState([]);
  const { companyData } = useSelector((state) => state.companyDataReducer);
  const [selectedSlots, setSelectedSlots] = useState([]);
  const [interviewTypeKey, setInetrviewTypeKey] = useState("");
  const [interviewersName, setInterviewerName] = useState([]);
  const [locationName, setLocationName] = useState([]);
  const date = new Date();
  const tomorrow = new Date().setDate(date.getDate() + 1);
  const dayAfterTomorrow = new Date().setDate(date.getDate() + 2);
  const [interviewerList, setInterviewerList] = useState([]);
  const [tmr, setTmr] = useState([]);
  const [tday, setTday] = useState([]);
  const [daftmr, setDaftmr] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [locationList, setLocationList] = useState({});
  const [openLocationBox, setOpenLocationBox] = useState(false);
  const location = useLocation();
  const errors = {};

  useEffect(() => {
    getAllAppointments();
    getFacilities();
    getInterviewSlots();
    getAllInterviewers();
  }, []);

  useEffect(() => {
    if (activeStep === 2) {
      ref?.current?.scrollIntoView({ behavior: "smooth" });
    }
  }, [activeStep]);

  function handleCheckboxChange(checkName, isChecked) {
    const data = [...allInterviewers];
    data.forEach((rec) => {
      if (rec.id === checkName) {
        rec.checked = isChecked;
      }
    });

    const selectedInterviewers = data.filter((d) => d.checked);
    const interviewersNames = selectedInterviewers.map(interviewer => {
      if (interviewer.userFirstName && interviewer.userLastName) {
        return `${interviewer.userFirstName} ${interviewer.userLastName}`;
      } else if (interviewer.userFirstName) {
        return interviewer.userFirstName;
      } else if (interviewer.userLastName) {
        return interviewer.userLastName;
      } else {
        return interviewer.userEmailAddress;
      }
    });

    setAllInterviewers(data);
    setInterviewerName(interviewersNames);

    const users = selectedInterviewers.map(interviewer => interviewer.id);
    setFormValues({ ...formValues, ["interviewers"]: users });

    if (users.length) {
      setFormErrors({ ...formErrors, interviewers: null });
    } else {
      setFormErrors({ ...formErrors, interviewers: "Please select at least one interviewer!" });
    }
  }


  function selectSlots(slots, day) {
    const data = [...selectedSlots];
    if (data.includes(slots)) {
      data.splice(data.indexOf(slots), 1);
    } else {
      data.push(slots);
    }

    if (day === "tday") {
      tday.includes(slots)
        ? tday.splice(tday.indexOf(slots), 1)
        : setTday([...tday, slots]);
    } else if (day === "tmr") {
      tmr.includes(slots)
        ? tmr.splice(tmr.indexOf(slots), 1)
        : setTmr([...tmr, slots]);
    } else {
      daftmr.includes(slots)
        ? daftmr.splice(daftmr.indexOf(slots), 1)
        : setDaftmr([...daftmr, slots]);
    }

    setSelectedSlots(data);
    setFormValues({ ...formValues, ["selectedSlots"]: data });
  }

  const recordThisMeet = (isChecked) => {
    setFormValues({ ...formValues, ["isRecordMeeting"]: isChecked });
  };

  const handleSubmit = () => {
    handleFormSubmit();
  };

  const handleChange = (e) => {
    setFormValues({ ...formValues, [e.target.name]: e.target.value });
  };

  const handleNext = () => {
    if (activeStep === 0) {
      setFormErrors(firstScreen());
      if (Object.keys(firstScreen()).length > 0) {
        return false;
      }
      return true;
    }

    if (activeStep === 1) {
      setFormErrors(secondScreen());
      if (Object.keys(secondScreen()).length > 0) {
        return false;
      }
      return true;
    }

    if (activeStep === 2) {
      setFormErrors(thirdScreen());
      if (Object.keys(thirdScreen()).length > 0) {
        return false;
      }
      return true;
    }
  };

  const firstScreen = () => {
    if (!formValues.interviewType) {
      errors.interviewType = validations.interviewType.req;
    }

    if (!formValues.duration) {
      errors.duration = validations.duration.req;
    }

    if (
      interviewTypeKey === "In_Person_Interview" &&
      (!formValues.facilities || formValues.facilities.length === 0) &&
      (!formValues.addLocation || Object.values(formValues.addLocation).length === 0)
    ) {
      errors.facilities = "Please add or select a location";
    }

    if (formValues.addLocation && (!formValues.addLocation.address1 || formValues.addLocation.address1 === "")) {
      errors.facilities = "Please add address field";
    }

    if (!formValues.notes) {
      errors.notes = validations.note.req;
    }

    return errors;
  };

  const secondScreen = () => {
    if (
      (!formValues.interviewers || formValues.interviewers.length === 0) &&
      (!formValues.interviewersList || formValues.interviewersList.length === 0)
    ) {
      errors.interviewers = "Please select at least one interviewer!";
      interviewerRef.current.scrollIntoView({ behavior: "smooth" });
    }

    return errors;
  };

  const thirdScreen = () => {
    if (!formValues.selectedSlots || formValues.selectedSlots.length === 0) {
      errors.selectedSlots = validations.selectedSlots.req;
    }

    return errors;
  };

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const data = [...interviewerList];
    data.at(index)[name] = value;
    setInterviewerList(data);
    setFormValues({ ...formValues, ["interviewersList"]: data });
  };

  const handleLocationInputChange = (e) => {
    const { name, value } = e.target;
    const data = { ...locationList };
    data[name] = value;
    setLocationList(data);
    setFormValues({ ...formValues, ["addLocation"]: data });
  };

  const getAllInterviewers = () => {
    Api("GET", `Job/GetTypeWiseUserList?companyId=${companyId}&type=0`)
      .then((res) => {
        res.data.map((user) => {
          user.name = user.userLastName + " " + user.userFirstName;
          user.id = user.userId;
          user.checked = false;
        });
        setAllInterviewers(sortAtoZ(res.data, "name"));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getFacilities = () => {
    Api("GET", `Facilities/GetAllFacilities?companyId=${companyId}`)
      .then((res) => {
        const data = [];
        res.data.data.map((facility) => {
          facility.id = facility.facilitiesId;
          data.push({
            id: facility.facilitiesId,
            checked: false,
          });
        });

        setFacilities(sortAtoZ(res.data.data, "address"));
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getInterviewSlots = () => {
    const params = {
      companyId: companyId,
      jobId: jobId,
      applicantId: applicantId,
      offset: new Date().getTimezoneOffset(),
    };

    Api("POST", `Job/GetInterviewSlot`, params)
      .then((res) => {
        const response = { ...res.data.data };
        setAllTimeSlots(response);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getAllAppointments = () => {
    Api(
      "GET",
      `Appointments/GetAppointmentsList?companyId=${companyData.companyId}&companyHirerchy=${companyData.companyHirerchy}`
    )
      .then((res) => {
        const data = [];
        res.data.data.filter((rec) => {
          if (rec.type === "interview") {
            data.push(rec);
          }
        });
        setInterviewTypes(data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const changeInterviewType = (e) => {
    const index = e.target.selectedIndex;
    const el = e.target.childNodes[index];
    const selectedOptionKey = el.getAttribute("id");

    setInterviewType(e.target.value);
    setInetrviewTypeKey(selectedOptionKey);
    const data = interviewTypes.find((a) => a.appointmentId === e.target.value);

    setFormValues({
      ...formValues,
      ["interviewType"]: e.target.value,
      ["duration"]: data.duration,
      ["notes"]: data.note,
    });
    setFormErrors({
      ...formErrors,
      ["interviewType"]: null,
      ["duration"]: null,
      ["notes"]: null,
    });
  };

  const handleAddBox = () => {
    setInterviewerList([
      ...interviewerList,
      {
        firstName: "",
        lastName: "",
        title: "",
        emailAddress: "",
      },
    ]);
  };

  const handleAddLocation = () => {
    setOpenLocationBox(true);
    setLocationList({
      name: "",
      address1: "",
      address2: "",
      city: "",
      country: "",
      postalCode: ""
    });
  }

  const handleRemoveClick = (index) => {
    const list = [...interviewerList];
    list.splice(index, 1);
    setInterviewerList(list);
    setFormValues({ ...formValues, ["interviewersList"]: list });
  };

  const handleRemoveLocation = () => {
    setLocationList({});
    setOpenLocationBox(false);
    setFormValues({ ...formValues, ["addLocation"]: {} });
  };

  const handleRadioChange = (e) => {
    const facility = allFacilities.find(
      (rec) => rec.facilitiesId === e.target.value
    );
    setLocationName(facility.address);
    setFormValues({ ...formValues, ["facilities"]: [e.target.value] });
    setFormErrors({ ...formErrors, ["facilities"]: null });
  };

  return (
    <MultiStepCol
      openForm={openForm}
      toggleForm={toggleForm}
      activeStep={activeStep}
      setActiveStep={setActiveStep}
      title="Interview Form"
      handleSubmit={handleSubmit}
      steps="4"
      handleNext={handleNext}
      applicantId={applicantId}
      isSubmitting={isSubmitting}
      isLoading={isLoading}
    >
      {activeStep === 0 && (
        <>
          <div className="forms-input">
            <label>Interview Type</label>
            <select
              onChange={(e) => changeInterviewType(e)}
              value={interviewType}
            >
              <option value="">Select</option>
              {interviewTypes.map((app, index) => (
                <option key={index} value={app.appointmentId} id={app.keyName}>
                  {app.name}
                </option>
              ))}
            </select>
            <span className="error_msg">{formErrors?.interviewType}</span>
          </div>

          {(interviewTypeKey === "In_Person_Interview" ||
            interviewTypeKey === "Live_Virtual_Interview" ||
            interviewTypeKey === "Phone_Interview") && (
              <div className="forms-input">
                <label>Duration(Minutes)</label>
                <input
                  type="text"
                  placeholder="Duration"
                  name="duration"
                  key="duration"
                  onChange={handleChange}
                  value={formValues?.duration}
                />
                <span className="error_msg">{formErrors?.duration}</span>
              </div>
            )}

          {interviewTypeKey === "In_Person_Interview" && (
            <>
              <div className="forms-input">
                <label>Location</label>
                <FormControl>
                  <RadioGroup
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="controlled-radio-buttons-group"
                    value={formValues.location}
                    onChange={handleRadioChange}
                  >
                    {allFacilities.map((facility, index) => (
                      <>
                        {facility.address !== "" && (
                          <FormControlLabel
                            sx={{
                              display: "flex",
                              alignItems: "flex-start",
                              paddingBlock: 0.5,
                            }}
                            value={facility.facilitiesId}
                            key={index}
                            control={
                              <Radio
                                sx={{
                                  color: indigo[800],
                                  padding: "2px 10px 2px 10px",
                                  "&.Mui-checked": {
                                    color: indigo[600],
                                  },
                                }}
                              />
                            }
                            label={facility.address}
                          />
                        )}
                      </>
                    ))}
                  </RadioGroup>
                </FormControl>
                {openLocationBox ?
                  <div className="forms-input" key="locationBox">
                    <div className="add-upload-container" key={1}>
                      <div className="text-right">
                        <IconButton
                          size="small"
                          onClick={() => handleRemoveLocation()}
                        >
                          <CloseIcon color="primary" fontSize="small" />
                        </IconButton>
                      </div>
                      <div className="forms-input">
                        <input
                          type="text"
                          placeholder="Location Name (Optional)"
                          name="name"
                          id="Name"
                          value={locationList.name}
                          onChange={(e) => handleLocationInputChange(e)}
                        />
                      </div>

                      <div className="forms-input ">
                        <input
                          type="text"
                          placeholder="Address"
                          name="address1"
                          id="address1"
                          value={locationList.address1}
                          onChange={(e) => handleLocationInputChange(e)}
                        />
                      </div>

                      <div className="forms-input">
                        <input
                          type="text"
                          placeholder="Landmark"
                          name="address2"
                          id="address2"
                          value={locationList.address2}
                          onChange={(e) => handleLocationInputChange(e)}
                        />
                      </div>

                      <div className="forms-input">
                        <input
                          type="text"
                          placeholder="City"
                          name="city"
                          id="city"
                          value={locationList.city}
                          onChange={(e) => handleLocationInputChange(e)}
                        />
                      </div>

                      <div className="forms-input sections">
                        <div className="col-2">
                          <input
                            type="text"
                            placeholder="Country"
                            name="country"
                            id="country"
                            value={locationList.country}
                            onChange={(e) => handleLocationInputChange(e)}
                          />
                        </div>

                        <div className="col-2">
                          <input
                            type="text"
                            placeholder="Postal Code"
                            name="postalCode"
                            id="postalCode"
                            value={locationList.postalCode}
                            onChange={(e) => handleLocationInputChange(e)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  :
                  <span className="participants pointer" onClick={handleAddLocation}>
                    <AddIcon fontSize="small" /> Add Location
                  </span>
                }
              </div>
              <span className="error_msg">{formErrors?.facilities}</span>
            </>
          )}

          {(interviewTypeKey === "In_Person_Interview" ||
            interviewTypeKey === "Live_Virtual_Interview" ||
            interviewTypeKey === "Phone_Interview") && (
              <div className="forms-input">
                <label>Notes/Instructions</label>
                <textarea
                  placeholder="Notes/Instructions"
                  name="notes"
                  onChange={handleChange}
                  value={formValues?.notes}
                />
                <span className="error_msg">{formErrors?.notes}</span>
              </div>
            )}

          {interviewTypeKey === "Live_Virtual_Interview" && (
            <FormControlLabel
              control={
                <CheckboxWithBlueCheck
                  disableRipple
                  onChange={(e) => recordThisMeet(e.target.checked)}
                />
              }
              label="Record this Meeting"
            />
          )}
        </>
      )}

      {activeStep === 1 && (
        <>
          <h4 ref={interviewerRef}>Select Interviewers</h4>
          <span className="error_msg">{formErrors?.interviewers}</span>
          <CheckboxList
            allData={allInterviewers}
            setAllData={setAllInterviewers}
            handleSubmit={handleNext}
            showSaveContainer={false}
            handleChange={handleCheckboxChange}
          />
          {interviewerList.map((interviewer, index) => (
            <div className="forms-input" key={index}>
              <div className="add-upload-container" key={1}>
                <div className="text-right">
                  <IconButton
                    size="small"
                    onClick={() => handleRemoveClick(index)}
                  >
                    <CloseIcon color="primary" fontSize="small" />
                  </IconButton>
                </div>
                <div className="forms-input sections">
                  <div className="col-2">
                    <label>First Name:</label>
                    <input
                      type="text"
                      placeholder="First Name"
                      name="firstName"
                      id="firstName"
                      value={interviewer.firstName}
                      onChange={(e) => handleInputChange(e, index)}
                    />
                  </div>

                  <div className="col-2">
                    <label>Last Name:</label>
                    <input
                      type="text"
                      placeholder="Last Name"
                      name="lastName"
                      id="lastName"
                      value={interviewer.lastName}
                      onChange={(e) => handleInputChange(e, index)}
                    />
                  </div>
                </div>

                <div className="forms-input sections">
                  <div className="col-2">
                    <label>Title:</label>
                    <input
                      type="text"
                      placeholder="Title"
                      name="title"
                      id="title"
                      value={interviewer.title}
                      onChange={(e) => handleInputChange(e, index)}
                    />
                  </div>

                  <div className="col-2">
                    <label>Email Address:</label>
                    <input
                      type="text"
                      placeholder="Email Address"
                      name="emailAddress"
                      id="emailAddress"
                      value={interviewer.email}
                      onChange={(e) => handleInputChange(e, index)}
                    />
                  </div>
                </div>
              </div>
            </div>
          ))}

          <span className="participants pointer" onClick={handleAddBox}>
            <AddIcon fontSize="small" /> Add Interviewer
          </span>
        </>
      )}

      {activeStep === 2 && (
        <>
          {allTimeSlots?.timeSlotsCurrent?.length > 0 &&
            allTimeSlots?.timeSlotTomorrow?.length > 0 &&
            allTimeSlots?.timeSlotDayAfterTomorrow?.length > 0 ? (
            <>
              <div className="time-slots" ref={ref}>
                <span>Date: {moment(date).format("dddd, MMMM DD")}</span>
                <span>Available slots :</span>
                <div className="slot-container">
                  {allTimeSlots?.timeSlotsCurrent?.map((slot, index) => (
                    <span
                      key={index}
                      className={
                        slot?.isDisabled === false
                          ? selectedSlots.includes(slot?.timeSlot)
                            ? "slot pointer selected"
                            : "slot pointer"
                          : "slot disabled"
                      }
                      onClick={() =>
                        slot?.isDisabled === false &&
                        selectSlots(slot?.timeSlot, "tday")
                      }
                    >
                      <>{moment(slot?.timeSlot).format("h:mm a")}</>
                    </span>
                  ))}
                </div>
              </div>

              <div className="time-slots mt-20">
                <span>Date: {moment(tomorrow).format("dddd, MMMM DD")}</span>
                <span>Available slots :</span>
                <div className="slot-container">
                  {allTimeSlots?.timeSlotTomorrow?.map((slot, index) => (
                    <span
                      key={index}
                      className={
                        slot?.isDisabled === false
                          ? selectedSlots.includes(slot?.timeSlot)
                            ? "slot pointer selected"
                            : "slot pointer"
                          : "slot disabled"
                      }
                      onClick={() =>
                        slot?.isDisabled === false &&
                        selectSlots(slot?.timeSlot, "tmr")
                      }
                    >
                      {moment(slot?.timeSlot).format("h:mm a")}
                    </span>
                  ))}
                </div>
              </div>

              <div className="time-slots mt-20">
                <span>
                  Date: {moment(dayAfterTomorrow).format("dddd, MMMM DD")}
                </span>
                <span>Available slots :</span>
                <div className="slot-container">
                  {allTimeSlots?.timeSlotDayAfterTomorrow?.map(
                    (slot, index) => (
                      <span
                        key={index}
                        className={
                          slot?.isDisabled === false
                            ? selectedSlots.includes(slot?.timeSlot)
                              ? "slot pointer selected"
                              : "slot pointer"
                            : "slot disabled"
                        }
                        onClick={() =>
                          slot?.isDisabled === false &&
                          selectSlots(slot?.timeSlot, "daftmr")
                        }
                      >
                        {moment(slot?.timeSlot).format("h:mm a")}
                      </span>
                    )
                  )}
                </div>
              </div>

              <span className="error_msg">{formErrors?.selectedSlots}</span>
            </>
          ) : (
            <span>
              No slots found ! Please check the system settings for slots
              availability !
            </span>
          )}
        </>
      )}

      {activeStep === 3 && (
        <>
          <div className="forms-inputfill">
            <label className="note-label">
              Review the following details and submit.
            </label>
          </div>
          <div className="forms-inputfill">
            <label className="company_label">
              {localStorage.getItem("companyName")}
            </label>
          </div>
          <div className="forms-inputfill">
            <label>Interview Type:</label>
            <span>{interviewTypeKey.split("_").join(" ")}</span>
          </div>
          <div className="forms-inputfill">
            <label>Duration:</label>
            <span>{formValues?.duration}</span>
          </div>
          {interviewTypeKey === "In_Person_Interview" && (
            <div className="forms-inputfill">
              <label>Location:</label>
              <span>{locationName.length > 0 ? locationName : formValues.addLocation.address1}</span>
            </div>
          )}
          <div className="forms-inputfill">
            <label>Notes/Instructions:</label>
            <span>{formValues?.notes}</span>
          </div>
          <div className="forms-inputfill">
            <label>Interviewers:</label>
            <div className="time-slots mt-5">
              <div className="slot-container">
                {interviewersName.map((intName, index) => (
                  <div key={index} className="inter-name">
                    <div className="sm-dot-div"></div>
                    <span>{intName}</span>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="forms-inputfill">
            <label>Selected dates and Times:</label>
            <div className="mt-5">
              {tday.length > 0 && (
                <>
                  <span>{moment(date).format("ddd, MMM DD")} :</span>
                  <div className="time-slots mt-5">
                    <div className="slot-container">
                      {tday.map((t) => (
                        <span className="b-slot">
                          {moment(t).format("h:mm a")}
                        </span>
                      ))}
                    </div>
                  </div>
                </>
              )}

              {tmr.length > 0 && (
                <>
                  <span>{moment(tomorrow).format("ddd, MMM DD")} :</span>
                  <div className="time-slots mt-5">
                    <div className="slot-container">
                      {tmr.map((t) => (
                        <span className="b-slot">
                          {moment(t).format("h:mm a")}
                        </span>
                      ))}
                    </div>
                  </div>
                </>
              )}

              {daftmr.length > 0 && (
                <>
                  <span>
                    {moment(dayAfterTomorrow).format("ddd, MMM DD")} :
                  </span>
                  <div className="time-slots mt-5">
                    <div className="slot-container">
                      {daftmr.map((t) => (
                        <span className="b-slot">
                          {moment(t).format("h:mm a")}
                        </span>
                      ))}
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </>
      )}
    </MultiStepCol>
  );
};
