import * as React from "react";
import Avatar from "@mui/material/Avatar";

function stringToColor(string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

function stringAvatar(name) {
  return {
    children: `${name?.split(" ")[0][0]?.toUpperCase()}${name
      ?.split(" ")[1][0]
      ?.toUpperCase()}`,
  };
}

function getColor(name) {
  let c = stringToColor(name);
  return c.substring(0, 4) === "#fff" ? "#333333" : "#fff";
}

export default function BackgroundLetterAvatars({
  name,
  width,
  height,
  shape,
  fontSize = "20px",
}) {
  return (
    <Avatar
      {...stringAvatar(name)}
      sx={{
        width: width,
        height: height,
        bgcolor: stringToColor(name),
        marginRight: 1,
        fontSize: fontSize,
        color: getColor(name),
      }}
      variant={shape}
    />
  );
}
