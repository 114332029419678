import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Api from "../../../../../../../helper/Api";
import { ButtonUI } from "../../../../../../ui/button";
import { OfferLetterForm } from "./offerLetterForm";
import { SendOfferLetterForm } from "./sendOfferLetterForm";
import { RescindedForm } from "./rescindedForm";
import axios from "axios";
import fileDownload from "js-file-download";
import { DeleteButton } from "../../../../../../ui/button/delete";
import { DownloadButton } from "../../../../../../ui/button/download";
import { EditButton } from "../../../../../../ui/button/edit";
import { AlertBox, showSuccess } from "../../../../../../../helper/utils";
import moment from "moment";
import { HubConnectionBuilder, LogLevel } from "@microsoft/signalr";
import { baseUrl } from "../../../../../../../helper/constants";

const OFFER_LETTER_STATUS = {
  Created: "orange-sign",
  Edited: "orange-sign",
  Pending: "red-delete",
  Signed: "green-active",
};

export const OfferLetterStatus = ({
  selectedSuitCase,
  applicantId,
  applicantName,
}) => {
  const [openOfferLetterForm, setOfferLetterForm] = useState(false);
  const [offerLetterList, setOfferLetterList] = useState([]);
  const [openSendForm, setSendForm] = useState(false);
  const [formValues, setFormValues] = useState({});
  const [offerLetterId, setOfferLetterId] = useState("");
  const [openRescindedForm, setRescindedOfferForm] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmitting, setSubmitting] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const [isOfferAccepted, setOfferAccepted] = useState(false);

  useEffect(() => {
    getOfferLetterList();
  }, []);

  useEffect(async () => {
    var the_arr = baseUrl.split("/");
    the_arr.pop();
    the_arr.pop();
    var apiUrl = the_arr.join("/");

    const connect = new HubConnectionBuilder()
      .withUrl(`${apiUrl}/liveUpdateHub`)
      .configureLogging(LogLevel.Information)
      .build();

    connect.on("OfferLetterList", (res) => {
      setOfferLetterList(res.data.offerLetterList);
      setOfferAccepted(res.data.isOfferingAcceptaed);
    });

    await connect.start();

  }, [selectedSuitCase.jobId]);

  const getOfferLetterList = () => {
    Api(
      "GET",
      `Job/GetOfferLetterList?companyId=${selectedSuitCase.companyId}&applicantId=${applicantId}&jobId=${selectedSuitCase.jobId}`
    )
      .then((res) => {
        setOfferLetterList(res.data.data.offerLetterList);
        setOfferAccepted(res.data.data.isOfferingAcceptaed);
      })
      .finally(() => setLoading(false));
  };

  const toggleOfferLetterForm = (open) => {
    setOfferLetterForm(open);
    setFormErrors({});
  };

  const toggleSendForm = (open) => {
    setSendForm(open);
    setFormValues({});
  };

  const toggleRescindedOfferForm = (open) => {
    setRescindedOfferForm(open);
  };

  // Create offer letter
  const handleSaveOfferLetter = (documentId) => {
    const params = {
      documentId: documentId,
      companyId: selectedSuitCase.companyId,
      jobId: selectedSuitCase.jobId,
      applicantId: applicantId,
    };

    setSubmitting(true);

    Api("POST", `Job/CreateOfferLetter`, params)
      .then((res) => {
        if (res.data.isSuccess === true) {
          toast.success(res.data.message);
          toggleOfferLetterForm(false);
        } else {
          toast.error(res.data.message);
        }
      })
      .finally(() => setSubmitting(false));
  };

  // Send Offer letter notification
  const handleSendOfferLetter = (id) => {
    const params = {
      applicantOfferLetterId: id,
      applicantMessage: formValues.applicantMessage,
      expireDate: formValues.expireDate,
    };

    setSubmitting(true);

    Api("POST", `Job/SendOfferLetterToApplicant`, params)
      .then((res) => {
        if (res.data.isSuccess === true) {
          toast.success(res.data.message);
          toggleSendForm(false);
        } else {
          toast.error(res.data.message);
        }
      })
      .finally(() => setSubmitting(false));
  };

  // Delete Offer letter
  const handleDeleteOfferLetter = (status, id) => {
    if (status === "Created" || status === "Edited") {
      AlertBox().then((res) => {
        if (!res.isConfirmed) return;

        // DELETE Offer letter api call
        Api(
          "DELETE",
          `Job/DeleteOfferLetter?jobId=${selectedSuitCase.jobId}&companyId=${selectedSuitCase.companyId}&applicantId=${applicantId}&applicantOfferLetterId=${id}`
        ).then((res) => {
          if (res.data.isSuccess === true) {
            showSuccess();
          } else {
            toast.error(res.data.message);
          }
        });
      });
    } else {
      // Rescinded offer letter form
      toggleRescindedOfferForm(true);
      setOfferLetterId(id);
    }
  };

  const handleRescindedOffer = (e) => {
    e.preventDefault();
    const params = {
      applicantOfferLetterId: offerLetterId,
      applicantMessage: formValues.applicantMessage,
    };

    setSubmitting(true);
    Api("POST", `Job/RescindedOfferLetter`, params)
      .then((res) => {
        if (res.data.isSuccess === true) {
          toast.success(res.data.message);
          toggleRescindedOfferForm(false);
          getOfferLetterList();
        } else {
          toast.error(res.data.message);
        }
      })
      .finally(() => setSubmitting(false));
  };

  // Download the document
  const downloadDocument = (offerLetter) => {
    axios
      .get(offerLetter.offerLetterURL, {
        responseType: "blob",
      })
      .then((res) => {
        fileDownload(res.data, offerLetter.offerLatterName + ".pdf");
      })
      .catch((err) => {
        toast.error(err.response.statusText);
      });
  };

  const handleClickOpen = (id) => {
    const docId = window.btoa(id);
    window.open(`/editDoc/${docId}`, "_blank");
  };

  const openOfferLetter = (id) => {
    const docId = window.btoa(id);
    window.open(`/view-letter/${docId}`, "_blank");
  };

  return (
    <div>
      {openOfferLetterForm && (
        <OfferLetterForm
          toggleForm={toggleOfferLetterForm}
          openForm={openOfferLetterForm}
          handleSubmit={handleSaveOfferLetter}
          applicantId={applicantId}
          formErrors={formErrors}
          setFormErrors={setFormErrors}
          isSubmitting={isSubmitting}
        />
      )}

      {openSendForm && (
        <SendOfferLetterForm
          toggleForm={toggleSendForm}
          handleSubmit={handleSendOfferLetter}
          openForm={openSendForm}
          formValues={formValues}
          setFormValues={setFormValues}
          offerLetterId={offerLetterId}
          applicantId={applicantId}
          isSubmitting={isSubmitting}
        />
      )}

      {openRescindedForm && (
        <RescindedForm
          openForm={openRescindedForm}
          toggleForm={toggleRescindedOfferForm}
          formValues={formValues}
          setFormValues={setFormValues}
          handleSubmit={handleRescindedOffer}
          applicantId={applicantId}
          isSubmitting={isSubmitting}
        />
      )}

      {!isOfferAccepted && offerLetterList.length === 0 && (
        <div className="d-flex flex-column">
          <strong>Offer Letter</strong>
          <span className="font-12">
            This applicant does not have an accepted offer.
          </span>
        </div>
      )}

      {isLoading === false &&
        isOfferAccepted === true &&
        (offerLetterList.length === 0 ||
          offerLetterList[0]?.offerLetterStatus === "Declined" ||
          offerLetterList[0]?.offerLetterStatus === "Rescinded") && (
          <button
            className="add-btn manage-btn job"
            onClick={() => toggleOfferLetterForm(true)}
          >
            Create Offer Letter
            <img
              src={require("../../../../../../../assets/images/Vector.png")}
              alt="right-arrow"
              className="right-arrow-btn"
            />
          </button>
        )}

      <div className="profile-data">
        {offerLetterList?.map((offer, index) => (
          <div className="section border-bottom" key={index}>
            <div className="d-flex align-items-center">
              <strong>Offer letter</strong>
              <span
                className={
                  "status " + OFFER_LETTER_STATUS[offer.offerLetterStatus]
                }
              >
                {offer.offerLetterStatus}
              </span>
            </div>

            {offer.offerLetterStatus === "Created" ||
              offer.offerLetterStatus === "Edited" ||
              offer.offerLetterStatus === "Pending" ? (
              <ButtonUI
                p="7px"
                ml="80px"
                mb="5px"
                fontSize="12px"
                onClick={() => (
                  toggleSendForm(true),
                  setOfferLetterId(offer.applicantOfferLetterId),
                  setFormValues({ ...formValues, ["expireDate"]: new Date() })
                )}
              >
                Send Offer Letter
              </ButtonUI>
            ) : null}

            <span className="font-12 d-flex flex-column">
              {offer.offerLetterStatus === "Signed" ? (
                <label>{offer.offerLatterName}</label>
              ) : (
                <label
                  className="pointer"
                  onClick={() => openOfferLetter(offer.applicantOfferLetterId)}
                >
                  <u>{offer.offerLatterName}</u>
                </label>
              )}

              {offer.offerLetterStatus === "Pending" && (
                <>
                  <span className="mt-10">
                    <strong>Requested :</strong>
                    {moment(offer.requested).format("MM/DD/YYYY")}
                  </span>
                  <span className="mt-1">
                    <strong>Expires :</strong>
                    {moment(offer.expires).format("MM/DD/YYYY")}
                  </span>
                  <span className="mt-1">
                    <strong>Last Viewed :</strong>
                    {offer.lastViewed}
                  </span>
                </>
              )}
            </span>
            <span className="d-flex">
              {offer.offerLetterStatus === "Pending" && (
                <DeleteButton
                  title="Rescind"
                  onClick={() =>
                    handleDeleteOfferLetter(
                      offer.offerLetterStatus,
                      offer.applicantOfferLetterId
                    )
                  }
                />
              )}

              {(offer.offerLetterStatus === "Created" ||
                offer.offerLetterStatus === "Edited") && (
                  <>
                    <EditButton
                      onClick={() =>
                        handleClickOpen(offer.applicantOfferLetterId)
                      }
                    />
                    <DeleteButton
                      title="Delete"
                      onClick={() =>
                        handleDeleteOfferLetter(
                          offer.offerLetterStatus,
                          offer.applicantOfferLetterId
                        )
                      }
                    />
                  </>
                )}

              {offer.offerLetterStatus === "Signed" && (
                <>
                  <DeleteButton
                    title="Rescind"
                    onClick={() =>
                      handleDeleteOfferLetter(
                        offer.offerLetterStatus,
                        offer.applicantOfferLetterId
                      )
                    }
                  />
                  <DownloadButton onClick={() => downloadDocument(offer)} />
                </>
              )}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};
