import { Tooltip } from "@mui/material";
import { ButtonUI } from "./index";
import { AcceptIcon } from "../../../icons/accept";

export const AcceptButton = ({ onClick }) => {
  return (
    <Tooltip title="Accept Interview">
      <ButtonUI
        variant="outlined"
        fontSize="10px"
        borderColor="#34AA44"
        color="#34AA44"
        mr="6px"
        p="5px"
        mt="5px"
        width="70px"
        rightIcon={<AcceptIcon color="action" />}
        onClick={onClick}
      >
        Accept
      </ButtonUI>
    </Tooltip>
  );
};
