import { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import Api from "../../../helper/Api";
import { toast } from "react-toastify";

export const DocContent = () => {
  const location = useLocation();
  const contentRef = useRef(null);
  const [content, setContent] = useState(null);

  const docId = location.pathname.substring(
    location.pathname.lastIndexOf("/") + 1
  );

  useEffect(() => {
    Api("GET", `Document/GetDocumentByIdV1?documentId=${docId}`).then((res) => {
      if (res.data.isSuccess) {
        setContent(res.data.data.htmlContent);
      } else {
        toast.error(res.data.message);
        window.close();
      }
    });
  }, []);

  useEffect(() => {
    if (content) {
      const dates = contentRef.current.querySelectorAll("[type='date']");
      dates.forEach((cur) => window.dateInputMask(cur));
    }
  }, [content]);

  return (
    <div>
      <div className="job-data-container">
        <div className="j-content">
          <div className="mt-20" id="fr-content">
            <div
              ref={contentRef}
              id="ol-content"
              dangerouslySetInnerHTML={{
                __html: content,
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
