import { AddEditPopup } from "../../common/addEditPopup";
import { useEffect, useRef, useState } from "react";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import { red } from "@mui/material/colors";
import { FilesIcon } from "../../../icons";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

export const AddEditSubCompany = ({
  toggleDrawer,
  openSidebar,
  addEditLabel,
  formValues,
  setFormValues,
  formErrors,
  editMode,
  allTimeZone,
  handleSubmit,
  isSubmitting,
}) => {
  const attachRef = useRef(null);
  const [attachment, setAttachment] = useState();

  useEffect(() => {
    if (!editMode) {
      setFormValues({});
      setAttachment("");
    }
  }, [openSidebar.right]);

  // On every input change (Add and Edit form)
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const openAttachment = () => {
    attachRef.current.click();
  };

  const handleLogo = (e) => {
    setFormValues({
      ...formValues,
      ["companyLogo"]: e.target.files[0],
    });
    setAttachment(e.target.files[0].name);
  };

  const closeFile = () => {
    setAttachment("");
    setFormValues({
      ...formValues,
      ["companyLogo"]: "",
    });
  };

  return (
    <AddEditPopup
      toggleDrawer={toggleDrawer}
      openSidebar={openSidebar}
      title={addEditLabel}
      handleSubmit={handleSubmit}
      isSubmitting={isSubmitting}
    >
      <div className="in-forms">
        <input
          type="file"
          ref={attachRef}
          hidden
          onChange={handleLogo}
          key={attachment}
          accept="image/png, image/jpeg, image/jpg, .svg"
        />

        <div className="forms-input">
          <label>Name</label>
          <input
            type="text"
            placeholder="Name here"
            name="name"
            id="name"
            value={formValues.name}
            onChange={handleChange}
          />
          {formErrors.name ? (
            <span className="error_msg">{formErrors.name}</span>
          ) : null}
        </div>
        <div className="forms-input">
          <label>Job Number Prefix</label>
          <input
            type="text"
            placeholder="Job Prefix here"
            name="jobPrefix"
            id="jobPrefix"
            value={formValues.jobPrefix}
            onChange={handleChange}
            readOnly={editMode}
            style={{ backgroundColor: editMode ? "#DCDCDC" : "" }}
          />
          {formErrors.jobPrefix ? (
            <span className="error_msg">{formErrors.jobPrefix}</span>
          ) : null}
        </div>
        {!editMode && (
          <div className="forms-input">
            <label>Email Address</label>
            <input
              type="text"
              placeholder="Email Address here"
              name="emailAddress"
              id="emailAddress"
              value={formValues.emailAddress}
              onChange={handleChange}
            />
            {formErrors.emailAddress ? (
              <span className="error_msg">{formErrors.emailAddress}</span>
            ) : null}
          </div>
        )}
        <div className="forms-input">
          <label>Timezone</label>
          <select
            name="timeZone"
            id="timeZone"
            value={formValues.timeZone}
            onChange={handleChange}
          >
            <option value="none">Select</option>
            {allTimeZone.map((time) => (
              <option key={time.id} value={time.timeZone}>
                {time.timeZone}
              </option>
            ))}
          </select>
          {formErrors.timeZone ? (
            <span className="error_msg">{formErrors.timeZone}</span>
          ) : null}
        </div>

        <p className="max-applicant">
          Maximum Applicants Allowed per Job (leave 0 for unlimited)
        </p>
        <div className="two-side-by">
          <div className="forms-input">
            <label>Total</label>
            <input
              type="number"
              placeholder="Name here"
              name="totalJob"
              id="totalJob"
              value={formValues.totalJob}
              onChange={handleChange}
            />
            <span className="error_msg">{formErrors.totalJob}</span>
          </div>
          <div className="forms-input">
            <label>Per Job Board</label>
            <input
              type="number"
              placeholder="Name here"
              name="perJobBoard"
              id="perJobBoard"
              value={formValues.perJobBoard}
              onChange={handleChange}
            />
            <span className="error_msg">{formErrors.perJobBoard}</span>
          </div>
        </div>
        {attachment || formValues.companyLogo ? (
          <div className="doucments-card">
            <div className="doc-detail">
              <FilesIcon
                stroke="#9EA0A5"
                line="#fff"
                width="32px"
                height="32px"
              />
              <div className="file-name-label">
                {attachment || formValues.companyLogo.split("_").at(-1)}
              </div>
            </div>
            <div className="doc-detail" role="button">
              <IconButton size="small" onClick={closeFile}>
                <CloseIcon fontSize="small" sx={{ color: red[600] }} />
              </IconButton>
            </div>
          </div>
        ) : (
          <div className="forms-input">
            <label>Company Logo: </label>
            <div
              role="button"
              className="document-section pointer"
              onClick={() => openAttachment()}
            >
              <div className="documents-upload">
                <span>Upload Document</span>
                <CloudUploadOutlinedIcon width="32" height="32" />
              </div>
            </div>
          </div>
        )}
      </div>
    </AddEditPopup>
  );
};
