import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import FormControlLabel from "@mui/material/FormControlLabel";
import { CKEditor } from "ckeditor4-react";
import { toast } from "react-toastify";
import Loader from "../../common/Loader";
import { ToastAlert, config, sortAtoZ } from "../../../helper/constants";
import "../../../assets/css/components/company-detail/_appointments.css";
import Api from "../../../helper/Api";
import { Android12Switch } from "../../ui/android/android2switch";
import EditIcon from "@mui/icons-material/Edit";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { ButtonUI } from "../../ui/button";

export function Messages({}) {
  const location = useLocation();
  const [msgRaw, setMsgRaw] = useState([]);
  const [msgShow, setMsgShow] = useState("");
  const [editor, setEditor] = useState("");
  const [showEditBox, setShowEditBox] = useState(false);
  const [openToastBar, setOpenToastBar] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [isLoader, setIsLoader] = useState(false);
  const [isSubmitting, setSubmitting] = useState(false);

  useEffect(() => {
    getMsgList();
    setIsLoader(true);
  }, []);

  const getMsgList = () => {
    Api(
      "GET",
      `MessageTemplate/GetMessageTemplateList?companyId=${location.state.companyId}`
    )
      .then((response) => {
        if (response.data) {
          setMsgShow(response.data[0]);
          setMsgRaw(sortAtoZ(response.data, "description"));
          setIsLoader(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const isActiveMsg = (id, setActive) => {
    const params = {
      messageTemplateId: id,
      isActive: !setActive,
    };

    Api(
      "PATCH",
      `MessageTemplate/ActiveInActiveMessageTemplate?companyId=${location.state.companyId}&messageTemplateId=${params.messageTemplateId}&isActive=${params.isActive}`
    )
      .then((res) => {
        if (res.data.isSuccess == true) {
          toast.success(res.data.message);
          getMsgList();
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
    setOpenToastBar(true);
  };

  const handleRestore = (key) => {
    setMsgShow({ ...msgShow, canCustomize: false });
    Api(
      "GET",
      `MessageTemplate/RestoreDefault?companyId=${location.state.companyId}&keyName=${key}`
    )
      .then((response) => {
        if (response.data) {
          setMsgShow({
            ...msgShow,
            emailContent: response.data.emailContent,
            emailSubject: response.data.emailSubject,
            canCustomize: false,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleEditor = (e) => {
    // setMsgShow({ ...msgShow, canCustomize: false});
    if (!msgShow.canCustomize) {
      msgShow.canCustomize = true;
    }
    msgShow.emailContent = e.editor.getData();
    // msgShow.canCustomize = false;
    // msgShow.emailContent = encodeURI(e.editor.getData());
    // setFormValues({ ...formValues, docContent: encodeURI(e.editor.getData()) });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    setFormErrors(validate(msgShow));
    if (Object.keys(validate(msgShow)).length > 0) {
      return;
    }

    setSubmitting(true);
    Api("POST", `MessageTemplate/SaveMessageTemplate`, msgShow)
      .then((res) => {
        if (res.data) {
          setShowEditBox(false);
          toast.success(res.data.message);
          getMsgList();
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setSubmitting(false);
      });
    setOpenToastBar(true);
  };

  const errors = {};
  const validate = (values) => {
    if (!values.emailContent) {
      errors.emailContent = "Email Content is required.";
    }
    if (!values.emailSubject) {
      errors.emailSubject = "Email Subject is required.";
    }

    return errors;
  };

  const showItems = (id) => {
    const listItemValue = msgRaw.filter((items) => {
      return items.messageTemplateId === id ? items : null;
    });
    setMsgShow(listItemValue[0]);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    // setFormValues({ ...formValues, [name]: value });
    setMsgShow({ ...msgShow, [name]: value, canCustomize: true });
  };

  const editBoxHandle = (items) => {
    setEditor(items);
    setShowEditBox(true);
  };

  return (
    <div>
      {openToastBar ? <ToastAlert /> : null}
      <div className="common-container users">
        <div className="inner-container">
          <div className="top-url">
            <p>
              <Link to="/companylist"> Companies &gt; </Link>
              <strong>Messages</strong>
            </p>
          </div>
          <div className="manage-container jobs">
            <div className="manage-bg active">
              <div className="parent-container-d">
                <div className="manage-checkboxes-none position-relative message-box">
                  <Loader inShow={isLoader} />
                  <div className="message-container-left">
                    <p className="message-count">
                      Messages {"(" + Object.keys(msgRaw).length + ")"}
                    </p>
                    <div className="message-list">
                      {msgRaw.map((items) => (
                        <div
                          className={
                            items.messageTemplateId ===
                              msgShow.messageTemplateId
                              ? "message-card active"
                              : "message-card"
                          }
                          key={items.messageTemplateId}
                          onClick={() => showItems(items.messageTemplateId)}
                        >
                          <div className="message-header">
                            <span>{items.description}</span>
                            <FormControlLabel
                              control={
                                <Android12Switch
                                  checked={items.isActive}
                                  onChange={() =>
                                    isActiveMsg(
                                      items.messageTemplateId,
                                      items.isActive
                                    )
                                  }
                                />
                              }
                            />
                          </div>
                          {items.canCustomize ? <p>Custom</p> : <p>Default</p>}
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="message-container-right">
                    <form className="h-100">
                      {msgShow ? (
                        <div key={msgShow.messageTemplateId} className="h-100">
                          <div className="message-name message-restore">
                            <span>{msgShow.description}</span>
                            {/* <div className='position-relative'> */}
                            {msgShow.canCustomize ? (
                              <button
                                alt="close"
                                className="cancel-btn restore-btn"
                                onClick={() => handleRestore(msgShow.keyName)}
                              >
                                <img
                                  src={
                                    require("../../../assets/images/restore.svg")
                                      .default
                                  }
                                  alt="Edit"
                                  className="restore-img"
                                />{" "}
                                Restore Default
                              </button>
                            ) : null}
                          </div>
                          <div className="message-details">
                            <div className="message-subject">
                              <div className="forms-input">
                                <label>Email Subject</label>
                                <input
                                  type="text"
                                  placeholder=""
                                  name="emailSubject"
                                  id="emailSubject"
                                  value={msgShow.emailSubject}
                                  onChange={handleChange}
                                // onChange={(e)=>setMsgShow({ ...msgShow, ['emailSubject']: e.target.value , canCustomize:true})}
                                />
                                <span className="error_msg">
                                  {formErrors.emailSubject}
                                </span>
                              </div>
                              <div className="forms-input">
                                <label>Set Message </label>
                                <FormControlLabel
                                  control={
                                    <Android12Switch
                                      checked={msgShow.isActive}
                                      onChange={() =>
                                        isActiveMsg(
                                          msgShow.messageTemplateId,
                                          msgShow.isActive
                                        )
                                      }
                                    />
                                  }
                                />
                              </div>
                            </div>
                            <div className="message-content">
                              <p className="message-content-p">
                                Message Contents{" "}
                                {showEditBox ? (
                                  <IconButton
                                    size="small"
                                    onClick={() => setShowEditBox(false)}
                                  >
                                    <CloseIcon
                                      fontSize="small"
                                      color="action"
                                    />
                                  </IconButton>
                                ) : (
                                  <IconButton
                                    size="small"
                                    onClick={() =>
                                      editBoxHandle(msgShow.emailContent)
                                    }
                                  >
                                    <EditIcon fontSize="small" color="action" />
                                  </IconButton>
                                )}
                              </p>
                              {showEditBox ? (
                                <CKEditor
                                  initData={editor}
                                  config={config}
                                  onChange={handleEditor}
                                  onBeforeLoad={(CKEDITOR) => {
                                    if (
                                      !CKEDITOR.plugins.registered[
                                      "timestamp"
                                      ] &&
                                      !CKEDITOR.plugins.registered[
                                      "custom_options"
                                      ]
                                    ) {
                                      CKEDITOR.plugins.add("timestamp", {
                                        init: function (editor) {
                                          editor.addCommand("insertTimestamp", {
                                            exec: function (editor) {
                                              var now = new Date();
                                              editor.insertHtml(now.toString());
                                            },
                                          });
                                          editor.ui.addButton("Timestamp", {
                                            label: "Insert Timestamp",
                                            command: "insertTimestamp",
                                            toolbar: "insert",
                                            icon: "https://cdn4.iconfinder.com/data/icons/24x24-free-pixel-icons/24/Clock.png",
                                          });
                                        },
                                      });
                                      CKEDITOR.plugins.add("custom_options", {
                                        init: function (editor) {
                                          editor.ui.addRichCombo(
                                            "custom_options",
                                            {
                                              label: "Customize",
                                              toolbar: "basicstyles,0",
                                              extraAllowedContent:
                                                "img[src,alt,width,height]",
                                              icon: "https://cdn4.iconfinder.com/data/icons/basic-user-interface-elements/700/document-text-file-sheet-doc-512.png",

                                              panel: {
                                                css: [
                                                  CKEDITOR.skin.getPath(
                                                    "editor"
                                                  ),
                                                ].concat(config.contentsCss),
                                                multiSelect: false,
                                                attributes: {
                                                  "aria-label":
                                                    "custom options",
                                                },
                                              },

                                              init: function () {
                                                this.startGroup(
                                                  "My custom options"
                                                );
                                                this.add(
                                                  "[Applicant_First_Name]",
                                                  "Applicant_First_Name"
                                                );
                                                this.add(
                                                  "[Applicant_Last_Name]",
                                                  "Applicant_Last_Name"
                                                );
                                                this.add(
                                                  "[Applicant_Full_Name]",
                                                  "Applicant_Full_Name"
                                                );
                                                this.add(
                                                  "[Company_Logo]",
                                                  "Company_Logo"
                                                );
                                                this.add(
                                                  "[Company_Name]",
                                                  "Company_Name"
                                                );
                                                this.add(
                                                  "[Job_Name]",
                                                  "Job_Name"
                                                );
                                                this.add(
                                                  "[Job_Code]",
                                                  "Job_Code"
                                                );
                                                this.add(
                                                  "[Job_Name_Code]",
                                                  "Job_Name_Code"
                                                );
                                                this.add(
                                                  "[LoginID]",
                                                  "LoginID"
                                                );
                                                this.add(
                                                  "[UserName]",
                                                  "UserName"
                                                );
                                                this.add(
                                                  "[CurrentUserName]",
                                                  "CurrentUserName"
                                                );
                                                this.add(
                                                  "[Password]",
                                                  "Password"
                                                );
                                                this.add(
                                                  "[Department_Name]",
                                                  "Department_Name"
                                                );
                                                this.add(
                                                  "[Facility_Name]",
                                                  "Facility_Name"
                                                );
                                                this.add(
                                                  "[Current_Date]",
                                                  "Current_Date"
                                                );
                                                this.add(
                                                  "[Start_Date]",
                                                  "Start_Date"
                                                );
                                                this.add(
                                                  "[End_Date]",
                                                  "End_Date"
                                                );
                                                this.add(
                                                  "[Expire_Date]",
                                                  "Expire_Date"
                                                );
                                                this.add(
                                                  "[Supervisor_Names]",
                                                  "Supervisor_Names"
                                                );
                                                this.add(
                                                  "[Participent_Names]",
                                                  "Participent_Names"
                                                );
                                                this.add(
                                                  "[Pay_Rate]",
                                                  "Pay_Rate"
                                                );
                                                this.add(
                                                  "[Applicant_Current_Stage]",
                                                  "Applicant_Current_Stage"
                                                );
                                                this.add(
                                                  "[Applicant_Next_Stage]",
                                                  "Applicant_Next_Stage"
                                                );
                                                this.add(
                                                  "[DownloadLink]",
                                                  "DownloadLink"
                                                );
                                                this.add(
                                                  "[Chat_Representative_Link]",
                                                  "Chat_Representative_Link"
                                                );
                                                this.add(
                                                  "[Video_Interview_Link]",
                                                  "Video_Interview_Link"
                                                );
                                                this.add(
                                                  "[ApplicantLink]",
                                                  "ApplicantLink"
                                                );
                                                this.add(
                                                  "[ReferenceLink]",
                                                  "ReferenceLink"
                                                );
                                                this.add(
                                                  "[OfferInfoLink]",
                                                  "OfferInfoLink"
                                                );
                                                this.add(
                                                  "[ReviewLink]",
                                                  "ReviewLink"
                                                );
                                                this.add(
                                                  "[OfferLetterLink]",
                                                  "OfferLetterLink"
                                                );
                                                this.add(
                                                  "[InterviewInfoLink]",
                                                  "InterviewInfoLink"
                                                );
                                                this.add(
                                                  "[MessageLink]",
                                                  "MessageLink"
                                                );
                                                this.add(
                                                  "[DocPackLink]",
                                                  "DocPackLink"
                                                );
                                                this.add(
                                                  "[NotifyMessage]",
                                                  "NotifyMessage"
                                                );
                                                this.add(
                                                  "[Notes_Message]",
                                                  "Notes_Message"
                                                );
                                                this.add(
                                                  "[ScheduledApplicant]",
                                                  "ScheduledApplicant"
                                                );
                                                this.add(
                                                  "[TypeName]",
                                                  "TypeName"
                                                );
                                                this.add(
                                                  "[Sequence]",
                                                  "Sequence"
                                                );
                                                this.add(
                                                  "[ChatPrompt]",
                                                  "ChatPrompt"
                                                );
                                              },

                                              onClick: function (value) {
                                                editor.focus();
                                                if (value == "[Company_Logo]") {
                                                  editor.insertHtml(
                                                    "<img src=" +
                                                    localStorage.getItem(
                                                      "companyLogoPath"
                                                    ) +
                                                    ">"
                                                  );
                                                } else {
                                                  editor.insertHtml(value);
                                                }
                                              },
                                            }
                                          );
                                        },
                                      });
                                    }
                                  }}
                                />
                              ) : (
                                <div
                                  className="format-detail"
                                  dangerouslySetInnerHTML={{
                                    __html: msgShow.emailContent,
                                  }}
                                ></div>
                              )}
                              <span className="error_msg">
                                {formErrors.emailContent}
                              </span>
                            </div>
                          </div>
                          <div className="btns-container">
                            <ButtonUI
                              p="10px"
                              width="100px"
                              onClick={(e) => handleSubmit(e)}
                            >{isSubmitting === true ? <div className="dot-pulse"></div> : "Save"}</ButtonUI>
                          </div>
                        </div>
                      ) : null}
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
