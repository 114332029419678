export const AudioIcon = ({ ...props }) => {
  return (
    <svg
      {...props}
      viewBox="0 0 18 17"
      fill={props.fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.89953 8.49992V4.42742C5.89953 3.61735 6.22133 2.84046 6.79414 2.26765C7.36694 1.69485 8.14384 1.37305 8.95391 1.37305C9.76398 1.37305 10.5409 1.69485 11.1137 2.26765C11.6865 2.84046 12.0083 3.61735 12.0083 4.42742V8.49992C12.0083 9.30999 11.6865 10.0869 11.1137 10.6597C10.5409 11.2325 9.76398 11.5543 8.95391 11.5543C8.14384 11.5543 7.36694 11.2325 6.79414 10.6597C6.22133 10.0869 5.89953 9.30999 5.89953 8.49992ZM14.0445 8.49992C14.0445 8.36491 13.9909 8.23543 13.8954 8.13996C13.8 8.04449 13.6705 7.99086 13.5355 7.99086C13.4005 7.99086 13.271 8.04449 13.1755 8.13996C13.08 8.23543 13.0264 8.36491 13.0264 8.49992C13.0264 9.58002 12.5973 10.6159 11.8336 11.3796C11.0699 12.1434 10.034 12.5724 8.95391 12.5724C7.87381 12.5724 6.83796 12.1434 6.07421 11.3796C5.31047 10.6159 4.88141 9.58002 4.88141 8.49992C4.88141 8.36491 4.82777 8.23543 4.73231 8.13996C4.63684 8.04449 4.50736 7.99086 4.37234 7.99086C4.23733 7.99086 4.10785 8.04449 4.01238 8.13996C3.91691 8.23543 3.86328 8.36491 3.86328 8.49992C3.86483 9.76147 4.33411 10.9776 5.18038 11.9132C6.02665 12.8488 7.18978 13.4374 8.44484 13.5651V15.1177C8.44484 15.2527 8.49848 15.3822 8.59394 15.4777C8.68941 15.5732 8.81889 15.6268 8.95391 15.6268C9.08892 15.6268 9.2184 15.5732 9.31387 15.4777C9.40934 15.3822 9.46297 15.2527 9.46297 15.1177V13.5651C10.718 13.4374 11.8812 12.8488 12.7274 11.9132C13.5737 10.9776 14.043 9.76147 14.0445 8.49992Z"
        fill={props.fill}
      />
    </svg>
  );
};
