import React, { useState } from "react";
import { ApplicantInfoTabs } from "./applicantInfoTabs";
import { ApplicantJobData } from "./applicantJobData";
import CloseIcon from "@mui/icons-material/Close";
import "../../../../../assets/css/common/_col-form.css";
import { IconButton, Rating, Tooltip } from "@mui/material";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { APPLICANT_DATA_ACTIONS } from "../../../../../redux/applicantData/actions";
import BackgroundLetterAvatars from "../../BackgroundLetterAvatars";
import { useEffect } from "react";
import Api from "../../../../../helper/Api";
import {
  BlueAddIcon,
  DarkSuitCaseIcon,
  LightSuitCaseIcon,
} from "../../../../../icons";
import EastIcon from "@mui/icons-material/East";
import EmailIcon from "@mui/icons-material/Email";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { AddApplicantForm } from "../../jobInfo/content/addApplicant";
import { toast } from "react-toastify";
import { SendApplicantForm } from "./sendApplicantForm";
import { validations } from "../../../../../helper/validations";
import { JobSelectForm } from "./applicantJobData/jobSelectForm";
import { DarkGreySuitCaseIcon } from "../../../../../icons/suitCase/dark/grey";
import { LightGreySuitCaseIcon } from "../../../../../icons/suitCase/light/grey";
import { indigo, red } from "@mui/material/colors";
import NotificationsIcon from "@mui/icons-material/Notifications";

export const ApplicantContent = ({ data }) => {
  const dispatch = useDispatch();
  const domNode = document?.getElementById(data.applicantId) || "";
  const [selectedSuitCase, setSelectedSuitCase] = useState({});
  const [selectedStage, setSelectedStage] = useState({});
  const [applicantInfo, setApplicantInfo] = useState([]);
  const [value, setValue] = useState(0);
  const [avgRating, setAvgRating] = useState(0);
  const [memberNo, setMemberNo] = useState(0);
  const [isShow, setShow] = useState(false);
  const [appDetails, setAppDetails] = useState({});
  const [appName, setAppName] = useState(data.name);
  const [applicantRatingList, setApplicantRatingList] = useState([]);
  const [openEditAppForm, setEditAppForm] = useState(false);
  const [formValues, setFormValues] = useState({});
  const formData = new FormData();
  const [isSubmitting, setSubmitting] = useState(false);
  const [openSendAppForm, setOpenAppSendForm] = useState(false);
  const [isAppSubmitting, setAppSubmitting] = useState(false);
  const [cvUpdated, setCvUpdated] = useState(false);
  const errors = {};
  const [formErrors, setFormErrors] = useState({});
  const [selectJob, setSelectedJob] = useState(false);
  const [isClosingTab, setIsClosingTab] = useState(false);

  const askForConfirmation = 0;
  const [isClicked, setClicked] = useState(false);
  const { applicantsArray } = useSelector(
    (state) => state.applicantDataReducer
  );

  useEffect(() => {
    // getApplicantRating();
    getApplicantJobData();
    getApplicantRatingList();
  }, []);

  useEffect(() => {
    if (data) {
      const storedSelectedSuitcaseId = localStorage.getItem(
        `selectedSuitcaseId_${data.applicantId}`
      );
      if (
        !storedSelectedSuitcaseId &&
        data.applicantList &&
        data.applicantList.length > 0
      ) {
        setSelectedSuitCase((prevSelectedSuitcases) => ({
          ...prevSelectedSuitcases,
          [data.applicantId]: data.applicantList[0].jobId, // Set the first suitcase as default
        }));
        localStorage.setItem(
          `selectedSuitcaseId_${data.applicantId}`,
          data.applicantList[0].jobId
        );
      }
    }
  }, [data]);

  const getApplicantRatingList = () => {
    Api(
      "GET",
      `Job/GetApplicantRatingList?applicantId=${data.applicantId}&companyId=${data.companyId}`
    ).then((res) => {
      setApplicantRatingList(res.data);
      const data = [...res.data];
      var el = 0;

      data.forEach((rec) => {
        if (rec.userId == localStorage.getItem("user")) {
          setValue(rec.rating);
        }
        el = el + rec.rating;
      });
      setMemberNo(data.length);

      const avg = el / data.length;
      setAvgRating(avg);
    });
  };

  const closeApplicantTab = () => {
    setIsClosingTab(true);
    const dataArr = [...applicantsArray];

    dataArr.forEach((rec, index) => {
      if (rec.applicantId === data.applicantId) {
        dataArr.splice(index, 1);
      }
    });
    setTimeout(() => {
      dispatch({
        type: APPLICANT_DATA_ACTIONS.SET_APPLICANTS_ARRAY,
        payload: dataArr,
      });

      setIsClosingTab(false);
    }, 400);
  };

  const saveApplicantRating = (e, rate) => {
    if (!isClicked) setClicked(true);
    const params = {
      companyId: data.companyId,
      applicantId: data.applicantId,
      rating: rate,
    };
    setValue(rate);
    Api("POST", `Job/AddAverageRateingUserWise`, params)
      .then((res) => {
        if (res.data.isSuccess === true) {
          getApplicantRatingList();
        }
      })
      .finally(() => {
        setClicked(false);
      });
  };

  const getApplicantJobData = (newJobId = data.jobId) => {
    Api(
      "GET",
      `Job/ViewApplicantData?companyId=${data.companyId}&applicantId=${data.applicantId}`
    ).then((res) => {
      setApplicantInfo(res.data);
      if (res.data.length > 1) {
        let rec = res.data?.find((d) => d.jobId === newJobId);
        setSelectedSuitCase(rec);
        showInfo(rec.jobId);
      } else {
        setSelectedSuitCase(res.data[0]);
        showInfo(res.data[0].jobId);
      }
    });
  };

  const toggleDetails = () => {
    setShow(!isShow);
  };

  const showInfo = (jobId) => {
    Api(
      "GET",
      `Job/GetApplicantById?applicantId=${data.applicantId}&companyId=${data.companyId}&jobId=${jobId}`
    ).then((res) => {
      setAppDetails(res.data);
      setAppName(res.data.firstName + " " + res.data.lastName);
      setFormValues(res.data);
    });
  };

  const ratingList = () => {
    return (
      <div>
        {applicantRatingList.map((rate) => (
          <div className="d-flex align-items-center">
            <span>{rate.fName} : </span>
            <Rating
              name="read-only"
              size="small"
              value={rate.rating}
              readOnly
              precision={0.5}
            />
          </div>
        ))}
      </div>
    );
  };

  const toggleEditAppForm = (anchor, open) => {
    setEditAppForm({ ...openEditAppForm, [anchor]: open });
  };

  const handleEditApp = () => {
    formValues.applicantPhoneList.map((phone, index) => {
      if (phone.phone !== "" && phone.isNotify !== "") {
        formData.append("applicantPhoneList[" + index + "].phone", phone.phone);
        formData.append(
          "applicantPhoneList[" + index + "].applicantNotificationId",
          phone.applicantNotificationId
        );
        formData.append(
          "applicantPhoneList[" + index + "].isNotify",
          phone.isNotify
        );
        formData.append(
          "applicantPhoneList[" + index + "].isDelete",
          phone.isDelete
        );
      }
    });

    formValues.applicantEmailList.map((emailId, index) => {
      if (emailId.email !== "" && emailId.isNotify !== "") {
        formData.append(
          "applicantEmailList[" + index + "].email",
          emailId.email
        );
        formData.append(
          "applicantEmailList[" + index + "].applicantNotificationId",
          emailId.applicantNotificationId
        );
        formData.append(
          "applicantEmailList[" + index + "].isNotify",
          emailId.isNotify
        );
        formData.append(
          "applicantEmailList[" + index + "].isDelete",
          emailId.isDelete
        );
      }
    });

    setSubmitting(true);
    formData.append("firstName", formValues?.firstName ?? "");
    formData.append("middleName", formValues?.middleName ?? "");
    formData.append("lastName", formValues?.lastName ?? "");
    formData.append("nickName", formValues?.nickName ?? "");
    formData.append("title", formValues?.title ?? "");
    formData.append("phoneNumber", formValues?.phoneNumber ?? "");
    formData.append("resume", formValues?.resume ?? "");
    formData.append("viewResume", formValues?.viewResume ?? "");
    formData.append("companyId", formValues?.companyId);
    formData.append("jobId", formValues.jobId);
    formData.append("applicantId", formValues.applicantId);
    formData.append("address.addressLineOne", formValues?.addressLineOne ?? "");
    formData.append("address.addressLineTwo", formValues?.addressLineTwo ?? "");
    formData.append("address.country", "");
    formData.append("address.city", formValues?.city ?? "");
    formData.append("address.state", formValues?.state ?? "");
    formData.append("address.zipCode", formValues?.zipCode ?? "");
    formData.append("source", formValues?.source ?? "");

    Api("PUT", `Job/EditApplicant`, formData, "multipart")
      .then((res) => {
        if (res.data.isSuccess === true) {
          dispatch({
            type: APPLICANT_DATA_ACTIONS.SET_APPLICANTS_REFRESH,
          });
          toast.success(res.data.message);
          toggleEditAppForm("right", false);
          showInfo(formValues.jobId);
          setCvUpdated(true);
        } else {
          toast.error(res.data.message);
        }
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const toggleSendApplicantForm = (open) => {
    setOpenAppSendForm(open);
  };

  const handleSendApp = (allData) => {
    const recs = [];
    allData.map((d) => {
      if (d.checked === true) {
        recs.push({ ["userId"]: d.userId });
      }
    });

    setFormErrors(validate(recs));
    if (Object.values(validate(recs)).length > 0) {
      return;
    }

    const params = {
      companyId: data.companyId,
      applicantId: data.applicantId,
      jobId: selectedSuitCase.jobId,
      userLists: recs,
    };

    setAppSubmitting(true);
    Api("POST", "Job/SendMailtoUser", params)
      .then((res) => {
        if (res.data.isSuccess === true) {
          toggleSendApplicantForm(false);
          toast.success(res.data.message);
        } else {
          toast.error(res.data.message);
        }
      })
      .finally(() => {
        setAppSubmitting(false);
      });
  };

  const validate = (values) => {
    if (values.length === 0) {
      errors.users = validations.users.req;
    }

    return errors;
  };

  const toggleJobSelectForm = (open) => {
    setSelectedJob(open);
  };

  const handleJobSelect = (jIds) => {
    const params = {
      companyId: data.companyId,
      applicantId: data.applicantId,
      jobIds: jIds,
    };

    setSubmitting(true);
    Api("POST", `Job/ApplyOtherJobs`, params)
      .then((res) => {
        if (res.data.isSuccess === true) {
          toggleJobSelectForm(false);
          toast.success(res.data.message);
          getApplicantJobData(jIds[0]);
          setSelectedStage("REVIEWING");
        } else {
          toast.error(res.data.message);
        }
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const openJob = (job, applicantId) => {
    setSelectedSuitCase(job); // Update selected suitCase directly
    localStorage.setItem(`selectedSuitcaseId_${applicantId}`, job.jobId);

    if (job.jobCycleStatus === "NEW_APPLICATION") {
      setSelectedStage("REVIEWING");
    } else {
      setSelectedStage(job.jobCycleStatus);
    }
  };

  const getSuitCase = (job, applicantId) => {
    const storedSelectedSuitcaseId = localStorage.getItem(
      `selectedSuitcaseId_${applicantId}`
    );

    const handleSuitcaseClick = () => {
      openJob(job, applicantId);
    };

    let suitCase;

    if (storedSelectedSuitcaseId === null && job.isStopApplication) {
      suitCase = (
        <Tooltip title={`${job.jobName} (${job.jobNo})`}>
          <IconButton size="small" onClick={handleSuitcaseClick}>
            <DarkGreySuitCaseIcon width="30" height="30" />
          </IconButton>
        </Tooltip>
      );
    } else if (
      job.jobId === storedSelectedSuitcaseId &&
      job.isStopApplication
    ) {
      suitCase = (
        <Tooltip title={`${job.jobName} (${job.jobNo})`}>
          <IconButton size="small" onClick={handleSuitcaseClick}>
            <DarkGreySuitCaseIcon width="30" height="30" />
          </IconButton>
        </Tooltip>
      );
    } else if (job.isStopApplication) {
      suitCase = (
        <Tooltip title={`${job.jobName} (${job.jobNo})`}>
          <IconButton size="small" onClick={handleSuitcaseClick}>
            <LightGreySuitCaseIcon width="30" height="30" />
          </IconButton>
        </Tooltip>
      );
    } else if (job.jobId === storedSelectedSuitcaseId) {
      suitCase = (
        <Tooltip title={`${job.jobName} (${job.jobNo})`}>
          <IconButton size="small" onClick={handleSuitcaseClick}>
            <DarkSuitCaseIcon width="30" height="30" />
          </IconButton>
        </Tooltip>
      );
    } else {
      suitCase = (
        <Tooltip title={`${job.jobName} (${job.jobNo})`}>
          <IconButton size="small" onClick={handleSuitcaseClick}>
            <LightSuitCaseIcon width="30" height="30" />
          </IconButton>
        </Tooltip>
      );
    }

    return suitCase;
  };

  return (
    <div className="user-list applicant-h" id={data.applicantId}>
      {openEditAppForm && (
        <AddApplicantForm
          openAddForm={openEditAppForm}
          toggleDrawer={toggleEditAppForm}
          handleAddApplicant={handleEditApp}
          formValues={formValues}
          setFormValues={setFormValues}
          label="Edit Applicant"
          isSubmitting={isSubmitting}
          askForConfirmation={askForConfirmation}
        />
      )}

      {openSendAppForm && (
        <SendApplicantForm
          openSidebar={openSendAppForm}
          toggleDrawer={toggleSendApplicantForm}
          handleSendApp={handleSendApp}
          applicantId={data.applicantId}
          companyId={data.companyId}
          isSubmitting={isAppSubmitting}
          setSubmitting={setAppSubmitting}
          formErrors={formErrors}
        />
      )}

      {selectJob && (
        <JobSelectForm
          openForm={selectJob}
          setOpenForm={setSelectedJob}
          handleJobSelect={handleJobSelect}
          companyId={data.companyId}
          applicantId={data.applicantId}
          toggleForm={toggleJobSelectForm}
          isSubmitting={isSubmitting}
          jobId={data.jobId}
        />
      )}

      <div className="applicant-header">
        <div className="d-flex align-items-center">
          <BackgroundLetterAvatars
            name={appName}
            width={32}
            height={32}
            fontSize="16px"
          />
          <strong>{appName}</strong>
        </div>
        <div>
          <Tooltip title="Edit Applicant">
            <IconButton
              size="small"
              onClick={() => (
                toggleEditAppForm("right", true), showInfo(data.jobId)
              )}
            >
              <EditOutlinedIcon sx={{ color: indigo[500] }} />
            </IconButton>
          </Tooltip>

          <Tooltip title="Close Applicant">
            <IconButton
              size="small"
              onClick={closeApplicantTab}
              disabled={isClosingTab}
            >
              <CloseIcon sx={{ color: red[500] }} />
            </IconButton>
          </Tooltip>
        </div>
      </div>

      <div className="column-content">
        <ApplicantInfoTabs
          applicantData={data}
          selectedSuitCase={selectedSuitCase}
          cvUpdated={cvUpdated}
          setCvUpdated={setCvUpdated}
        />
        <div className="border-bottom"></div>
        <div className="border-bottom">
          {appDetails?.applicantEmailList && (
            <div className="show-details">
              <strong className="pointer" onClick={() => toggleDetails()}>
                {isShow ? "Hide" : "Show"} Applicant Details
              </strong>
              <Tooltip title="Send Applicant to User">
                <IconButton
                  size="small"
                  onClick={() => (
                    toggleSendApplicantForm(true), setFormErrors({})
                  )}
                >
                  <EastIcon color="action" />
                </IconButton>
              </Tooltip>
            </div>
          )}
          {isShow && (
            <div className="applicant-show-details">
              <div className="">
                <span>
                  {appDetails?.applicantEmailList?.length > 0 && (
                    <div className="show-tabuser">
                      {appDetails.applicantEmailList.map((appEmail, index) => (
                        <div
                          key={`email-${index}`}
                          className="email-phone-item"
                        >
                          <Tooltip title="Email">
                            <EmailIcon color="action" fontSize="small" />
                          </Tooltip>
                          <div className="ml-15">
                            <Tooltip title={appEmail.email}>
                              <a
                                href={`mailto:${appEmail.email}`}
                                target="_blank"
                              >
                                {appEmail.email}
                              </a>
                            </Tooltip>
                            {appEmail.isNotify && (
                              <Tooltip title="Notify" className="bell-icon">
                                <NotificationsIcon color="action" />
                              </Tooltip>
                            )}
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </span>
              </div>
              <div className="s">
                <span>
                  {appDetails?.applicantPhoneList?.length > 0 && (
                    <div className="how-tabuser">
                      {appDetails.applicantPhoneList.map((appPhone, index) => (
                        <div
                          key={`phone-${index}`}
                          className="email-phone-item"
                        >
                          <Tooltip title="Phone Number">
                            <LocalPhoneIcon color="action" fontSize="small" />
                          </Tooltip>
                          <div className="ml-15">
                            <Tooltip title={appPhone.phone}>
                              <a
                                key={`phone-${index}`}
                                href={`tel:${appPhone.phone}`}
                                target="_blank"
                              >
                                {appPhone.phone}
                              </a>
                            </Tooltip>
                            {appPhone.isNotify && (
                              <Tooltip title="Notify" className="bell-icon">
                                <NotificationsIcon color="action" />
                              </Tooltip>
                            )}
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </span>
              </div>
              <div className="show-tabuser">
                <Tooltip title="TimeZone">
                  <AccessTimeIcon color="action" fontSize="small" />
                </Tooltip>
                <span className="">{appDetails.timeZone}</span>
              </div>
              <div className="show-tabuser">
                <strong>Title:</strong>
                <span className="ml10span">{appDetails?.title}</span>
              </div>
            </div>
          )}
        </div>

        {/* Rating start */}
        <div className="border-bottom">
          <div className="p-16 font-12">
            <div className="d-flex align-items-center justify-content-between">
              <div className="d-flex align-items-center">
                <strong>Average Rating :</strong>
                <Rating
                  name="read-only"
                  value={avgRating}
                  readOnly
                  precision={0.5}
                />
              </div>
              {memberNo > 0 ? (
                <Tooltip title={ratingList()}>
                  <span>({memberNo})</span>
                </Tooltip>
              ) : (
                <span>({memberNo})</span>
              )}
            </div>
            <div className="d-flex align-items-center mt-5">
              <strong>Your Rating: </strong>
              <Rating
                name="simple-controlled"
                value={value}
                precision={1}
                onChange={saveApplicantRating}
                disabled={isClicked}
              />
            </div>
          </div>
        </div>
        {/* Rating end */}

        {/* Suit case start */}
        <div className="p-16">
          <div className="d-flex" style={{ flexWrap: "wrap" }}>
            {applicantInfo?.map((job) => (
              <div key={job.jobId}>{getSuitCase(job, job.applicantId)}</div>
            ))}
            <Tooltip
              title="Select a Job"
              onClick={() => toggleJobSelectForm(true)}
            >
              <IconButton size="small">
                <BlueAddIcon width="30" height="30" className="pointer" />
              </IconButton>
            </Tooltip>
          </div>
        </div>
        {/* Suit case end */}

        {/* Job stages start */}
        <div
          className={
            domNode ? "user-details-tab applicants" : "user-details-tab"
          }
        >
          <ApplicantJobData
            applicantData={data}
            selectedSuitCase={selectedSuitCase}
            selectedStage={selectedStage}
            setSelectedStage={setSelectedStage}
            getApplicantJobData={getApplicantJobData}
          />
        </div>
        {/* Job stages end */}
      </div>
    </div>
  );
};
