export const AcceptIcon = () => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.6518 4.2232C8.71298 4.28417 8.76152 4.35662 8.79464 4.43638C8.82777 4.51615 8.84482 4.60168 8.84482 4.68805C8.84482 4.77442 8.82777 4.85994 8.79464 4.93971C8.76152 5.01948 8.71298 5.09192 8.6518 5.15289L5.5893 8.21539C5.52833 8.27657 5.45589 8.32511 5.37612 8.35824C5.29635 8.39136 5.21083 8.40841 5.12446 8.40841C5.03808 8.40841 4.95256 8.39136 4.87279 8.35824C4.79303 8.32511 4.72058 8.27657 4.65961 8.21539L3.34711 6.90289C3.28607 6.84185 3.23765 6.76938 3.20461 6.68962C3.17157 6.60986 3.15457 6.52438 3.15457 6.43805C3.15457 6.35172 3.17157 6.26623 3.20461 6.18648C3.23765 6.10672 3.28607 6.03425 3.34711 5.9732C3.40816 5.91216 3.48063 5.86374 3.56038 5.8307C3.64014 5.79766 3.72563 5.78066 3.81196 5.78066C3.89828 5.78066 3.98377 5.79766 4.06353 5.8307C4.14329 5.86374 4.21575 5.91216 4.2768 5.9732L5.125 6.82031L7.72321 4.22156C7.78426 4.16065 7.85672 4.11238 7.93645 4.07949C8.01618 4.04661 8.1016 4.02976 8.18784 4.02991C8.27408 4.03007 8.35945 4.04721 8.43906 4.08038C8.51867 4.11355 8.59096 4.16208 8.6518 4.2232ZM11.9063 6C11.9063 7.16815 11.5599 8.31006 10.9109 9.28134C10.2619 10.2526 9.33945 11.0096 8.26023 11.4567C7.181 11.9037 5.99345 12.0207 4.84775 11.7928C3.70205 11.5649 2.64966 11.0024 1.82365 10.1763C0.997649 9.35035 0.435134 8.29795 0.20724 7.15225C-0.0206544 6.00655 0.0963091 4.819 0.543339 3.73978C0.990369 2.66055 1.74739 1.73812 2.71867 1.08913C3.68994 0.440146 4.83186 0.09375 6 0.09375C7.5659 0.095487 9.06717 0.718309 10.1744 1.82557C11.2817 2.93283 11.9045 4.4341 11.9063 6ZM10.5938 6C10.5938 5.09144 10.3243 4.20329 9.81957 3.44785C9.3148 2.69241 8.59735 2.10362 7.75795 1.75593C6.91856 1.40824 5.99491 1.31727 5.10381 1.49452C4.21271 1.67177 3.39418 2.10928 2.75173 2.75173C2.10928 3.39418 1.67177 4.2127 1.49452 5.1038C1.31727 5.9949 1.40824 6.91855 1.75593 7.75795C2.10362 8.59735 2.69241 9.3148 3.44785 9.81956C4.20329 10.3243 5.09144 10.5938 6 10.5938C7.21794 10.5924 8.38562 10.108 9.24683 9.24683C10.108 8.38562 10.5924 7.21794 10.5938 6Z"
        fill="#34AA44"
      />
    </svg>
  );
};
