export const EditIconOutlined = () => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.6678 2.89486L9.22438 0.45088C9.12281 0.349269 9.00222 0.268664 8.86949 0.21367C8.73676 0.158676 8.5945 0.130371 8.45083 0.130371C8.30715 0.130371 8.16489 0.158676 8.03216 0.21367C7.89943 0.268664 7.77884 0.349269 7.67727 0.45088L0.933755 7.19494C0.831818 7.29621 0.751001 7.41671 0.695989 7.54945C0.640978 7.68219 0.612867 7.82454 0.613286 7.96822V10.4122C0.613286 10.7023 0.72852 10.9805 0.933638 11.1856C1.13876 11.3907 1.41696 11.506 1.70704 11.506H4.15102C4.2947 11.5063 4.43704 11.4782 4.56977 11.4232C4.70251 11.3682 4.82301 11.2874 4.9243 11.1855L11.6678 4.44143C11.8729 4.23632 11.9881 3.95817 11.9881 3.66815C11.9881 3.37812 11.8729 3.09997 11.6678 2.89486ZM4.0586 10.1935H1.92579V8.06065L6.51954 3.4669L8.65235 5.59971L4.0586 10.1935ZM9.58204 4.67002L7.44922 2.53721L8.45219 1.53424L10.585 3.66705L9.58204 4.67002Z"
        fill="#39A571"
      />
    </svg>
  );
};
