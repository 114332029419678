import { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { FlexiCard } from "../../common/cards/FlexiCard";
import { EditJobTemplate } from "./editJobTemplate";
import { validations } from "../../../helper/validations";
import Api from "../../../helper/Api";
import NoRecordsFound from "../../common/NoRecordsFound";
import Loader from "../../common/Loader";
import { toast } from "react-toastify";
import { StyledPagination } from "../../ui/pagination/pagination";
import { ButtonUI } from "../../ui/button";
import { JOB_TEMPLATE_ACTIONS } from "../../../redux/jobTemplate/actions";
import { useDispatch } from "react-redux";
import AddIcon from "@mui/icons-material/Add";
import { AlertBox, showSuccess } from "../../../helper/utils";

export const JobTemplates = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [openSidebar, setSidebar] = useState(false);
  const [formValues, setFormValues] = useState([]);
  const [formErrors, setFormErrors] = useState([]);
  const [jobTemplates, setJobTemplates] = useState([]);
  const [isLoader, setIsLoader] = useState(true);
  const [searchInput, setSearchInput] = useState("");
  const [totalPage, setTotalPage] = useState(1);
  const [page, setPage] = useState(1);
  const [edit, setEdit] = useState(false);
  const dispatch = useDispatch();
  const [isSubmitting, setSubmitting] = useState(false);

  const errors = {};
  let navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (searchInput === "") {
      setIsLoader(true);
      getJobTemplateData();
    }
  }, [searchInput]);

  useEffect(() => {
    setIsLoader(true);
    getJobTemplateData();
  }, [page]);

  const cardClickEvent = (data) => {
    location.state.data = data;
    navigate("/job-templates/details", { state: { ...location.state } });
  };

  const toggleDrawer = (anchor, open) => {
    setSidebar({ ...openSidebar, [anchor]: open });
    setFormValues([]);
  };

  // pagination
  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const saveJobTemplate = (e) => {
    e.preventDefault();
    setFormErrors(validate(formValues));
    if (Object.keys(validate(formValues)).length > 0) {
      return;
    }
    var params = {};

    setSubmitting(true);
    params = { ...formValues };
    params.rate = formValues.rate ? +formValues.rate : 0;

    if (edit === true) {
      Api("PUT", "JobTemplate/EditJobTemplate", params)
        .then((res) => {
          if (res.data.isSuccess === true) {
            getJobTemplateData();
            toggleDrawer("right", false);
            toast.success(res.data.message);
          } else {
            toast.error(res.data.message);
          }
        })
        .catch((err) => {
          console.log(err);
          toast.error(err);
        })
        .finally(() => {
          setSubmitting(false);
        });
    } else {
      Api("POST", `JobTemplate/AddJobTemplate`, params)
        .then((res) => {
          if (res.data.isSuccess === true) {
            toast.success(res.data.message);
            toggleDrawer("right", false);
            getJobTemplateData();
          } else {
            toast.error(res.data.message);
          }
        })
        .catch((err) => {
          console.log(err);
          toast.error(err);
        })
        .finally(() => {
          setSubmitting(false);
        });
    }
  };

  // On every search input added
  const handleSearch = (event) => {
    setSearchInput(event.target.value);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      getJobTemplateData();
    }
  };

  // Return searched records
  const getSearchJobTemplate = () => {
    if (!searchInput) {
      window.alert("Please Enter Search Value!");
    } else {
      let data = jobTemplates.filter((jobTemp) =>
        jobTemp.name.toLowerCase().match(searchInput.toLowerCase())
      );
      setJobTemplates(data);
    }
  };

  const getJobTemplateById = (jobTemplateId) => {
    Api(
      "GET",
      `JobTemplate/GetJobTemplateById?companyId=${location.state.companyId}&jobTemplateId=${jobTemplateId}`
    )
      .then((res) => {
        dispatch({
          type: JOB_TEMPLATE_ACTIONS.SET_JOB_TEMP_DATA,
          payload: res.data,
        });
        setFormValues({
          jobTemplateId: jobTemplateId,
          companyId: res.data.description.companyId,
          isActive: res.data.description.isActive,
          name: res.data.description.name,
          description: res.data.description.description,
          excemptOverTime: res.data.description.excemptOverTime,
          facilityId: res.data.description.facilityId,
          departmentId: res.data.description.departmentId,
          rate: res.data.description.rate,
          rateTypeId: res.data.description.rateTypeId,
          shiftTypeId: res.data.description.shiftTypeId,
          scheduleTypeId: res.data.description.scheduleTypeId,
          employmentTypeId: res.data.description.employmentTypeId,
          videoInterviewId: res.data.description.videoInterviewId,
          previousEmp: res.data.description.previousEmp,
          totalApplication: res.data.description.totalApplication,
          appPerJobBoard: res.data.description.appPerJobBoard,
        });
      })
      .catch((err) => {
        console.log(err);
        toast.error(err);
      });
  };

  // Validations
  const validate = (values) => {
    if (!values.companyId) {
      errors.companyId = validations?.companyId?.req;
    }

    if (!values.name) {
      errors.name = validations?.name?.req;
    }

    if (!values.description) {
      errors.description = validations?.description?.req;
    }

    return errors;
  };

  const handleEdit = (jobTemplateId) => {
    setEdit(true);
    setFormErrors({});
    getJobTemplateById(jobTemplateId);
    toggleDrawer("right", true);
  };

  const getJobTemplateData = () => {
    Api(
      "GET",
      `JobTemplate/GetJobTemplateList?companyId=${location.state.companyId}&companyHirerchy=${location.state.companyHirerchy}&pageNumber=${page}&searchText=${searchInput}&OrderBy=createdAt&OrderbyDirection=desc`
    )
      .then((res) => {
        setJobTemplates(res.data.data);
        setTotalPage(res.data.totalPage);
      })
      .catch((err) => {
        console.log(err);
        toast.error(err);
      })
      .finally(() => setIsLoader(false));
  };

  const handleDelete = (jobTemplateId) => {
    AlertBox().then((res) => {
      if (!res.isConfirmed) return;
      // Delete Job Template api call
      Api(
        "DELETE",
        `JobTemplate/DeleteJobTemplate?companyId=${location.state.companyId}&jobTemplateId=${jobTemplateId}`
      )
        .then((res) => {
          if (res.data.isSuccess == true) {
            showSuccess("Deleted", res.data.message);
            getJobTemplateData();
          } else {
            toast.error(res.data.message);
          }
        })
        .catch((err) => {
          console.log(err);
          toast.error(err);
        });
    });
  };

  return (
    <div>
      {openSidebar && (
        <EditJobTemplate
          openSidebar={openSidebar}
          toggleDrawer={toggleDrawer}
          formValues={formValues}
          setFormValues={setFormValues}
          formErrors={formErrors}
          setFormErrors={setFormErrors}
          saveJobTemplate={saveJobTemplate}
          label={edit ? "Edit Job Template" : "Add Job Template"}
          isSubmitting={isSubmitting}
        />
      )}

      <div className="common-container users">
        <div className="inner-container">
          <div className="top-url">
            <p>
              <Link to="/companylist"> Companies &gt; </Link>
              <strong>Job Templates</strong>
            </p>
          </div>
          <div className="manage-container jobs">
            <div className="manage-bg active">
              <div className="parent-container-d">
                <div className="content_header_reasons">
                  <div className="company_search">
                    <img
                      src={require("../../../assets/images/search_icon.png")}
                      alt="search-icon"
                      className="search_img"
                    />
                    <input
                      type="text"
                      placeholder="Search Job Template"
                      className="search_input appointments"
                      name="search_input"
                      value={searchInput}
                      onChange={handleSearch}
                      onKeyPress={handleKeyPress}
                    />
                    <button
                      className="search-btn"
                      onClick={getSearchJobTemplate}
                    >
                      Search
                    </button>
                  </div>
                  <div>
                    <ButtonUI
                      p="12px"
                      leftIcon={<AddIcon style={{ height: 16, width: 16 }} />}
                      onClick={() => (
                        toggleDrawer("right", true),
                        setFormValues({}),
                        setFormErrors({}),
                        setEdit(false)
                      )}
                    >
                      Add
                    </ButtonUI>
                  </div>
                </div>
                {isLoader === true ? (
                  <Loader inShow={true} />
                ) : jobTemplates.length > 0 ? (
                  <>
                    <div className="j-card-container">
                      {jobTemplates.map((data) => (
                        <FlexiCard
                          showPopup={showPopup}
                          setShowPopup={setShowPopup}
                          cardClickEvent={cardClickEvent}
                          data={data}
                          key={data.jobTemplateNo}
                          handleEdit={handleEdit}
                          handleDelete={handleDelete}
                        />
                      ))}
                    </div>

                    <StyledPagination
                      count={totalPage}
                      page={page}
                      variant="outlined"
                      shape="rounded"
                      onChange={handlePageChange}
                    />
                  </>
                ) : (
                  <NoRecordsFound />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
