import React, { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import NoRecordsFound from "../../common/NoRecordsFound";
import { toast } from "react-toastify";
import Loader from "../../common/Loader";
import Api from "../../../helper/Api";
import "../../../assets/css/components/company-detail/_appointments.css";
import { StyledTableCell, StyledTableRow } from "../../ui/table/table";
import { ButtonUI } from "../../ui/button";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import { IconButton, Tooltip } from "@mui/material";
import { AddEditSubCompany } from "./AddEditSubCompany";
import { EditDeleteMenu } from "../../ui/editDeleteMenu";
import RefreshIcon from "@mui/icons-material/Refresh";
import { indigo } from "@mui/material/colors";

export function SubCompanies() {
  const location = useLocation();
  const [view, setView] = useState("list");
  const [showActions, setEditActions] = useState();
  const [companyId, setCompanyId] = useState(0);
  const [sortType, setSorted] = useState("desc");
  const [isLoader, setIsLoader] = useState(true);
  const [allTimeZone, setAllTimeZone] = useState([]);
  const [rows, setRows] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [editImageShow, setEditImageShow] = useState(false);
  const [showAddEditLabel, setAddEditLabel] = useState("");
  const initialValues = {
    name: "",
    jobPrefix: "",
    emailAddress: "",
    timeZone: "",
    companyLogo: "",
  };
  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [editMode, setEditMode] = useState(false);
  const errors = {};
  const pageLimit = 500;
  const [openSidebar, setOpenSidebar] = useState(false);
  const [isSubmitting, setSubmitting] = useState(false);
  const [isAnimating, setAnimation] = useState(false);

  let navigate = useNavigate();

  useEffect(() => {
    getTimeZone();
  }, []);

  useEffect(() => {
    if (searchInput == "") {
      getSubCompanyData("desc", "updatedAt", searchInput);
    }
  }, [searchInput]);

  const getSubCompanyData = (sortType, sortColumnName) => {
    Api(
      "GET",
      `companies/getcompany?pageLimit=${pageLimit}&parentCompanyId=${location.state.companyId}&pageNumber=1&OrderbyDirection=${sortType}&OrderBy=${sortColumnName}&companyHirerchy=${location.state.companyHirerchy}`
    )
      .then((response) => {
        if (response.data.data.length) {
          setRows(response.data.data);
        } else {
          setRows([]);
        }
        setIsLoader(false);
      })
      .catch(() => {
        setIsLoader(false);
      });
  };

  // Get timezone dropdown data
  const getTimeZone = () => {
    Api("GET", "TimeZone/GetTimeZone?isActive=true")
      .then((response) => {
        if (response.data) {
          setAllTimeZone(response.data);
          setIsLoader(false);
        } else {
          setAllTimeZone([]);
          setIsLoader(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // On every search input added
  const handleSearch = (event) => {
    setSearchInput(event.target.value);
  };

  // Return searched records
  const getSearchCompany = () => {
    if (!searchInput) {
      window.alert("Please Enter Search Value!");
    } else {
      let data = rows.filter((subCompany) =>
        subCompany.name.toLowerCase().match(searchInput.toLowerCase())
      );
      setRows(data);
    }
  };

  // Perticular card wise actions box open
  const handleClick = (id) => {
    setEditActions(id !== showActions ? id : null);
  };

  // Sort data
  function handleSort(columnType) {
    const sortOrder = sortType === "asc" ? "desc" : "asc";
    setSorted(sortOrder);
    getSubCompanyData(sortType, columnType, "");
  }

  // Open Add form
  const addDataTable = () => {
    setEditMode(false);
    setFormValues({});
    setFormErrors({});
    setAddEditLabel("Add Sub Company");
    toggleForm("right", true);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      getSearchCompany();
    }
  };

  // On submit
  const handleSubmit = (e) => {
    e.preventDefault();
    formValues.parentCompanyId = location.state.companyId;
    formValues.companyHirerchy = location.state.companyHirerchy;
    setFormErrors(validate(formValues));

    if (Object.keys(validate(formValues)).length == 0) {
      setSubmitting(true);
      if (editMode) {
        formValues.companyId = companyId;
        Api("PUT", "Companies/EditCompany", formValues, "multipart")
          .then((res) => {
            if (res.data.isSuccess) {
              toast.success(res.data.message);
              toggleForm("right", false);
              getSubCompanyData("desc", "updatedAt", "");
            } else {
              toast.error(res.data.message);
            }
          })
          .catch((err) => {
            console.log(err);
          })
          .finally(() => setSubmitting(false));
      } else {
        Api("POST", "Companies/AddCompany", formValues, "multipart")
          .then((res) => {
            if (res.data.isSuccess === true) {
              toast.success(res.data.message);
              toggleForm("right", false);
              getSubCompanyData("desc", "updatedAt", "");
            } else {
              toast.error(res.data.message);
            }
          })
          .catch((err) => {
            console.log(err);
          })
          .finally(() => setSubmitting(false));
      }
    }
  };

  // Validations
  const validate = (values) => {
    if (!values.name) {
      errors.name = "Name is required.";
    } else if (!isNaN(values.name)) {
      errors.name = "Name must be a text!";
    }

    if (!values.jobPrefix) {
      errors.jobPrefix = "Job prefix is required.";
    } else if (!isNaN(values.jobPrefix)) {
      errors.jobPrefix = "Job prefix must be a text!";
    } else if (values.jobPrefix.length < 2 || values.jobPrefix.length > 5) {
      errors.jobPrefix = "Job prefix length must be between 2 to 5 characters!";
    }

    if (!editMode) {
      var validRegex =
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      if (!values.emailAddress) {
        errors.emailAddress = "Email id is required!";
      } else if (!values.emailAddress.match(validRegex)) {
        errors.emailAddress = "Please enter a valid email address!";
      }
    }

    if (!values.timeZone) {
      errors.timeZone = "Time zone is required.";
    }

    // if (!values.companyLogo) {
    //   errors.companyLogo = "Company Logo is required!";
    // }

    return errors;
  };

  const handleCellClick = (compId, compHierarchy) => {
    navigate("/sources", {
      state: { companyId: compId, companyHirerchy: compHierarchy },
    });
    window.location.reload(false);
  };

  const displayData = (method) => {
    setView(method);
  };

  const toggleForm = (anchor, open) => {
    setOpenSidebar({ ...openSidebar, [anchor]: open });
  };

  const editCompany = (id) => {
    // Edit button clicked in - popup
    setEditMode(true);
    setAddEditLabel("Edit Sub Company");
    setCompanyId(id);
    setFormErrors({});

    const listItemValue = rows.find((items) => {
      return items.companyId === id ? items : null;
    });

    setFormValues({
      ["name"]: listItemValue.name,
      ["jobPrefix"]: listItemValue.jobPrefix,
      ["totalJob"]: listItemValue.totalJob,
      ["perJobBoard"]: listItemValue.perJobBoard,
      ["emailAddress"]: listItemValue.emailAddress,
      ["timeZone"]: listItemValue.timeZone,
      ["companyLogo"]: listItemValue.companyImage,
    });
    toggleForm("right", true);
  };

  const deleteCompany = (id) => {
    // Delete data of row table - popup clicked delte
    var answer = window.confirm("Are you sure you want to delete?");
    if (answer) {
      setIsLoader(true);
      // Delete comapny api call
      Api("DELETE", `Companies/DeleteCompany?companyId=${id}`)
        .then((res) => {
          res.data.isSuccess == true
            ? toast.success(res.data.message)
            : toast.error(res.data.message);
          getSubCompanyData("desc", "updatedAt", "");
        })
        .catch((err) => {
          setIsLoader(false);
        });
    }
  };

  // Card view
  const subCompaniesList =
    rows.length > 0 ? (
      rows.map((val, key) => {
        return (
          <div key={key} className="company_grid">
            <div className="grid_1 image grid_values">
              {val.companyImage != "" ? (
                <img
                  src={val.companyImage}
                  alt="logo"
                  className="company_list_logo"
                  width="80px"
                  height="70px"
                />
              ) : (
                <img
                  src={require("../../../assets/images/no-img.png")}
                  alt="logo"
                  className="company_list_logo"
                  width="80px"
                  height="70px"
                />
              )}
            </div>
            <div className="grid_7 grid_values text-right">
              <EditDeleteMenu
                id={val.companyId}
                onEdit={editCompany}
                onDelete={deleteCompany}
              />
            </div>
            <div
              onClick={(e) =>
                handleCellClick(val.companyId, val.companyHirerchy)
              }
            >
              <div className="grid_2 td_name">{val.name}</div>
              <div className="grid_3 grid_values">
                {val.jobPrefix}
                <br />
                <span className="grid_keys">Job Number Prefix</span>
              </div>
              <div className="grid_4 grid_values">
                {val.timeZoneOffSet}
                <br />
                <span className="grid_keys">Time Zone</span>
              </div>
            </div>
          </div>
        );
      })
    ) : (
      <NoRecordsFound />
    );

  function Row(props) {
    const { row } = props;
    const [open, setOpen] = React.useState(false);

    return (
      <React.Fragment>
        <StyledTableRow sx={{ "& > *": { borderBottom: "unset" } }}>
          <StyledTableCell
            component="th"
            scope="row"
            align="left"
            sx={{ cursor: "pointer" }}
            onClick={(e) => handleCellClick(row.companyId, row.companyHirerchy)}
          >
            {row.companyImage != "" ? (
              <img
                src={row.companyImage}
                alt="logo"
                className="company_list_logo"
                width="80px"
                height="70px"
                id={row.companyId}
              />
            ) : (
              <img
                src={require("../../../assets/images/no-img.png")}
                alt="logo"
                className="company_list_logo"
                id={row.companyId}
                width="80px"
                height="70px"
              />
            )}
            {row.name}
          </StyledTableCell>
          <StyledTableCell align="center">{row.jobPrefix}</StyledTableCell>
          <StyledTableCell align="center">{row.timeZoneOffSet}</StyledTableCell>
          <StyledTableCell align="right" sx={{ cursor: "pointer" }}>
            <Tooltip title="Edit">
              <IconButton onClick={() => editCompany(row.companyId)}>
                <EditIcon color="action" fontSize="small" />
              </IconButton>
            </Tooltip>

            <Tooltip title="Delete">
              <IconButton onClick={() => deleteCompany(row.companyId)}>
                <DeleteIcon color="action" fontSize="small" />
              </IconButton>
            </Tooltip>
          </StyledTableCell>
        </StyledTableRow>
        <StyledTableRow>
          <StyledTableCell
            style={{ paddingBottom: 0, paddingTop: 0 }}
            colSpan={6}
          >
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 1 }}>
                <div>
                  <p className="notes-table">Notes</p>
                  <p className="content-table">{row.note}</p>
                </div>
              </Box>
            </Collapse>
          </StyledTableCell>
        </StyledTableRow>
      </React.Fragment>
    );
  }

  return (
    <div>
      {openSidebar && (
        <AddEditSubCompany
          toggleDrawer={toggleForm}
          openSidebar={openSidebar}
          addEditLabel={showAddEditLabel}
          formValues={formValues}
          setFormValues={setFormValues}
          formErrors={formErrors}
          editMode={editMode}
          allTimeZone={allTimeZone}
          editImageShow={editImageShow}
          setEditImageShow={setEditImageShow}
          handleSubmit={handleSubmit}
          isSubmitting={isSubmitting}
        />
      )}

      <div className="common-container users">
        <div className="inner-container">
          <div className="top-url">
            <p>
              <Link to="/companylist"> Companies &gt; </Link>
              <strong>
                Sub-Companies {"(" + Object.keys(rows).length + ")"}
              </strong>
            </p>
          </div>
          <div className="manage-container jobs">
            <div className="manage-bg active">
              <div className="parent-container-d">
                <div className="content_header">
                  <div className="company_search">
                    <img
                      src={require("../../../assets/images/search_icon.png")}
                      alt="search-icon"
                      className="search_img"
                    />
                    <input
                      type="text"
                      placeholder="Search Sub Company"
                      className="search_input appointments"
                      name="search_input"
                      value={searchInput}
                      onChange={handleSearch}
                      onKeyPress={handleKeyPress}
                    />
                    <button onClick={getSearchCompany} className="search-btn">
                      Search
                    </button>
                  </div>
                  {view === "grid" ? (
                    <div className="company_sort">
                      <div className="sort_text">
                        <span className="text_light"> SORT:</span> A-Z
                      </div>
                      <button
                        className="icon icon_button"
                        onClick={() => handleSort("name")}
                      >
                        <img
                          src={require("../../../assets/images/drop_down.png")}
                          alt="sort-icon"
                          className="icon sort_img"
                        />
                      </button>
                    </div>
                  ) : null}
                  <div className="company_view">
                    <button
                      type="submit"
                      onClick={() => displayData("grid")}
                      className="icon icon_button"
                    >
                      {view === "grid" ? (
                        <Tooltip title="Grid View">
                          <img
                            src={require("../../../assets/images/select_grid_view.png")}
                            alt="grid-icon"
                          />
                        </Tooltip>
                      ) : (
                        <Tooltip title="Grid View">
                          <img
                            src={require("../../../assets/images/grid_view.png")}
                            alt="grid-icon"
                          />
                        </Tooltip>
                      )}
                    </button>
                    <button
                      type="submit"
                      onClick={() => displayData("list")}
                      className="icon icon_button"
                    >
                      {view === "list" ? (
                        <Tooltip title="List View">
                          <img
                            src={require("../../../assets/images/select_list_view.png")}
                            alt="list-icon"
                          />
                        </Tooltip>
                      ) : (
                        <Tooltip title="List View">
                          <img
                            src={require("../../../assets/images/list_view.png")}
                            alt="list-icon"
                          />
                        </Tooltip>
                      )}
                    </button>
                  </div>
                  <IconButton
                    className={isAnimating && "animate-refresh"}
                    onClick={() => (
                      getSubCompanyData("desc", "updatedAt", ""),
                      setSearchInput(""),
                      setAnimation(true)
                    )}
                  >
                    <RefreshIcon sx={{ color: indigo[500] }} />
                  </IconButton>
                  <ButtonUI
                    p="8px"
                    ml="6px"
                    onClick={addDataTable}
                    leftIcon={<AddIcon />}
                  >
                    Add
                  </ButtonUI>
                </div>
                {view === "list" ? (
                  <div className="manage-checkboxes-none position-relative">
                    <Loader inShow={isLoader} />
                    {rows.length > 0 ? (
                      <TableContainer
                        component={Paper}
                        className="box-shadow-none t-bg"
                      >
                        <Table aria-label="collapsible table">
                          <TableHead>
                            <TableRow>
                              <StyledTableCell align="left">
                                Company Name:{" "}
                                <img
                                  src={
                                    require("../../../assets/images/tabsort.svg")
                                      .default
                                  }
                                  alt="sort-icon"
                                  className="cursor-pointer"
                                  onClick={() => handleSort("name")}
                                />
                              </StyledTableCell>
                              <StyledTableCell align="center" width="100px">
                                Job Prefix
                              </StyledTableCell>
                              <StyledTableCell align="center" width="100px">
                                Time zone
                              </StyledTableCell>
                              <StyledTableCell align="center" width="100px">
                                Action
                              </StyledTableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {rows.map((row) => (
                              <Row key={row.companyId} row={row} />
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    ) : (
                      <NoRecordsFound />
                    )}
                  </div>
                ) : (
                  <div className="company_card">{subCompaniesList}</div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
