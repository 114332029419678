import React, { useEffect, useState } from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import { IconButton, InputBase, Paper, Tooltip } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import { red } from "@mui/material/colors";
import { Android12Switch } from "../../../../ui/android/android2switch";
import { AddEditPopup } from "../../../addEditPopup";


export const EditUserInfo = ({
  formValues,
  setFormValues,
  openSidebar,
  handleNext,
  formErrors,
  editMode,
  handleSubmit,
  label,
  allTimeZone,
  toggleDrawer,
  isSubmitting,
  usersDataIndi,
}) => {
  const [emails, setEmails] = useState([]);
  const [phoneNumbers, setPhoneNumbers] = useState([]);
  const [postalAddresses, setPostalAddresses] = useState([]); // Added postalAddresses state


  useEffect(() => {
    if (usersDataIndi) {
      setEmails(usersDataIndi.emails || []);
      setPhoneNumbers(usersDataIndi.phoneNumbers || []);
      setPostalAddresses(usersDataIndi.postalAddresses || []);
      setFormValues({
        ...formValues,
        firstName: usersDataIndi.firstName || "",
        middleName: usersDataIndi.middleName || "",
        lastName: usersDataIndi.lastName || "",
        nickName: usersDataIndi.nickName || "",
        user_title: usersDataIndi.title || "",
        login_id: usersDataIndi.loginID || "",
        email_id: usersDataIndi.emails ? usersDataIndi.emails[0] : "",
        cell_phone: usersDataIndi.phoneNumbers ? usersDataIndi.phoneNumbers[0] : "",
        postalAddresses: usersDataIndi.postalAddresses ? usersDataIndi.postalAddresses[0] : [],
        emailNotification: usersDataIndi.emailNotification || false,
        smsNotification: usersDataIndi.smsNotification || false,
        timeZone: usersDataIndi.timeZone || "none",
      });
    }
  }, [usersDataIndi]); // Make sure to include usersDataIndi in the dependency array

  const handleChange = (e) => {
    const { name, value, checked } = e.target;
    setFormValues({
      ...formValues,
      [name]: name === "emailNotification" || name === "smsNotification" ? checked : value,
    });
  };

  const handleAddEmail = () => {
    setEmails([...emails, ""]);
    setFormValues({
      ...formValues,
      emails: [...emails, ""], // Update formValues to include the new email address
    });
  };

  const handleAddPhone = () => {
    setPhoneNumbers([...phoneNumbers, ""]);
    setFormValues({
      ...formValues,
      phoneNumbers: [...phoneNumbers, ""], // Update formValues to include the new phone number
    });
  };

  const handleAddAddress = () => {
    setPostalAddresses([...postalAddresses, { address1: "", city: "", state: "", zipCode: "" }]);
    setFormValues({
      ...formValues,
      postalAddresses: [...postalAddresses, { address1: "", city: "", state: "", zipCode: "" }],
    });
  };

  const handleAddressChange = (e, index, field) => {
    const value = e.target.value;
    const updatedAddresses = [...postalAddresses];
    updatedAddresses[index][field] = value;
    setPostalAddresses(updatedAddresses);

    // Update formValues with the updated postalAddresses array
    setFormValues(prevFormValues => ({
      ...prevFormValues,
      postalAddresses: updatedAddresses,
    }));
  };

  // Function to remove an address field
  const removeAddress = (index) => {
    const updatedAddresses = [...postalAddresses];
    updatedAddresses.splice(index, 1);
    setPostalAddresses(updatedAddresses);
    setFormValues(prevFormValues => ({
      ...prevFormValues,
      postalAddresses: updatedAddresses,
    }));
  };
 

  const handleDynamicInput = (e, index, type) => {
    const value = e.target.value;
    if (type === "email") {
      const updatedEmails = [...emails];
      updatedEmails[index] = value;
      setEmails(updatedEmails);
      setFormValues({
        ...formValues,
        emails: updatedEmails,
        email_id: updatedEmails[0], // Update the email_id input value
      });
    } else {
      const updatedPhoneNumbers = [...phoneNumbers];
      updatedPhoneNumbers[index] = value;
      setPhoneNumbers(updatedPhoneNumbers);
      setFormValues({
        ...formValues,
        phoneNumbers: updatedPhoneNumbers,
        cell_phone: updatedPhoneNumbers[0], // Update the cell_phone input value
      });
    }
  };

  const removeEmail = (index) => {
    const updatedEmails = [...emails];
    updatedEmails.splice(index, 1);
    setEmails(updatedEmails);
    setFormValues({ ...formValues, email_id: updatedEmails[0] });
  };

  const removePhone = (index) => {
    const updatedPhoneNumbers = [...phoneNumbers];
    updatedPhoneNumbers.splice(index, 1);
    setPhoneNumbers(updatedPhoneNumbers);
    setFormValues({ ...formValues, cell_phone: updatedPhoneNumbers[0] });
  };

  const handleUserEdit = (e) => {
    e.preventDefault();
    if (editMode) {
      handleSubmit();
    } else {
      handleNext();
    }
  };

  const renderAddresses = () => {
    return postalAddresses.map((address, index) => (
      <div key={index}>
        <div className="forms-input">
          <label>Address:</label>
          <input
            type="text"
            name={`address1-${index}`}
            value={address.address1}
            placeholder="Address here"
            onChange={(e) => handleAddressChange(e, index, "address1")}
          />
        </div>
        <div className="forms-input sections">
          <div className="col-3">
            <input
              type="text"
              name={`city-${index}`}
              value={address.city}
              placeholder="City here"
              onChange={(e) => handleAddressChange(e, index, "city")}
            />
          </div>
          <div className="col-3">
            <input
              type="text"
              name={`state-${index}`}
              value={address.state}
              placeholder="State here"
              onChange={(e) => handleAddressChange(e, index, "state")}
            />
          </div>
          <div className="col-3">
            <input
              type="text"
              name={`zipCode-${index}`}
              value={address.zipCode}
              placeholder="Zipcode here"
              onChange={(e) => handleAddressChange(e, index, "zipCode")}
            />
          </div>
          {index >= 0 && (
            <IconButton aria-label="Remove Address" onClick={() => removeAddress(index)}>
              <CloseIcon style={{ color: red[500] }} />
            </IconButton>
          )}
        </div>
      </div>
    ));
  };

  return (
    <div>
      <AddEditPopup
        title={label}
        openSidebar={openSidebar}
        toggleDrawer={toggleDrawer}
        handleSubmit={handleUserEdit}
        isSubmitting={isSubmitting}
      >
        <div className="forms-input">
          <label> First Name</label>
          <input
            type="text"
            placeholder="First Name"
            name="firstName"
            id="firstName"
            value={formValues.firstName}
            onChange={handleChange}
            autoComplete="off"
          />
          <span className="error_msg">{formErrors.firstName}</span>
        </div>
        <div className="forms-input">
          <label>Middle Name</label>
          <input
            type="text"
            placeholder="Middle Name"
            name="middleName"
            id="middleName"
            value={formValues.middleName}
            onChange={handleChange}
            autoComplete="off"
          />
          <span className="error_msg">{formErrors.middleName}</span>
        </div>
        <div className="forms-input">
          <label>Last Name</label>
          <input
            type="text"
            placeholder="Last Name"
            name="lastName"
            id="lastName"
            value={formValues.lastName}
            onChange={handleChange}
            autoComplete="off"
          />
          <span className="error_msg">{formErrors.lastName}</span>
        </div>
        <div className="forms-input">
          <label>Nick Name</label>
          <input
            type="text"
            placeholder="Nick Name"
            name="nickName"
            id="nickName"
            value={formValues.nickName}
            onChange={handleChange}
            autoComplete="off"
          />
          <span className="error_msg">{formErrors.nickName}</span>
        </div>
        <div className="forms-input">
          <label>Title</label>
          <input
            type="text"
            placeholder="Title"
            name="user_title"
            id="user_title"
            value={formValues.user_title}
            onChange={handleChange}
            autoComplete="off"
          />
          <span className="error_msg">{formErrors.user_title}</span>
        </div>
        {/* temproray hide login id according to old */}
        <div className="forms-input d-none">
          <label>Login ID</label>
          <input
            type="text"
            placeholder="Login ID"
            name="login_id"
            id="login_id"
            value={formValues.login_id}
            onChange={handleChange}
            readOnly={editMode}
            style={{ backgroundColor: editMode ? "#DCDCDC" : "" }}
            autoComplete="off"
          />
          <span className="error_msg">{formErrors.login_id}</span>
        </div>
        {!editMode ? (
          <div className="forms-input">
            <label>Email Address</label>
            <input
              type="text"
              placeholder="Email Address"
              name="email_id"
              id="email_id"
              value={formValues.email_id}
              onChange={handleChange}
              autoComplete="off"
            />
            <span className="error_msg">{formErrors.email_id}</span>
          </div>
        ) : null}


        {/* <div className="forms-input">
          <label>Cell Phone</label>
          <input
            type="text"
            placeholder="Cell Phone"
            name="cell_phone"
            id="cell_phone"
            value={formValues.cell_phone}
            onChange={handleChange}
            readOnly={editMode}
            style={{ backgroundColor: editMode ? "#DCDCDC" : "" }}
          />
          <span className="error_msg">{formErrors.cell_phone}</span>
        </div> */}

        {editMode && (
          <div className="mr-address">
            <Tooltip title="Add Address">
              <span className="participants add pointer" onClick={handleAddAddress}>
                <AddIcon fontSize="small" /> Add Postal Address
              </span>
            </Tooltip>
          </div>
        )}

        {/* Render existing postalAddresses */}
        {renderAddresses()}

        {editMode ? (
          <>
            {/* Email input section */}
            <div className="d-flex justify-content-between mb-2">
              <div>
                <label className="labelemail-phone d-flex justify-content-between align-items-center">
                  Email Address
                  <Tooltip title="Add Email">
                    <span
                      className="participants add pointer"
                      onClick={handleAddEmail}
                    >
                      <AddIcon fontSize="small" /> Add
                    </span>
                  </Tooltip>
                </label>

                {(emails ?? []).map((email, index) => (
                  <>
                    <Paper
                      component="form"
                      sx={{
                        marginTop: "6px",
                        p: "4px 4px",
                        border: "1px solid #D0D5DD",
                        display: "flex",
                        alignItems: "center",
                        width: 270,
                        boxShadow: "none",
                        borderRadius: "8px",
                      }}
                      key={"email -" + index}
                    >
                      <InputBase
                        sx={{ ml: 1, flex: 1 }}
                        placeholder="Email here"
                        inputProps={{ "aria-label": "Email here" }}
                        value={emails[index] || ''}
                        onChange={(e) => handleDynamicInput(e, index, "email")}
                        key={"email -" + index}
                        autoComplete="off"
                      />

                      {index !== 0 && (
                        <Tooltip title="Close">
                          <IconButton
                            sx={{ p: "10px" }}
                            aria-label="directions"
                            onClick={() => removeEmail(index)}
                          >
                            <CloseIcon sx={{ color: red[500] }} />
                          </IconButton>
                        </Tooltip>
                      )}
                    </Paper>
                    <span className="error_msg">{formErrors.email_id}</span> {/* Display error message */}

                  </>
                ))}
              </div>

              {/* Phone input section */}
              <div>
                <label className="labelemail-phone d-flex justify-content-between align-items-center">
                  Cell Phone:
                  <Tooltip title="Add Phone">
                    <span
                      className="participants add pointer"
                      onClick={handleAddPhone}
                    >
                      <AddIcon fontSize="small" /> Add
                    </span>
                  </Tooltip>
                </label>

                {(phoneNumbers ?? []).map((phone, index) => (
                  <>
                    <Paper
                      component="form"
                      sx={{
                        marginTop: "6px",
                        p: "4px 4px",
                        border: "1px solid #D0D5DD",
                        display: "flex",
                        alignItems: "center",
                        width: 270,
                        boxShadow: "none",
                        borderRadius: "8px",
                      }}
                      key={"phone" + index}
                    >
                      <InputBase
                        sx={{ ml: 1, flex: 1 }}
                        placeholder="Phone here"
                        inputProps={{ "aria-label": "Phone here" }}
                        value={phoneNumbers[index] || ''}
                        onChange={(e) => handleDynamicInput(e, index, "phone")}
                        key={"phone-" + index}
                        autoComplete="off"
                      />

                      {index !== 0 && (
                        <Tooltip title="Close">
                          <IconButton
                            sx={{ p: "10px" }}
                            aria-label="directions"
                            onClick={() => removePhone(index)}
                          >
                            <CloseIcon sx={{ color: red[500] }} />
                          </IconButton>
                        </Tooltip>
                      )}
                    </Paper>
                    <span className="error_msg">{formErrors.cell_phone}</span> {/* Display error message */}
                  </>
                ))}
              </div>
            </div>

            <div className="d-flex justify-content-between mr-top ">
              <div className="mr-2">
                <FormControlLabel
                  control={<Android12Switch />}
                  label="Email Notifications"
                  name="emailNotification"
                  key="emailNotification"
                  checked={formValues.emailNotification}
                  onChange={handleChange}
                />
              </div>

              <div>
                <FormControlLabel
                  control={<Android12Switch />}
                  label="Text Notifications"
                  name="smsNotification"
                  key="smsNotification"
                  checked={formValues.smsNotification}
                  onChange={handleChange}
                />
              </div>

            </div>
          </>

        ) : null}

        <div className="forms-input mr-top-12">
          <label>Timezone</label>
          <select
            name="timeZone"
            id="timeZone"
            value={formValues.timeZone}
            onChange={handleChange}
          >
            <option key="none" value="none">Select</option>
            {allTimeZone.map((time) => (
              <option key={time.id} value={time.timeZone}>
                {time.timeZone}
              </option>
            ))}
          </select>
          <span className="error_msg">{formErrors.timeZone}</span>
        </div>
      </AddEditPopup>
    </div>
  );
};
