import * as React from "react";

export default function SearchInCol({
  searchOpen,
  setSearchOpen,
  searchInput,
  setSearchInput,
}) {
  const handleChange = (e) => {
    setSearchInput(e.target.value);
  };

  const expandSearch = () => {
    setSearchOpen(!searchOpen);
  };

  return (
    <div className={searchOpen ? "searchbox searchbox-open" : "searchbox"}>
      {searchOpen && (
        <input
          type="text"
          placeholder="Search......"
          name="search"
          className="searchbox-input"
          onChange={handleChange}
          value={searchInput}
          autoFocus={searchOpen}
        />
      )}
      <span className="searchbox-icon" onClick={expandSearch}>
        <i
          className={searchOpen ? "fas fa-times" : "fa fa-search"}
          aria-hidden="true"
        ></i>
      </span>
    </div>
  );
}
