import React, { useState } from "react";
import "../../../assets/css/components/search/_search.css";
import Rating from "@mui/material/Rating";
import Api from "../../../helper/Api";
import * as FileSaver from "file-saver";
import XLSX from "sheetjs-style";
import BackgroundLetterAvatars from "../../../components/common/layouts/BackgroundLetterAvatars";
import { ButtonUI } from "../../../components/ui/button";
import { ChevronRight } from "@material-ui/icons";
import { IconButton, Tooltip } from "@mui/material";
import { useDispatch } from "react-redux";
import { APPLICANT_DATA_ACTIONS } from "../../../redux/applicantData/actions";
import axios from "axios";
import { baseUrl, userAsCompany } from "../../../helper/constants";
import SearchIcon from "@mui/icons-material/Search";
import { ColoredLinearProgress } from "../../../components/common/coloredProgressBar";
import InfiniteScroll from "react-infinite-scroll-component";
import { EclipseLoader } from "../../../components/common/eclipseLoader";
import { useSelector } from "react-redux";
import { JCCard } from "../../../components/common/cards/JCCard";
import { JOB_DATA_ACTIONS } from "../../../redux/jobData/actions";
import { blueGrey, grey } from "@mui/material/colors";
import CloseIcon from "@mui/icons-material/Close";
import { SEARCH_ACTIONS } from "../../../redux/search/actions";

export function Search() {
  const [isLoader, setIsLoader] = useState(false);
  const [page, setPage] = useState(1);
  const [searchData, setSearchData] = useState([]);
  const [totalItem, setTotalItem] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [searchRating, setSearchRating] = useState("");
  const pageLimit = 12;
  const userId = localStorage.getItem("user");
  const dispatch = useDispatch();
  const [progress, setProgress] = useState(0);
  const [isCircleLoading, setCircleLoading] = useState();
  const [selected, setSelected] = useState("candidate");
  const [infLoader, setInfLoader] = useState(false);
  const [prevSearchText, setPrevSearchText] = useState("");
  const { companyData } = useSelector((state) => state.companyDataReducer);
  const { jobsArray } = useSelector((state) => state.jobDataReducer);
  const { applicantsArray } = useSelector(
    (state) => state.applicantDataReducer
  );

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      getSearch();
    }
  };

  const handleSearch = (event) => {
    setSearchInput(event.target.value);
  };

  const handleRating = (e) => {
    setSearchRating(e.target.value);
  };

  const getSearch = () => {
    if (searchRating === "" && searchInput === "") {
      window.alert("Please Enter Search Value!");
    } else {
      setIsLoader(true);
      setPage(1);
      setSearchData((prev) => []);
      getSearchData(searchInput, searchRating, 1);
    }
  };

  function print(msg) {
    document.body.insertAdjacentHTML("beforeEnd", "<div>" + msg + "</div>");
  }

  /***/
  function fn_wrapper(letter, lastIndex) {
    // 1-st argument of replacer-function == full match (each letter, in this case)
    let wrap = `<b>${letter}</b>`;
    lastIndex += wrap.length;

    return wrap;
  }

  const highlightData = (items, searchText) => {
    let regex = new RegExp(searchText.split("").join(".*"));
    for (let i = 0; i < items.length; i++) {
      let str = items[i];
      if (
        !regex.test(str.firstName) &&
        !regex.test(str.lastName) &&
        !regex.test(str.emailid)
      ) {
        continue;
      }

      const lastIndex = 0;
      for (let letter of searchText) {
        str.firstName =
          str.firstName.slice(0, lastIndex) +
          str.firstName.slice(lastIndex).replace(letter, fn_wrapper);

        str.lastName =
          str.lastName.slice(0, lastIndex) +
          str.lastName.slice(lastIndex).replace(letter, fn_wrapper);

        str.emailid =
          str.emailid.slice(0, lastIndex) +
          str.emailid.slice(lastIndex).replace(letter, fn_wrapper);
      }
    }
    return items;
  };

  const getSearchData = (
    searchText = searchInput,
    rating = searchRating,
    pageNumber = page
  ) => {
    Api(
      "GET",
      `Search/GetSearchList?type=${selected.toLocaleLowerCase()}&pageLimit=${pageLimit}&pageNumber=${pageNumber}&searchByTerm=${searchText}&companyId=${
        userAsCompany(window.location.hostname) ? companyData.companyId : ""
      }&searchByRating=${rating}`
    )
      .then((res) => {
        const data = { ...res.data.data[0] };
        if (
          prevSearchText !== searchText &&
          (data.searchCandidateResponses !== null ||
            data.searchJobResponses !== null)
        ) {
          data.searchCandidateResponses !== null
            ? setSearchData(
                highlightData(data.searchCandidateResponses, searchText)
              )
            : setSearchData(highlightData(data.searchJobResponses, searchText));
        }

        if (
          prevSearchText === searchText &&
          (data.searchCandidateResponses !== null ||
            data.searchJobResponses !== null)
        ) {
          if (data.searchCandidateResponses !== null) {
            const allData = [...searchData, ...data.searchCandidateResponses];
            setSearchData(highlightData(allData, searchText));
          } else {
            const allRecs = [...searchData, ...data.searchJobResponses];
            setSearchData(highlightData(allRecs, searchText));
          }
        }
        setPrevSearchText(searchText);
        setTotalItem(res.data.totalRecord);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsLoader(false);
        setInfLoader(false);
      });
  };

  //excel sheet
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const exportToCSV = async (fileName) => {
    setCircleLoading(true);
    const headers = {};
    const token = localStorage.getItem("token");
    headers["Authorization"] = `Bearer ${token}`;

    let urlData = `Search/GetSearchList?userId=${userId}&type=${selected.toLocaleLowerCase()}&pageLimit=${pageLimit}&pageNumber=${page}&OrderbyDirection=asc&OrderBy=firstName&searchText=${searchInput}&IsExport=${true}`;

    await axios({
      method: "GET",
      url: `${baseUrl}${urlData}`,
      headers: headers,
      onDownloadProgress: (progressEvent) => {
        let percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );

        setProgress(percentCompleted);
      },
    })
      .then((res) => {
        if (res.data.data.length) {
          const ws = XLSX.utils.json_to_sheet(res.data.data);
          const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
          const excelBuffer = XLSX.write(wb, {
            bookType: "xlsx",
            type: "array",
          });
          const data = new Blob([excelBuffer], { type: fileType });
          FileSaver.saveAs(data, fileName + fileExtension);
        }
      })
      .finally(() => {
        setCircleLoading(false);
      });
    setProgress(0);
  };

  const openJobApplicant = (items) => {
    Api(
      "GET",
      `Job/GetApplicantById?jobId=${items.jobId}&applicantId=${items.applicantId}&companyId=${items.companyId}`
    ).then((res) => {
      res.data.name = res.data.firstName + " " + res.data.lastName;
      const isPresent = applicantsArray.some(
        (d) => d.applicantId === items.applicantId
      );

      if (isPresent === false) {
        const recs = [res.data, ...applicantsArray];
        dispatch({
          type: APPLICANT_DATA_ACTIONS.SET_APPLICANTS_ARRAY,
          payload: recs,
        });
      }
    });
  };

  const openJob = (items, createdAt, jobCode) => {
    Api("GET", `Job/GetJobById?jobId=${items.jobId}`)
      .then((res) => {
        res.data.jobNo = jobCode;
        res.data.createdAt = createdAt;
        const isPresent = jobsArray.some((d) => d.jobId === items.jobId);

        if (isPresent === false) {
          const recs = [res.data, ...jobsArray];
          dispatch({
            type: JOB_DATA_ACTIONS.SET_JOBS_ARRAY,
            payload: recs,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchMore = () => {
    setInfLoader(true);
    setPage((prevState) => prevState + 1);
    getSearchData(searchInput, searchRating, page + 1);
  };

  const closeSearchCol = () => {
    dispatch({
      type: SEARCH_ACTIONS.SET_SEARCH_FLAG,
      payload: false,
    });
  };

  return (
    <div>
      <div className="user-list job-h">
        {/* Header start */}
        <div className="job-header">
          <strong>Search</strong>
          <Tooltip title="Close">
            <IconButton onClick={closeSearchCol}>
              <CloseIcon sx={{ color: grey[600] }} />
            </IconButton>
          </Tooltip>
        </div>
        <div className="top-filters">
          <div className="search-tabs">
            <div
              className={selected === "job" ? "tab selected" : "tab"}
              role="button"
              onClick={() => (
                setSelected("job"),
                setSearchInput(""),
                setSearchData([]),
                setPage(1),
                setTotalItem(0),
                setSearchRating("")
              )}
            >
              Job
            </div>
            <div
              className={selected === "candidate" ? "tab selected" : "tab"}
              role="button"
              onClick={() => (
                setSelected("candidate"),
                setSearchInput(""),
                setSearchData([]),
                setPage(1),
                setTotalItem(0)
              )}
            >
              Candidate
            </div>
          </div>
        </div>
        <div className="top-filters no-border">
          <div className="top-inputs">
            <p>Search Terms</p>
            <input
              type="text"
              name=""
              value={searchInput}
              id="search-terms"
              className="search-in-filter"
              onChange={handleSearch}
              onKeyPress={handleKeyPress}
            />
          </div>
          {selected === "candidate" && (
            <div className="top-inputs">
              <p>Rating</p>
              <select
                name="searchRating"
                id="rating"
                value={searchRating}
                onChange={handleRating}
              >
                <option></option>
                <option value="1" key="rating1">
                  1
                </option>
                <option value="2" key="rating2">
                  2
                </option>
                <option value="3" key="rating3">
                  3
                </option>
                <option value="4" key="rating4">
                  4
                </option>
                <option value="5" key="rating5">
                  5
                </option>
              </select>
            </div>
          )}
          <ButtonUI p="4px" onClick={() => getSearch()}>
            <SearchIcon sx={{ color: blueGrey[50] }} />
          </ButtonUI>
        </div>
        {totalItem > 0 && (
          <div className="top-filters search-field">
            {totalItem + " Records found !"}
          </div>
        )}
        <div className="p-border"></div>
        {/* Header End */}

        {isLoader ? (
          <ColoredLinearProgress />
        ) : searchData?.length > 0 ? (
          <div className="inner-h applicant-list full">
            <div className="job_template_option_menu sd pointer">
              <InfiniteScroll
                height={window.innerHeight * 0.57}
                dataLength={searchData?.length || 0}
                next={fetchMore}
                hasMore={searchData?.length !== searchData?.totalRecords}
                loader={
                  infLoader && (
                    <h4 style={{ textAlign: "center" }}>
                      <EclipseLoader />
                    </h4>
                  )
                }
                endMessage={
                  <p style={{ textAlign: "center" }}>
                    <b>Yay! You have seen it all</b>
                  </p>
                }
              >
                {selected === "job" ? (
                  <>
                    {(searchData ?? []).map((items) => (
                      <JCCard
                        jobCode={items.jobCode}
                        name={items.jobName}
                        description={items.jobDescription}
                        jobStatus={items.jobStatus}
                        priority={items.priority}
                        positions={items.position}
                        jobId={items.jobId}
                        createdAt={items.createdAt}
                        jobProgressStatus={items.jobProgressStatus}
                        jobProgressStatusDescription={
                          items.jobProgressStatusDescription
                        }
                        companyId={companyData?.companyId}
                        cardClickEvent={() =>
                          openJob(items, items.createdAt, items.jobCode)
                        }
                      />
                    ))}
                  </>
                ) : (
                  <>
                    {(searchData ?? []).map((items) => (
                      <div className="resume-cards">
                        <div
                          className="perticular-cards"
                          key={items.id}
                          onClick={() => openJobApplicant(items)}
                        >
                          <BackgroundLetterAvatars
                            name={items.firstName + " " + items.lastName}
                          />
                          <div className="d-flex flex-column right-side-content">
                            <div
                              className="resume-person"
                              dangerouslySetInnerHTML={{
                                __html: items.firstName + " " + items.lastName,
                              }}
                            />
                            <div className="res-details">
                              <p className="work-design">{items.designation}</p>
                              <div
                                className="font-12 resume-email"
                                dangerouslySetInnerHTML={{
                                  __html: items.emailid,
                                }}
                              />
                              <span className="rating-stars d-flex align-items-center">
                                Rating :
                                <Rating
                                  name="avg-rating"
                                  defaultValue={items.rating}
                                  size="small"
                                  readOnly
                                />
                              </span>
                            </div>
                          </div>
                          <Tooltip title="Open Applicant">
                            <IconButton
                              size="small"
                              onClick={() => openJobApplicant(items)}
                            >
                              <ChevronRight color="action" />
                            </IconButton>
                          </Tooltip>
                        </div>
                      </div>
                    ))}
                  </>
                )}
              </InfiniteScroll>
            </div>
          </div>
        ) : (
          <div className="d-flex flex-column p-16">
            <img
              src={require("../../../assets/images/searchData.png")}
              width="300px"
              textAlign="center"
              alt="wait"
            />
            <span className="text-center p-16">
              Type the applicant or job you are looking for !
            </span>
          </div>
        )}
      </div>
    </div>
  );
}
