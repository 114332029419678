import { useState, useEffect } from "react";
import { FormControlLabel } from "@material-ui/core";
import { FormGroup } from "@material-ui/core";
import { CheckboxWithBlueCheck } from "../../../../ui/checkbox/checkboxWithBlueTick";
import { useSelector } from "react-redux";
import Api from "../../../../../helper/Api";
import { toast } from "react-toastify";
import { ColoredLinearProgress } from "../../../../common/coloredProgressBar";

export const Documents = ({ getJobTempById }) => {
  const [allData, setAllData] = useState([]);
  const { companyData } = useSelector((state) => state.companyDataReducer);
  const [isLoader, setIsLoader] = useState(false);
  const [isCheckedAll, setAllChecked] = useState(true);
  const [allDocuments, setAllDocuments] = useState([]);
  const selectedData = useSelector(
    (state) => state.jobTemplateReducer.jobTemplateData.documents
  );

  useEffect(() => {
    setIsLoader(true);
    Api(
      "GET",
      `Document/GetDocumentList?companyId=${companyData.companyId}&companyHirerchy=${companyData.companyHirerchy}`
    )
      .then((res) => {
        res.data.data.map((d) => {
          d.checked = selectedData.includes(d.documentId) ? true : false;
          d.id = d.documentId;
        });
        setAllDocuments(res.data.data);

        var result = [];
        const dataResponse = res.data.data.reduce((group, value) => {
          if (!group[value.documentTypeDisplayName]) {
            group[value.documentTypeDisplayName] = {
              type: value.documentTypeDisplayName,
              documents: [],
            };
            result.push(group[value.documentTypeDisplayName]);
          }
          group[value.documentTypeDisplayName].documents = [
            ...group[value.documentTypeDisplayName].documents,
            value,
          ];
          return group;
        }, {});

        setAllData(Object.values(dataResponse));
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsLoader(false);
      });
  }, []);

  const onCheckBoxChange = (checkName, isChecked = isCheckedAll) => {
    if (checkName === "all") {
      isCheckedAll === false ? setAllChecked(true) : setAllChecked(false);
    }

    let isAllChecked = checkName === "all" && isCheckedAll;
    let isAllUnChecked = checkName === "all" && !isCheckedAll;

    let checkList = [...allData];
    checkList.map((data) => {
      data.documents.map((d) => {
        if (isAllChecked || d.documentId === checkName) {
          d.checked = isChecked;
        } else if (isAllUnChecked) {
          d.checked = false;
        }
      });
      return data;
    });

    setAllData(checkList);
    allDocuments.findIndex((item) => item.checked === false) === -1
      ? setAllChecked(false)
      : setAllChecked(true);
  };

  const handleSubmit = (data) => {
    setIsLoader(true);
    let selected = [];
    data.map(function (record) {
      record.documents.filter(function (d) {
        if (d.checked === true) {
          selected.push(d.documentId);
        }
      });
    });

    const params = {
      companyId: companyData.companyId,
      jobTemplateId: companyData.data.jobTemplateId,
      ids: selected,
      enumSourceType: 3, // Documents
    };

    Api("POST", "JobTemplate/SaveJobTemplateDetails", params)
      .then((res) => {
        if (res.data.isSuccess === true) {
          getJobTempById();
          toast.success(res.data.message);
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsLoader(false);
      });
  };

  return (
    <div>
      <div className="top-header-user">
        <span className="main-name">Documents</span>
        <span
          className="blue-t pointer"
          onClick={() => onCheckBoxChange("all")}
        >
          Select all
        </span>
      </div>
      {isLoader === true ? (
        <ColoredLinearProgress />
      ) : (
        <div className="main_content">
          <div>
            <FormGroup>
              {allData.map((data) => (
                <div key={data.type}>
                  <h4>{data.type}</h4>
                  {data.documents.map((d) => {
                    return (
                      <div className="checkbox-label" key={d.id}>
                        <FormControlLabel
                          control={
                            <CheckboxWithBlueCheck
                              disableRipple
                              checked={d.checked}
                              onChange={(e) =>
                                onCheckBoxChange(d.id, e.target.checked)
                              }
                              value={d.id}
                              key={d.id}
                            />
                          }
                          label={d.name}
                        />
                      </div>
                    );
                  })}
                </div>
              ))}
            </FormGroup>

            <div className="save_settings">
              <input
                className="save_btn"
                type="submit"
                value="Save"
                onClick={() => {
                  handleSubmit(allData);
                }}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
