import { AddEditPopup } from "../../common/addEditPopup";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { indigo } from "@material-ui/core/colors";

export const AddEditSource = ({
  openSidebar,
  toggleDrawer,
  showInputLabel,
  showAddEditLabel,
  formValues,
  setFormValues,
  formErrors,
  setFormErrors,
  currSourceType,
  allDocs,
  handleSubmit,
  rolesPer,
  setRolesPer,
  allDocTypes,
}) => {
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleRolesChecked = (e, i, ci) => {
    const list = [...rolesPer];

    if (ci === undefined || ci === null) {
      list[i]["accessModifier"].map(
        (items) => (items.isAccessible = e.target.checked)
      );
    } else {
      list[i]["accessModifier"][ci].isAccessible = e.target.checked;
    }

    setRolesPer(list);
  };

  return (
    <AddEditPopup
      openSidebar={openSidebar}
      toggleDrawer={toggleDrawer}
      title={showAddEditLabel}
      handleSubmit={handleSubmit}
    >
      <div className="forms-input">
        <label>{showInputLabel}</label>
        <input
          type="text"
          placeholder="Name here"
          name="roleName"
          id="roleName"
          value={formValues.roleName}
          onChange={handleChange}
        />
        <span className="error_msg">{formErrors?.roleName}</span>
      </div>

      {currSourceType === "departments" && (
        <div className="forms-input">
          <label>Job Number Prefix</label>
          <input
            type="text"
            placeholder="Job Number Prefix here"
            name="jobPrefix"
            id="jobPrefix"
            value={formValues.jobPrefix}
            onChange={handleChange}
          />
          <span className="error_msg">{formErrors?.jobPrefix}</span>
        </div>
      )}

      {currSourceType === "userroles"
        ? rolesPer.map((roles, i) => (
            <div className="forms-checkbox" key={roles.moduleName}>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon color="action" />}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        disableRipple
                        style={{
                          color: indigo[600],
                        }}
                        value={roles.moduleName}
                        onChange={(e) => handleRolesChecked(e, i)}
                      />
                    }
                    label={roles.moduleName}
                  />
                </AccordionSummary>
                {roles.accessModifier.map((items, ci) => (
                  <AccordionDetails key={items.name}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          disableRipple
                          style={{
                            color: indigo[600],
                          }}
                          onChange={(e) => handleRolesChecked(e, i, ci)}
                          checked={items.isAccessible}
                          value={items.name}
                        />
                      }
                      label={items.name}
                    />
                  </AccordionDetails>
                ))}
              </Accordion>
            </div>
          ))
        : null}

      {currSourceType === "checklistitems" && (
        <div>
          <div className="forms-input">
            <label>Document</label>
            <select
              name="doc"
              id="doc"
              value={formValues.doc}
              onChange={handleChange}
            >
              <option value="none">Select</option>
              {allDocs.map((docs) => (
                <option key={docs.documentId} value={docs.documentId}>
                  {docs.name}
                </option>
              ))}
            </select>
          </div>
          <div className="forms-input">
            <label>Document Pack Type</label>
            <select
              name="docType"
              id="docType"
              value={formValues.docType}
              onChange={handleChange}
            >
              <option value="none">Select</option>
              {allDocTypes.map((doctypes) => (
                <option
                  key={doctypes.documentTypeId}
                  value={doctypes.documentTypeId}
                >
                  {doctypes.name}
                </option>
              ))}
            </select>
          </div>
        </div>
      )}
    </AddEditPopup>
  );
};
