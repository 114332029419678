import { APPLICANT_DATA_ACTIONS } from "./actions";

const initialState = {
  applicantData: [],
  applicantsArray: [],
  isApplicantRefresh: false,
};

export const applicantDataReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case APPLICANT_DATA_ACTIONS.SET_APPLICANT_DATA:
      return { ...state, applicantData: payload };

    case APPLICANT_DATA_ACTIONS.SET_APPLICANTS_ARRAY:
      return { ...state, applicantsArray: payload };

    case APPLICANT_DATA_ACTIONS.SET_HOT_APPLICANT:
      return { ...state, isHotApplicant: payload };

    case APPLICANT_DATA_ACTIONS.SET_APPLICANTS_REFRESH:
      return {
        ...state,
        isApplicantRefresh: !state.isApplicantRefresh,
      };

    default:
      return state;
  }
};
