import { AddEditPopup } from "../../../../../common/addEditPopup";
import * as React from "react";
import "../../../../../../assets/css/common/_drawer.css";

export const AddNote = (props) => {
  const handleChange = (e) => {
    props.setFormValues({
      ...props.formValues,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <AddEditPopup
      title="Add Note"
      openSidebar={props.openSidebar}
      toggleDrawer={props.toggleDrawer}
      handleSubmit={props.handleSubmit}
      isSubmitting={props.isSubmitting}
    >
      <div className="forms-input">
        <label>Note</label>
        <textarea
          name="note"
          id="note"
          cols="20"
          rows="4"
          value={props.formValues.note}
          onChange={handleChange}
        ></textarea>
        {props.formErrors.note ? (
          <span className="error_msg">{props.formErrors.note}</span>
        ) : null}
      </div>
    </AddEditPopup>
  );
};
