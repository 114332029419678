export const VideoIcon = ({ ...props }) => {
  return (
    <svg
      {...props}
      viewBox="0 0 18 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_302_3012)">
        <path
          d="M13.1183 4.93609V12.063C13.1183 12.333 13.0111 12.592 12.8201 12.7829C12.6292 12.9738 12.3702 13.0811 12.1002 13.0811H2.93707C2.66705 13.0811 2.40808 12.9738 2.21715 12.7829C2.02621 12.592 1.91895 12.333 1.91895 12.063V4.93609C1.91895 4.66607 2.02621 4.40711 2.21715 4.21617C2.40808 4.02524 2.66705 3.91797 2.93707 3.91797H12.1002C12.3702 3.91797 12.6292 4.02524 12.8201 4.21617C13.0111 4.40711 13.1183 4.66607 13.1183 4.93609ZM16.809 4.952C16.7372 4.93443 16.6625 4.93226 16.5898 4.94564C16.5171 4.95902 16.448 4.98765 16.3871 5.02963L14.2497 6.45437C14.2148 6.47764 14.1863 6.50915 14.1665 6.54612C14.1467 6.58308 14.1364 6.62435 14.1364 6.66627V10.3328C14.1364 10.3747 14.1467 10.416 14.1665 10.4529C14.1863 10.4899 14.2148 10.5214 14.2497 10.5447L16.3992 11.9777C16.4797 12.0314 16.5738 12.061 16.6705 12.0632C16.7671 12.0653 16.8624 12.0398 16.9452 11.9898C17.0218 11.9411 17.0846 11.8736 17.1276 11.7937C17.1706 11.7138 17.1923 11.6243 17.1908 11.5335V5.44516C17.1909 5.33226 17.1534 5.22254 17.0843 5.13327C17.0152 5.04399 16.9183 4.98023 16.809 4.952Z"
          fill={props.fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_302_3012">
          <rect
            width="16.29"
            height="16.29"
            fill={props.fill}
            transform="translate(0.901367 0.355469)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
