import { useState } from "react";
import { DateSelector } from "../../../../../../ui/pickers/date";
import { AddEditCol } from "../../../../../addEditPopup/AddEditCol";
import { AddEditBox } from "../../../../../addEditPopup/AddEditBox";

export const HireForm = ({
  openForm,
  toggleForm,
  applicantId,
  formValues,
  setFormValues,
  saveHireForm,
  isSubmitting,
}) => {
  const [hireDate, setHireDate] = useState();
  const [startDate, setStartDate] = useState();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleHireDateChange = (newValue) => {
    setHireDate(newValue.toISOString());
    setFormValues({ ...formValues, ["hiredDate"]: newValue.toISOString() });
  };

  const handleStartDateChange = (newValue) => {
    setStartDate(newValue.toISOString());
    setFormValues({ ...formValues, ["startdDate"]: newValue.toISOString() });
  };

  return (
    <AddEditBox
      openForm={openForm}
      toggleForm={toggleForm}
      applicantId={applicantId}
      handleSubmit={saveHireForm}
      title="Hire"
      isSubmitting={isSubmitting}
    >
      <div className="forms-input">
        <label>Hire Date :</label>
        <DateSelector
          value={hireDate}
          setValue={setHireDate}
          handleDateChange={handleHireDateChange}
        />
      </div>

      <div className="forms-input">
        <label>Start Date :</label>
        <DateSelector
          value={startDate}
          setValue={setStartDate}
          minDate={hireDate}
          handleDateChange={handleStartDateChange}
        />
      </div>

      <div className="forms-input">
        <label>Notes :</label>
        <textarea
          name="notes"
          value={formValues.notes}
          onChange={handleChange}
        />
      </div>
    </AddEditBox>
  );
};
