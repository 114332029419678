import { AddEditCol } from "../../../../../addEditPopup/AddEditCol";
import { ButtonUI } from "./../../../../../../ui/button/index";
import AddIcon from "@mui/icons-material/Add";
import { useEffect, useRef, useState } from "react";
import { FilesIcon } from "../../../../../../../icons";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import Api from "../../../../../../../helper/Api";
import { Box, IconButton, Tooltip } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { red } from "@mui/material/colors";
import { RemoveCircleOutlineOutlined } from "@material-ui/icons";
import { errors } from "../../../../../../../helper/constants";

const DEFAULT_DOC = {
  document: "",
  documentTypeId: "",
  notes: "",
  docName: "",
};

const DOC_ERROR = {
  document: null,
  documentTypeId: null,
  notes: null,
};

export const AddDocument = ({
  openSidebar,
  toggleDrawer,
  applicantId,
  isSubmitting,
  handleSubmit,
}) => {
  const attachRef = useRef(null);
  const [documents, setDocuments] = useState([]);
  const [attachment, setAttachment] = useState([]);
  const [docData, setDocData] = useState([]);
  const [allDocs, setAllDocs] = useState([]);
  const initialValues = [DEFAULT_DOC];
  const [selectedDocumentsLists, setSelectedDocumentsLists] =
    useState(initialValues);
  const [docErrors, setDocErrors] = useState([]);
  const [submitCount, setSubmitCount] = useState(0);

  useEffect(() => {
    setDocuments([]);
    getDocs();
  }, [openSidebar]);

  const toggle = () => {
    setDocErrors([]);
    setSelectedDocumentsLists([]);
    setDocuments([]);
    toggleDrawer();
  };

  const handleSave = (e) => {
    e.preventDefault();
    setSubmitCount((prev) => prev + 1);
    const isError = validateDocuments([...selectedDocumentsLists]);
    if (isError) return;
    handleSubmit([...selectedDocumentsLists]);
  };

  const handleChange = (e, index) => {
    const { name, value } = e.target;
    const documentsList = [...selectedDocumentsLists];
    documentsList[index] = { ...documentsList[index], [name]: value };
    setSelectedDocumentsLists([...documentsList]);
    validateDocuments([...documentsList]);
  };

  const handleSelectedFile = (e, index) => {
    setDocData({ ...docData, ["document"]: e.target.files[0] });
    const documentsList = [...selectedDocumentsLists];
    documentsList[index] = {
      ...documentsList[index],
      document: e.target.files[0],
      docName: e.target.files[0].name,
    };
    setSelectedDocumentsLists([...documentsList]);
    validateDocuments([...documentsList]);
  };

  const handleRemoveSelectedFile = (e, index) => {
    setDocData({ ...docData, ["document"]: null });
    const documentsList = [...selectedDocumentsLists];
    documentsList[index].document = null;
    documentsList[index].docName = null;
    setSelectedDocumentsLists([...documentsList]);
    validateDocuments([...documentsList]);
  };

  const openAttachment = (i) => {
    attachRef.current[i].click();
  };

  const createDocument = (e) => {
    e.preventDefault();
    setDocuments([...documents, DEFAULT_DOC]);
    setSelectedDocumentsLists([...selectedDocumentsLists, DEFAULT_DOC]);
    // validateDocuments([...selectedDocumentsLists, DEFAULT_DOC]);
  };

  const removeDocument = async (index) => {
    const updated = [...selectedDocumentsLists];
    await updated.splice(index, 1);

    setSelectedDocumentsLists([...updated]);
    validateDocuments([...updated]);
    // setDocuments([...updated]);
  };

  const validateDocuments = (list) => {
    let errors = [];

    list.forEach((doc, i) => {
      errors.push({
        document: !doc.document ? "Document is required." : null,
        documentTypeId: !doc.documentTypeId
          ? "Document Type is required."
          : null,
        notes: !doc.notes ? "Notes is required." : null,
      });
    });

    if (
      errors.length === 1 &&
      JSON.stringify(errors[0]) === JSON.stringify(DOC_ERROR)
    ) {
      errors = [];
      setDocErrors([]);
      return false;
    } else if (errors.length) {
      let isError = false;
      setDocErrors([...errors]);
      errors.forEach((err) => {
        if (JSON.stringify(err) !== JSON.stringify(DOC_ERROR)) {
          isError = true;
        }
      });
      return isError;
    }
  };

  //get all docs dropdown api
  const getDocs = () => {
    Api("GET", "Document/GetDocumentType?type=doctype")
      .then((res) => {
        if (res.data) {
          setAllDocs(res.data);
        } else {
          setAllDocs([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <AddEditCol
      title="Add Document"
      applicantId={applicantId}
      openForm={openSidebar}
      toggleForm={toggle} //{toggleDrawer}
      handleSubmit={handleSave}
      isSubmitting={isSubmitting}
    >
      <div className="job-document">
        <label>Documents({selectedDocumentsLists.length})</label>
        <ButtonUI
          p="10px"
          leftIcon={<AddIcon sx={{ height: "16px", width: "16px" }} />}
          onClick={createDocument}
          disabled={isSubmitting}
        >
          Add
        </ButtonUI>
      </div>

      {selectedDocumentsLists.map((doc, i) => (
        <div key={i}>
          <div className="forms-input">
            <input
              type="file"
              accept="image/png, image/jpg, image/jpg, .doc, .docx, .pdf"
              ref={(node) => {
                attachRef.current = {
                  ...attachRef.current,
                  [i]: node,
                };
              }}
              hidden
              onClick={(event) => {
                event.currentTarget.value = null;
              }}
              onChange={(e) => handleSelectedFile(e, i)}
              key={attachment}
            />
            {doc.docName ? (
              <div className="doucments-card">
                <div className="doc-detail">
                  <FilesIcon
                    stroke="#9EA0A5"
                    line="#fff"
                    width="32px"
                    height="32px"
                  />
                  <div className="file-name-label">{doc.docName}</div>
                </div>
                <div className="doc-detail" role="button">
                  <IconButton
                    size="small"
                    onClick={(e) => handleRemoveSelectedFile(e, i)}
                  >
                    <CloseIcon fontSize="small" sx={{ color: red[600] }} />
                  </IconButton>
                </div>
              </div>
            ) : (
              <div
                role="button"
                className="document-section pointer"
                onClick={() => openAttachment(i)}
              >
                <div className="documents-upload">
                  <span>Upload Document</span>
                  <CloudUploadOutlinedIcon width="32" height="32" />
                </div>
              </div>
            )}
            {submitCount > 0 && (
              <span className="error_msg">{docErrors[i]?.document}</span>
            )}{" "}
          </div>

          <div className="forms-input">
            <label>Select Document Type</label>
            <select name="documentTypeId" onChange={(e) => handleChange(e, i)}>
              <option value="none">Select</option>
              {allDocs.map((docs) => (
                <option key={docs.documentTypeId} value={docs.documentTypeId}>
                  {docs.name}
                </option>
              ))}
            </select>
            {submitCount > 0 && (
              <span className="error_msg">{docErrors[i]?.documentTypeId}</span>
            )}
          </div>

          <div className="forms-input">
            <label>Notes</label>
            <textarea
              name="notes"
              placeholder="Enter a description..."
              id="notes"
              onChange={(e) => handleChange(e, i)}
            />
            {submitCount > 0 && (
              <span className="error_msg">{docErrors[i]?.notes}</span>
            )}
          </div>
          {i !== 0 && (
            <Box display={"flex"} justifyContent={"flex-end"}>
              <Tooltip placement="top" title="Remove">
                <IconButton size="small" onClick={() => removeDocument(i)}>
                  <RemoveCircleOutlineOutlined color="error" />{" "}
                </IconButton>
              </Tooltip>
            </Box>
          )}
        </div>
      ))}
    </AddEditCol>
  );
};
