
export const timeOptions = [
    { id: 0, value: "As soon as possible" },
    { id: 1, value: "12:00 AM" },
    { id: 2, value: "1:00 AM" },
    { id: 3, value: "2:00 AM" },
    { id: 4, value: "3:00 AM" },
    { id: 5, value: "4:00 AM" },
    { id: 6, value: "5:00 AM" },
    { id: 7, value: "6:00 AM" },
    { id: 8, value: "7:00 AM" },
    { id: 9, value: "8:00 AM" },
    { id: 10, value: "9:00 AM" },
    { id: 11, value: "10:00 AM" },
    { id: 12, value: "11:00 AM" },
    { id: 13, value: "12:00 PM" },
    { id: 14, value: "1:00 PM" },
    { id: 15, value: "2:00 PM" },
    { id: 16, value: "3:00 PM" },
    { id: 17, value: "4:00 PM" },
    { id: 18, value: "5:00 PM" },
    { id: 19, value: "6:00 PM" },
    { id: 20, value: "7:00 PM" },
    { id: 21, value: "8:00 PM" },
    { id: 22, value: "9:00 PM" },
    { id: 23, value: "10:00 PM" },
    { id: 24, value: "11:00 PM" },
];

export const sendEmails = [
    { id: 1, value: "Send to email only" },
    { id: 2, value: "Send to all email addresses" }
]

export const sendPhones = [
    { id: 1, value: "Send to phone only" },
    { id: 2, value: "Send to all phones" }
]

export const sendPreference = [
    { id: 1, value: "Send to phone only" },
    { id: 2, value: "Send to email only" },
    { id: 3, value: "Send to both" },
];

// on click of time dropdown show content of 1 hour sequence
export function ComponentWithContentSequence({ value }) {
    let componentToRender;
    const currentIndex = timeOptions.findIndex(
        (option) => option.value === value
    );
    const nextIndex = (currentIndex + 1) % timeOptions.length;
    const nextOption = timeOptions[nextIndex];

    switch (value) {
        case "As soon as possible":
            componentToRender = (
                <p className="text-center content-time">
                    Delays sometimes occur when sending large numbers of
                    messages. This sequence will start and finish{" "}
                    {value}.
                </p>
            );
            break;
        case "12:00 AM":
            componentToRender = (
                <p className="text-center content-time">
                    Delays sometimes occur when sending large numbers of
                    messages. This sequence will start and finish between{" "}
                    midnight and {timeOptions[2].value}.
                </p>
            );
            break;
        case "11:00 PM":
            componentToRender = (
                <p className="text-center content-time">
                    Delays sometimes occur when sending large numbers of
                    messages. This sequence will start and finish between{" "}
                    {value} and midnight.
                </p>
            );
            break;
        case "12:00 PM":
            componentToRender = (
                <p className="text-center content-time">
                    Delays sometimes occur when sending large numbers of
                    messages. This sequence will start and finish between{" "}
                    noon and {timeOptions[14].value}.
                </p>
            );
            break;
        case "11:00 AM":
            componentToRender = (
                <p className="text-center content-time">
                    Delays sometimes occur when sending large numbers of
                    messages. This sequence will start and finish between{" "}
                    {value} and noon.
                </p>
            );
            break;
        default:
            componentToRender = (
                <><p className="text-center content-time">
                    Delays sometimes occur when sending large numbers of
                    messages. This sequence will start {value} and finish{" "}
                    {nextOption.value}.
                </p></>
            );
    }

    return <div>{componentToRender}</div>;
}

export const allTimeZones = {
    "Alaskan Standard Time": "America/Anchorage",
    "Central Standard Time": "America/Chicago",
    "Eastern Standard Time": "America/New_York",
    "Hawaiian Standard Time": "Pacific/Honolulu",
    "Mountain Standard Time": "America/Phoenix",
    "Pacific Standard Time": "America/Los_Angeles"
}
