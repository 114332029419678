import { Login } from "../../views/pages/authentication/Login";
import { Navigate } from "react-router-dom";
import { Otp } from "../../views/pages/authentication/Otp";
import { ForgotPassword } from "../../views/pages/authentication/ForgotPassword";
import { ResetPassword } from "../../views/pages/authentication/ResetPassword";
import { ViewJob } from "../../views/pages/applyForJob/view";
import { ChatByApplicant } from "../../components/common/layouts/applicantInfo/content/applicantInfoTabs/chat/byApplicant";
import { Video } from "../../components/video/Video";
import SelectCompany from "../../views/pages/authentication/SelectCompany";
import { ViewOfferLetter } from "../../components/common/layouts/applicantInfo/content/applicantJobData/OfferLetter/viewOfferLetter";
import { ViewDocPack } from "../../components/common/layouts/applicantInfo/content/applicantJobData/PreHire/viewDocPack";
import { ViewPhysicalPPD } from "../../components/common/layouts/applicantInfo/content/applicantJobData/PreHire/viewPhysicalPPD";
import { ViewRating } from "../../components/common/layouts/applicantInfo/content/applicantJobData/VideoInterview/viewRating";
import { ThankYouPage } from "../../components/common/layouts/applicantInfo/content/applicantJobData/Interviewing/thankYou";
import MeetPage from "../../components/common/layouts/applicantInfo/content/applicantJobData/Interviewing/meetPage";
import { ViewInterview } from "../../components/common/layouts/applicantInfo/content/applicantJobData/Interviewing/viewInterview";
import { ViewOffer } from "../../components/common/layouts/applicantInfo/content/applicantJobData/Offering/viewOffer";
import { EditDoc } from "../../components/common/layouts/applicantInfo/content/applicantJobData/OfferLetter/editDoc";
import { ViewOL } from "../../components/common/layouts/applicantInfo/content/applicantJobData/OfferLetter/ViewOL";
import { ViewDoc } from "../../components/common/layouts/applicantInfo/content/applicantJobData/PreHire/viewDoc";
import { DocEditor } from "../../components/companyDetail/documents/docEditor";
import { DocContent } from "../../components/companyDetail/documents/viewDocContent";

export const publicRoutes = [
  { path: "/", element: <Navigate to="/login" /> },
  { path: "/login", element: <Login /> },
  { path: "/otp", element: <Otp /> },
  { path: "/forgot-password", element: <ForgotPassword /> },
  { path: "/reset-password", element: <ResetPassword /> },
  { path: "/apply-job/:token", element: <ViewJob /> },
  { path: "/chat", element: <ChatByApplicant /> },
  { path: "/select-company", element: <SelectCompany /> },
  { path: "/offer/:id", element: <ViewOffer /> },
  { path: "/offer-letter/:id", element: <ViewOfferLetter /> },
  { path: "/view-letter/:id", element: <ViewOL /> },
  { path: "/view-doc/:id", element: <ViewDoc /> },
  { path: "/doc-content/:id", element: <DocContent /> },

  // Document pack : PreHire stage
  { path: "/documentpack/:id", element: <ViewDocPack /> },
  { path: "/appt/:id", element: <ViewPhysicalPPD /> },
  { path: "/editDoc/:id", element: <EditDoc /> },
  { path: "/addEditDoc/:id", element: <DocEditor /> },

  // Interview stage
  { path: "/interview/:id", element: <ViewInterview /> },

  // Add Rating : Reviewers
  { path: "/review", element: <ViewRating /> },

  // Jitsi meet for live virtual interview
  { path: "/meet/:token", element: <MeetPage /> },
  { path: "/thank-you", element: <ThankYouPage /> },
];
