import React, { useEffect, useState } from "react";
import { AddEditCol } from "../../../../../addEditPopup/AddEditCol";
import Api from "../../../../../../../helper/Api";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  FormControlLabel,
} from "@material-ui/core";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { indigo } from "@material-ui/core/colors";
import { FormControl } from "@mui/material";

export const CreatePreHirePack = ({
  toggleForm,
  openForm,
  applicantId,
  saveDocPack,
  companyId,
  isSubmitting,
}) => {
  const [docPackList, setDocPackList] = useState([]);
  const [docList, setDocList] = useState([]);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    Api("GET", `Job/PreHireDocumentPackList?companyId=${companyId}`)
      .then((res) => {
        // Document packs
        const dataPacks = [...res.data.preHireDocumentPackViewModels];
        dataPacks.forEach((rec) => {
          rec.checked = false;
          rec.documentPackLists.forEach((dl) => {
            dl.checked = false;
          });
        });
        setDocPackList(dataPacks);

        // Other Documents
        const data = [...res.data.remainingDocumentsList];
        data.forEach((d) => (d.checked = false));
        setDocList(data);
      })
      .finally(() => setLoading(false));
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    const data = [...docPackList];
    const records = [];
    data.forEach((rec) => {
      rec.documentPackLists.forEach((r) => {
        if (r.checked === true) {
          records.push({
            documentId: r.documentId,
            documentUrl: r.documentUrl,
          });
        }
      });
    });

    const docData = [...docList];

    docData.forEach((d) => {
      if (d.checked) {
        records.push({
          documentId: d.documentId,
          documentUrl: d.documentUrl,
        });
      }
    });

    saveDocPack(records);
  };

  const handleDocChange = (e, i, ci) => {
    const list = [...docPackList];

    if (ci === undefined || ci === null) {
      list[i]["documentPackLists"].map(
        (items) => (items.checked = e.target.checked)
      );
      list[i].checked = e.target.checked;
    } else {
      list[i]["documentPackLists"][ci].checked = e.target.checked;
      if (e.target.checked === false) {
        list[i].checked = false;
      }
    }

    setDocPackList(list);
  };

  const handleDocumentChange = (e, docId) => {
    const data = [...docList];
    data.map((d) => {
      if (d.documentId === docId) {
        d.checked = e.target.checked;
      }
    });

    setDocList(data);
  };

  return (
    <AddEditCol
      toggleForm={toggleForm}
      openForm={openForm}
      applicantId={applicantId}
      title="Create Pre Hire Pack"
      handleSubmit={handleSubmit}
      isLoading={isLoading}
      isSubmitting={isSubmitting}
    >
      {docPackList.map((docPack, index) => (
        <div className="forms-checkbox mt-5" key={index}>
          <Accordion square={true}>
            <AccordionSummary expandIcon={<ExpandMoreIcon color="action" />}>
              <FormControlLabel
                control={
                  <Checkbox
                    disableRipple
                    value={docPack.documentPackId}
                    onChange={(e) => handleDocChange(e, index)}
                    style={{
                      color: indigo[600],
                    }}
                    checked={docPack.checked}
                  />
                }
                label={docPack.documentPackName}
              />
            </AccordionSummary>
            {docPack.documentPackLists.map((items, ci) => (
              <AccordionDetails key={items.documentName}>
                <FormControlLabel
                  control={
                    <Checkbox
                      disableRipple
                      style={{
                        color: indigo[600],
                      }}
                      onChange={(e) => handleDocChange(e, index, ci)}
                      checked={items.checked}
                      value={items.documentName}
                    />
                  }
                  label={items.documentName}
                />
              </AccordionDetails>
            ))}
          </Accordion>
        </div>
      ))}

      <div className="forms-input mt-10">
        <label>Other Documents:</label>
        {docList.map((d, index) => (
          <div className="forms-checkbox mt-5">
            <FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    disableRipple
                    value={d.documentId}
                    onChange={(e) => handleDocumentChange(e, d.documentId)}
                    style={{
                      color: indigo[600],
                    }}
                    checked={d.checked}
                  />
                }
                label={d.documentName}
              />
            </FormControl>
          </div>
        ))}
      </div>
    </AddEditCol>
  );
};
