import React, { useState, useEffect } from "react";
import { AddEditPopup } from "../../../components/common/addEditPopup";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import {
  Box,
  Chip,
  FormControlLabel,
  IconButton,
  InputLabel,
  MenuItem,
  OutlinedInput,
} from "@mui/material";
import { ButtonUI } from "../../../components/ui/button";
import { hrs, mins } from "../../../helper/constants";
import { useTheme } from "@emotion/react";
import CloseIcon from "@mui/icons-material/Close";

import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { toast } from "react-toastify";
import moment from "moment";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;


export const EditTask = ({
  openSidebar,
  toggleDrawer,
  formValues,
  setFormValues,
  label,
  editMode,
  newUser,
  setNewUser,
  users,
  addTask,
  updateTask,
}) => {
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const [userDetails, setUserDetails] = useState([]);
  const [userIds, setUserIds] = useState(formValues?.users);
  const [userFormErrors, setUserFormErrors] = useState([]);



  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  const [formErrors, setFormErrors] = useState({});
  const names = [
    "Oliver Hansen",
    "Van Henry",
    "April Tucker",
    "Ralph Hubbard",
    "Omar Alexander",
    "Carlos Abbott",
    "Miriam Wagner",
    "Bradley Wilkerson",
    "Virginia Andrews",
    "Kelly Snyder",
  ];

  const date = new Date().toLocaleDateString("en-us", {
    weekday: "long",
    month: "long",
    day: "numeric",
    year: "numeric",
  });

  const errors = {};
  const validate = (values) => {
    console.log(values);
    if (!values.name) {
      errors.name = "Name is required.";
    }
    if (!values.timeHour || !values.timeMinute || !values.timeAMPM) {
      errors.time = "Please enter time.";
    }
    if (!values.duration) {
      errors.duration = "Duration is required.";
    }

    return errors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const userErrors = userDetails.map((user) => validateUserDetails(user));

    // Check if any user details contain errors
    const hasUserErrors = userErrors.some((errors) => Object.keys(errors).length > 0);

    // Validate main form fields
    const mainFormErrors = validate(formValues);

    // Check if any errors are present in the main form fields or user details
    if (Object.keys(mainFormErrors).length > 0 || hasUserErrors) {
      setFormErrors(mainFormErrors);
      setUserFormErrors(userErrors);
      return; // Prevent form submission if there are errors
    }

    setNewUser(false);
    if (editMode === true) {
      console.log("edit mode");
      console.log(userIds);
      updateTask(userIds);
    } else {
      console.log(formValues);
      setFormErrors(validate(formValues));
      if (Object.keys(validate(formValues)).length > 0) {
        return;
      }
      addTask(userIds);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const addUser = () => {
    setUserDetails([
      ...userDetails,
      {
        userId: "",
        firstName: "",
        lastName: "",
        emailAddress: "",
        phoneNumber: "",
      },
    ]);
  };

  const handleRemoveClick = (index) => {
    const data = [...userDetails];
    data.splice(index, 1);
    setUserDetails(data);
    setFormValues({ ...formValues, ["usersForTasks"]: data });
  };
  
  const validateUserDetails = (user) => {
    const errors = {};

    if (!user.firstName.trim()) {
      errors.firstName = 'First Name is required.';
    }

    if (!user.lastName.trim()) {
      errors.lastName = 'Last Name is required.';
    }

    if (!user.emailAddress.trim()) {
      errors.emailAddress = 'Email is required.';
    } else if (!/\S+@\S+\.\S+/.test(user.emailAddress)) {
      errors.emailAddress = 'Email is invalid.';
    }

    if (!user.phoneNumber.trim()) {
      errors.phoneNumber = 'Phone Number is required.';
    } else if (!/^[0-9]+$/.test(user.phoneNumber)) {
      errors.phoneNumber = 'Phone Number should contain only numbers.';
    }

    return errors;
  };



  const handleUserChange = (e, index) => {
    const { name, value } = e.target;
    const data = [...userDetails];
    data.at(index)[name] = value;
    setUserDetails(data);
    setFormValues({ ...formValues, ["usersForTasks"]: data });

    const errors = validateUserDetails(data[index]);
    setUserFormErrors((prevErrors) => {
      const updatedErrors = [...prevErrors];
      updatedErrors[index] = errors;
      return updatedErrors;
    });

  };

  const handleUserIdChange = (value) => {
    const rec = [];
    const data = [...value];

    data.forEach((d) => {
      rec.push({
        userId: d.userId,
      });
    });

    setUserIds(rec);
  };

  return (
    <AddEditPopup
      title={label}
      openSidebar={openSidebar}
      toggleDrawer={toggleDrawer}
      handleSubmit={handleSubmit}
      setFormErrors={setFormErrors}
      type="jobTemplate"
    >
      <div className="forms-input">
        <label>
          {formValues?.scheduledDate
            ? moment(formValues?.scheduledDate).format("dddd, MMMM Do YYYY")
            : date}
        </label>
      </div>
      <div className="forms-input">
        <label>Name:</label>
        <input
          type="text"
          placeholder="Name here"
          name="name"
          id="name"
          value={formValues?.name}
          onChange={handleChange}
        />
        {formErrors.name ? (<span className="error_msg">{formErrors.name}</span>) : null}
      </div>
      <div className="forms-input ">
        <label>Time:</label>
        <div className="sections d-flex">
          <div className="col-3">
            <FormControl size="small">
              <Select
                inputProps={{ "aria-label": "Without label" }}
                defaultValue=""
                name="timeHour"
                onChange={handleChange}
                value={formValues.timeHour}
              >
                {hrs.map((hr) => (
                  <MenuItem value={hr}>{hr}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div className="col-3">
            <FormControl size="small">
              <Select
                inputProps={{ "aria-label": "Without label" }}
                defaultValue=""
                name="timeMinute"
                onChange={handleChange}
                value={formValues.timeMinute}
              >
                {mins.map((min) => (
                  <MenuItem value={min}>{min}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div className="col-3">
            <FormControl size="small">
              <Select
                inputProps={{ "aria-label": "Without label" }}
                defaultValue=""
                name="timeAMPM"
                onChange={handleChange}
                value={formValues.timeAMPM}
              >
                <MenuItem value="am">AM</MenuItem>
                <MenuItem value="pm">PM</MenuItem>
              </Select>
            </FormControl>
          </div>

        </div>
        {formErrors.time ? (<span className="error_msg">{formErrors.time}</span>) : null}
      </div>
      <div className="forms-input">
        <label>Length (minutes):</label>
        <input
          type="text"
          name="duration"
          id="duration"
          placeholder="120"
          onChange={handleChange}
          value={formValues?.duration}
        />
        {formErrors.duration ? (<span className="error_msg">{formErrors.duration}</span>) : null}
      </div>
      <div className="forms-input">
        <label>Notes:</label>
        <textarea
          name="notes"
          placeholder="Type here"
          id="notes"
          cols="20"
          rows="4"
          onChange={handleChange}
          value={formValues?.notes}
        ></textarea>
      </div>
      <div className="forms-input">
        <div className="sections d-flex">
          <label>Add User:</label>
          <ButtonUI
            color="#006AFF"
            variant="contained"
            bgColor="#ffffff"
            onClick={() => addUser()}
          >
            + New User
          </ButtonUI>
        </div>
      </div>

      <Autocomplete
        multiple
        id="checkboxes-tags-demo"
        options={users}
        size="small"
        onChange={(e, value) => handleUserIdChange(value)}
        disableCloseOnSelect
        getOptionLabel={(option) =>
          option.userFirstName + " " + option.userLastName
        }
        renderOption={(props, option, { selected }) => (
          <li {...props}>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={selected}
            />
            {option.userFirstName +
              " " +
              option.userLastName +
              " (" +
              option.userEmailAddress +
              ")"}
          </li>
        )}
        defaultValue={formValues?.users}
        renderInput={(params) => (
          <TextField {...params} label="Select Users" placeholder="Users" />
        )}
      />

      {userDetails.map((user, index) => (
        <div className="add-upload-container" key={index}>
          <div className="text-right">
            <IconButton size="small" onClick={() => handleRemoveClick(index)}>
              <CloseIcon color="primary" fontSize="small" />
            </IconButton>
          </div>
          <div className="forms-input sections">
            <div className="col-2">
              <label>First Name:</label>
              <input
                type="text"
                id={"firstName" + index}
                placeholder="First Name"
                name="firstName"
                value={user.firstName}
                onChange={(e) => handleUserChange(e, index)}
              />
              {userFormErrors[index] && userFormErrors[index].firstName ? (
                <span className="error_msg">{userFormErrors[index].firstName}</span>
              ) : null}
            </div>

            <div className="col-2">
              <label>Last Name:</label>
              <input
                type="text"
                id={"lastName" + index}
                placeholder="Last Name"
                name="lastName"
                value={user.lastName}
                onChange={(e) => handleUserChange(e, index)}
              />
              {userFormErrors[index] && userFormErrors[index].lastName ? (
                <span className="error_msg">{userFormErrors[index].lastName}</span>
              ) : null}
            </div>
          </div>

          <div className="forms-input sections">
            <div className="col-2">
              <label>Email :</label>
              <input
                type="text"
                id={"emailAddress" + index}
                placeholder="Email Address"
                name="emailAddress"
                value={user.emailAddress}
                onChange={(e) => handleUserChange(e, index)}
              />
              {userFormErrors[index] && userFormErrors[index].emailAddress ? (
                <span className="error_msg">{userFormErrors[index].emailAddress}</span>
              ) : null}
            </div>

            <div className="col-2">
              <label>Phone Number:</label>
              <input
                type="text"
                id={"phoneNumber" + index}
                placeholder="Phone Number"
                name="phoneNumber"
                value={user.phoneNumber}
                onChange={(e) => handleUserChange(e, index)}
              />
              {userFormErrors[index] && userFormErrors[index].phoneNumber ? (
                <span className="error_msg">{userFormErrors[index].phoneNumber}</span>
              ) : null}
            </div>
          </div>
        </div>
      ))}
    </AddEditPopup>
  );
};
