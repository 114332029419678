import { useEffect, useState } from "react";
import Api from "../../../../../../../helper/Api";
import { AddEditCol } from "../../../../../addEditPopup/AddEditCol";
import { CheckboxList } from "../../../../CheckboxList";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import { useRef } from "react";

export const AddOnboardDocForm = ({
  openForm,
  toggleForm,
  applicantId,
  saveDocument,
  formValues,
  setFormValues,
  formErrors,
  companyId,
  isSubmitting,
}) => {
  const [allData, setAllData] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const uploadDoc = useRef();

  useEffect(() => {
    Api("GET", `Job/GetExistingOnboardingDocumentList?companyId=${companyId}`)
      .then((res) => {
        const response = res.data.map((d) => {
          return { ...d, id: d.documentId, name: d.name };
        });

        setAllData(response);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [openForm]);

  const handleChange = (checkName, isChecked) => {
    const checkList = allData.map((data) => {
      if (data.id === checkName) {
        data.checked = isChecked;
      }
      return data;
    });

    const data = [...checkList];
    const checkedDocs = [];
    data.forEach((rec) => {
      if (rec.checked === true) {
        checkedDocs.push(rec.documentId);
      }
    });

    setFormValues({ ...formValues, ["SelectedDocLists"]: checkedDocs });
    setAllData(checkList);
  };

  const openAttachment = () => {
    uploadDoc.current.click();
  };

  const handleSelectedFile = (e, index) => {
    const data = [...allData];
    setFormValues({ ...formValues, ["UploadDocLists"]: e.target.files[0] });
    data.push({ id: 1, checked: true, name: e.target.files[0].name });
    setAllData(data);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    saveDocument();
  };

  return (
    <AddEditCol
      openForm={openForm}
      toggleForm={toggleForm}
      applicantId={applicantId}
      title="Add Document for onboarding"
      handleSubmit={handleSubmit}
      isLoading={isLoading}
      isSubmitting={isSubmitting}
    >
      <input
        type="file"
        ref={uploadDoc}
        hidden
        accept="application/pdf,image/png,image/jpeg,.doc, .docx"
        className="pointer"
        onChange={(e) => handleSelectedFile(e, 0)}
      />
      <CheckboxList
        allData={allData}
        setAllData={setAllData}
        showSaveContainer={false}
        handleChange={handleChange}
      />
      <div className="forms-input">
        <div
          className="d-flex mt-10 justify-content-center pointer"
          role="button"
          onClick={openAttachment}
        >
          <FileUploadOutlinedIcon color="action" />
          <label>Upload new documents</label>
        </div>
        <span className="success_msg">
          *Allowed file types are jpg, png, pdf, doc, docs only
        </span>
        <label className="error_msg">{formErrors?.document}</label>
      </div>
    </AddEditCol>
  );
};
