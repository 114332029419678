import * as React from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { LinkCalIcon } from "../../../icons";
import { Tooltip } from "@mui/material";
import { ButtonUI } from "../../ui/button";
import { GoogleIcon } from "../../../icons/google";
import Api from "../../../helper/Api";
import { useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiPaper-root": {
    "& .MuiDialogTitle-root": {
      background: "#475467",
      color: "#fff",
      fontSize: "16px",
    },
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon color="action" />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function SyncCalDialogBox() {
  const [open, setOpen] = useState(false);
  const { companyData } = useSelector((state) => state.companyDataReducer);
  const [url, setUrl] = useState("");

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const connectToGoogle = () => {
    Api(
      "GET",
      `Calendar/GoogleSync?scheduledDate=${new Date().toISOString()}&companyId=${
        companyData.companyId
      }&userId=${localStorage.getItem("user")}`
    )
      .then((res) => {
        setUrl(res.data);
        window.open(res.data, "_blank");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div>
      <Tooltip title="Link with Google calendar">
        <IconButton sx={{ padding: "1px" }} onClick={handleClickOpen}>
          <LinkCalIcon className="icon" />
        </IconButton>
      </Tooltip>

      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          Connected Calendars
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <ButtonUI
            p="10px"
            width="310px"
            variant="outlined"
            borderColor="#9EA0A5"
            mlicon="16px"
            leftIcon={<GoogleIcon color="action" />}
            onClick={connectToGoogle}
          >
            Connect to Google
          </ButtonUI>
          <span className="success_msg">
            *Make sure your browser has not blocked the pop ups
          </span>
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
}
