import { useState } from "react";
import { DateSelector } from "../../../../../../ui/pickers/date";
import { AddEditBox } from "../../../../../addEditPopup/AddEditBox";

export const SendVINotification = ({
  toggleForm,
  openSidebar,
  applicantId,
  isSubmitting,
  handleSubmit,
  expDate,
}) => {
  const [value, setValue] = useState(expDate);
  const [description, setDescription] = useState("");

  const handleDateChange = (newValue) => {
    setValue(newValue.toISOString());
  };

  const handleSave = () => {
    handleSubmit(value, description);
  };

  const handleChange = (e) => {
    setDescription(e.target.value);
  };

  return (
    <AddEditBox
      toggleForm={toggleForm}
      applicantId={applicantId}
      openForm={openSidebar}
      handleSubmit={handleSave}
      isSubmitting={isSubmitting}
      title="Send Notification"
    >
      <div className="forms-input">
        <label>Expires :</label>
        <DateSelector
          value={value}
          setValue={setValue}
          disablePast={true}
          handleDateChange={handleDateChange}
        />
      </div>

      <div className="forms-input">
        <label>Description:</label>
        <textarea
          placeholder="Message"
          value={description}
          name="description"
          onChange={handleChange}
        />
      </div>
    </AddEditBox>
  );
};
