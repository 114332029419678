import { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import Api from "../../../helper/Api";
import { FilesIcon, CloseXIcon } from "../../../icons";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import {
  skeletonBaseColor,
  skeletonBorderRadius,
  skeletonHighLightColor,
  sortAtoZ,
} from "../../../helper/constants";
import AddIcon from "@mui/icons-material/Add";
import { IconButton, InputBase, Paper, Tooltip } from "@mui/material";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import CloseIcon from "@mui/icons-material/Close";
import { grey, indigo, red } from "@mui/material/colors";
import NotificationsActiveOutlinedIcon from "@mui/icons-material/NotificationsActiveOutlined";
import axios from "axios";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import Repeat from "../../../helper/utils";
import { string } from "yup";

export const ApplyForJob = ({
  formValues,
  setFormValues,
  formErrors,
  isEdit,
}) => {
  const { companyData } = useSelector((state) => state.companyDataReducer);
  const [sources, setSources] = useState([]);
  const attachRef = useRef("null");
  const [attachment, setAttachment] = useState("");
  const [docData, setDocData] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [emails, setEmails] = useState(formValues?.applicantEmailList);
  const [phoneNumbers, setPhoneNumbers] = useState(
    formValues?.applicantPhoneList
  );

  useEffect(() => {
    if (Object.keys(formValues).length !== 0) {
      formValues?.applicantPhoneList.length === 0
        ? setPhoneNumbers([
            {
              applicantNotificationId: "",
              isDelete: false,
              isNotify: true,
              phone: "",
            },
          ])
        : setPhoneNumbers(formValues?.applicantPhoneList);
    }

    Api(
      "GET",
      `Sources/GetSourceList?type=applicantsources&companyId=${companyData.companyId}&companyHirerchy=${companyData.companyHirerchy}`
    )
      .then((res) => {
        setSources(sortAtoZ(res.data, "name"));
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const openAttachment = () => {
    attachRef.current.click();
  };

  const handleResume = async (e) => {
    var selectedFile = e.target.files;
    // Check File is not Empty
    setFormValues({ ...formValues, ["resume"]: e.target.files[0] });

    if (selectedFile.length > 0) {
      var fileToLoad = selectedFile[0];
      var fileReader = new FileReader();
      fileReader.onload = function (fileLoadedEvent) {
        const params = {
          filedata: fileLoadedEvent.target.result.split(",")[1],
          filename: e.target.files[0].name,
          userkey: "4L5L513VFD0",
          version: "8.0.0",
          subuserid: "GrayPeakWorkforce",
        };

        setLoading(true);
        axios
          .post(
            `https://rest.rchilli.com/RChilliParser/Rchilli/parseResumeBinary`,
            params
          )
          .then((res) => {
            const data = { ...res.data.ResumeParserData };
            setFormValues({
              ...formValues,
              ["resume"]: e.target.files[0],
              ["firstName"]: data.Name.FirstName,
              ["middleName"]: data.Name.MiddleName,
              ["lastName"]: data.Name.LastName,
              ["title"]: data.Name.TitleName,
              ["emailAddress"]: data.Email[0].EmailAddress,
              ["phoneNumber"]: formatPhoneNumber(
                data.PhoneNumber[0].OriginalNumber.trim()
              ),
              ["address"]: {
                city: data.Address[0].City,
                addressLineOne: data.Address[0].FormattedAddress,
                state: data.Address[0].State,
                zipCode: data.Address[0].ZipCode,
              },
            });
          })
          .finally(() => setLoading(false));
      };

      fileReader.readAsDataURL(fileToLoad);
    }

    setDocData({ ...docData, ["resume"]: e.target.files[0] });
    setAttachment(e.target.files[0].name);
  };

  const formatPhoneNumber = (phoneNumber) => {
    if (!phoneNumber) return "";
    return phoneNumber.replace(/[^\d+]/g, "");
  };

  const handleAddEmail = () => {
    const data = {
      applicantNotificationId: "",
      email: "",
      isNotify: false,
      isDelete: false,
    };
    setEmails([...emails, data]);
  };

  const handleAddPhone = () => {
    const data = {
      applicantNotificationId: "",
      phone: "",
      isNotify: false,
      isDelete: false,
    };
    setPhoneNumbers([...phoneNumbers, data]);
  };

  const removePhone = (index) => {
    const data = [...phoneNumbers];
    data[index].applicantNotificationId === ""
      ? data.splice(index, 1)
      : (data[index].isDelete = true);

    setPhoneNumbers(data);
    setFormValues({ ...formValues, ["applicantPhoneList"]: data });
  };

  const removeEmail = (index) => {
    const data = [...emails];

    data[index].applicantNotificationId === ""
      ? data.splice(index, 1)
      : (data[index].isDelete = true);

    setEmails(data);
    setFormValues({ ...formValues, ["applicantEmailList"]: data });
  };

  const changeNotification = (value, type, index) => {
    if (type === "email") {
      const eData = [...emails];
      eData[index].isNotify = value;
      setEmails(eData);
      setFormValues({ ...formValues, ["applicantEmailList"]: eData });
    } else {
      const pData = [...phoneNumbers];
      pData[index].isNotify = value;
      setPhoneNumbers(pData);
      setFormValues({ ...formValues, ["applicantPhoneList"]: pData });
    }
  };

  const handleDynamicInput = (e, index) => {
    if (e.target.name === "email") {
      const eData = [...emails];
      eData[index].email = e.target.value;
      setEmails(eData);
      setFormValues({ ...formValues, ["applicantEmailList"]: eData });
    } else {
      const pData = [...phoneNumbers];
      pData[index].phone = e.target.value;
      setPhoneNumbers(pData);
      setFormValues({ ...formValues, ["applicantPhoneList"]: pData });
    }
  };

  const FieldsSkeleton = () => {
    return (
      <Repeat numTimes={4}>
        {(index) => (
          <div className="forms-input sections" key={index}>
            <Skeleton count={1} height={35} width={280} />
            <Skeleton count={1} height={35} width={280} />
          </div>
        )}
      </Repeat>
    );
  };

  const handlePhoneChange = (e) => {
    const re = /^[0-9\b]+$/;
    if (re.test(e.target.value)) {
      setFormValues({ ...formValues, ["phoneNumber"]: e.target.value });
    } else {
      setFormValues({ ...formValues, ["phoneNumber"]: "" });
    }
  }

  return (
    <div>
      <div className="forms-input">
        <input
          type="file"
          ref={attachRef}
          hidden
          onChange={handleResume}
          key={attachment}
          accept="image/png, image/jpeg, image/jpg, .doc, .docx, .pdf"
        />

        <label>Resume:</label>
        {formValues.fileDisplayName !== "" &&
        formValues.fileDisplayName !== undefined ? (
          <div className="doucments-card applicant">
            <div className="doc-detail">
              <FilesIcon
                stroke="#9EA0A5"
                line="#fff"
                width="32px"
                height="32px"
              />
              <div className="file-name-label">
                {formValues.fileDisplayName}
              </div>
            </div>
            <div
              className="doc-detail"
              role="button"
              onClick={() =>
                setFormValues({
                  ...formValues,
                  ["fileDisplayName"]: "",
                })
              }
            >
              <div className="pointer">
                <CloseXIcon width="28px" height="28px" />
              </div>
            </div>
          </div>
        ) : attachment ? (
          <div className="doucments-card applicant">
            <div className="doc-detail">
              <FilesIcon
                stroke="#9EA0A5"
                line="#fff"
                width="32px"
                height="32px"
              />
              <div className="file-name-label">{attachment}</div>
            </div>
            <div
              className="doc-detail"
              role="button"
              onClick={() => setAttachment("")}
            >
              <div className="pointer">
                <CloseXIcon width="28px" height="28px" />
              </div>
            </div>
          </div>
        ) : (
          <div
            role="button"
            className="document applicant pointer"
            onClick={() => openAttachment()}
          >
            <div className="documents-upload">
              <span>Upload Document</span>
              <CloudUploadOutlinedIcon width="32" height="32" />
            </div>
          </div>
        )}

        <span className="success_msg">
          * Allowed File types are .doc, .docx, .pdf, .jpg, .jpeg, .png
        </span>
      </div>

      {isLoading ? (
        <div>
          <SkeletonTheme
            borderRadius={skeletonBorderRadius}
            baseColor={skeletonBaseColor}
            highlightColor={skeletonHighLightColor}
          >
            <FieldsSkeleton />
          </SkeletonTheme>
        </div>
      ) : (
        <div>
          <div className="forms-input sections">
            <div className="col-2">
              <label>First Name:</label>
              <input
                type="text"
                id="firstName"
                name="firstName"
                value={formValues.firstName}
                placeholder="Name here"
                onChange={handleChange}
              />
              <span className="error_msg">{formErrors.firstName}</span>
            </div>
            <div className="col-2">
              <label>Middle Name:</label>
              <input
                type="text"
                name="middleName"
                id="middleName"
                value={formValues.middleName}
                placeholder="Name here"
                onChange={handleChange}
              />
            </div>
          </div>

          <div className="forms-input sections">
            <div className="col-2">
              <label>Last Name:</label>
              <input
                type="text"
                name="lastName"
                id="lastName"
                value={formValues.lastName}
                placeholder="Name here"
                onChange={handleChange}
              />
              <span className="error_msg">{formErrors.lastName}</span>
            </div>
            <div className="col-2">
              <label>Nick Name:</label>
              <input
                type="text"
                id="nickName"
                name="nickName"
                value={formValues.nickName}
                placeholder="Name here"
                onChange={handleChange}
              />
            </div>
          </div>

          <div className="forms-input sections">
            <div className="col-2">
              <label>Title:</label>
              <input
                type="text"
                name="title"
                id="title"
                value={formValues?.title}
                placeholder="Title here"
                onChange={handleChange}
              />
            </div>
            <div className="col-2">
              <label>Source:</label>
              <select
                id="source"
                name="source"
                onChange={handleChange}
                value={formValues.source}
              >
                <option>Select</option>
                {sources.map((source) => (
                  <option value={source.sourceId} key={source.sourceId}>
                    {source.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="forms-input">
            <label>Address:</label>
            <input
              type="text"
              name="addressLineOne"
              id="address"
              value={
                formValues?.addressLineOne ||
                formValues?.address?.addressLineOne
              }
              placeholder="Address line one"
              onChange={handleChange}
            />
          </div>
          <div className="forms-input">
            <input
              type="text"
              name="addressLineTwo"
              id="address4"
              value={
                formValues?.addressLineTwo ||
                formValues?.address?.addressLineTwo
              }
              placeholder="Address line two"
              onChange={handleChange}
            />
          </div>

          <div className="forms-input sections">
            <div className="col-3">
              <input
                type="text"
                id="address1"
                name="city"
                value={formValues?.city || formValues?.address?.city}
                placeholder="City here"
                onChange={handleChange}
              />
            </div>
            <div className="col-3">
              <input
                type="text"
                id="address2"
                name="state"
                value={formValues?.state || formValues?.address?.state}
                placeholder="State here"
                onChange={handleChange}
              />
            </div>
            <div className="col-3">
              <input
                type="text"
                id="address3"
                name="zipCode"
                value={formValues?.zipCode || formValues?.address?.zipCode}
                placeholder="Zipcode here"
                onChange={handleChange}
              />
            </div>
          </div>
        </div>
      )}

      {isEdit ? (
        <div className="d-flex justify-content-between">
          <div key="applicant-email">
            <label className="d-flex justify-content-between align-items-center">
              Email:
              <Tooltip title="Add Email">
                <span
                  className="participants add pointer"
                  onClick={handleAddEmail}
                >
                  <AddIcon fontSize="small" /> Add
                </span>
              </Tooltip>
            </label>

            {(emails ?? []).map((e, index) => (
              <>
                {e.isDelete === false && (
                  <Paper
                    component="form"
                    sx={{
                      marginTop: "6px",
                      p: "4px 4px",
                      border: "1px solid #D0D5DD",
                      display: "flex",
                      alignItems: "center",
                      width: 270,
                      boxShadow: "none",
                      borderRadius: "8px",
                    }}
                    key={"email" + index}
                  >
                    <InputBase
                      sx={{ ml: 1, flex: 1 }}
                      placeholder="Email here"
                      inputProps={{ "aria-label": "Email here" }}
                      value={e.email}
                      name="email"
                      onChange={(e) => handleDynamicInput(e, index)}
                    />

                    {e.isNotify ? (
                      <Tooltip title="Email Notification">
                        <IconButton
                          type="button"
                          sx={{ p: "10px" }}
                          aria-label="search"
                          onClick={() =>
                            changeNotification(false, "email", index)
                          }
                        >
                          <NotificationsActiveIcon
                            sx={{ color: indigo[500] }}
                          />
                        </IconButton>
                      </Tooltip>
                    ) : (
                      <Tooltip title="Email Notification">
                        <IconButton
                          type="button"
                          sx={{ p: "10px" }}
                          aria-label="search"
                          onClick={() =>
                            changeNotification(true, "email", index)
                          }
                        >
                          <NotificationsActiveOutlinedIcon
                            sx={{ color: grey[400] }}
                          />
                        </IconButton>
                      </Tooltip>
                    )}

                    {index !== 0 && (
                      <Tooltip title="Close">
                        <IconButton
                          sx={{ p: "10px" }}
                          aria-label="directions"
                          onClick={() => removeEmail(index)}
                        >
                          <CloseIcon sx={{ color: red[500] }} />
                        </IconButton>
                      </Tooltip>
                    )}
                  </Paper>
                )}
              </>
            ))}
          </div>

          <div key="applicant-phone">
            <label className="d-flex justify-content-between align-items-center">
              Cell Phone:
              <Tooltip title="Add Phone">
                <span
                  className="participants add pointer"
                  onClick={handleAddPhone}
                >
                  <AddIcon fontSize="small" /> Add
                </span>
              </Tooltip>
            </label>

            {(phoneNumbers ?? []).map((phone, index) => (
              <>
                {phone.isDelete === false && (
                  <Paper
                    component="form"
                    sx={{
                      marginTop: "6px",
                      p: "4px 4px",
                      border: "1px solid #D0D5DD",
                      display: "flex",
                      alignItems: "center",
                      width: 270,
                      boxShadow: "none",
                      borderRadius: "8px",
                    }}
                    key={"phone" + index}
                  >
                    <InputBase
                      sx={{ ml: 1, flex: 1 }}
                      placeholder="Phone here"
                      inputProps={{ "aria-label": "Phone here" }}
                      name="phone"
                      value={phone?.phone}
                      onChange={(e) => handleDynamicInput(e, index)}
                    />
                    {phone.isNotify && phone?.phone !== "" ? (
                      <Tooltip title="SMS Notification">
                        <IconButton
                          id={"sms" + index}
                          type="button"
                          sx={{ p: "10px" }}
                          aria-label="search"
                          onClick={() =>
                            changeNotification(false, "phone", index)
                          }
                        >
                          <NotificationsActiveIcon
                            sx={{ color: indigo[500] }}
                          />
                        </IconButton>
                      </Tooltip>
                    ) : (
                      <Tooltip title="SMS Notification">
                        <IconButton
                          id={"sms_light" + index}
                          type="button"
                          sx={{ p: "10px" }}
                          aria-label="search"
                          onClick={() =>
                            changeNotification(true, "phone", index)
                          }
                        >
                          <NotificationsActiveOutlinedIcon
                            sx={{ color: grey[400] }}
                          />
                        </IconButton>
                      </Tooltip>
                    )}

                    {index !== 0 && (
                      <Tooltip title="Close">
                        <IconButton
                          sx={{ p: "10px" }}
                          aria-label="directions"
                          onClick={() => removePhone(index)}
                        >
                          <CloseIcon sx={{ color: red[500] }} />
                        </IconButton>
                      </Tooltip>
                    )}
                  </Paper>
                )}
              </>
            ))}
            <label className="error_msg">{formErrors.phoneNumber}</label>
          </div>
        </div>
      ) : isLoading ? (
        <div>
          <SkeletonTheme
            borderRadius={skeletonBorderRadius}
            baseColor={skeletonBaseColor}
            highlightColor={skeletonHighLightColor}
          >
            <div className="forms-input sections">
              <Skeleton count={1} height={35} width={280} />
              <Skeleton count={1} height={35} width={280} />
            </div>
          </SkeletonTheme>
        </div>
      ) : (
        <div className="forms-input sections">
          <div className="col-2">
            <label>Email Address:</label>
            <input
              type="text"
              id="emailAddress"
              name="emailAddress"
              value={formValues.emailAddress}
              placeholder="Email here"
              onChange={handleChange}
            />
            <span className="error_msg">{formErrors.emailAddress}</span>
          </div>

          <div className="col-2">
            <label>Cell Phone:</label>
            <input
              type="text"
              name="phoneNumber"
              id="phoneNumber"
              value={formValues.phoneNumber}
              placeholder="Cell Phone here"
              onChange={handlePhoneChange}
            />
            <span className="error_msg">{formErrors.phoneNumber}</span>
          </div>
        </div>
      )}
    </div>
  );
};
