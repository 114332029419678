import { CircularProgress } from "@mui/material";
import Api from "../../../../helper/Api";
import { EditDeleteMenu } from "../../../ui/editDeleteMenu";
import { CheckCircleOutline, HighlightOff } from "@mui/icons-material";
import {
  CellPhoneIcon,
  LocationIcon,
  OutlinedEmailIcon,
} from "../../../../icons";
import { Roles } from "./role";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { EditUserInfo } from "./add/editUser";
import { UserNext } from "./add/UserNext";
import { UserAccess } from "./access/UserAccess";
import { useState } from "react";
import { API_types } from "../../../../helper/enum";
import { validations } from "../../../../helper/validations";
import NotificationsIcon from "@mui/icons-material/Notifications";
import QueryBuilderIcon from "@mui/icons-material/QueryBuilder";
import { indigo } from "@mui/material/colors";

export const UserInfo = ({
  usersDataIndi,
  isUserActive,
  companyData,
  getUsersData,
  allTimeZone,
  isCompany,
  editMode,
  setEditMode,
  getData,
  setUserId,
  usersRoles,
  setUsersRoles,
}) => {
  let navigate = useNavigate();
  const [formValues, setFormValues] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [openUserAccessForm, setUserAccessForm] = useState(false);
  const [openSidebar, setOpenSideBar] = useState(false);
  const [accessData, setAccessData] = useState([]);
  const [isNext, setIsNext] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [label, setLabel] = useState("Edit User");
  const [isButtonSubmitting, setButtonSubmitting] = useState(false);
  const errors = {};

  const manageJob = (userId) => {
    navigate("managejobs", {
      state: {
        companyId: companyData.companyId,
        companyHirerchy: companyData.companyHirerchy,
        userId: userId,
      },
    });
  };

  const manageNot = (userId) => {
    navigate("notifications", {
      state: {
        companyId: companyData.companyId,
        companyHirerchy: companyData.companyHirerchy,
        userId: userId,
      },
    });
  };

  const editJobAccess = (id) => {
    setLabel("Edit Job Management");
    toggleUserAccess("right", true);
    setEditMode(true);
    putValuesFromEdit(id, "jobManagement");
  };

  const putValuesFromEdit = (id, type) => {
    if (type == "jobManagement") {
      const listAccess = usersDataIndi.accessManagementDetails.map((items) =>
        items.entityId.find((ent) => (ent.cardId === id ? ent : null))
      );
      let url, entId;
      switch (listAccess[0].entType) {
        case "department":
          url = `Department/GetDepartmentList?companyId=${companyData.companyId}`;
          entId = "departmentId";
          break;
        case "company":
          url = `Companies/GetCompany?parentCompanyId=${companyData.companyId}`;
          entId = "companyId";
          break;
        case "facility":
          url = `Facilities/GetFacilitiesList?companyId=${companyData.companyId}`;
          entId = "facilitiesId";
          break;
        case "job":
          url = "Jobs";
          entId = "jobId";
          break;
      }

      if (
        listAccess[0].entType === "job" ||
        listAccess[0].entType === "none" ||
        listAccess[0].entType === "Select"
      ) {
        setAccessData([]);
        return;
      }

      Api("GET", url + `&companyHirerchy=${companyData.companyHirerchy}`)
        .then((res) => {
          listAccess[0].entType === "department"
            ? setAccessData(
              res.data.map((res) => ({ ["id"]: res[entId], ...res }))
            )
            : setAccessData(
              res.data.data.map((res) => ({ ["id"]: res[entId], ...res }))
            );

          // This condition is for temporary basis untill the job module APIs get prepared
          // The condition for department module is due to the response variables
        })
        .catch((err) => {
          console.log(err);
        });
      setFormValues({
        ["userRole"]: listAccess[0].roleId,
        ["jobAccess"]: listAccess[0].entType,
        ["accessRecords"]: listAccess[0].entId,
        ["cardId"]: listAccess[0].cardId,
        ["entName"]: listAccess[0].entName,
        ["isNotify"]: listAccess[0].isNotify,
      });
    } else {
      setFormValues({
        ["firstName"]: usersDataIndi.firstName,
        ["middleName"]: usersDataIndi.middleName,
        ["nickName"]: usersDataIndi.nickName,
        ["lastName"]: usersDataIndi.lastName,
        ["email_id"]: usersDataIndi.emails,
        ["cell_phone"]: usersDataIndi.phoneNumbers,
        ["timeZone"]: usersDataIndi.timeZone,
        ["user_title"]: usersDataIndi.title,
        ["login_id"]: usersDataIndi.loginID,
        ["roleName"]: usersDataIndi.user_role,
        ["emailNotification"]: usersDataIndi.emailNotification,
        ["smsNotification"]: usersDataIndi.smsNotification,
        ["postalAddresses"]: usersDataIndi.postalAddresses,
      });
    }
    setFormErrors({});
  };

  const deleteJobAccess = (id) => {
    var answer = window.confirm("Are you sure you want to delete?");
    if (answer) {
      Api(
        API_types.DELETE,
        `Users/DeleteAccessManagement?userId=${usersDataIndi.userId}&cardId=${id}`
      )
        .then((res) => {
          if (res.data.isSuccess) {
            setIsActive(false);
            getUsersData();
            toast.success(res.data.message);
            setUserId(usersDataIndi.userId);
          } else {
            toast.error(res.data.message);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const toggleUserActiveState = async (id, isActive) => {
    try {
      const params = {
        userID: id,
        isActive: !isActive,
      };

      // Your API call to update the user's active/inactive status
      const response = await Api(
        "PATCH",
        `Users/UserActiveInActive?companyId=${companyData.companyId}&userId=${params.userID}&isActive=${params.isActive}`
      );

      if (response?.data.isSuccess) {
        params.isActive ? setIsActive(false) : setIsActive(true);
        setUserId(params.userID);
      } else {
        toast.error(response.data.message);
      }
      getData(params.userID, companyData.companyId);
    } catch (error) {
      console.error(error);
    }
  };

  function editUserDetails(id) {
    setLabel("Edit User");
    toggleUserEdit("right", true);
    setEditMode(true);
    putValuesFromEdit(id, "userInfo");
  }

  const handleNext = () => {
    setFormErrors(validate(formValues));
    if (Object.values(formErrors).length > 0) {
      return;
    }
    toggleUserEdit("right", false);
    toggleUserNext("right", true);
  };

  const toggleUserEdit = (anchor, open) => {
    setOpenSideBar({ ...openSidebar, [anchor]: open });
  };

  const toggleUserNext = (anchor, open) => {
    setIsNext({ ...isNext, [anchor]: open });
  };

  const toggleUserAccess = (anchor, open) => {
    setUserAccessForm({ ...openUserAccessForm, [anchor]: open });
  };

  const handleUserAccess = (e) => {
    e.preventDefault();
    setFormErrors(validate(formValues));
    if (Object.values(formErrors).length > 0) {
      return;
    }
    const params = {
      userId: usersDataIndi.userId,
      companyId: companyData.companyId,
      entType: formValues.jobAccess,
      entId: formValues.accessRecords,
      roleId: formValues.userRole,
    };

    if (editMode) {
      params.isNotify = formValues.isNotify;
      params.cardId = formValues.cardId;

      // EditJobManagement API call
      Api(API_types.PUT, `Users/EditAccessManagement`, params)
        .then((res) => {
          if (res.data.isSuccess) {
            setIsActive(false);
            getUsersData();
            toast.success(res.data.message);
            setUserAccessForm(false);
            setIsActive(params.userId);
          } else {
            toast.error(res.data.message);
            setUserAccessForm(true);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      params.isNotify = true;
      // Add JobManagement API call
      Api(API_types.POST, `Users/AddAccessManagement`, params)
        .then((res) => {
          if (res.data.isSuccess) {
            setIsActive(false);
            getUsersData();
            toast.success(res.data.message);
            setIsActive(params.userId);
          } else {
            toast.error(res.data.message);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
    setUserAccessForm(false);
  };

  const handleCreate = () => {
    setFormErrors(validate(formValues));
    if (Object.values(formErrors).length > 0) {
      return;
    }
    toggleUserNext("right", false);
  };

  const validate = (values) => {
    if (isNext) {
      if (values.roles.length == 0) {
        errors.roleName = validations?.urole?.req;
      }
      if (!values.jobAccess) {
        errors.jobAccess = validations?.job_level?.req;
      }
      if (values.jobAccessCheck.length == 0) {
        errors.jobAccessLevel = validations?.facilities?.req;
      }
      if (!values.accessRecords) {
        errors.accessRecords = validations?.accessRecords?.req;
      }

      if (!values.accessRecords.length == 0) {
        errors.accessRecords = validations?.accessRecords?.req;
      }
    } else if (openUserAccessForm) {
      if (!values.jobAccess) {
        errors.jobAccess = validations?.job_level?.req;
      }
      if (!values.accessRecords) {
        errors.accessRecords = validations?.accessRecords?.req;
      }
      if (!values.userRole) {
        errors.userRole = validations?.urole?.req;
      }
    }

    return errors;
  };

  const handleEditUser = () => {
    let emailValue = [];
    let phoneValue = [];

    emailValue = Array.isArray(formValues.email_id)
      ? formValues.email_id
      : formValues.emails;
    phoneValue = Array.isArray(formValues.cell_phone)
      ? formValues.cell_phone
      : formValues.phoneNumbers;

    const postalAddresses = formValues.postalAddresses
      ? formValues.postalAddresses.map((address) => ({
        address1: address.address1,
        address2: "", // Assuming this is not provided in the form
        city: address.city,
        state: address.state,
        zipCode: address.zipCode,
      }))
      : [];

    const params = {
      companyId: companyData.companyId,
      firstName: formValues.firstName,
      lastName: formValues.lastName,
      middleName: formValues.middleName,
      nickName: formValues.nickName,
      emails: emailValue,
      phoneNumbers: phoneValue,
      loginID: formValues.login_id,
      title: formValues.user_title,
      timeZone: formValues.timeZone,
      roleId: formValues.roles,
      isSendWelcomeMessage: formValues.isSendWelcomeMessage,
      jobAccessLevel: {
        entityId: formValues.jobAccessCheck,
        entityType: formValues.jobAccess,
      },
      postalAddresses: postalAddresses,
    };

    // setFormErrors(addUserValidate(formValues));
    // if (Object.values(addUserValidate(formValues)).length > 0) {
    //   return;
    // }

    setButtonSubmitting(true);
    delete params.loginID;
    delete params.email_id;
    delete params.cell_phone;
    delete params.roleId;
    delete params.jobAccessLevel;
    params.userId = usersDataIndi.userId;
    params.postalAddresses = postalAddresses || [];
    params.phoneNumbers = phoneValue || [];
    params.emails = emailValue || [];
    params.nickName = formValues.nickName;
    params.title = formValues.user_title;
    params.emailNotification = formValues.emailNotification;
    params.smsNotification = formValues.smsNotification;
    // EditUser API call
    Api(API_types.PUT, `Users/EditPersonalInformation`, params)
      .then((res) => {
        if (res.data.isSuccess) {
          setIsActive(false);
          getData(usersDataIndi.userId, params.companyId);
          getUsersData();
          toast.success(res.data.message);
          setUserId(params.userId);
          toggleUserEdit("right", false);
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setButtonSubmitting(false);
      });
  };

  return (
    <div className="user-detail position-relative">
      <EditUserInfo
        formValues={formValues}
        setFormValues={setFormValues}
        openSidebar={openSidebar}
        handleNext={handleNext}
        formErrors={formErrors}
        editMode={editMode}
        handleSubmit={handleEditUser}
        label={label}
        toggleDrawer={toggleUserEdit}
        allTimeZone={allTimeZone}
        isButtonSubmitting={isButtonSubmitting}
        usersDataIndi={usersDataIndi}
      />

      {isNext && (
        <UserNext
          formValues={formValues}
          setFormValues={setFormValues}
          openSidebar={isNext}
          formErrors={formErrors}
          handleCreate={handleCreate}
          usersRoles={usersRoles}
          companyData={companyData}
          label={label}
          toggleDrawer={toggleUserNext}
        />
      )}

      {openUserAccessForm && (
        <UserAccess
          toggleDrawer={toggleUserAccess}
          openSidebar={openUserAccessForm}
          selectedUserRoles={usersDataIndi.roleDetails}
          companyData={companyData}
          handleUserAccess={handleUserAccess}
          formValues={formValues}
          editMode={editMode}
          setFormValues={setFormValues}
          label={label}
          accessRecordsData={accessData}
          formErrors={formErrors}
          validate={validate}
          setFormErrors={setFormErrors}
        />
      )}

      <span className="main-names main-divs-header content-uname">
        {usersDataIndi.firstName && usersDataIndi.lastName ? (
          `${usersDataIndi.firstName} ${usersDataIndi.lastName}`
        ) : (
          <CircularProgress />
        )}
      </span>

      <div className="user-status main-divs-header-info">
        <div>
          <span className="check-status">
            <b>Status:</b> {isUserActive ? "Active" : "Inactive"}
          </span>
        </div>
        {/* Custom toggle switch for active/inactive */}
        <div
          onClick={() =>
            toggleUserActiveState(usersDataIndi.userId, isUserActive)
          }
        >
          {isUserActive ? (
            <CheckCircleOutline style={{ color: "green", cursor: "pointer" }} />
          ) : (
            <HighlightOff style={{ color: "red", cursor: "pointer" }} />
          )}
        </div>
      </div>

      <div className="personal-info">
        <div className="d-flex justify-content-between mb-15">
          <span className="main-head">Personal Information</span>
          <div className="position-relative">
            <EditDeleteMenu
              id={usersDataIndi.userId}
              onEdit={editUserDetails}
              deleteOption={false}
            />
          </div>
        </div>
        <div className="personal-data">
          <div className="p-entity">
            <OutlinedEmailIcon width="20" height="16" />
            <div>
              <p className="p-label">Email Address</p>
              {usersDataIndi.emails?.map((email) => (
                <div key={email} className="p-data">
                  <span>{email}</span>
                  {usersDataIndi.emailNotification && (
                    <NotificationsIcon color="action" />
                  )}
                </div>
              ))}
            </div>
          </div>
          <div className="p-entity">
            <CellPhoneIcon width="14" height="22" />
            <div>
              <p className={`p-label ${isCompany ? "pl-3" : ""}`}>Cell Phone</p>
              {usersDataIndi.phoneNumbers?.map((phoneNumber) => (
                <div
                  key={phoneNumber}
                  className={`p-data ${isCompany ? "pl-3" : ""}`}
                >
                  <span>{phoneNumber}</span>
                  {usersDataIndi.smsNotification && (
                    <NotificationsIcon color="action" />
                  )}
                </div>
              ))}
            </div>
          </div>

          <div className="p-entity">
            <LocationIcon width="20" height="20" className="p-icon" />
            <div>
              <p className="p-label">Postal Addresses</p>
              {usersDataIndi.postalAddresses?.map((address, index) => (
                <div key={index} className="p-data">
                  <span>
                    {`${address.address1}, ${address.city}, ${address.state}, ${address.zipCode}`}
                  </span>
                </div>
              ))}
            </div>
          </div>

          <div className="p-entity">
            <QueryBuilderIcon sx={{ color: indigo[500] }} />
            <div>
              <p className="p-label">Time Zone</p>
              <p className="p-data">{usersDataIndi.timeZone}</p>
            </div>
          </div>
          <div className="p-entity">
            <img
              src={require("../../../../assets/images/User.svg").default}
              alt="nav-icon"
            />
            <div>
              <p className="p-label">Title</p>
              <p className="p-data">{usersDataIndi.title}</p>
            </div>
          </div>
          <div className="p-entity">
            <img
              src={require("../../../../assets/images/User.svg").default}
              alt="nav-icon"
            />
            <div>
              <p className="p-label">Login ID</p>
              <p className="p-data">{usersDataIndi.loginID}</p>
            </div>
          </div>
          <div className="p-entity">
            <img
              src={require("../../../../assets/images/User.svg").default}
              alt="nav-icon"
            />
            <div>
              <p className="p-label">Nick Name</p>
              <p className="p-data">{usersDataIndi.nickName}</p>
            </div>
          </div>
        </div>
      </div>
      <Roles
        getUsersData={getUsersData}
        usersRoles={usersRoles}
        setUsersRoles={setUsersRoles}
        usersDataIndi={usersDataIndi}
        setIsActive={setIsActive}
        setUserId={setUserId}
        getData={getData}
      />
      <div className="job-management">
        <div className="d-flex justify-content-between mb-15">
          <span className="main-head">Job Management</span>
          <img
            src={
              require("../../../../assets/images/add-user-roles.svg").default
            }
            alt=""
            className="cursor-pointer"
            onClick={() => (
              toggleUserAccess("right", true),
              setEditMode(false),
              setLabel("Add Job Management"),
              setFormErrors({}),
              setFormValues({})
            )}
          />
        </div>
        <div className="job-container overflow-unset">
          <div className="w-100">
            {usersDataIndi.accessManagementDetails?.map((items) =>
              // no access management details found so its pending right now
              items.entityId?.map((ent) => (
                <div className="job-tabs w-100" key={ent.cardId}>
                  <img
                    src={localStorage.getItem("companyLogoPath")}
                    alt=""
                    className="cursor-pointer"
                  />
                  <div className="job-in-name w-100">
                    <div className="d-flex align-items-center justify-content-between">
                      <p>{localStorage.getItem("companyName")}</p>
                      <EditDeleteMenu
                        id={ent.cardId}
                        onEdit={editJobAccess}
                        onDelete={deleteJobAccess}
                      />
                    </div>
                    <div className="job-content">
                      <span>
                        User Role: <strong>{ent.roleName}</strong>
                      </span>
                      <span>
                        Notify: <strong>{ent.isNotify ? "Yes" : "No"}</strong>
                      </span>
                      <span>
                        Level: <strong>{ent.entType}</strong>
                      </span>
                      <span>
                        Name: <strong>{ent.entName}</strong>
                      </span>
                    </div>
                  </div>
                </div>
              ))
            )}
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-center">
        <button
          className="add-btn manage-btn"
          onClick={() => manageNot(usersDataIndi.userId)}
        >
          Manage Notifications
          <img
            src={require("../../../../assets/images/Vector.png")}
            alt="right-arrow"
            className="right-arrow-btn"
          />
        </button>
        <button
          className="add-btn manage-btn"
          onClick={() => manageJob(usersDataIndi.userId)}
        >
          Manage Job Assignments
          <img
            src={require("../../../../assets/images/Vector.png")}
            alt="right-arrow"
            className="right-arrow-btn"
          />
        </button>
      </div>
    </div>
  );
};
