import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Api from "../../../../../../../helper/Api";
import { PublicNavigationBar } from "../../../../../navigationBar/public";
import { ButtonUI } from "../../../../../../ui/button";
import { toast } from "react-toastify";
import { EclipseLoader } from "../../../../../eclipseLoader";

export const ViewOfferLetter = () => {
  const location = useLocation();
  const offerLetterId = location.pathname.substring(
    location.pathname.lastIndexOf("/") + 1
  );
  const [content, setContent] = useState("");
  const [status, setStatus] = useState("");
  const [isLoading, setLoader] = useState(true);
  const [isSubmitting, setSubmitting] = useState(false);

  useEffect(() => {
    getOfferLetterData();
  }, []);

  const getOfferLetterData = () => {
    Api(
      "GET",
      `Job/getOfferLetterDocumentContent?id=${offerLetterId}&isSystemLink=${false}`
    )
      .then((res) => {
        if (res.data.isSuccess) {
          setContent(res.data.data.htmlContent);
          setStatus(res.data.data.offerLetterStatus);
        } else {
          setContent("");
        }
      })
      .finally(() => setLoader(false));
  };

  const saveData = (e) => {
    e.preventDefault();
    const inputs = [...Object.values(document.getElementsByTagName("input"))];
    const tAreas = [
      ...Object.values(document.getElementsByTagName("textarea")),
    ];

    if (tAreas.length > 0) {
      tAreas.map((area) => {
        area.setAttribute("value", document.getElementById(area.id).value);
      });
    }

    if (inputs.length > 0) {
      inputs.map((i) => {
        const inputEl = document.getElementById(i.id);
        if (i.type === "text" && content.includes(i.id)) {
          inputEl.setAttribute("value", inputEl.value);
        }

        if (i.type === "radio") {
          inputEl.checked = i.checked;
          inputEl.setAttribute("checked", inputEl.checked);
        }

        if (i.type === "checkbox") {
          inputEl.checked = i.checked;
          inputEl.setAttribute("checked", inputEl.checked);
        }
      });
    }

    const params = {
      applicantOfferLetterId: offerLetterId,
      signedOfferLetter: document.getElementById("ol-content").innerHTML,
    };

    setSubmitting(true);
    Api("POST", `Job/SignAndSubmitOfferLetter`, params)
      .then((res) => {
        if (res.data.isSuccess === true) {
          getOfferLetterData();
          toast.success(res.data.message);
          window.close();
        }
      })
      .finally(() => setSubmitting(false));
  };

  return (
    <div>
      <PublicNavigationBar />
      <div className="job-data-container">
        <div className="j-content">
          {isLoading ? (
            <EclipseLoader inShow={true} />
          ) : status === "SIGNED_OFFERLETTER" ? (
            <div>
              <h3>Thank you</h3>
              <span>Thank you ! You have signed the offer letter !</span>
            </div>
          ) : content.length > 0 ? (
            <>
              <form onSubmit={saveData}>
                <div className="mt-20" id="fr-content">
                  <div
                    id="ol-content"
                    dangerouslySetInnerHTML={{
                      __html: content,
                    }}
                  />
                </div>
                <ButtonUI p="8px" width="120px">
                  {isSubmitting ? (
                    <div className="dot-pulse"></div>
                  ) : (
                    "Sign & Submit"
                  )}
                </ButtonUI>
              </form>
            </>
          ) : (
            <div>
              <span>Offer letter expired !!!</span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
