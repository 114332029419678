import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Api from "../../../../../../../helper/Api";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import { Rating } from "@mui/material";
import { makeStyles, CircularProgress } from "@material-ui/core";
import { ButtonUI } from "../../../../../../ui/button";
import Loader from "../../../../../Loader";

export const ViewRating = () => {
  const location = useLocation();
  const [videoQuestions, setVideoQuestions] = useState([]);
  const [activeStep, setActiveStep] = useState(0);
  const [loader, setLoader] = useState(false);
  const [reviewData, setReviewData] = useState([]);
  const [formErrors, setFormErrors] = useState("");
  const userId = new URLSearchParams(location.search).get("userId");
  const [isSubmit, setSubmit] = useState(false);
  const [saveLoader, setSaveLoader] = useState(false); // State for 'Save & Next' loader
  const jobApplicantVideoInterviewId = new URLSearchParams(location.search).get(
    "jobApplicantVideoInterviewId"
  );

  useEffect(() => {
    setLoader(true);
    Api(
      "GET",
      `Job/GetQuestionWiseDataByMailLink?jobApplicantVideoInterviewId=${jobApplicantVideoInterviewId}&userId=${userId}`
    )
      .then((res) => {
        setVideoQuestions(res.data.data.videoQuestions);
        const videoQues = [...res.data.data.videoQuestions];
        const reviewerData = [];
        videoQues.map((video) => {
          const data = video.reviewers;
          data.forEach((rec) => {
            if (rec.userId == userId) {
              reviewerData.push(rec);
            }
          });
        });

        setReviewData(reviewerData);
      })
      .finally(() => setLoader(false));
  }, []);

  const useStyles = makeStyles(() => ({
    root: {
      "& .MuiStepIcon-active": { color: "#3954A5" },
      "& .MuiStepIcon-completed": { color: "#00875A" },
      padding: "10px",
    },
  }));

  const c = useStyles();

  const nextStep = () => {
    if (activeStep === videoQuestions.length) {
      return;
    }
    setActiveStep((currentStep) => currentStep + 1);
  };

  const previousStep = () => {
    if (activeStep === 0) {
      return;
    }
    setActiveStep((currentStep) => currentStep - 1);
  };

  const handleChange = (e) => {
    const data = [...reviewData];
    data[activeStep][e.target.name] = e.target.value;
    setReviewData(data);
  };

  const saveReviewerRating = () => {
    var ratingId = "";
    const data = [...videoQuestions[activeStep].reviewers];
    data.forEach((rec) => {
      if (rec.userId == userId) {
        ratingId = rec.rateingId;
      }
    });

    if (reviewData[activeStep].rating == 0) {
      setFormErrors("Please give rating!");
      return;
    }

    const params = {
      jobApplicantVideoInterviewId: jobApplicantVideoInterviewId,
      rateingId: ratingId,
      userId: userId,
      videoQuestionId: videoQuestions[activeStep].videoQuestionId,
      rating: +reviewData[activeStep].rating,
      ratingNote: reviewData[activeStep].ratingNote,
    };
    setSaveLoader(true);


    Api("POST", `Job/SaveRateingQuestionWiseByMailLink`, params)
      .then((res) => {
        if (res.data.isSuccess === true) {
          nextStep();
        }
      })
      .finally(() => {
        setSaveLoader(false); 
      });
  };

  const handleSubmit = () => {
    saveReviewerRating();
    setSubmit(true);
  };

  const skipQuestion = () => {
    if (activeStep === videoQuestions.length - 1) {
      setSubmit(true);
    } else {
      nextStep();
    }
  };

  return (
    <div className="white-container">
      {loader ? (
        <Loader inShow={true} />
      ) : isSubmit === false ? (
        <>
          <div className="mb-10">
            <img
              src={require("../../../../../../../assets/images/logo.png")}
              width="125px"
            />
          </div>
          <div>
            <Stepper className={c.root} activeStep={activeStep}>
              {videoQuestions?.map((video, index) => (
                <Step key={index}>
                  <StepLabel></StepLabel>
                </Step>
              ))}
            </Stepper>
            <div className="question-title">
              <strong>Question : </strong>
              <span>{videoQuestions[activeStep]?.videoQuestionName}</span>
            </div>
            <div className="video-section">
              <video
                className="rating-video"
                key={videoQuestions[activeStep]?.videoQuestionId}
                alt={videoQuestions[activeStep]?.videoQuestionId}
                controls
              >
                <source
                  src={
                    "https://" + videoQuestions[activeStep]?.mediaFile?.slice(6)
                  }
                  type="video/mp4"
                />
              </video>
            </div>

            <div className="mt-10">
              <Rating
                name="rating"
                value={reviewData[activeStep]?.rating}
                onChange={handleChange}
              />
            </div>
            <div className="mt-10 forms-input">
              <textarea
                name="ratingNote"
                placeholder="Comment"
                value={reviewData[activeStep]?.ratingNote}
                onChange={handleChange}
              />
              <span className="error_msg">{formErrors}</span>
            </div>
            <div
              className={
                activeStep !== 0
                  ? "d-flex justify-content-between"
                  : "d-flex justify-content-between"
              }
            >
              {activeStep !== 0 && (
                <ButtonUI p="9px" variant="outlined" onClick={previousStep}>
                  Back
                </ButtonUI>
              )}

              {activeStep === videoQuestions.length - 1 ? (
                <ButtonUI p="9px" onClick={handleSubmit}>
                  Submit
                </ButtonUI>
              ) : (
                <ButtonUI p="9px" onClick={saveReviewerRating}>
                  {saveLoader ? (
                    <CircularProgress size={24} color="inherit" />
                  ) : (
                    'Save & Next'
                  )}                </ButtonUI>
              )}
              <ButtonUI p="9px" variant="outlined" onClick={skipQuestion}>
                Skip
              </ButtonUI>
            </div>
          </div>
        </>
      ) : (
        <div>
          <span>Thank you for submitting your valuable reviews!</span>
        </div>
      )}
    </div>
  );
};
