export const HireIcon = ({ ...props }) => {
  return (
    <svg
      width="24"
      height="17"
      viewBox="0 0 24 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M17.668 10H22.168"
        stroke="#C7C7D2"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.918 7.75V12.25"
        stroke="#C7C7D2"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.04297 12.25C12.1496 12.25 14.668 9.7316 14.668 6.625C14.668 3.5184 12.1496 1 9.04297 1C5.93637 1 3.41797 3.5184 3.41797 6.625C3.41797 9.7316 5.93637 12.25 9.04297 12.25Z"
        stroke="#C7C7D2"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M1 15.9998C1.98563 14.8255 3.21643 13.8813 4.60594 13.2335C5.99544 12.5857 7.50995 12.25 9.04304 12.25C10.5761 12.25 12.0906 12.5857 13.4801 13.2335C14.8696 13.8814 16.1004 14.8256 17.0861 15.9999"
        stroke="#C7C7D2"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
