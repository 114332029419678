import React, { useState, useEffect } from "react";
import "../../../assets/css/components/help/_help.css";
import Api from "../../../helper/Api";

export function Help() {
  const [results, setResults] = useState([]);
  const [searchInput, setSearchInput] = useState("");

  /*useEffect(() => {
    getAllHelpQuestions();
  }, []);*/

  const getAllHelpQuestions = () => {
    Api("GET", `HelpQuestion/GetHelpQuestion`)
      .then((res) => {
        setResults(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleSearch = (event) => {
    setSearchInput(event.target.value);
  };

  const openInNewTab = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };

  useEffect(() => {
    if (searchInput !== "") {
      const filteredData = results.filter((result) => {
        return result.title.toLowerCase().includes(searchInput.toLowerCase());
      });
      setResults(filteredData);
    } else {
      getAllHelpQuestions();
    }
  }, [searchInput]);

  return (
    <React.Fragment>
      <div className="company_content help-container">
        <div className="content_header">
          <div className="company_text">Help</div>
        </div>
        <div className="company_table">
          <div className="max-width-container h-100">
            <p className="main-heading">How can we help?</p>
            <span className="search-svg"></span>
            <input
              type="text"
              name=""
              id=""
              className="search-inputs"
              placeholder="Start typing here..."
              value={searchInput}
              onChange={handleSearch}
            />

            <div className="results-inputs">
              {results.map((items) => (
                <p key={items.id} onClick={() => openInNewTab(items.url)}>
                  {items.title}
                </p>
              ))}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
