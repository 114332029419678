import { useState } from "react";
import { AddEditBox } from "../../../../../addEditPopup/AddEditBox";

export const CancelPreHireForm = ({
  openForm,
  toggleForm,
  applicantId,
  cancelPhysicalPpd,
  isSubmitting,
}) => {
  const [cancelComment, setCancelComment] = useState();
  const [error, setError] = useState(null);

  const handleChange = (e) => {
    if (!cancelComment) {
      setError(null);
    }
    setCancelComment(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!cancelComment) {
      setError("Message is Required.");
      return;
    }
    cancelPhysicalPpd(cancelComment);
  };

  return (
    <AddEditBox
      openForm={openForm}
      toggleForm={toggleForm}
      title="Cancel this Appointment ?"
      applicantId={applicantId}
      handleSubmit={handleSubmit}
      isSubmitting={isSubmitting}
    >
      <div className="forms-input">
        <label>Message to Applicant: </label>
        <textarea
          placeholder="Message"
          name="applicantMessage"
          value={cancelComment}
          onChange={handleChange}
        />
        <span className="error_msg">{error}</span>
      </div>
    </AddEditBox>
  );
};
