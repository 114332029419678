import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import { FormControl, FormControlLabel } from "@mui/material";
import { indigo } from "@mui/material/colors";
import { DateSelector } from "../../../../../../ui/pickers/date";
import { useState } from "react";
import { AddEditBox } from "../../../../../addEditPopup/AddEditBox";

export const EditReviewForm = (props) => {
  const [value, setValue] = useState(props.formValues.canStart);

  function handleChange(e) {
    const { name, value } = e.target;
    if (name === "rate") {
      props.setFormErrors({
        ...props.formValues,
        [name]: value?.charAt(0) === "-" ? "Rate must be positive." : null,
      });
    }

    props.setFormValues({ ...props.formValues, [name]: value });
  }

  function handleDateChange(newValue) {
    if (newValue === null) {
      setValue(null);
    } else {
      setValue(new Date(newValue));
    }

    if (newValue === null) {
      props.setFormValues({
        ...props.formValues,
        ["canStart"]: newValue,
      });
    } else {
      props.setFormValues({
        ...props.formValues,
        ["canStart"]: new Date(newValue),
      });
    }
  }

  return (
    <AddEditBox
      applicantId={props.applicantId}
      openForm={props.openForm}
      title="Reviewing edit Form"
      toggleForm={props.toggleForm}
      handleSubmit={props.handleSubmit}
      isSubmitting={props.isSubmitting}
    >
      <div className="forms-input multiple">
        <label>Work Eligible: </label>
        <FormControl>
          <RadioGroup
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="workEligible"
            onChange={handleChange}
            defaultValue={props.formValues.workEligible}
            key="workEligible"
          >
            <FormControlLabel
              key="yesEligible"
              value={true}
              control={
                <Radio
                  sx={{
                    color: indigo[800],
                    "&.Mui-checked": {
                      color: indigo[600],
                    },
                  }}
                  size="small"
                />
              }
              label="Yes"
            />
            <FormControlLabel
              key="noEligible"
              value={false}
              control={
                <Radio
                  sx={{
                    color: indigo[800],
                    "&.Mui-checked": {
                      color: indigo[600],
                    },
                  }}
                  size="small"
                />
              }
              label="No"
            />
          </RadioGroup>
        </FormControl>
      </div>

      <div className="forms-input">
        <label>Rate:</label>
        <input
          type="text"
          placeholder="Rate here"
          onChange={handleChange}
          name="rate"
          value={props.formValues.rate}
        />
        <span className="error_msg">{props?.formErrors?.rate}</span>
      </div>

      <div className="forms-input">
        <label>Can Interview:</label>
        <textarea
          placeholder="Can Interview"
          onChange={handleChange}
          name="canInterview"
          value={props.formValues.canInterview}
        />
      </div>

      <div className="forms-input">
        <label>Can start:</label>
        <DateSelector
          value={value}
          setValue={setValue}
          disablePast={value === new Date().toISOString() ? true : false}
          handleDateChange={handleDateChange}
          views={["year", "month", "day"]}
        />
      </div>

      <div className="forms-input multiple">
        <label>Can Relocate : </label>
        <FormControl>
          <RadioGroup
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="isRelocatable"
            value={props.formValues.isRelocatable}
            onChange={handleChange}
            key="isRelocatable"
          >
            <FormControlLabel
              key="yesRelocatable"
              value={true}
              control={
                <Radio
                  sx={{
                    color: indigo[800],
                    "&.Mui-checked": {
                      color: indigo[600],
                    },
                  }}
                  size="small"
                />
              }
              label="Yes"
            />
            <FormControlLabel
              key="noRelocatable"
              value={false}
              control={
                <Radio
                  sx={{
                    color: indigo[800],
                    "&.Mui-checked": {
                      color: indigo[600],
                    },
                  }}
                  size="small"
                />
              }
              label="No"
            />
          </RadioGroup>
        </FormControl>
      </div>

      <div className="forms-input">
        <label>Employee History:</label>
        <textarea
          placeholder="Employee History"
          onChange={handleChange}
          name="employee_history"
          value={props?.formValues?.employee_history}
        />
      </div>
    </AddEditBox>
  );
};
