import { useState } from "react";
import { DateSelector } from "../../../../../../ui/pickers/date";
import { AddEditBox } from "../../../../../addEditPopup/AddEditBox";

export const ActionForm = ({
  openForm,
  toggleForm,
  applicantId,
  saveAction,
  flag,
  isSubmitting,
}) => {
  const [notes, setNotes] = useState("");
  const [sDate, setDate] = useState();

  const handleDateChange = (newValue) => {
    setDate(newValue.toISOString());
  };

  const handleChange = (e) => {
    setNotes(e.target.value);
  };

  const handleSubmit = () => {
    saveAction(notes, sDate);
  };

  return (
    <AddEditBox
      openForm={openForm}
      toggleForm={toggleForm}
      applicantId={applicantId}
      handleSubmit={handleSubmit}
      isSubmitting={isSubmitting}
      title={
        flag === "terminate"
          ? "Terminate this employee ?"
          : flag === "quit"
          ? "Employee has quit ?"
          : "Furlough this employee ?"
      }
    >
      <div className="forms-input">
        <label>Date of separation :</label>
        <DateSelector
          value={sDate}
          setValue={setDate}
          disablePast={true}
          handleDateChange={handleDateChange}
        />
      </div>

      <div className="forms-input">
        <label>Notes :</label>
        <textarea
          name="notes"
          placeholder="Notes"
          value={notes}
          onChange={handleChange}
        />
      </div>
    </AddEditBox>
  );
};
