import { USER_ACTIONS } from "./actions";

const initialState = {
  users: [
    {
      email: "hetal@test.com",
      password: "test@123",
      firstName: "Hetal",
      lastName: "User",
      phone: "9898989898",
      dob: "27-03-2000",
      gender: "female",
      image: "blob:http://localhost:3000/9e7ce90a-0ac7-458f-ab02-6932d4d29182",
      agreeTerms: true,
      rememberMe: false,
      loginStatus: false,
    },
  ],
};

export const userReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case USER_ACTIONS.SET_USER:
      return { ...state, users: [...state.users, payload] };

    default:
      return state;
  }
};
