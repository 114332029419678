import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import moment from "moment";
import { indigo } from "@mui/material/colors";
import { useEffect, useState } from "react";
import { AddEditCol } from "../../../../../addEditPopup/AddEditCol";
import { AddEditBox } from "../../../../../addEditPopup/AddEditBox";

export const AcceptPreHireForm = ({
  toggleForm,
  openForm,
  applicantId,
  selectedSlots,
  acceptPhysicalPPD,
  isSubmitting,
}) => {
  const [slots, setSlots] = useState([]);
  const [error, setError] = useState(null);
  const [value, setValue] = useState(selectedSlots[0]);

  useEffect(() => {
    setSlots(selectedSlots);
  }, [selectedSlots]);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (typeof value === "object" && !value?.isAcceptedSlots) {
      setError("Please select a slot.");
      return;
    }
    acceptPhysicalPPD(value);
  };

  const handleChange = (e) => {
    setValue(e.target.value);
    setError(null);
  };

  return (
    <AddEditBox
      openForm={openForm}
      toggleForm={toggleForm}
      title="Accept PreHire Form"
      handleSubmit={handleSubmit}
      applicantId={applicantId}
      isSubmitting={isSubmitting}
    >
      <FormControl>
        <RadioGroup
          aria-labelledby="demo-controlled-radio-buttons-group"
          name="controlled-radio-buttons-group"
          value={value}
          onChange={handleChange}
          className="ml-10"
        >
          {slots.map((slot, index) => (
            <FormControlLabel
              key={index}
              value={slot.slots}
              control={
                <Radio
                  style={{
                    color: indigo[800],
                    "&.Mui-checked": {
                      color: indigo[600],
                    },
                  }}
                />
              }
              label={moment(slot.slots).format("h:mm a, MMM Do, YYYY")}
            />
          ))}
        </RadioGroup>
        <span className="error_msg">{error}</span>
      </FormControl>
    </AddEditBox>
  );
};
