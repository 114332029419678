import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import rootReducer from "./reducers";

import { persistStore, persistReducer } from "redux-persist";
// import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import storage from "redux-persist-indexeddb-storage";

const isProduction = process.env.NODE_ENV === "production";

const persistConfig = {
  key: "root",
  storage: storage("myDB"),
};

const composeEnhancer = composeWithDevTools({
  trace: true,
  traceLimit: 25,
});

const bindMiddleware = (middleware) =>
  !isProduction
    ? composeEnhancer(applyMiddleware(...middleware))
    : applyMiddleware(...middleware);

const persistedReducer = persistReducer(persistConfig, rootReducer);
let store = createStore(persistedReducer, bindMiddleware([thunk]));
let persistor = persistStore(store);
export { store, persistor };
