import * as React from "react";
import PropTypes from "prop-types";
import DialogTitle from "@mui/material/DialogTitle";
import { ButtonUI } from "../../ui/button";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import { useState, useEffect, useRef } from "react";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { Tooltip } from "@mui/material";

export const URLDialogbox = (props) => {
  const { onClose, value: valueProp, open, url, jobCode, ...other } = props;
  const [value, setValue] = useState(valueProp);
  const [iconTitle, setIconTitle] = useState("Copy URL");
  const radioGroupRef = useRef(null);

  useEffect(() => {
    if (!open) {
      setValue(valueProp);
    }
  }, [valueProp, open]);

  useEffect(() => {
    if (iconTitle === "Copied to Clipboard") {
      setTimeout(() => {
        setIconTitle("Copy URL");
      }, 1500);
    }
  }, [iconTitle]);

  const handleEntering = () => {
    if (radioGroupRef.current != null) {
      radioGroupRef.current.focus();
    }
  };

  const handleCancel = () => {
    onClose();
  };

  const handleOk = () => {
    onClose(value);
  };

  return (
    <Dialog
      sx={{ "& .MuiDialog-paper": { width: "80%", maxHeight: 435 } }}
      maxWidth="sm"
      TransitionProps={{ onEntering: handleEntering }}
      open={open}
      {...other}
    >
      <DialogTitle>
        <strong>Job application URL</strong>
      </DialogTitle>
      <DialogContent dividers>
        <p>Please find below url to apply for the job :</p>
        <div className="p-content border">
          <span>{window.location.origin + "/apply-job/" + jobCode}</span>
          <div>
            <Tooltip title={iconTitle}>
              <ContentCopyIcon
                className="pointer"
                onClick={() => {
                  setIconTitle("Copied to Clipboard");
                  navigator.clipboard.writeText(url);
                }}
              />
            </Tooltip>
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <ButtonUI
          p="12px"
          className="mr-10"
          variant="outlined"
          onClick={handleCancel}
        >
          Close
        </ButtonUI>
      </DialogActions>
    </Dialog>
  );
};

URLDialogbox.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  value: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  jobCode: PropTypes.string.isRequired,
};
