import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import "../../../assets/css/common/_side-bar.css";
import Api from "../../../helper/Api";
import { userAsCompany } from "../../../helper/constants";
import {
  DashboardIcon,
  CompanyIcon,
  UsersIcon,
  ReportsIcon,
  SettingsIcon,
  HotlistIcon,
  HireIcon,
  CalendarIcon,
  OutlinedSuitcaseIcon,
} from "../../../icons";
import { useDispatch } from "react-redux";
import { APPLICANT_DATA_ACTIONS } from "../../../redux/applicantData/actions";
import { JOB_DATA_ACTIONS } from "../../../redux/jobData/actions";
import { SEARCH_ACTIONS } from "../../../redux/search/actions";

export const Sidebar = () => {
  const [companyInfo, setCompanyInfo] = useState({});
  const { companyData } = useSelector((state) => state.companyDataReducer);
  const dispatch = useDispatch();

  useEffect(() => {
    {
      userAsCompany(window.location.hostname) &&
        Api(
          "GET",
          `Companies/GetCompanyById?companyId=${companyData.companyId}`
        )
          .then((response) => {
            if (response.data) {
              setCompanyInfo(response.data);
              localStorage.setItem(
                "companyLogoPath",
                response.data.companyImage
              );
              localStorage.setItem("companyName", response.data.name);
            } else {
              setCompanyInfo({});
            }
          })
          .catch((error) => {
            console.log(error);
          });
    }
  }, []);

  const removeAppData = () => {
    dispatch({
      type: APPLICANT_DATA_ACTIONS.SET_APPLICANTS_ARRAY,
      payload: [],
    });

    dispatch({
      type: JOB_DATA_ACTIONS.SET_JOBS_ARRAY,
      payload: [],
    });

    dispatch({
      type: SEARCH_ACTIONS.SET_SEARCH_FLAG,
      payload: false,
    });
  };

  return (
    <div
      className={
        userAsCompany(window.location.hostname)
          ? "sidebar company-active"
          : "sidebar"
      }
    >
      {userAsCompany(window.location.hostname) ? (
        <div className="sidebar-header">
          <img
            src={
              companyInfo.companyImage
                ? companyInfo.companyImage
                : require("../../../assets/images/dummy-img.png")
            }
            alt="logo"
          />
          <p className="company-names" title={companyInfo.name}>
            {companyInfo.name}
          </p>
        </div>
      ) : null}

      <div className="sidebar-content">
        <div className="dashboard">
          {userAsCompany(window.location.hostname) ? (
            <NavLink
              to="/dashboard_new"
              className={(navData) => (navData.isActive ? "active" : "")}
            >
              <DashboardIcon width="24" height="24" className="sidebar-icon" />
              <span className="dashboard_text">Dashboard</span>
            </NavLink>
          ) : (
            <NavLink
              to="/dashboard"
              className={(navData) => (navData.isActive ? "active" : "")}
            >
              <DashboardIcon width="24" height="24" className="sidebar-icon" />
              <span className="dashboard_text">Dashboard</span>
            </NavLink>
          )}
        </div>

        {userAsCompany(window.location.hostname) ? (
          <div className="dashboard">
            <NavLink
              to="/all-jobs"
              className={(navData) => (navData.isActive ? "active" : "")}
              onClick={() => removeAppData()}
            >
              <OutlinedSuitcaseIcon
                width="24"
                height="24"
                className="sidebar-icon"
              />
              <span className="dashboard_text">Jobs</span>
            </NavLink>
          </div>
        ) : null}

        {userAsCompany(window.location.hostname) ? (
          <div className="dashboard">
            <NavLink
              to="/applicants"
              state={companyData}
              className={(navData) => (navData.isActive ? "active" : "")}
              onClick={() => removeAppData()}
            >
              <UsersIcon width="24" height="24" className="sidebar-icon" />
              <span className="dashboard_text">Applicants</span>
            </NavLink>
          </div>
        ) : null}

        {userAsCompany(window.location.hostname) ? (
          <div className="dashboard">
            <NavLink
              to="/hot-list"
              state={companyData}
              className={(navData) => (navData.isActive ? "active" : "")}
              onClick={() => removeAppData()}
            >
              <HotlistIcon width="24" height="24" className="sidebar-icon" />
              <span className="dashboard_text">Hot List</span>
            </NavLink>
          </div>
        ) : null}

        {userAsCompany(window.location.hostname) ? (
          <div className="dashboard">
            <NavLink
              to="/calendar"
              className={(navData) => (navData.isActive ? "active" : "")}
              onClick={() => removeAppData()}
            >
              <CalendarIcon width="24" height="24" className="sidebar-icon" />
              <span className="dashboard_text">Calendar</span>
            </NavLink>
          </div>
        ) : null}

        {userAsCompany(window.location.hostname) ? (
          <div className="dashboard">
            <NavLink
              to="/hires"
              state={companyData}
              className={(navData) => (navData.isActive ? "active" : "")}
              onClick={() => removeAppData()}
            >
              <HireIcon width="24" height="24" className="sidebar-icon" />
              <span className="dashboard_text">Hires</span>
            </NavLink>
          </div>
        ) : null}

        {userAsCompany(window.location.hostname) ? null : (
          <div className="dashboard">
            <NavLink
              to="/companylist"
              className={(navData) => (navData.isActive ? "active" : "")}
            >
              <CompanyIcon
                width="24"
                height="24"
                fill="#C7C7D2"
                className="sidebar-icon"
              />
              <span className="dashboard_text">Companies</span>
            </NavLink>
          </div>
        )}

        {userAsCompany(window.location.hostname) ? (
          <div className="dashboard">
            <NavLink
              to="/all-users"
              className={(navData) => (navData.isActive ? "active" : "")}
            >
              <UsersIcon width="24" height="24" className="sidebar-icon" />
              <span className="dashboard_text">Users</span>
            </NavLink>
          </div>
        ) : null}

        {/* Temporary hidden the Users option for Admin */}
        {userAsCompany(window.location.hostname) ? null : (
          <div className="dashboard">
            <NavLink
              to="/usersdetail"
              className={(navData) => (navData.isActive ? "active" : "")}
              onClick={() => removeAppData()}
            >
              <UsersIcon width="24" height="24" className="sidebar-icon" />
              <span className="dashboard_text">Users</span>
            </NavLink>
          </div>
        )}

        {userAsCompany(window.location.hostname) && (
          <div className="dashboard">
            <NavLink
              to="/reports"
              className={(navData) => (navData.isActive ? "active" : "")}
              onClick={() => removeAppData()}
            >
              <ReportsIcon width="24" height="24" className="sidebar-icon" />
              <span className="dashboard_text">Reports</span>
            </NavLink>
          </div>
        )}

        {userAsCompany(window.location.hostname) ? null : (
          <div className="dashboard">
            <NavLink
              to="/settings"
              className={(navData) => (navData.isActive ? "active" : "")}
            >
              <SettingsIcon width="24" height="24" className="sidebar-icon" />
              <span className="dashboard_text">Settings</span>
            </NavLink>
          </div>
        )}
      </div>
    </div>
  );
};
