export const SearchIcon = ({ ...props }) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 -3 18 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6.8401 0.599976C8.49505 0.599976 10.0822 1.2574 11.2524 2.42763C12.4227 3.59786 13.0801 5.18502 13.0801 6.83998C13.0801 8.38558 12.5137 9.80638 11.5825 10.9008L11.8417 11.16H12.6001L17.4001 15.96L15.9601 17.4L11.1601 12.6V11.8416L10.9009 11.5824C9.8065 12.5136 8.3857 13.08 6.8401 13.08C5.18515 13.08 3.59798 12.4226 2.42775 11.2523C1.25752 10.0821 0.600098 8.49493 0.600098 6.83998C0.600098 5.18502 1.25752 3.59786 2.42775 2.42763C3.59798 1.2574 5.18515 0.599976 6.8401 0.599976ZM6.8401 2.51998C4.4401 2.51998 2.5201 4.43998 2.5201 6.83998C2.5201 9.23998 4.4401 11.16 6.8401 11.16C9.2401 11.16 11.1601 9.23998 11.1601 6.83998C11.1601 4.43998 9.2401 2.51998 6.8401 2.51998Z"
        {...props}
      />
    </svg>
  );
};
