import { useState, useEffect } from "react";
import { CheckboxList } from "../../../../common/layouts/CheckboxList";
import { useSelector } from "react-redux";
import Api from "../../../../../helper/Api";
import { toast } from "react-toastify";
import { ColoredLinearProgress } from "../../../../common/coloredProgressBar";

export const CheckListItems = ({ getJobTempById }) => {
  const [allData, setAllData] = useState([]);
  const { companyData } = useSelector((state) => state.companyDataReducer);
  const [isCheckedAll, setAllChecked] = useState(true);
  const hasSelectAll = true;
  const [isLoader, setIsLoader] = useState(false);
  const selectedData = useSelector(
    (state) => state.jobTemplateReducer.jobTemplateData.checklistItems
  );

  useEffect(() => {
    setIsLoader(true);
    Api(
      "GET",
      `Checklist/GetCheckList?companyId=${companyData.companyId}&companyHirerchy=${companyData.companyHirerchy}`
    )
      .then((res) => {
        res.data.map((d) => {
          d.checked = selectedData.includes(d.checklisttId) ? true : false;
          d.id = d.checklisttId;
        });
        setAllData(res.data);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsLoader(false);
      });
  }, []);

  const selectAll = () => {
    setAllChecked(!isCheckedAll);
    allData.map((d) => {
      d.checked = isCheckedAll;
    });
  };

  const handleSubmit = (data) => {
    setIsLoader(true);
    let selected = [];
    data.filter(function (record) {
      if (record.checked === true) {
        selected.push(record.checklisttId);
      }
    });

    const params = {
      companyId: companyData.companyId,
      jobTemplateId: companyData.data.jobTemplateId,
      ids: selected,
      enumSourceType: 2, // Checklist Items
    };

    Api("POST", "JobTemplate/SaveJobTemplateDetails", params)
      .then((res) => {
        if (res.data.isSuccess === true) {
          getJobTempById();
          toast.success(res.data.message);
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsLoader(false);
      });
  };

  return (
    <div>
      <div className="top-header-user">
        <span className="main-name">
          Checklist Items {"(" + Object.keys(allData).length + ")"}
        </span>
        <span className="blue-t pointer" onClick={() => selectAll()}>
          Select all
        </span>
      </div>
      {isLoader === true ? (
        <ColoredLinearProgress />
      ) : (
        <div className="main_content">
          <CheckboxList
            allData={allData}
            setAllData={setAllData}
            handleSubmit={handleSubmit}
            hasSelectAll={hasSelectAll}
            setAllChecked={setAllChecked}
          />
        </div>
      )}
    </div>
  );
};
