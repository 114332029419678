import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import { indigo } from "@mui/material/colors";
import moment from "moment";
import { useState } from "react";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import Api from "../../../../../../../helper/Api";
import { ButtonUI } from "../../../../../../ui/button";
import Loader from "../../../../../Loader";

export const ViewPhysicalPPD = () => {
  const location = useLocation();
  const [physicalPpdData, setPhysicalPpdData] = useState([]);
  const [suggestedSlots, setSuggestedSlots] = useState([]);
  const preHireRequestId = location.pathname.substring(
    location.pathname.lastIndexOf("/") + 1
  );
  const [value, setValue] = useState();
  const [comment, setComment] = useState("");
  const [loading, setLoading] = useState(true);
  const [isSubmitted, setSubmitted] = useState(true);

  useEffect(() => {
    getPreHireDetails();
  }, []);

  const getPreHireDetails = () => {
    Api(
      "GET",
      `Job/GetPhysicalPPDRequestData?preHireRequestId=${preHireRequestId}`
    )
      .then((res) => {
        setPhysicalPpdData(res?.data?.data);
        setSuggestedSlots(res?.data?.data?.suggestedSlots);
        const data = [...res?.data?.data?.suggestedSlots];
        const accepted = data.every((d) => d.isAcceptedSlots === false);
        setSubmitted(accepted);
        setValue(res?.data?.data?.suggestedSlots[0]?.slots);
      })
      .finally(() => setLoading(false));
  };

  const handleChange = (e) => {
    setValue(e.target.value);
  };

  const acceptOrDecline = (flag) => {
    setLoading(true);
    const params = {
      preHireRequestId: preHireRequestId,
      acceptedSlot: value,
      isAccept: flag,
      comment: comment,
    };

    Api("POST", `Job/AcceptDeclinePhysicalPPDRequest`, params)
      .then((res) => {
        if (res.data.isSuccess === true) {
          toast.success(res.data.message);
          getPreHireDetails();
        } else {
          toast.error(res.data.message);
        }
      })
      .finally(() => setLoading(false));
  };

  const handleCommentChange = (e) => {
    setComment(e.target.value);
  };

  return (
    <div>
      {loading ? (
        <Loader inShow={true} />
      ) : (
        <div>
          <div className="white-container">
            <div className="mb-10">
              <img
                src={require("../../../../../../../assets/images/logo.png")}
                width="125px"
              />
            </div>
            <div className="physical-request-container">
              <div>{physicalPpdData.heading}</div>
              <div className="doc-section"></div>
              <div className="apt-container">
                <span>
                  <strong>Appointment Type : </strong>
                  {physicalPpdData.appointmentType}
                </span>
                <span>
                  <strong>Duration : </strong> {physicalPpdData.duration}
                </span>
                <span>
                  <strong>Location : </strong> {physicalPpdData.location}
                </span>
                <span>
                  <strong>Notes/Instructions : </strong>{" "}
                  {physicalPpdData.instructions}
                </span>
                <span>
                  <strong>Participant(s) : </strong>{" "}
                  {physicalPpdData.participants.join(", ")}
                </span>
                <div>
                  <strong>Suggested Dates & Times : </strong>{" "}
                  <div className="avail-slots">
                    <FormControl>
                      <RadioGroup
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="controlled-radio-buttons-group"
                        value={value}
                        onChange={handleChange}
                        className="ml-10 mt-10"
                      >
                        {suggestedSlots.map((slot, index) => (
                          <FormControlLabel
                            key={index}
                            value={slot.slots}
                            control={
                              <Radio
                                style={{
                                  color: indigo[800],
                                  "&.Mui-checked": {
                                    color: indigo[600],
                                  },
                                }}
                              />
                            }
                            label={moment(slot.slots).format(
                              "h:mm a, MMM Do, YYYY"
                            )}
                          />
                        ))}
                      </RadioGroup>
                    </FormControl>
                  </div>
                </div>
                {suggestedSlots.length > 0 && isSubmitted === true && (
                  <>
                    <strong>Comments:</strong>
                    <div className="forms-input">
                      <textarea
                        name="comment"
                        value={comment}
                        onChange={handleCommentChange}
                      />
                    </div>
                    <div className="d-flex justify-content-center">
                      <ButtonUI
                        p="8px"
                        mt="0px"
                        onClick={() => acceptOrDecline(true)}
                      >
                        Accept
                      </ButtonUI>
                      <ButtonUI
                        p="8px"
                        ml="10px"
                        mt="0px"
                        variant="outlined"
                        onClick={() => acceptOrDecline(false)}
                      >
                        Decline
                      </ButtonUI>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
