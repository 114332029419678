import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { StyledPagination } from "../../ui/pagination/pagination";
import "../../../assets/css/components/company-detail/_appointments.css";
import Api from "../../../helper/Api";
import { reasonPageLimit } from "../../../helper/constants";
import { toast } from "react-toastify";
import Loader from "../../common/Loader";
import NoRecordsFound from "../../common/NoRecordsFound";
import { StyledTableCell, StyledTableRow } from "../../ui/table/table";
import { ButtonUI } from "../../ui/button";
import AddIcon from "@mui/icons-material/Add";
import { EditDeleteMenu } from "../../ui/editDeleteMenu";
import { AlertBox } from "../../../helper/utils";
import { AddEditFacility } from "./addEditFacility";

let originalRows = [];

export function Facilities() {
  const location = useLocation();
  const [rows, setRows] = useState(originalRows);
  const [searchInput, setSearchInput] = useState("");
  const [showSideBar, setShowSideBar] = useState(false);
  const [showAddEditLabel, setAddEditLabel] = useState("");
  const [facilityId, setFacilityId] = useState(0);
  const initialValues = {
    facilityName: "",
    job_number_prefix: "",
    timezone: "",
    address: "",
    zipCode: "",
  };
  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [totalPage, setTotalPage] = useState(1);
  const [page, setPage] = useState(1);
  const [orderByDirection, setSortedDirection] = useState("desc");
  const [isLoader, setIsLoader] = useState(false);
  const [allTimeZone, setAllTimeZone] = useState([]);

  // Get timezone dropdown data
  const getTimeZone = () => {
    Api("GET", "TimeZone/GetTimeZone?isActive=true")
      .then((response) => {
        if (response.data) {
          setAllTimeZone(response.data);
          setIsLoader(false);
        } else {
          setAllTimeZone([]);
          setIsLoader(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleSearch = (event) => {
    setSearchInput(event.target.value);
  };

  React.useEffect(() => {
    if (searchInput !== "") {
      const filteredData = originalRows.filter((company) => {
        return company.facility_name
          .toLowerCase()
          .includes(searchInput.toLowerCase());
      });
    } else {
      setIsLoader(true);
      getFacilities("desc", "updatedAt", searchInput);
      getTimeZone();
    }
  }, [searchInput, page]);

  const getFacilities = (sortType, sortColumnName, searchText) => {
    Api(
      "GET",
      `Facilities/GetFacilitiesList?companyId=${location.state.companyId}&companyHirerchy=${location.state.companyHirerchy}&pageLimit=${reasonPageLimit}&pageNumber=${page}&searchText=${searchText}&OrderBy=createdAt&OrderbyDirection=${sortType}`
    )
      .then((res) => {
        setIsLoader(false);
        setRows(res.data.data);
        setTotalPage(res.data.totalPage);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      let rowUpdated = [];
      if (editMode) {
        rowUpdated = rows.map((items) => {
          return items.id === showSideBar
            ? Object.assign(items, {
              facility_name: formValues.facilityName,
              job_number_prefix: formValues.job_number_prefix,
              timezone: formValues.timezone,
              address: formValues.address,
              zipCode: formValues.zipCode,
            })
            : items;
        });
      } else {
        const maxId = [];
        rows.map((items) => {
          return maxId.push(items.id);
        });
        rowUpdated = originalRows.push({
          id: Math.max(...maxId) + 1,
          facility_name: formValues.facilityName,
          job_number_prefix: formValues.job_number_prefix,
          timezone: formValues.timezone,
          address: formValues.address,
          zipCode: formValues.zipCode,
        });
      }

      setRows(originalRows);
      setShowSideBar(false);
      setEditMode(false);
      setFormValues("");
    }
  }, [formErrors]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormErrors(validate(formValues));

    if (Object.keys(validate(formValues)).length > 0) {
      return;
    }

    const params = {
      companyId: location.state.companyId,
      isActive: true,
      name: formValues.facilityName,
      address: formValues.address,
      zipCode: formValues.zipCode,
      jobNumberPrefix: formValues.job_number_prefix,
      timeZone: formValues.timezone,
    };

    if (editMode == true) {
      params.facilitiesId = facilityId;
      Api("PUT", "Facilities/EditFacilities", params)
        .then((res) => {
          if (res.data.isSuccess == true) {
            getFacilities("desc", "createdAt", searchInput);
            toast.success(res.data.message);
            toggleDrawer("right", false);
          } else {
            toast.error(res.data.message);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      Api("POST", "Facilities/AddFacilities", params)
        .then((res) => {
          if (res.data.isSuccess == true) {
            getFacilities("desc", "updatedAt", searchInput);
            toast.success(res.data.message);
            toggleDrawer("right", false);
          } else {
            toast.error(res.data.message);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const addDataTable = (e) => {
    setFormValues("");
    formErrorClear();
    setAddEditLabel("Add Facilities");
    setEditMode(false);
    toggleDrawer("right", true);
  };

  const toggleDrawer = (anchor, open) => {
    setShowSideBar({ ...showSideBar, [anchor]: open });
  };

  const errors = {};
  const validate = (values) => {
    if (!values.facilityName) {
      errors.facilityName = "Name is required.";
    } else if (!isNaN(values.facilityName)) {
      errors.facilityName = "Name must be a text!";
    }

    if (!values.job_number_prefix) {
      errors.job_number_prefix = "Job Number Prefix is required.";
    } else if (!isNaN(values.duration)) {
      errors.job_number_prefix = "Job Number Prefix must be a text!";
    }

    if (!values.timezone) {
      errors.timezone = "Timezone is required.";
    } else if (!isNaN(values.timezone)) {
      errors.timezone = "Timezone must be a text!";
    }

    if (!values.address) {
      errors.address = "Address is required.";
    } else if (!isNaN(values.address)) {
      errors.address = "Address must be a text!";
    }

    if (!values.zipCode) {
      errors.zipCode = "Zipcode is required.";
    }

    return errors;
  };

  const formErrorClear = () => {
    formErrors.facilityName = "";
    formErrors.job_number_prefix = "";
    formErrors.timezone = "";
    formErrors.address = "";
    formErrors.zipCode = "";
  };

  const closeSideBar = () => {
    setFormValues("");
    formErrorClear();
    setShowSideBar(false);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      getSearchFacility();
    }
  };

  const getSearchFacility = () => {
    if (!searchInput) {
      window.alert("Please Enter Search Value!");
    } else {
      setIsLoader(true);
      getFacilities("desc", "updatedAt", searchInput);
    }
  };

  const handleSort = (key) => {
    let sortOrder = orderByDirection === "desc" ? "asc" : "desc";
    setSortedDirection(sortOrder);
    getFacilities(sortOrder, key, searchInput);
  };

  // pagination
  const handlePageChange = (event, value) => {
    setPage(value);
  };

  function handleEdit(facilitiesId) {
    // Edit button clicked in - popup
    setEditMode(true);
    setAddEditLabel("Edit Facilities");
    setFacilityId(facilitiesId);

    const listItemValue = rows.filter((items) => {
      return items.facilitiesId === facilitiesId ? items : null;
    });
    setFormValues({
      ["facilityName"]: listItemValue[0].name,
      ["job_number_prefix"]: listItemValue[0].jobNumberPrefix,
      ["timezone"]: listItemValue[0].timeZone,
      ["address"]: listItemValue[0].address,
      ["zipCode"]: listItemValue[0].zipCode,
    });
    // setShowSideBar(facilitiesId);
    toggleDrawer("right", true);
  }

  function handleDelete(facilitiesId) {
    // Alert box to delete facilities
    AlertBox().then((res) => {
      if (!res.isConfirmed) return;
      // Delete facilities api call
      Api("DELETE", `Facilities/DeleteFacilities?facilitiesId=${facilitiesId}`)
        .then((res) => {
          if (res.data.isSuccess == true) {
            toast.success(res.data.message);
            getFacilities("desc", "updatedAt", searchInput);
          } else {
            toast.error(res.data.message);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    });
  }

  function Row(props) {
    const { row } = props;
    const [open, setOpen] = React.useState(false);

    return (
      <React.Fragment>
        <StyledTableRow sx={{ "& > *": { borderBottom: "unset" } }}>
          <StyledTableCell component="th" scope="row" align="left">
            {row.name ? row.name : "--"}
          </StyledTableCell>
          <StyledTableCell align="center" width="150px">
            {row.jobNumberPrefix}
          </StyledTableCell>
          <StyledTableCell align="center" width="200px">
            {row.timeZone}
          </StyledTableCell>
          <StyledTableCell align="center" width="70px">
            <div className="position-relative">
              {location.state.companyId === row.companyId &&
                <EditDeleteMenu
                  id={row.facilitiesId}
                  onEdit={handleEdit}
                  onDelete={handleDelete}
                />
              }
            </div>
          </StyledTableCell>
          <StyledTableCell width="50px" align="right">
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? (
                <KeyboardArrowUpIcon color="primary" />
              ) : (
                <KeyboardArrowDownIcon color="action" />
              )}
            </IconButton>
          </StyledTableCell>
        </StyledTableRow>
        <StyledTableRow>
          <StyledTableCell
            style={{ paddingBottom: 0, paddingTop: 0 }}
            colSpan={6}
          >
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 1 }}>
                <div>
                  <p className="notes-table">Address</p>
                  <p className="content-table">{row.address}</p>
                </div>
              </Box>
            </Collapse>
          </StyledTableCell>
        </StyledTableRow>
      </React.Fragment>
    );
  }

  return (
    <div>
      {showSideBar &&
        <AddEditFacility
          showSideBar={showSideBar}
          formErrors={formErrors}
          formValues={formValues}
          showAddEditLabel={showAddEditLabel}
          setFormValues={setFormValues}
          toggleDrawer={toggleDrawer}
          handleSubmit={handleSubmit}
          allTimeZone={allTimeZone}
        />
      }

      <div className="common-container users">
        <div className="inner-container">
          <Loader inShow={isLoader} />
          <div className="top-url">
            <p>
              <Link to="/companylist"> Companies &gt; </Link>
              <strong>Facilities {"(" + Object.keys(rows).length + ")"}</strong>
            </p>
          </div>
          <div className="manage-container jobs">
            <div className="manage-bg active">
              <div className="parent-container-d">
                <div className="top-search">
                  <div className="company_search">
                    <img
                      src={require("../../../assets/images/search_icon.png")}
                      alt="search-icon"
                      className="search_img"
                    />
                    <input
                      type="text"
                      placeholder="Search Facilities"
                      className="search_input appointments"
                      name="search_input"
                      value={searchInput}
                      onChange={handleSearch}
                      onKeyPress={handleKeyPress}
                    />
                    <button onClick={getSearchFacility} className="search-btn">
                      {" "}
                      Search{" "}
                    </button>
                  </div>
                  <ButtonUI
                    p="12px"
                    onClick={addDataTable}
                    leftIcon={
                      <AddIcon sx={{ height: "16px", width: "16px" }} />
                    }
                  >
                    Add Facilities
                  </ButtonUI>
                </div>
                <div className="manage-checkboxes-none specific-facilities">
                  {rows.length > 0 ? (
                    <TableContainer
                      component={Paper}
                      className="box-shadow-none t-bg"
                    >
                      <Table aria-label="collapsible table">
                        <TableHead>
                          <TableRow>
                            <StyledTableCell align="left">
                              Facility Name{" "}
                              <img
                                src={
                                  require("../../../assets/images/tabsort.svg")
                                    .default
                                }
                                alt="sort-icon"
                                className="cursor-pointer"
                                onClick={() => handleSort("name")}
                              />
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              Job Number Prefix:{" "}
                              <img
                                src={
                                  require("../../../assets/images/tabsort.svg")
                                    .default
                                }
                                alt="sort-icon"
                                className="cursor-pointer"
                                onClick={() => handleSort("jobNumberPrefix")}
                              />
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              Timezone:{" "}
                              <img
                                src={
                                  require("../../../assets/images/tabsort.svg")
                                    .default
                                }
                                alt="sort-icon"
                                className="cursor-pointer"
                                onClick={() => handleSort("timeZone")}
                              />
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              Action
                            </StyledTableCell>
                            <StyledTableCell />
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {rows.map((row) => (
                            <Row key={row.facilitiesId} row={row} />
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  ) : (
                    <NoRecordsFound />
                  )}
                </div>
                {rows.length > 0 && (
                  <StyledPagination
                    count={totalPage}
                    page={page}
                    variant="outlined"
                    shape="rounded"
                    onChange={handlePageChange}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
