import { JOB_DATA_ACTIONS } from "./actions";

const initialState = {
  jobData: [],
  jobAllStatus: [],
  jobCycleStatus: "NEW_APPLICATION",
  jobsArray: [],
  isRefresh: false,
  allJobs: {
    allJobsList: [],
    jobsList: [],
    activeJobs: [],
    hiringJobs: [],
    pendingJobs: [],
    filledJobs: [],
    holdJobs: [],
    cancelledJobs: [],
  },
};

export const jobDataReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case JOB_DATA_ACTIONS.SET_JOB_DATA:
      return { ...state, jobData: payload };

    case JOB_DATA_ACTIONS.SET_ALL_JOB_STATUS:
      return { ...state, jobAllStatus: payload };

    case JOB_DATA_ACTIONS.SET_JOB_CYCLE_STATUS:
      return { ...state, jobCycleStatus: payload };

    case JOB_DATA_ACTIONS.SET_JOBS_ARRAY:
      return { ...state, jobsArray: payload };

    case JOB_DATA_ACTIONS.SET_REFRESH:
      if (payload) {
        const updateJobIndex = state.jobsArray.findIndex(
          (job) => job.jobId === payload?.jobId
        );
        if (updateJobIndex !== -1) state.jobsArray[updateJobIndex] = payload;
      }
      return {
        ...state,
        isRefresh: !state.isRefresh,
        jobsArray: state.jobsArray,
      };

    case JOB_DATA_ACTIONS.SET_JOBS:
      return {
        ...state,
        allJobs: { ...state.allJobs, [payload.name]: payload.value },
      };
    case JOB_DATA_ACTIONS.SET_ALL_JOBS:
      return {
        ...state,
        allJobs: { ...state.allJobs, allJobsList: payload, jobsList: payload },
      };
    case JOB_DATA_ACTIONS.DELETE_ALL_JOBS: {
      return {
        ...state,
        allJobs: {
          allJobsList: [],
          jobsList: [],
          activeJobs: [],
          hiringJobs: [],
          pendingJobs: [],
          filledJobs: [],
          holdJobs: [],
          cancelledJobs: [],
        },
      };
    }

    default:
      return state;
  }
};
