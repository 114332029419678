import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import Api from "../../../helper/Api";
import { toast } from "react-toastify";
import { IconButton, Tooltip } from "@mui/material";
import { red } from "@mui/material/colors";

export default function CancelQuestionBox({
  jobApplicantVideoInterviewId,
  videoQuestionId,
}) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    const params = {
      jobApplicantVideoInterviewId: jobApplicantVideoInterviewId,
      videoQuestionId: videoQuestionId,
    };

    Api("POST", `Job/CancelledQuestion`, params).then((res) => {
      if (res.data.isSuccess === true) {
        toast.success(res.data.message);
        setOpen(false);
      } else {
        toast.error(res.data.message);
      }
    });
  };

  const closeForm = () => {
    setOpen(false);
  };

  return (
    <div>
      <Tooltip title="Cancel Question">
        <IconButton onClick={handleClickOpen}>
          <DeleteOutlinedIcon fontSize="small" sx={{ color: red[900] }} />
        </IconButton>
      </Tooltip>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Cancel this question?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to cancel this question?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeForm}>No</Button>
          <Button onClick={handleClose} autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
