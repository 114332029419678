import { AddEditPopup } from "../../../components/common/addEditPopup";

export const AddAdminUser = ({
  openSidebar,
  toggleDrawer,
  handleSubmit,
  formValues,
  setFormValues,
  formErrors,
  allTimeZone,
  editMode,
}) => {
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  return (
    <AddEditPopup
      openSidebar={openSidebar}
      toggleDrawer={toggleDrawer}
      handleSubmit={handleSubmit}
      title={editMode ? "Edit User" : "Add New User"}
      type="jobTemplate"
    >
      <div className="forms-input sections">
        <div className="col-2">
          <label> First Name</label>
          <input
            type="text"
            placeholder="First Name"
            name="firstName"
            id="firstName"
            value={formValues.firstName}
            onChange={handleChange}
          />
          <span className="error_msg">{formErrors?.firstName}</span>
        </div>
        <div className="col-2">
          <label>Middle Name</label>
          <input
            type="text"
            placeholder="Middle Name"
            name="middleName"
            id="middleName"
            value={formValues.middleName}
            onChange={handleChange}
          />
          <span className="error_msg">{formErrors?.middleName}</span>
        </div>
      </div>

      <div className="forms-input sections">
        <div className="col-2">
          <label>Last Name</label>
          <input
            type="text"
            placeholder="Last Name"
            name="lastName"
            id="lastName"
            value={formValues.lastName}
            onChange={handleChange}
          />
          <span className="error_msg">{formErrors?.lastName}</span>
        </div>
        <div className="col-2">
          <label>Nick Name</label>
          <input
            type="text"
            placeholder="Nick Name"
            name="nickName"
            id="nickName"
            value={formValues.nickName}
            onChange={handleChange}
          />
          <span className="error_msg">{formErrors?.nickName}</span>
        </div>
      </div>

      <div className="forms-input sections">
        <div className="col-2">
          <label>Title</label>
          <input
            type="text"
            placeholder="Title"
            name="user_title"
            id="user_title"
            value={formValues.user_title}
            onChange={handleChange}
          />
          <span className="error_msg">{formErrors?.user_title}</span>
        </div>
        <div className="col-2">
          <label>Login ID</label>
          <input
            type="text"
            placeholder="Login ID"
            name="loginId"
            id="loginId"
            value={formValues.loginId}
            onChange={handleChange}
          />
          <span className="error_msg">{formErrors?.loginId}</span>
        </div>
      </div>

      <div className="forms-input sections">
        <div className="col-2">
          <label>Email Address</label>
          <input
            type="text"
            placeholder="Email Address"
            name="emails"
            id="emails"
            value={formValues.emails}
            onChange={handleChange}
          />
          <span className="error_msg">{formErrors?.emails}</span>
        </div>
        <div className="col-2">
          <label>Cell Phone</label>
          <input
            type="text"
            placeholder="Cell Phone"
            name="phoneNumbers"
            id="phoneNumbers"
            value={formValues.phoneNumbers}
            onChange={handleChange}
          />
          <span className="error_msg">{formErrors?.phoneNumbers}</span>
        </div>
      </div>

      <div className="forms-input sections">
        <div className="col-2">
          <label>Timezone</label>
          <select
            name="timeZone"
            id="timeZone"
            value={formValues.timeZone}
            onChange={handleChange}
          >
            <option value="none">Select</option>
            {allTimeZone.map((time) => (
              <option key={time.id} value={time.timeZone}>
                {time.timeZone}
              </option>
            ))}
          </select>
          <span className="error_msg">{formErrors?.timeZone}</span>
        </div>
      </div>
    </AddEditPopup>
  );
};
