import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import TableRow from "@mui/material/TableRow";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import NoRecordsFound from "../../common/NoRecordsFound";
import { toast } from "react-toastify";
import Loader from "../../common/Loader";
import Api from "../../../helper/Api";
import "../../../assets/css/components/company-detail/_appointments.css";
import { StyledTableCell, StyledTableRow } from "../../ui/table/table";
import { EditDeleteMenu } from "../../ui/editDeleteMenu";
import { AlertBox, showSuccess } from "../../../helper/utils";

export function Appointments() {
  const location = useLocation();
  const [appointmentId, setAppointmentId] = useState(0);
  const [sortType, setSorted] = useState("desc");
  const [isLoader, setIsLoader] = useState(false);
  const [rows, setRows] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [edits, setEdits] = useState(false);
  const [showSideBar, setShowSideBar] = useState(false);
  const [showAddEditLabel, setAddEditLabel] = useState("");
  const initialValues = {
    name: "",
    duration: "",
    timeBetween: "",
    slotClose: "",
    note: "",
  };
  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [editMode, setEditMode] = useState(false);

  const pageLimit = 500;
  const pageNumber = 0;

  const getAppointmentsData = (sortType, sortColumnName, searchText) => {
    setIsLoader(true);
    Api(
      "GET",
      `Appointments/GetAppointmentsList?companyId=${location.state.companyId}&companyHirerchy=${location.state.companyHirerchy}&pageLimit=${pageLimit}&pageNumber=${pageNumber}&OrderBy=${sortColumnName}&OrderbyDirection=${sortType}&searchText=${searchText}`
    )
      .then((response) => {
        if (response.data.data.length) {
          setRows(response.data.data);
        } else {
          setRows([]);
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsLoader(false);
      });
  };

  useEffect(() => {
    if (searchInput == "") {
      getAppointmentsData("desc", "updatedAt", searchInput);
    }
  }, [searchInput]);

  const handleSearch = (event) => {
    setSearchInput(event.target.value);
  };

  const getSearchCompany = () => {
    if (!searchInput) {
      window.alert("Please Enter Search Value!");
    } else {
      getAppointmentsData("desc", "updatedAt", searchInput);
    }
  };

  function handleEdit(appointmentId) {
    setEditMode(true);
    setAddEditLabel("Edit Appointments");
    setAppointmentId(appointmentId);

    const listItemValue = rows.filter((items) => {
      return items.appointmentId === appointmentId ? items : null;
    });
    setFormValues({
      ["name"]: listItemValue[0].name,
      ["duration"]: listItemValue[0].duration,
      ["timeBetween"]: listItemValue[0].timeBetween,
      ["slotClose"]: listItemValue[0].slotClose,
      ["note"]: listItemValue[0].note,
    });
    setShowSideBar(appointmentId);
  }

  function handleDelete(appointmentId) {
    AlertBox().then((res) => {
      if (!res.isConfirmed) return;

      setIsLoader(true);
      // Delete facilities api call
      Api(
        "DELETE",
        `Appointments/DeleteAppointments?appointmentId=${appointmentId}`
      )
        .then((res) => {
          if (res.data.isSuccess == true) {
            showSuccess("Deleted!", res.data.message);
          } else {
            toast.error(res.data.message);
          }
          getAppointmentsData("desc", "updatedAt", "");
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setIsLoader(false);
        });
    });
  }

  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      getAppointmentsData("desc", "updatedAt", "");
      setShowSideBar(false);
      setEditMode(false);
      setFormValues("");
    }
  }, [formErrors]);

  const handleKeyPress = (e) => {
    if (e.key == "Enter") {
      getAppointmentsData("desc", "updatedAt", searchInput);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormErrors(validate(formValues));
    const params = {
      companyId: location.state.companyId,
      isActive: true,
      isDeleted: true,
      name: formValues.name,
      duration: formValues.duration,
      timeBetween: formValues.timeBetween,
      slotClose: formValues.slotClose,
      note: formValues.note,
    };

    if (Object.keys(validate(formValues)).length == 0) {
      setIsLoader(true);
      if (editMode) {
        params.appointmentId = appointmentId;
        Api("PUT", "Appointments/EditAppointments", params)
          .then((res) => {
            if (res.data.isSuccess == true) {
              rows.map((row) => {
                if (row.appointmentId == params.appointmentId) {
                  rows[rows.indexOf(row)] = Object.assign(params);
                }
              });
              setRows(rows);
              toast.success(res.data.message);
            } else {
              toast.error(res.data.message);
            }
            getAppointmentsData("desc", "updatedAt", "");
            setIsLoader(false);
          })
          .catch((err) => {
            console.log(err);
            setIsLoader(false);
          });
      } else {
        Api("POST", "Appointments/AddAppointments", params)
          .then((res) => {
            res.data.isSuccess == true
              ? toast.success(res.data.message)
              : toast.error(res.data.message);
            getAppointmentsData("desc", "updatedAt", "");
            setIsLoader(false);
          })
          .catch((err) => {
            console.log(err);
          });
      }
      setIsSubmit(true);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  function handleSort(columnType) {
    const sortOrder = sortType === "asc" ? "desc" : "asc";
    setSorted(sortOrder);
    getAppointmentsData(sortType, columnType, "");
  }

  const addDataTable = (e) => {
    setAddEditLabel("Add Appointments");
    setShowSideBar(true);
  };

  const errors = {};
  const validate = (values) => {
    if (!values.name) {
      errors.name = "Name is required.";
    } else if (!isNaN(values.name)) {
      errors.name = "Name must be a text!";
    }

    if (!values.duration) {
      errors.duration = "Duration is required.";
    } else if (isNaN(values.duration)) {
      errors.duration = "Duration must be a number!";
    }

    if (!values.timeBetween) {
      errors.timeBetween = "Time between is required.";
    } else if (isNaN(values.timeBetween)) {
      errors.timeBetween = "Time between must be a number!";
    }

    if (!values.slotClose) {
      errors.slotClose = "slot close is required.";
    } else if (isNaN(values.slotClose)) {
      errors.slotClose = "Slot close must be a number!";
    }

    if (!values.note) {
      errors.note = "Note is required.";
    } else if (!isNaN(values.note)) {
      errors.note = "Note must be a text!";
    }
    return errors;
  };

  const formErrorClear = () => {
    formErrors.name = "";
    formErrors.duration = "";
    formErrors.timeBetween = "";
    formErrors.slotClose = "";
    formErrors.note = "";
  };

  const closeSideBar = () => {
    setFormValues("");
    formErrorClear();
    setShowSideBar(false);
  };

  function Row(props) {
    const { row } = props;
    const [open, setOpen] = React.useState(false);

    return (
      <React.Fragment>
        <StyledTableRow sx={{ "& > *": { borderBottom: "unset" } }}>
          <StyledTableCell component="th" scope="row" align="left">
            {row.name}
          </StyledTableCell>
          <StyledTableCell align="center" width="150px">
            {row.duration}
          </StyledTableCell>
          <StyledTableCell align="center" width="150px">
            {row.timeBetween}
          </StyledTableCell>
          <StyledTableCell align="center" width="150px">
            {row.slotClose}
          </StyledTableCell>
          <StyledTableCell align="center" width="150px">
            <div className="position-relative">
              {location.state.companyId === row.companyId && (
                <EditDeleteMenu
                  id={row.appointmentId}
                  onEdit={handleEdit}
                  onDelete={handleDelete}
                />
              )}
            </div>
          </StyledTableCell>
          <StyledTableCell align="right" width="50px">
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => (setOpen(!open), setEdits(edits))}
            >
              {open ? (
                <KeyboardArrowUpIcon color="primary" />
              ) : (
                <KeyboardArrowDownIcon color="action" />
              )}
            </IconButton>
          </StyledTableCell>
        </StyledTableRow>
        <StyledTableRow>
          <StyledTableCell
            style={{ paddingBottom: 0, paddingTop: 0 }}
            colSpan={6}
          >
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 1 }}>
                <div>
                  <p className="notes-table">Notes</p>
                  <p className="content-table">{row.note}</p>
                </div>
              </Box>
            </Collapse>
          </StyledTableCell>
        </StyledTableRow>
      </React.Fragment>
    );
  }

  return (
    <div>
      {/* sidebar for add and edit data into the table */}
      {showSideBar ? <div className="back-shadow"></div> : null}
      {showSideBar ? (
        <div
          className={showSideBar ? "side-bar-parent active" : "side-bar-parent"}
        >
          <header>
            <span>{showAddEditLabel}</span>
            <img
              src={require("../../../assets/images/close.svg").default}
              alt="close-img"
              className="close-img"
              onClick={closeSideBar}
            />
          </header>
          <form onSubmit={handleSubmit} className="inner-container">
            <div className="in-forms">
              <div className="forms-input">
                <label>Appointments Name</label>
                <input
                  type="text"
                  placeholder="Name here"
                  name="name"
                  id="name"
                  value={formValues.name}
                  onChange={handleChange}
                />
                {formErrors.name ? (
                  <span className="error_msg">{formErrors.name}</span>
                ) : null}
              </div>
              <div className="forms-input">
                <label>Duration (Minutes)</label>
                <input
                  type="text"
                  placeholder="Type here"
                  name="duration"
                  id="duration"
                  value={formValues.duration}
                  onChange={handleChange}
                />
                {formErrors.duration ? (
                  <span className="error_msg">{formErrors.duration}</span>
                ) : null}
              </div>
              <div className="forms-input">
                <label>Time Between (Minutes)</label>
                <input
                  type="text"
                  placeholder="Type here"
                  name="timeBetween"
                  id="timeBetween"
                  value={formValues.timeBetween}
                  onChange={handleChange}
                />
                {formErrors.timeBetween ? (
                  <span className="error_msg">{formErrors.timeBetween}</span>
                ) : null}
              </div>
              <div className="forms-input">
                <label>Slot Close (Hours)</label>
                <input
                  type="text"
                  placeholder="Type here"
                  name="slotClose"
                  id="slotClose"
                  value={formValues.slotClose}
                  onChange={handleChange}
                />
                {formErrors.slotClose ? (
                  <span className="error_msg">{formErrors.slotClose}</span>
                ) : null}
              </div>
              <div className="forms-input">
                <label>Note</label>
                <textarea
                  name="note"
                  id="note"
                  cols="20"
                  rows="6"
                  value={formValues.note}
                  onChange={handleChange}
                ></textarea>
                {formErrors.note ? (
                  <span className="error_msg">{formErrors.note}</span>
                ) : null}
              </div>
            </div>
            <div className="btns-container">
              <input type="submit" className="add-btn" value="Submit" />
              <button
                className="cancel-btn"
                type="reset"
                onClick={() => (
                  setShowSideBar(false),
                  setFormValues(initialValues),
                  formErrorClear()
                )}
              >
                Cancel
              </button>
            </div>
          </form>
        </div>
      ) : null}
      <div className="common-container users">
        <div className="inner-container">
          <div className="top-url">
            <p>
              <Link to="/companylist"> Companies &gt; </Link>
              <strong>
                Appointments {"(" + Object.keys(rows).length + ")"}
              </strong>
            </p>
          </div>
          <div className="manage-container jobs">
            <div className="manage-bg active">
              <div className="parent-container-d">
                <div className="top-search">
                  <div className="company_search">
                    <img
                      src={require("../../../assets/images/search_icon.png")}
                      alt="search-icon"
                      className="search_img"
                    />
                    <input
                      type="text"
                      placeholder="Search Appointment"
                      className="search_input appointments"
                      name="search_input"
                      value={searchInput}
                      onChange={handleSearch}
                      onKeyPress={handleKeyPress}
                    />
                    <button onClick={getSearchCompany} className="search-btn">
                      Search
                    </button>
                  </div>
                  {/* <ButtonUI
                    p="12px"
                    onClick={addDataTable}
                    leftIcon={
                      <AddIcon sx={{ height: "16px", width: "16px" }} />
                    }
                  >
                    Add Appointment
                  </ButtonUI> */}
                </div>
                <div className="manage-checkboxes-none position-relative">
                  {rows.length > 0 ? (
                    <TableContainer
                      component={Paper}
                      className="box-shadow-none t-bg"
                    >
                      <Table aria-label="collapsible table" className="t-body">
                        <TableHead>
                          <TableRow>
                            <StyledTableCell align="left">
                              Appointments Name:{" "}
                              <img
                                src={
                                  require("../../../assets/images/tabsort.svg")
                                    .default
                                }
                                alt="sort-icon"
                                className="cursor-pointer"
                                onClick={() => handleSort("name")}
                              />
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              Duration:{" "}
                              <img
                                src={
                                  require("../../../assets/images/tabsort.svg")
                                    .default
                                }
                                alt="sort-icon"
                                className="cursor-pointer"
                                onClick={() => handleSort("duration")}
                              />
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              Time Between:{" "}
                              <img
                                src={
                                  require("../../../assets/images/tabsort.svg")
                                    .default
                                }
                                alt="sort-icon"
                                className="cursor-pointer"
                                onClick={() => handleSort("timeBetween")}
                              />
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              Slot Close:{" "}
                              <img
                                src={
                                  require("../../../assets/images/tabsort.svg")
                                    .default
                                }
                                alt="sort-icon"
                                className="cursor-pointer"
                                onClick={() => handleSort("slotClose")}
                              />
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              Action
                            </StyledTableCell>
                            <StyledTableCell />
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {rows.map((row) => (
                            <Row key={row.appointmentId} row={row} />
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  ) : isLoader ? (
                    <Loader inShow={true} />
                  ) : (
                    <NoRecordsFound />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
