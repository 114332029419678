import moment from "moment/moment";
import React, { forwardRef, useState } from "react";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const DatePicker = ({ handleChange }) => {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [sYear, setStartYear] = useState(new Date());
  const [eYear, setEndYear] = useState(new Date());
  const CustomInput = forwardRef(({ value, onClick }, ref) => (
    <div className="custom-date-input" onClick={onClick} ref={ref}>
      {value}
    </div>
  ));

  return (
    <>
      <ReactDatePicker
        selected={startDate}
        onChange={(date) => {
          handleChange(date, endDate);
          setStartDate(date);
        }}
        dateFormat="MMM"
        showMonthYearPicker
        customInput={<CustomInput />}
        disabledKeyboardNavigation
        nextYearAriaLabel={"hideYear"}
        previousYearAriaLabel={"hideYear"}
      />
      <ReactDatePicker
        selected={sYear}
        onChange={(date) => {
          const startYear = new Date(date);
          startYear.setFullYear(date.getFullYear());
          handleChange(startYear, eYear);
          setStartDate(date);
          setStartYear(date);
        }}
        showYearPicker
        dateFormat="yyyy"
        yearItemNumber={10}
        minDate={new Date("01-01-2011")}
        maxDate={new Date()}
        customInput={<CustomInput />}
        wrapperClassName={"year-picker"}
      />
      <span className="date-to">to</span>
      <ReactDatePicker
        selected={endDate}
        onChange={(date) => {
          let lastDate = moment(date).endOf("months").format("YYYY-MM-DD");
          handleChange(startDate, new Date(lastDate));
          setEndDate(new Date(lastDate));
        }}
        dateFormat="MMM"
        showMonthYearPicker
        customInput={<CustomInput />}
        disabledKeyboardNavigation
        nextYearAriaLabel={"hideYear"}
        previousYearAriaLabel={"hideYear"}
      />
      <ReactDatePicker
        selected={eYear}
        onChange={(date) => {
          const endYear = new Date(date);
          endYear.setFullYear(date.getFullYear());
          handleChange(sYear, endYear);
          setEndDate(date);
          setEndYear(date);
        }}
        showYearPicker
        dateFormat="yyyy"
        yearItemNumber={10}
        minDate={new Date("01-01-2011")}
        maxDate={new Date()}
        customInput={<CustomInput />}
        wrapperClassName={"year-picker"}
      />
    </>
  );
};

export default DatePicker;
