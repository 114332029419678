import { Tooltip } from "@mui/material";
import { ButtonUI } from "./index";
import { PlainDeleteIcon } from "../../../icons/delete/plain";
import { CloseXIcon } from "../../../icons/closeX";

export const DeleteButton = ({ onClick, title = "Delete", width = "66px" }) => {
  return (
    <Tooltip title={title}>
      <ButtonUI
        variant="outlined"
        fontSize="10px"
        borderColor="#B80000"
        color="#B80000"
        mr="6px"
        p="5px"
        mt="5px"
        width={width}
        onClick={onClick}
        rightIcon={
          title === "Delete" ? (
            <PlainDeleteIcon fill="#B80000" />
          ) : (
            <CloseXIcon fill="#B80000" />
          )
        }
      >
        {title}
      </ButtonUI>
    </Tooltip>
  );
};
