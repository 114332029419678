import { Settings } from "../../views/admin/settings/Settings";
import { Dashboard } from "../../views/admin/dashboard/Dashboard";
import { Help } from "../../views/pages/help/Help";
import { CompanyList } from "../../components/companyView/CompanyList";
import { Notification } from "../../views/pages/notification/notification";
import { Search } from "../../views/pages/search/search";
import { UserDetails } from "../../views/admin/userDetails";

const adminRoutes = [
  { path: "/settings", element: <Settings /> },
  { path: "/dashboard", element: <Dashboard /> },
  { path: "/search", element: <Search /> },
  { path: "/notification", element: <Notification /> },
  { path: "/help", element: <Help /> },
  { path: "/companyList", element: <CompanyList /> },
  { path: "/usersdetail", element: <UserDetails /> },
];

export default adminRoutes;
