import { TextareaAutosize, MenuItem, Tooltip } from "@material-ui/core";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import "../../../../../../../assets/css/components/company-detail/_applicantTabs.css";
import {
  AudioIcon,
  BlueAddIcon,
  FilesIcon,
  ImageIcon,
  VideoIcon,
} from "../../../../../../../icons";
import { ButtonUI } from "../../../../../../ui/button";
import { HubConnectionBuilder, LogLevel } from "@microsoft/signalr";
import { useSelector } from "react-redux";
import Api from "../../../../../../../helper/Api";
import { ColoredLinearProgress } from "../../../../../coloredProgressBar";
import ForumIcon from "@mui/icons-material/Forum";
import { IconButton } from "@mui/material";
import { indigo, red } from "@mui/material/colors";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import axios from "axios";
import fileDownload from "js-file-download";
import { sortAtoZ } from "../../../../../../../helper/constants";
import { toast } from "react-toastify";
import InfiniteScroll from "react-infinite-scroll-component";
import { EclipseLoader } from "../../../../../eclipseLoader";

const icons = {
  image: <ImageIcon fill="#9EA0A5" width="30px" height="30px" />,
  audio: <AudioIcon fill="#9EA0A5" width="30px" height="30px" />,
  video: <VideoIcon fill="#9EA0A5" width="28px" height="30px" />,
  text: <FilesIcon stroke="#9EA0A5" line="#fff" width="30px" height="30px" />,
  application: (
    <FilesIcon stroke="#9EA0A5" line="#fff" width="30px" height="30px" />
  ),
};

const chatIcons = {
  png: <ImageIcon fill="#fff" width="30px" height="30px" />,
  jpg: <ImageIcon fill="#fff" width="30px" height="30px" />,
  svg: <ImageIcon fill="#fff" width="30px" height="30px" />,
  aud: <AudioIcon fill="#fff" width="30px" height="30px" />,
  mov: <AudioIcon fill="#fff" width="30px" height="30px" />,
  mp4: <VideoIcon fill="#fff" width="28px" height="30px" />,
  pdf: <FilesIcon stroke="#fff" line="#9EA0A5" width="30px" height="30px" />,
  txt: <FilesIcon stroke="#fff" line="#9EA0A5" width="30px" height="30px" />,
  docx: <FilesIcon stroke="#fff" line="#9EA0A5" width="30px" height="30px" />,
  csv: <FilesIcon stroke="#fff" line="#9EA0A5" width="30px" height="30px" />,
  xls: <FilesIcon stroke="#fff" line="#9EA0A5" width="30px" height="30px" />,
  doc: <FilesIcon stroke="#fff" line="#9EA0A5" width="30px" height="30px" />,
};

export const ChatTab = ({ applicantData, selectedSuitCase }) => {
  const attachRef = useRef("null");
  const { companyData } = useSelector((state) => state.companyDataReducer);

  const [attachments, setAttachments] = useState([]);
  const [noteType, setNoteType] = useState("");
  const [connectionString, setConnection] = useState(null);
  const [inputText, setInputText] = useState("");
  const [messages, setMessages] = useState([]);
  const [menus, setMenus] = useState([]);
  const [chatConnectionId, setChatConnectionId] = useState("");
  const [isLoader, setLoader] = useState(false);
  const [isUploaded, setUploaded] = useState(false);
  const [infLoader, setInfLoader] = useState(false);
  const [pageNumber, setPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [totalPage, setTotalPage] = useState(0);

  const BASEURL = process.env.REACT_APP_BASE_URL;
  const formData = new FormData();
  const MAX_FILE_SIZE = 5120; // 5 MB

  useEffect(async () => {
    var the_arr = BASEURL.split("/");
    the_arr.pop();
    the_arr.pop();
    var apiUrl = the_arr.join("/");

    const connect = new HubConnectionBuilder()
      .withUrl(`${apiUrl}/chatHub`)
      .configureLogging(LogLevel.Information)
      .build();

    setConnection(connect);
    connect.on("ReceiveMessage", (message, attachment, textAreaId) => {
      setMessages((messages) => [
        ...messages,
        {
          message: message,
          attachments: attachment,
          textAreaId: textAreaId,
        },
      ]);
    });

    await connect.start();

    saveConnectionId(connect.connection.connectionId);
    getChatConnection();
  }, []);

  useEffect(() => {
    getAllChat();
    getChatQuickNotes();
  }, []);

  const openAttachment = () => {
    attachRef.current.click();
  };

  const getChatConnection = () => {
    const args = {
      isCandidate: false,
      applicantId: applicantData.applicantId,
      jobId: selectedSuitCase.jobId,
    };

    Api("POST", `Chat/GetApplicantSocketConnection`, args)
      .then((res) => {
        setChatConnectionId(res.data.socketConnectionId);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const saveConnectionId = (connId) => {
    const args = {
      connectionId: connId,
      applicantId: applicantData.applicantId,
      isCandidate: false,
      jobId: selectedSuitCase.jobId,
      companyId: applicantData.companyId,
    };

    Api("POST", `Chat/setConnection`, args).then();
  };

  const getAllChat = () => {
    setLoader(true);
    Api(
      "GET",
      `Chat/GetAllChat?id=${window.btoa(
        applicantData.applicantId
      )}&textAreaId=system&jobId=${window.btoa(
        selectedSuitCase.jobId
      )}&pageLimit=50&pageNumber=1`
    )
      .then((res) => {
        setMessages(res.data.chatViewModels);
        setTotalRecords(res.data.totalCount);
      })
      .finally(() => {
        setLoader(false);
      });
  };

  const getChatQuickNotes = () => {
    Api(
      "GET",
      `QuickNote/AllChatQuickNoteForDropdown?companyId=${companyData.companyId}`
    ).then((res) => {
      setMenus(sortAtoZ(res.data.data, "note"));
    });
  };

  const handleFiles = (e) => {
    if (e.target.files.length > 0) {
      let files = [...e.target.files];
      files.map((file) => {
        console.log(file);
        Math.round(file.size / 1024) < MAX_FILE_SIZE
          ? setAttachments([...attachments, file])
          : toast.error("File size must be smaller than 5 MB");
      });
    } else {
      Math.round(e.target.files[0].size / 1024) < MAX_FILE_SIZE
        ? setAttachments([...attachments, ...e.target.files])
        : toast.error("File size must be smaller than 5 MB");
    }
  };

  const handleChange = (event) => {
    setNoteType(event.target.value);
    setInputText(event.target.value);
  };

  const removeAttachment = (index) => {
    const updateAttachments = [...attachments];
    updateAttachments.splice(index, 1);
    setAttachments([...updateAttachments]);
  };

  const sendMessageToHR = async (attachment = "") => {
    const params = {
      userId: localStorage.getItem("user"),
      userName: "string",
      message: inputText,
      applicantId: applicantData.applicantId,
      applicantName: applicantData.name,
      isRead: true,
      mediaType: 0,
      companyId: applicantData.companyId,
      jobId: selectedSuitCase.jobId,
      quickNoteId: "string",
      quickNoteType: "string",
      attachments: attachment,
    };

    setInputText("");

    await connectionString.invoke(
      "SendPrivateMessage",
      params,
      true,
      "Hetal Nathvani",
      localStorage.getItem("user"),
      "system",
      chatConnectionId
    );
  };

  const sendMessage = () => {
    if (attachments.length > 0) {
      attachments.map((attachment) => {
        sendMessageToHR(
          "https://talentats.s3.us-east-1.amazonaws.com/talentats/Chat/" +
            attachment.name
        );
        uploadAttachment(attachment);
        // added
        setNoteType("");
      });
    } else {
      sendMessageToHR();
      setNoteType("");
    }
  };

  const uploadAttachment = (attachment) => {
    formData.append("applicantId", window.btoa(applicantData.applicantId));
    formData.append("attachments", attachment);
    setUploaded(true);
    Api("POST", `Chat/UploadAttachments`, formData)
      .then((res) => {
        setAttachments([]);
        if (!res.data.isSuccess) {
          toast.error(res.data.message);
        }
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setUploaded(false);
      });
  };

  const downloadFile = (url) => {
    axios
      .get(url, {
        responseType: "blob",
      })
      .then((res) => {
        fileDownload(res.data, url.split("/").at(-1));
      });
  };

  const fetchMore = () => {
    console.log(pageNumber, totalPage);
    if (pageNumber <= totalPage) {
      setPage((prevPage) => prevPage + 1); // Increment pageNumber
    }
  };

  return (
    <div className="tab-container">
      <input
        type="file"
        multiple
        ref={attachRef}
        hidden
        onChange={handleFiles}
        allow=".doc, .docx, .pdf, .txt, .jpg, .png, .svg, .csv, .xls, .mov"
      />

      {isLoader === true ? (
        <ColoredLinearProgress />
      ) : messages.length > 0 ? (
        <>
          <span className="heading-label p-16 font-blue">
            Chat Messages: ({messages.length})
          </span>
          <div className="chat-container" id="chatScrollSystem">
            <InfiniteScroll
              height="100%"
              dataLength={messages?.length || 0}
              next={fetchMore}
              hasMore={messages?.length !== messages?.totalRecords}
              loader={
                infLoader && (
                  <h4 style={{ textAlign: "center" }}>
                    <EclipseLoader />
                  </h4>
                )
              }
              endMessage={
                <p style={{ textAlign: "center" }}>
                  <b>Yay! You have seen it all</b>
                </p>
              }
            >
              {messages.map((message) => (
                <div
                  className="message-chat-container p-0-16"
                  style={{
                    justifyContent:
                      message.textAreaId === "system"
                        ? "flex-end"
                        : "flex-start",
                    position: "relative",
                    paddingRight: !!message.textAreaId && "8px",
                    paddingLeft: message.textAreaId !== "system" && "8px",
                  }}
                  key={new Date() * Math.random()}
                >
                  <div
                    className="message-chat"
                    style={{
                      backgroundColor:
                        message.textAreaId === "system" ? "#CCDCFF" : "#F2F6FF",
                      borderRadius:
                        message.textAreaId === "applicant"
                          ? "0px 8px 8px 8px"
                          : "8px 0px 8px 8px",
                    }}
                  >
                    <span className="sender-label">
                      {message.textAreaId === "system"
                        ? "You"
                        : message.applicantName}
                    </span>
                    <div className="message-text">
                      {message?.attachments?.length > 0 ? (
                        <div
                          className="d-flex align-items-center justify-content-between"
                          style={{ width: "210px" }}
                        >
                          <div className="attach-icon">
                            {
                              chatIcons[
                                message?.attachments[0]?.split(".").at(-1)
                              ]
                            }
                          </div>
                          <div className="ml-10 attach-data">
                            <span>
                              {message?.attachments[0]
                                ?.split("/")
                                .at(-1)
                                .substring(0, 25) + "..."}
                            </span>
                          </div>
                          {messages.indexOf(message) != -1 &&
                            isUploaded === false && (
                              <Tooltip title="Download">
                                <IconButton
                                  onClick={() =>
                                    downloadFile(message?.attachments[0])
                                  }
                                >
                                  <FileDownloadOutlinedIcon
                                    sx={{ color: indigo[500] }}
                                  />
                                </IconButton>
                              </Tooltip>
                            )}
                        </div>
                      ) : (
                        message.message
                      )}
                    </div>
                    <div className="chat-footer">
                      <div className="chat-footer-detail">
                        <span className="chat-footer-labels">
                          {moment(message.createdAt).format("MM/DD/YYYY")}
                        </span>
                        <div className="sm-dot-div" />
                        <span className="chat-footer-labels">
                          {moment(message.createdAt).format("LT")}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </InfiniteScroll>
          </div>
          <div className="border-bottom"></div>
          <div className="chat-quick-note-container p-0-16">
            <strong className="font-blue font-14">Quick Notes:</strong>
            <FormControl sx={{ marginTop: 2 }} size="small">
              <Select
                value={noteType}
                onChange={handleChange}
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
              >
                <MenuItem value="">
                  <em>Select Note</em>
                </MenuItem>
                {menus.map((menu, index) => (
                  <MenuItem value={menu.note} key={index}>
                    {menu.note.substring(0, 60)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <div className="text-area-container">
              <TextareaAutosize
                aria-label="minimum height"
                minRows={4}
                maxRows={4}
                style={{
                  resize: "none",
                  width: "100%",
                  border: "none",
                  outline: "none",
                  fontFamily: "Inter",
                }}
                onChange={(input) => {
                  setInputText(input.target.value);
                }}
                value={inputText}
              />
            </div>
          </div>
          <div className="border-bottom"></div>
          <div className="d-flex flex-column">
            <div className="d-flex flex-row justify-content-between align-items-center mt-10 p-0-16">
              <strong className="font-blue font-14">Attachment(s): </strong>
              <Tooltip title="Choose a file to send">
                <IconButton size="small" onClick={openAttachment}>
                  <BlueAddIcon width="25" height="25" className="pointer" />
                </IconButton>
              </Tooltip>
            </div>

            <div className="attachment-container">
              {attachments?.map((attachment, index) => (
                <div className="attachemt-item">
                  <Tooltip title={attachment.name}>
                    <div className="attachment">
                      {icons[attachment.type.split("/")[0]]}
                      <span className="font-14">
                        {attachment.name.substring(0, 8) + "..."}
                      </span>
                      <IconButton onClick={() => removeAttachment(index)}>
                        <CloseOutlinedIcon sx={{ color: red[500] }} />
                      </IconButton>
                    </div>
                  </Tooltip>
                </div>
              ))}
            </div>

            <div className="attach-send">
              <ButtonUI
                width={"72px"}
                p={"8px"}
                ml="10px"
                radius="4px"
                onClick={sendMessage}
              >
                Send
              </ButtonUI>
            </div>
          </div>
        </>
      ) : (
        <>
          <span className="heading-label p-16 font-blue">
            Chat Messages: ({messages.length})
          </span>
          <div className="d-flex flex-column no-message-container">
            <div className="chat-icon mt-15">
              <ForumIcon fontSize="large" />
            </div>
            <span className="chat-icon-label mb-10">
              Start your chat with the applicant by sending your first message !{" "}
            </span>
          </div>
          <div className="chat-quick-note-container p-0-16">
            <strong className="font-blue font-14">Quick Notes</strong>
            <FormControl sx={{ marginTop: 2 }} size="small">
              <Select
                value={noteType}
                onChange={handleChange}
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
              >
                <MenuItem value="">
                  <em>Select Note</em>
                </MenuItem>
                {menus.map((menu, index) => (
                  <MenuItem value={menu.note} key={index}>
                    {menu.note.substring(0, 60)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <div className="text-area-container">
              <TextareaAutosize
                aria-label="minimum height"
                minRows={4}
                maxRows={4}
                style={{
                  resize: "none",
                  width: "100%",
                  border: "none",
                  outline: "none",
                  fontFamily: "Inter",
                }}
                onChange={(input) => {
                  setInputText(input.target.value);
                }}
                value={inputText}
              />
            </div>
          </div>
          <div className="chat-main-footer p-0-16">
            <div className="attach-send">
              <ButtonUI
                width={"72px"}
                p={"8px"}
                radius="4px"
                onClick={sendMessage}
              >
                Send
              </ButtonUI>
            </div>
          </div>
        </>
      )}
    </div>
  );
};
