import { JOB_TEMPLATE_ACTIONS } from "./actions";

const initialState = {
  currentMenu: "description",
  jobTemplateData: {},
};

export const jobTemplateReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case JOB_TEMPLATE_ACTIONS.SET_MENU:
      return { ...state, currentMenu: payload };
    case JOB_TEMPLATE_ACTIONS.SET_JOB_TEMP_DATA:
      return { ...state, jobTemplateData: payload };
    default:
      return state;
  }
};
