import { combineReducers } from "redux";
import { userReducer } from "./users/reducer";
import { jobTemplateReducer } from "./jobTemplate/reducer";
import { companyDataReducer } from "./companyData/reducer";
import { jobDataReducer } from "./jobData/reducer";
import { applicantDataReducer } from "./applicantData/reducer";
import { globalSearchReducer } from "./search/reducer";
import { videoInterviewDataReducer } from "./videoInterviewData/reducer";
import { sequenceReducer } from "./sequenceData/reducer";

export default combineReducers({
  userReducer,
  videoInterviewDataReducer,
  jobTemplateReducer,
  companyDataReducer,
  jobDataReducer,
  applicantDataReducer,
  globalSearchReducer,
  sequenceReducer
});
