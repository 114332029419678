import React, { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import "../../../../../../assets/css/common/_col-form.css";
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  IconButton,
  Tooltip,
} from "@mui/material";
import { red } from "@mui/material/colors";
import flashIcon from "../../../../../../assets/images/flash_icon.svg";
import { LocalizationProvider, StaticDatePicker } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import "./connectSequence.css";
import moment from "moment";
import {
  ComponentWithContentSequence,
  sendEmails,
  sendPhones,
  timeOptions,
} from "./constant";
import { ButtonUI } from "../../../../../ui/button";

import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { SEQUENCE_DATA_ACTIONS } from "../../../../../../redux/sequenceData/actions";
import { SequenceMessages } from "./sequenceMessages";

const styles = {
  label: {
    fontSize: "12px",
    marginTop: "10px",
  },
  padding20: {
    padding: "0 20px 20px 20px",
  },
  icon: {
    height: "20px",
    width: "20px",
    marginRight: "10px",
  },
  header: {
    padding: "0 10px 10px 10px",
  },
  input: {
    fontSize: "12px",
    boxSizing: "border-box",
    border: "1px solid #9f9f9f",
    padding: "7px 7px",
    borderRadius: "6px",
    margin: "7px 0 0px 0",
    outline: "none",
  },
};

const steps = [
  {
    step: 1,
    validationSchema: Yup.object({
      sequenceName: Yup.string().required("Required name"),
      description: Yup.string().required("Required description"),
      selectedDate: Yup.date().required("Required date"),
      seqTime: Yup.string().required("Required time"),
    }),
  },
  {
    step: 2,
    validationSchema: Yup.object({
      emailSubject: Yup.string().required("Required"),
    }),
  },
  {
    step: 3,
    validationSchema: Yup.object({
      age: Yup.string(),
    }),
  },
];

export const ConnectSequence = ({ data }) => {
  const [isClosingTab, setIsClosingTab] = useState(false);

  const dispatch = useDispatch();

  const [currentStep, setCurrentStep] = useState(1);
  const [formValues, setFormValues] = useState({});
  const [showFormValues, setShowFormValues] = useState(false);

  const handleBack = () => {
    setCurrentStep((prevStep) => Math.max(prevStep - 1, 1));
  };

  const { handleSubmit, handleChange, values, errors, setFieldValue } =
    useFormik({
      initialValues: {
        selectedDate: null,
        sequenceName: "Test sequence",
        description: "Demo description",
        sequenceMessages: [
          {
            messageOrder: 0,
            sendStartDate: "2024-04-16T11:55:07.437Z",
            sendStartTime: "2024-04-16T11:55:07.437Z",
            batchMessagePreference: 1,
            multipleEmails: 2,
            multipleCellPhones: 2,
            sendPreferences: 1,
            emailSubject: "",
            includeGreetings: true,
            messageContent: "",
            sendDaysAfterMessage: "",
            sendModes: 1,
          },
        ],
      },
      enableReinitialize: true,
      validationSchema: steps[currentStep - 1].validationSchema,
      onSubmit: (values, { setSubmitting }) => {
        setFormValues((prevValues) => ({ ...prevValues, ...values }));

        if (currentStep < steps.length) {
          setCurrentStep((prevStep) => prevStep + 1);
        } else {
          setShowFormValues(true);
        }

        setSubmitting(false);
      },
    });

  const closeApplicantTab = () => {
    setIsClosingTab(true);

    setTimeout(() => {
      dispatch({
        type: SEQUENCE_DATA_ACTIONS.SET_SEQ_DATA,
        payload: [],
      });
    }, 500);
  };

  function CustomDatePicker(props) {
    return (
      <div className="custom-datepicker">
        <StaticDatePicker {...props} />
      </div>
    );
  }

  // function handleChange() {
  //   console.log("asjhjkasdhajksdh");
  // }

  return (
    <div className="user-list applicant-h connectSeq">
      <div className="applicant-header" style={styles.header}>
        <div className="d-flex align-items-center">
          <img src={flashIcon} alt="flash-icon" style={styles.icon} />
          <strong>New Sequence</strong>
        </div>
        <div>
          <Tooltip title="Close Sequence">
            <IconButton
              size="small"
              onClick={closeApplicantTab}
              disabled={isClosingTab}
            >
              <CloseIcon sx={{ color: red[500] }} />
            </IconButton>
          </Tooltip>
        </div>
      </div>

      <div className="column-content" style={styles.padding20}>
        <form onSubmit={handleSubmit}>
          {currentStep === 1 && (
            <>
              <div className="d-flex flex-column">
                <label style={styles.label}>
                  <strong>Sequence Name</strong>
                </label>
                <input
                  name="sequenceName"
                  onChange={handleChange}
                  value={values.sequenceName || ""}
                  type="text"
                  placeholder="Name Here"
                  style={styles.input}
                />
                {errors.sequenceName && (
                  <div className="error-txt">{errors.sequenceName}</div>
                )}
              </div>
              <div className="d-flex flex-column">
                <label style={styles.label}>
                  <strong>Description</strong>
                </label>
                <textarea
                  name="description"
                  onChange={handleChange}
                  value={values.description || ""}
                  placeholder="Description here"
                  cols="30"
                  rows="10"
                  style={styles.input}
                ></textarea>
                {errors.description && (
                  <div className="error-txt">{errors.description}</div>
                )}
              </div>
              <div className="d-flex flex-column">
                <label style={styles.label}>
                  <strong>Start Date & Time</strong>
                </label>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <CustomDatePicker
                    value={values.selectedDate}
                    onChange={(date) => setFieldValue("selectedDate", date)}
                    name="startDate"
                    ToolbarComponent={() => null} // This removes the toolbar
                    disablePast // Disable dates in the past
                  />
                </LocalizationProvider>
                {errors.selectedDate && (
                  <div className="error-txt">{errors.selectedDate}</div>
                )}
                <>
                  <div>
                    <select
                      className="w-100"
                      name="seqTime"
                      onChange={handleChange}
                      value={values.seqTime || ""}
                    >
                      <option value="">Select</option>
                      {timeOptions.map((item) => (
                        <option key={item.id} value={item.value}>
                          {item.value}
                        </option>
                      ))}
                    </select>
                    {errors.seqTime && (
                      <div className="error-txt">{errors.seqTime}</div>
                    )}
                  </div>
                  <ComponentWithContentSequence value={values.seqTime} />
                </>
              </div>
            </>
          )}

          {currentStep === 2 && (
            <SequenceMessages
              values={values}
              errors={errors}
              styles={styles}
              handleChange={handleChange}
            />
          )}

          {currentStep === 3 && (
            <>
              <div className="text-center review-text">
                Please review the following and click <strong>Save</strong> to
                create the sequence.
              </div>
              <div>
                <p>
                  <strong> Name:</strong> {values.sequenceName}
                </p>
                <p>
                  <strong> Description:</strong> {values.description}
                </p>
                <p>
                  <strong>Message #1</strong>
                </p>
                <div className="underline-border"></div>
                <p>
                  <strong>Send:</strong>{" "}
                  {moment(values.selectedDate).format("L")} starting at{" "}
                  {values.seqTime}{" "}
                </p>
                <p>
                  <strong>Send Via:</strong> Email and Text
                </p>
                <div className="underline-border mb-10"></div>
              </div>
              <div>1 additional recipient</div>
              <div>Total contacts: 1</div>
              <div className="mt-10 mb-10">
                Replies will be available for review via the sequence activity
                report. If you would also like to have replies forwarded
                directly to you, please select below:
              </div>
              <div>
                <div className="d-flex flex-column">
                  <FormControlLabel
                    control={<Checkbox defaultChecked />}
                    label="Forward Replies to My Email"
                  />
                  <FormControlLabel
                    control={<Checkbox defaultChecked />}
                    label="Forward Replies to My Phone"
                  />
                </div>
              </div>
              <div className="text-center">
                <div className="seq-start">
                  Do you want to activate this sequence now?
                </div>
                <FormGroup className="d-flex flex-row justify-content-center">
                  <FormControlLabel
                    control={<Checkbox />}
                    label="Yes"
                    value="yes"
                  />
                  <FormControlLabel
                    control={<Checkbox />}
                    label="No"
                    value="no"
                  />
                </FormGroup>
                <div className="seq-warning">
                  This sequence will begin on <br />
                  {moment(values.selectedDate).format("L")} starting at{" "}
                  {values.seqTime}{" "}
                </div>
              </div>
            </>
          )}
          <div className="d-flex mt-10">
            {currentStep > 1 && (
              <ButtonUI
                p="10px 19px"
                ml="auto"
                mr="5px"
                type="button"
                className="remove-margin-span"
                onClick={handleBack}
              >
                Back
              </ButtonUI>
            )}
            <ButtonUI
              p="10px 19px"
              ml={currentStep === 1 ? "auto" : "5px"}
              mr="auto"
              className="remove-margin-span"
              type="submit"
              onClick={() => console.log("called stepper")}
            >
              {currentStep === 3 ? "Save" : "Next"}
            </ButtonUI>
          </div>
        </form>

        {showFormValues && (
          <div>
            <h2>Final Form Values:</h2>
            <pre>{JSON.stringify(formValues, null, 2)}</pre>
          </div>
        )}
      </div>
    </div>
  );
};
