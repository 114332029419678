import { Tooltip } from "@mui/material";
import { ButtonUI } from ".";
import { SendIcon } from "../../../icons/send";

export const SendButton = ({ onClick }) => {
  return (
    <Tooltip title="Send Message">
      <ButtonUI
        variant="outlined"
        fontSize="10px"
        borderColor="#E6492D"
        color="#E6492D"
        mr="6px"
        p="5px"
        mt="5px"
        width="60px"
        onClick={onClick}
        rightIcon={<SendIcon fontSize="small" />}
      >
        Send
      </ButtonUI>
    </Tooltip>
  );
};
