export const validations = {
  address: {
    req: "Address is required.",
  },
  content: {
    req: "Email Content is required.",
  },
  doc_file: {
    req: "Document File is required.",
  },
  doc_name: {
    req: "Document Name is required.",
  },
  doc_type: {
    req: "Document Type is required.",
  },
  document: {
    req: "Please select at least one document",
  },
  duration: {
    req: "Duration is required.",
  },
  email: {
    req: "Email is required.",
    pattern: {
      value: RegExp("^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$"), //RegExp("^[a-zA-Z0-9]+@[a-zA-Z_0-9]+?.[a-zA-Z]{2,3}$"),
      message: "Please enter valid Email.",
    },
  },
  fname: {
    req: "First Name is required.",
  },
  facilities: {
    req: "Please select one facility.",
  },
  job_level: {
    req: "Job Access Level is required.",
  },
  job_no: {
    req: "Job Number Prefix is required.",
  },
  logo: {
    req: "Company Logo is required.",
  },
  loginid: {
    req: "Login ID is required.",
    custom: "LoginID must be maximum 15 and minimum 4 characters.",
  },
  lname: {
    req: "Last Name is required.",
  },
  mname: {
    req: "Middle Name is required.",
  },
  nname: {
    req: "Nick Name is required.",
  },
  mobile: {
    req: "Mobile No. is required.",
    pattern: {
      value: RegExp("^[0-9\b]{10}$"),
      message: "Please enter valid Mobile No.",
    },
    custom: "Please Enter Valid Email or Mobile.",
  },
  name: {
    req: "Name is required.",
    pattern: {
      value: RegExp("^[A-Za-z]*$"),
      message: "Name must be a text!",
    },
  },
  note: {
    req: "Note is required.",
  },
  otp: {
    req: "OTP is required.",
  },
  password: {
    req: "Password is required.",
    custom: {
      isValid: (value) => value.length > 5,
      message: "New Password should be 6 characters long.",
    },
    match: {
      message: "New and Confirm Passwords do not match.",
    },
  },
  per_job: {
    req: "Per Job Board is required.",
  },
  reason: {
    req: "Reason Name is required.",
  },
  slot: {
    req: "Slot close is required.",
  },
  subject: {
    req: "Email Subject is required.",
  },
  time: {
    req: "Time between is required.",
  },
  urole: {
    req: "User Role is required.",
  },
  accessRecords: {
    req: "Access Module Records is required"
  },
  timezone: {
    req: "Timezone is required.",
  },
  title: {
    req: "Title is required.",
  },
  total_job: {
    req: "Total Job is required.",
  },
  zipcode: {
    req: "Zipcode is required.",
  },
  duartion: {
    req: "Duration is required.",
  },
  timeBetween: {
    req: "Time between is required.",
  },
  slotClose: {
    req: "Slot close is required.",
  },
  note: {
    req: "Note description is required.",
  },
  companyId: {
    req: "Company name is required.",
  },
  description: {
    req: " Description is required.",
  },
  phoneNumber: {
    req: "Phone number is required.",
    invalid: "Invalid phone number.",
  },
  quickNoteId: {
    req: "Quick note is required.",
  },
  crntPass: {
    req: "Current password is required.",
  },
  newPass: {
    req: "New password is required.",
  },
  conPass: {
    req: "Confirm password is required.",
  },
  videoInterviewId: {
    req: "Video Interview is required.",
  },
  expDate: {
    req: "Expiry date is required.",
  },
  checklisttId: {
    req: "Existing item is required.",
  },
  newItem: {
    req: "New item is required.",
  },
  rate: {
    req: "Rate is required.",
  },
  rateTypeId: {
    req: "Rate Type Id is required.",
  },
  shiftTypeId: {
    req: "Shift Type Id is required.",
  },
  scheduleTypeId: {
    req: "Schedule Type Id is required.",
  },
  employmentTypeId: {
    req: "Employment Type Id is required.",
  },
  startDate: {
    req: "Start date is required.",
  },
  endDate: {
    req: "End date is required.",
    max: "Expiry Date should not be greater than 19 days from current date.",
    min: "Expiry Date should not be less than current date.",
  },
  interviewType: {
    req: "Interview type is required.",
  },
  appointmentType: {
    req: "Appointment type is required.",
  },
  interviewers: {
    req: "Please select at least one interviewer!",
  },
  selectedSlots: {
    req: "Please select at least one time slot!",
  },
  offerLetter: {
    req: "Please select at least one Offer Letter!",
  },
  users: {
    req: "Please select at least one user!",
  },
  stopReasonsId: {
    req: "Please select at least one stop reason!",
  },
};
