import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import "../../../assets/css/components/company-detail/_stopReasons.css";
import Api from "../../../helper/Api";
import NoRecordsFound from "../../common/NoRecordsFound";
import { toast } from "react-toastify";
import { EditDeleteMenu } from "../../ui/editDeleteMenu";
import { ButtonUI } from "../../ui/button";
import AddIcon from "@mui/icons-material/Add";
import Loader from "../../common/Loader";
import { EditStopReasons } from "./editStopReason";
import { AlertBox, showSuccess } from "../../../helper/utils";

export function StopReasons() {
  const location = useLocation();
  const [formValues, setFormValues] = useState([]);
  const [showAddEditLabel, setLabel] = useState("Add Stop Reason");
  const [formErrors, setFormErrors] = useState({});
  const [searchInput, setSearchInput] = useState("");
  const [reasonsList, setReasonsList] = useState([]);
  const [id, setId] = useState(0);
  const [editMode, setEditMode] = useState(false);
  const [loading, setLoader] = useState(false);
  const [openSidebar, setSidebar] = useState(false);
  const [isSubmitting, setSubmitting] = useState(false);
  const [reasonsListOriginal, setReasonsListOriginal] = useState([]);

  useEffect(() => {
    getStopReasonsList();
  }, []);

  const toggleDrawer = (anchor, open) => {
    setSidebar({ ...openSidebar, [anchor]: open });
  };

  const getStopReasonsList = () => {
    setLoader(true);
    Api(
      "GET",
      `StopReasons/GetStopReasonsList?companyId=${location.state.companyId}&companyHirerchy=${location.state.companyHirerchy}&searchText=${searchInput}&OrderBy=updated_at&OrderbyDirection=desc`
    )
      .then((res) => {
        localStorage.setItem("StopReasons", JSON.stringify(res.data.data));
        setReasonsList(res.data.data);
        setReasonsListOriginal(res.data.data); // Set the original list
      })
      .finally(() => setLoader(false));
  };

  const addReason = () => {
    setEditMode(false);
    setLabel("Add Stop Reason");
    setFormValues({});
    setFormErrors({});
    toggleDrawer("right", true);
  };

  const handleSubmit = () => {
    formValues.companyId = location.state.companyId;
    formValues.companyHirerchy = location.state.companyHirerchy;

    setSubmitting(true);
    if (editMode === true) {
      // Edit stop reason api call
      formValues.stopReasonsId = id;
      Api("PUT", "StopReasons/EditStopReasons", formValues)
        .then((res) => {
          if (res.data.isSuccess) {
            delete formValues.companyHirerchy;
            reasonsList.map((reason) => {
              if (reason.stopReasonsId == id) {
                reasonsList.splice(reasonsList.indexOf(reason), 1);
                reasonsList.unshift(formValues);
              }
            });
            setReasonsList(reasonsList);
            toast.success(res.data.message);
            toggleDrawer("right", false);
          } else {
            toast.error(res.data.message);
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => setSubmitting(false));
    } else {
      // Add stop reason api call
      Api("POST", "StopReasons/AddStopReasons", formValues)
        .then((res) => {
          if (res.data.isSuccess) {
            toast.success(res.data.message);
            toggleDrawer("right", false);
            getStopReasonsList();
          } else {
            toast.error(res.data.message);
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => setSubmitting(false));
    }
  };

  const handleEdit = (stopReasonsId) => {
    // Edit the reason
    const reason = reasonsList.find((reason) =>
      reason.stopReasonsId === stopReasonsId ? reason : ""
    );
    setLabel("Edit Stop Reason");
    setEditMode(true);
    setFormValues(reason);
    setFormErrors({});
    setId(stopReasonsId);
    toggleDrawer("right", true);
  };

  const handleDelete = (stopReasonsId) => {
    // Delete the reason
    setId(stopReasonsId);
    AlertBox().then((res) => {
      if (!res.isConfirmed) return;

      // Delete stop reason API call
      Api(
        "DELETE",
        "StopReasons/DeleteStopReasons?stopReasonsId=" + stopReasonsId
      )
        .then((res) => {
          if (res.data.isSuccess === true) {
            showSuccess();
            getStopReasonsList();
          } else {
            toast.error(res.data.message);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    });
  };

  const handleSearchChange = (e) => {
    setSearchInput(e.target.value.toLowerCase());
  };

  const handleSearchBlur = () => {
    handleSearch();
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSearch();
    }
  };

  const handleSearch = () => {
    const inputValue = searchInput.toLowerCase();

    if (inputValue === "") {
      setReasonsList([...reasonsListOriginal]); // Reset to the original list
    } else {
      const searchedFiltered = reasonsListOriginal.filter((d) => {
        return d.name.toLowerCase().includes(inputValue);
      });

      setReasonsList(searchedFiltered);
    }
  };

  return (
    <div>
      {/* Add and Edit form */}

      {openSidebar && (
        <EditStopReasons
          toggleDrawer={toggleDrawer}
          openSidebar={openSidebar}
          label={showAddEditLabel}
          formValues={formValues}
          setFormValues={setFormValues}
          formErrors={formErrors}
          setFormErrors={setFormErrors}
          handleSubmit={handleSubmit}
          isSubmitting={isSubmitting}
        />
      )}

      <div className="common-container users">
        <div className="inner-container">
          <div className="top-url">
            <p>
              <Link to="/companylist"> Companies &gt; </Link>
              <strong>Stop Reasons</strong>
            </p>
          </div>
          <div className="manage-container jobs">
            <div className="manage-bg active">
              <div className="parent-container-d">
                <div className="content_header_reasons">
                  <div className="company_search">
                    <img
                      src={require("../../../assets/images/search_icon.png")}
                      alt="search-icon"
                      className="search_img"
                    />
                    <input
                      type="text"
                      placeholder="Search Stop Reason"
                      className="search_input appointments"
                      name="search_input"
                      value={searchInput}
                      onChange={(e) => {
                        handleSearchChange(e);
                        handleSearch(); // Add this line to trigger search on type
                      }}
                      onBlur={handleSearchBlur}
                      onKeyDown={handleKeyPress}
                    />

                    <button className="search-btn" onClick={handleSearch}>
                      Search
                    </button>
                  </div>
                  <div>
                    <ButtonUI
                      p="12px"
                      leftIcon={<AddIcon style={{ height: 16, width: 16 }} />}
                      onClick={() => addReason()}
                    >
                      Add
                    </ButtonUI>
                  </div>
                </div>

                {loading === true ? (
                  <Loader inShow={true} />
                ) : reasonsList.length > 0 ? (
                  <div className="j-card-container">
                    {reasonsList.map((reason) => (
                      <div className="j-card" key={reason.stopReasonsId}>
                        <div className="reason_card_header">
                          <span>{reason.name}</span>
                          <EditDeleteMenu
                            id={reason.stopReasonsId}
                            onEdit={handleEdit}
                            onDelete={handleDelete}
                          />
                        </div>
                        <div className="reason_card_content">
                          {reason.defaultChatMessage}
                        </div>
                      </div>
                    ))}
                  </div>
                ) : (
                  <NoRecordsFound />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
