import { useState, useEffect } from "react";
import Api from "../../../../../../../helper/Api";
import { sortAtoZ } from "../../../../../../../helper/constants";
import { AddEditBox } from "../../../../../addEditPopup/AddEditBox";

export const AddEditNote = (props) => {
  const [quickNotesList, setQuickNotesList] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [saveAsQuickNote, setSaveAsQuickNote] = useState(
    props.formValues.saveAsQuickNote || false
  );

  useEffect(() => {
    Api(
      "GET",
      `QuickNote/AllApplicantQuickNoteForDropdown?companyId=${props.companyId}&quickNoteType=applicant`
    )
      .then((res) => {
        const data = sortAtoZ(res.data.data, "note");
        setQuickNotesList(data);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const handleChange = (e) => {
    props.setFormValues({
      ...props.formValues,
      ["description"]: e.target.options[e.target.selectedIndex].dataset.desc,
      ["quickNoteId"]: e.target.value,
    });
  };

  const handleDescription = (e) => {
    props.setFormValues({
      ...props.formValues,
      [e.target.name]: e.target.value,
    });
  };

  const handleCheckbox = (e) => {
    const isChecked = e.target.checked;
    setSaveAsQuickNote(isChecked);
    props.setFormValues({
      ...props.formValues,
      saveAsQuickNote: isChecked,
    });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    props.handleSubmit();
  };

  return (
    <AddEditBox
      applicantId={props.applicantId}
      openForm={props.openForm}
      title={props.edit === true ? "Edit Note" : "Add Note"}
      toggleForm={props.toggleForm}
      handleSubmit={handleSubmit}
      isLoading={isLoading}
      isSubmitting={props.isSubmitting}
    >
      <div className="forms-input">
        <label>Quick Notes:</label>
        <select
          onChange={handleChange}
          name="quickNoteId"
          value={props.formValues.quickNoteId}
        >
          <option>Select</option>
          {quickNotesList.map((note) => (
            <option
              key={note.quickNoteId}
              value={note.quickNoteId}
              data-desc={note.note}
            >
              {note.note.substring(0, 25) + "..."}
            </option>
          ))}
        </select>
        {props.formErrors.description ? (
          <span className="error_msg">{props.formErrors.quickNoteId}</span>
        ) : null}
      </div>

      <div className="forms-input">
        <textarea
          name="description"
          id="note"
          value={props.formValues.description}
          placeholder="Quick note description here"
          onChange={handleDescription}
          rows={3}
        />
        {props.formErrors.description ? (
          <span className="error_msg">{props.formErrors.description}</span>
        ) : null}
      </div>

      <div className="forms-input">
        {props.edit === false && (
          <label>
            <input
              value={props.edit}
              type="checkbox"
              checked={saveAsQuickNote}
              onChange={handleCheckbox}
            />
            Save as Quick Note
          </label>
        )}
      </div>
    </AddEditBox>
  );
};
