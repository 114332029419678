import { LinkIcon } from "../../../icons";
import { Fragment, useState } from "react";
import { URLDialogbox } from "../../common/dialogBox/urlDialogbox";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { IconButton, Tooltip } from "@mui/material";
import { getDays } from "../../../helper/utils";

const color = {
  ON_HOLD: "red",
  ACTIVE: "active",
  CANCELLED: "",
  FILLED: "brown",
  HIRING: "lblue",
  PENDING: "yellow",
};

const priority = {
  NORMAL: "",
  MEDIUM: "yellow",
  HIGH: "red",
};

export const JobCard = (props) => {
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState("Done");
  const urlJobData = window.btoa(
    "jobId=" + props.jobId + "&companyId=" + props.companyId
  );

  const url = window.location.origin + "/apply-job/" + urlJobData;

  const handleClickListItem = () => {
    setOpen(true);
  };

  const handleClose = (newValue) => {
    setOpen(false);

    if (newValue) {
      setValue(newValue);
    }
  };

  return (
    <Fragment>
      <div key={props.jobCode}>
        {open && (
          <URLDialogbox
            id="copy-url-box"
            keepMounted
            open={open}
            onClose={handleClose}
            value={value}
            url={url}
            jobCode={props.jobCode}
          />
        )}
        <div className="j-card-header">
          <div>
            <span>{props.jobCode}</span>
            <Tooltip title="Get job application url">
              <IconButton onClick={handleClickListItem} size="small">
                <LinkIcon height="20" width="20" />
              </IconButton>
            </Tooltip>
          </div>

          <Tooltip title="Edit Job">
            <IconButton
              size="small"
              onClick={() => props.editAction(props.jobId)}
            >
              <EditOutlinedIcon color="action" fontSize="small" />
            </IconButton>
          </Tooltip>
        </div>
        <div className="j-card-content">
          <span className="title" onClick={() => props.cardClickEvent(props)}>
            {props.name}
          </span>
          <div
            onClick={() => props.cardClickEvent(props)}
            className="show-more less"
            dangerouslySetInnerHTML={{
              __html: props.description.substring(0, 101),
            }}
          />

          <div
            onClick={() => props.cardClickEvent(props)}
            className="job-posted"
          >
            <span>
              Positions : <label>{props.positions}</label>
            </span>
            <span>
              Days Posted : <label>{getDays(props?.createdAt)}</label>
            </span>
          </div>
        </div>
        <div className="sidebar-status">
          <span className={`status-label ${color[props.jobStatus]}`}>
            {props?.jobStatus[0] +
              props?.jobStatus?.slice(1).toLowerCase().replace("_", " ")}
          </span>

          <span className={`status-label ${priority[props.priority]}`}>
            {props?.priority[0] + props?.priority?.slice(1).toLowerCase()}
          </span>
        </div>
      </div>
    </Fragment>
  );
};
