import { AddEditPopup } from "../../common/addEditPopup";

export const AddEditFacility = ({ showSideBar, formErrors, formValues, showAddEditLabel, setFormValues, toggleDrawer, handleSubmit, allTimeZone }) => {
    const handleChange = (e) => {
        setFormValues({ ...formValues, [e.target.name]: e.target.value });
    }

    return (
        <AddEditPopup
            openSidebar={showSideBar}
            toggleDrawer={toggleDrawer}
            title={showAddEditLabel}
            handleSubmit={handleSubmit}
        >
            <div className="forms-input">
                <label>Facility Name</label>
                <input
                    type="text"
                    placeholder="Name here"
                    name="facilityName"
                    id="facilityName"
                    value={formValues.facilityName}
                    onChange={handleChange}
                />
                {formErrors.facilityName && <span className="error_msg">{formErrors.facilityName}</span>}
            </div>
            <div className="forms-input">
                <label>Address</label>
                <textarea
                    placeholder="Type here"
                    name="address"
                    id="address"
                    cols="20"
                    rows="6"
                    value={formValues.address}
                    onChange={handleChange}
                ></textarea>
                {formErrors.address && <span className="error_msg">{formErrors.address}</span>}
            </div>
            <div className="forms-input">
                <label>Zipcode</label>
                <input
                    type="text"
                    placeholder="Zipcode here"
                    name="zipCode"
                    id="zipCode"
                    value={formValues.zipCode}
                    onChange={handleChange}
                />
                {formErrors.zipCode && <span className="error_msg">{formErrors.zipCode}</span>}
            </div>
            <div className="forms-input">
                <label>Job Number Prefix</label>
                <input
                    type="text"
                    placeholder="Type here"
                    name="job_number_prefix"
                    id="job_number_prefix"
                    value={formValues.job_number_prefix}
                    onChange={handleChange}
                />
                {formErrors.job_number_prefix && <span className="error_msg">{formErrors.job_number_prefix}</span>}
            </div>
            <div className="forms-input">
                <label>Timezone</label>
                <select
                    name="timezone"
                    id="timezone"
                    value={formValues.timezone}
                    onChange={handleChange}
                >
                    <option value="none">Select</option>
                    {allTimeZone.map((time) => (
                        <option key={time.id} value={time.timeZone}>
                            {time.timeZone}
                        </option>
                    ))}
                </select>
                {formErrors.timezone && <span className="error_msg">{formErrors.timezone}</span>}
            </div>
        </AddEditPopup>
    );
}