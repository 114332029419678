import { DropzoneArea } from "material-ui-dropzone";
import { AddEditPopup } from "../addEditPopup";

export const EditCompanyForm = ({
  showAddEditLabel,
  showSideBar,
  toggleDrawer,
  handleSubmit,
  formValues,
  setFormValues,
  editImageShow,
  setEditImageShow,
  formErrors
}) => {
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  return (
    <AddEditPopup
      title={showAddEditLabel}
      openSidebar={showSideBar}
      toggleDrawer={toggleDrawer}
      handleSubmit={handleSubmit}
    >
      <div className="forms-input">
        <label>Name</label>
        <input
          type="text"
          placeholder="Name"
          name="name"
          id="name"
          value={formValues.name}
          onChange={handleChange}
        />
        <span className="error_msg">{formErrors.name}</span>
      </div>
      <div className="forms-input">
        {editImageShow ? (
          <div className="upload-file-shower">
            <img
              src={formValues.companyImage}
              alt="preview-img"
              className="show-img"
            />
            <button
              onClick={() => setEditImageShow(false)}
              className="icon icon_button"
            >
              <img
                src={require("../../../assets/images/close-upload.png")}
                alt="close"
                className="close-img"
              />
            </button>
          </div>
        ) : (
          <div className="forms-input">
            <DropzoneArea
              acceptedFiles={["image/*"]}
              dropzoneText={"Drag and drop an image here or click"}
              onChange={(files) => (formValues["companyLogo"] = files[0])}
              maxFileSize={500000}
              filesLimit={1}
            />
            <span className="error_msg">{formErrors.companyImage}</span>
          </div>
        )}
      </div>
    </AddEditPopup>
  );
};
