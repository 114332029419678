export const PlainDeleteIcon = () => {
  return (
    <svg
      width="12"
      height="13"
      viewBox="0 0 12 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.0703 2.24707H1.44531C1.27126 2.24707 1.10434 2.31621 0.981274 2.43928C0.858203 2.56235 0.789062 2.72927 0.789062 2.90332C0.789062 3.07737 0.858203 3.24429 0.981274 3.36736C1.10434 3.49043 1.27126 3.55957 1.44531 3.55957H1.66406V10.9971C1.66406 11.2872 1.7793 11.5654 1.98441 11.7705C2.18953 11.9756 2.46773 12.0908 2.75781 12.0908H9.75781C10.0479 12.0908 10.3261 11.9756 10.5312 11.7705C10.7363 11.5654 10.8516 11.2872 10.8516 10.9971V3.55957H11.0703C11.2444 3.55957 11.4113 3.49043 11.5344 3.36736C11.6574 3.24429 11.7266 3.07737 11.7266 2.90332C11.7266 2.72927 11.6574 2.56235 11.5344 2.43928C11.4113 2.31621 11.2444 2.24707 11.0703 2.24707ZM9.53906 10.7783H2.97656V3.55957H9.53906V10.7783ZM3.41406 0.71582C3.41406 0.541772 3.4832 0.374852 3.60627 0.251782C3.72934 0.128711 3.89626 0.0595703 4.07031 0.0595703H8.44531C8.61936 0.0595703 8.78628 0.128711 8.90935 0.251782C9.03242 0.374852 9.10156 0.541772 9.10156 0.71582C9.10156 0.889869 9.03242 1.05679 8.90935 1.17986C8.78628 1.30293 8.61936 1.37207 8.44531 1.37207H4.07031C3.89626 1.37207 3.72934 1.30293 3.60627 1.17986C3.4832 1.05679 3.41406 0.889869 3.41406 0.71582Z"
        fill="#B80100"
      />
    </svg>
  );
};
