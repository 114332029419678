import { SEARCH_ACTIONS } from "./actions";

const initialState = {
  isSearchColOpen: false
};

export const globalSearchReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SEARCH_ACTIONS.SET_SEARCH_FLAG:
      return { ...state, isSearchColOpen: payload };
    default:
      return state;
  }
};
