import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Api from "../../../../../../../helper/Api";
import { ButtonUI } from "../../../../../../ui/button";
import { AddDocPackForm } from "./addDocPackForm";
import { SendOnboardingPackForm } from "./sendPackForm";
import { CancelDocPackForm } from "../PreHire/cancelDocPackForm";
import { AddOnboardDocForm } from "./addOnboardDocForm";
import { validations } from "../../../../../../../helper/validations";
import fileDownload from "js-file-download";
import axios from "axios";
import { AddCircleOutlineOutlined } from "@material-ui/icons";
import { AcceptButton } from "../../../../../../ui/button/accept";
import { DeleteButton } from "../../../../../../ui/button/delete";
import { DownloadButton } from "../../../../../../ui/button/download";
import { AlertBox, showSuccess } from "../../../../../../../helper/utils";
import { HubConnectionBuilder, LogLevel } from "@microsoft/signalr";
import { baseUrl } from "../../../../../../../helper/constants";

export const OnBoarding = ({ selectedSuitCase, applicantId }) => {
  const [openOnboardForm, setOnboardForm] = useState(false);
  const [formValues, setFormValues] = useState({});
  const [openSendDocPackForm, setSendDocPackForm] = useState(false);
  const [docPackList, setDocPackList] = useState([]);
  const [id, setId] = useState("");
  const [documentId, setDocumentId] = useState("");
  const [openCancelForm, setCancelForm] = useState(false);
  const [openAddDocForm, setAddDocForm] = useState(false);
  const [formErrors, setFormErrors] = useState([]);
  const [isSubmitting, setSubmitting] = useState(false);
  const formData = new FormData();
  const errors = {};

  useEffect(async () => {
    var the_arr = baseUrl.split("/");
    the_arr.pop();
    the_arr.pop();
    var apiUrl = the_arr.join("/");

    const connect = new HubConnectionBuilder()
      .withUrl(`${apiUrl}/liveUpdateHub`)
      .configureLogging(LogLevel.Information)
      .build();

    connect.on("OnboardingList", (res) => {
      setDocPackList(res);
    });

    await connect.start();
  }, [selectedSuitCase.jobId]);

  useEffect(() => {
    getOnboardingDocPackList();
  }, []);

  const toggleOnboardForm = (open) => {
    setOnboardForm(open);
  };

  const getOnboardingDocPackList = () => {
    Api(
      "GET",
      `Job/OnboardingList?companyId=${selectedSuitCase.companyId}&applicantId=${applicantId}&jobId=${selectedSuitCase.jobId}`
    ).then((res) => {
      setDocPackList(res.data);
    });
  };

  const toggleSendPackForm = (open) => {
    setSendDocPackForm(open);
  };

  const toggleCancelForm = (open) => {
    setCancelForm(open);
  };

  const handleSendPack = (comment) => {
    const params = {
      onboardingId: docPackList[0].onboardingId,
      messageToApplicant: comment,
    };

    setSubmitting(true);
    Api("POST", `Job/SendOnboardingDocPack`, params)
      .then((res) => {
        if (res.data.isSuccess === true) {
          toast.success(res.data.message);
          toggleSendPackForm(false);
        } else {
          toast.error(res.data.message);
        }
      })
      .finally(() => setSubmitting(false));
  };

  const handleCommonIcon = (title, message, docId, flag, comment) => {
    const params = {
      onboardingId: docPackList[0].onboardingId,
      documentId: docId,
      comments: comment,
      flag: flag,
    };

    setSubmitting(true);
    Api("POST", `Job/OnboardingDocPackIcons`, params)
      .then((res) => {
        if (res.data.isSuccess === true) {
          toggleCancelForm(false);
          showSuccess(title, message);
        } else {
          toast.error(res.data.message);
        }
      })
      .finally(() => setSubmitting(false));
  };

  const saveDocPack = (docPackList) => {
    const params = {
      companyId: selectedSuitCase.companyId,
      jobId: selectedSuitCase.jobId,
      applicantId: applicantId,
      documentPackId: "",
      onboardingDocumentList: docPackList,
    };

    setSubmitting(true);
    Api("POST", `Job/SaveOnboardingDocument`, params)
      .then((res) => {
        if (res.data.isSuccess === true) {
          toast.success(res.data.message);
          toggleOnboardForm(false);
        } else {
          toast.error(res.data.message);
        }
      })
      .finally(() => setSubmitting(false));
  };

  const handleAcceptDoc = (docId) => {
    AlertBox().then((res) => {
      if (!res.isConfirmed) return;
      handleCommonIcon("Accepted!", "Accepted Successfully!", docId, 1);
    });
  };

  const handleDeleteDoc = (docId) => {
    AlertBox().then((res) => {
      if (!res.isConfirmed) return;
      handleCommonIcon("Deleted!", "Deleted Successfully!", docId, 3);
    });
  };

  const cancleDocPack = () => {
    handleCommonIcon("Rejected!", "Rejected Successfully!", documentId, 2);
  };

  const toggleAddDocForm = (open) => {
    setAddDocForm(open);
  };

  const saveDocument = () => {
    setFormErrors(validate(formValues));
    if (Object.keys(validate(formValues)).length > 0) {
      return;
    }

    formData.append("onboardingId", docPackList[0].onboardingId);
    formData.append(
      "SelectedOnboardingDocList",
      formValues?.SelectedDocLists ?? ""
    );
    formData.append("UploadDocLists", formValues?.UploadDocLists ?? "");

    setSubmitting(true);
    Api("POST", `Job/SaveExistingOnboardingDocument`, formData, "multipart")
      .then((res) => {
        if (res.data.isSuccess === true) {
          toast.success(res.data.message);
          toggleAddDocForm(false);
        } else {
          toast.error(res.data.message);
        }
      })
      .finally(() => setSubmitting(false));
  };

  // Validations
  const validate = (values) => {
    if (!values.SelectedDocLists && !values.UploadDocLists) {
      errors.document = validations?.document?.req;
    }

    return errors;
  };

  const handleDownload = (url) => {
    axios
      .get(url, {
        responseType: "blob",
      })
      .then((res) => {
        fileDownload(res.data, "OB_" + url.split("/").at(-1).substring(24, 50));
      });
  };

  return (
    <div>
      {openOnboardForm && (
        <AddDocPackForm
          openForm={openOnboardForm}
          toggleForm={toggleOnboardForm}
          applicantId={applicantId}
          saveDocPack={saveDocPack}
          companyId={selectedSuitCase.companyId}
          isSubmitting={isSubmitting}
        />
      )}

      {openSendDocPackForm && (
        <SendOnboardingPackForm
          openForm={openSendDocPackForm}
          toggleForm={toggleSendPackForm}
          applicantId={applicantId}
          handleSendPack={handleSendPack}
          isSubmitting={isSubmitting}
        />
      )}

      {openCancelForm && (
        <CancelDocPackForm
          openForm={openCancelForm}
          toggleForm={toggleCancelForm}
          applicantId={applicantId}
          cancleDocPack={cancleDocPack}
          isSubmitting={isSubmitting}
        />
      )}

      {openAddDocForm && (
        <AddOnboardDocForm
          openForm={openAddDocForm}
          toggleForm={toggleAddDocForm}
          applicantId={applicantId}
          saveDocument={saveDocument}
          formValues={formValues}
          setFormValues={setFormValues}
          formErrors={formErrors}
          companyId={selectedSuitCase.companyId}
          isSubmitting={isSubmitting}
        />
      )}
      {docPackList[0]?.onboardingDocPackList?.length > 0 ? (
        <ButtonUI
          p="9px"
          mt="10px"
          ml="65px"
          mb="10px"
          onClick={() => toggleSendPackForm(true)}
        >
          Send Onboarding Pack
        </ButtonUI>
      ) : (
        <ButtonUI
          p="9px"
          mt="10px"
          ml="65px"
          mb="10px"
          onClick={() => toggleOnboardForm(true)}
        >
          Create On Boarding Pack
        </ButtonUI>
      )}
      <div className="profile-data mt-10">
        {docPackList[0]?.onboardingDocPackList?.map((doc, index) => (
          <div className="section border-bottom" key={index}>
            <div className="mt-5">
              <strong>{doc.documentName}</strong>
              <span className="status blue-completed">
                {doc?.documentStatus?.split("_")[0]}
              </span>
            </div>

            <span className="scheduled">
              <label>
                <strong>Last Viewed :</strong>{" "}
                {doc.lastViewed}
              </label>
            </span>
            <span className="mt-5 d-flex mb-5">
              {doc?.documentStatus === "REJECTED_PREHIRE" ||
                doc?.documentStatus === "SIGNED_PREHIRE" ||
                (doc?.documentStatus === "UPLOADED_PREHIRE" && (
                  <AcceptButton
                    onClick={() => (
                      handleAcceptDoc(doc.documentId),
                      setId(docPackList[0].preHireDocumentPackId)
                    )}
                  />
                ))}
              {doc?.documentStatus === "UPLOADED_PREHIRE" ||
                doc?.documentStatus === "ACCEPTED_PREHIRE" ||
                (doc?.documentStatus === "SIGNED_PREHIRE" && (
                  <DeleteButton
                    title="Cancel"
                    onClick={() => (
                      toggleCancelForm(true),
                      setId(docPackList[0].preHireRequestId),
                      setDocumentId(doc.documentId)
                    )}
                  />
                ))}
              {doc?.documentStatus !== "UPLOADED_PREHIRE" &&
                doc?.documentStatus !== "DELIVERED_PREHIRE" && (
                  <DeleteButton
                    onClick={() => (
                      handleDeleteDoc(doc.documentId),
                      setId(docPackList[0].preHireDocumentPackId),
                      setDocumentId(doc.documentId)
                    )}
                  />
                )}

              {doc?.documentStatus !== "ACCEPTED_PREHIRE" ||
                (doc?.documentStatus !== "CREATED_PREHIRE" && (
                  <DownloadButton
                    onClick={() => handleDownload(doc.documentUrl)}
                  />
                ))}
            </span>
          </div>
        ))}
        {docPackList.length > 0 && (
          <div className="text-center pointer">
            <ButtonUI
              p="7px"
              mt="10px"
              radius="6px"
              color="#1F68D1"
              borderColor="#1F68D1"
              variant="outlined"
              ml="80px"
              onClick={() => (
                toggleAddDocForm(true), setFormValues({}), setFormErrors({})
              )}
              rightIcon={<AddCircleOutlineOutlined fontSize="small" />}
            >
              Add Documents
            </ButtonUI>
          </div>
        )}
      </div>
    </div>
  );
};
