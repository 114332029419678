export const HotlistIcon = ({ ...props }) => {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10.233 12.9621C10.7774 12.9621 11.3095 12.8083 11.7622 12.5203C12.2148 12.2323 12.5676 11.8228 12.776 11.3438C12.9843 10.8648 13.0388 10.3377 12.9326 9.82924C12.8264 9.32073 12.5642 8.85363 12.1793 8.48702C11.7943 8.1204 11.3039 7.87074 10.77 7.76959C10.236 7.66844 9.68259 7.72035 9.17964 7.91876C8.67668 8.11717 8.2468 8.45317 7.94435 8.88426C7.6419 9.31535 7.48047 9.82218 7.48047 10.3407C7.4811 11.0357 7.77129 11.7021 8.28735 12.1936C8.80341 12.6851 9.50316 12.9615 10.233 12.9621Z"
        stroke="#C7C7D2"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.54297 15.1238C6.91666 14.5063 7.45455 13.9935 8.10249 13.6369C8.75042 13.2804 9.48555 13.0926 10.2339 13.0926C10.9822 13.0926 11.7173 13.2804 12.3653 13.6369C13.0132 13.9935 13.5511 14.5063 13.9248 15.1238"
        stroke="#C7C7D2"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.6787 18.7099V17.3214H15.8702V14.8758L19.0511 18.0517L15.9483 21V18.7099H11.6787Z"
        fill="#C7C7D2"
      />
      <path
        d="M10.287 18.746H5.35469C5.13393 18.7454 4.9224 18.6616 4.7663 18.5129C4.6102 18.3642 4.52223 18.1628 4.5216 17.9525V5.80332C4.52223 5.59328 4.61028 5.39205 4.76644 5.24374C4.92261 5.09544 5.13415 5.01215 5.35469 5.01215H15.0749C15.2952 5.01215 15.5065 5.09551 15.6623 5.24388C15.8181 5.39225 15.9056 5.59349 15.9056 5.80332V13.0001H17.3256V5.80332C17.3256 5.23481 17.0885 4.68958 16.6664 4.28758C16.2443 3.88558 15.6718 3.65974 15.0749 3.65974H5.35469C5.05892 3.65944 4.76598 3.71467 4.49263 3.82226C4.21929 3.92985 3.97088 4.0877 3.76163 4.28678C3.55238 4.48586 3.38638 4.72227 3.27312 4.98249C3.15986 5.2427 3.10156 5.52163 3.10156 5.80332V17.9525C3.10156 18.2343 3.15984 18.5134 3.27307 18.7737C3.3863 19.0341 3.55227 19.2706 3.76149 19.4699C3.97071 19.6691 4.21909 19.8272 4.49246 19.935C4.76582 20.0429 5.05881 20.0984 5.35469 20.0984H10.287V18.746Z"
        fill="#C7C7D2"
        stroke="#C7C7D2"
        strokeWidth="0.3"
      />
      <path
        d="M3.81193 16.0862H2.50076C2.28022 16.0856 2.06893 16.0018 1.91321 15.853C1.75749 15.7043 1.67004 15.5028 1.67004 15.2928V3.14358C1.67066 2.93393 1.75839 2.73304 1.91404 2.5848C2.0697 2.43656 2.28063 2.35301 2.50076 2.35242H12.2233C12.4434 2.35301 12.6544 2.43656 12.81 2.5848C12.9657 2.73304 13.0534 2.93393 13.054 3.14358V4.33596H14.474V3.14358C14.474 2.57507 14.2369 2.02984 13.8148 1.62784C13.3927 1.22584 12.8202 1 12.2233 1H2.50076C1.90382 1 1.33133 1.22584 0.909234 1.62784C0.487134 2.02984 0.250001 2.57507 0.250001 3.14358V15.2928C0.24969 15.5745 0.307678 15.8535 0.42065 16.1138C0.533622 16.3741 0.699363 16.6107 0.908396 16.81C1.11743 17.0093 1.36566 17.1674 1.63889 17.2752C1.91212 17.3831 2.20499 17.4386 2.50076 17.4386H3.81193V16.0862Z"
        fill="#C7C7D2"
        stroke="#C7C7D2"
        strokeWidth="0.3"
      />
    </svg>
  );
};
