import { VIDEO_DATA_ACTIONS } from "./actions";

const initialState = {
  videosArray: [],
};

export const videoInterviewDataReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case VIDEO_DATA_ACTIONS.SET_VIDEOS_ARRAY:
      return { ...state, videosArray: payload };

    default:
      return state;
  }
};
