import React, { useEffect } from "react";
import AddIcon from "@mui/icons-material/Add";
import { FilesIcon } from "../../../../../../../icons";
import "../../../../../../../assets/css/components/company-detail/_applicantTabs.css";
import { ButtonUI } from "../../../../../../ui/button";
import { useState } from "react";
import { AddDocument } from "./addDocument";
import Api from "../../../../../../../helper/Api";
import { toast } from "react-toastify";
import axios from "axios";
import fileDownload from "js-file-download";
import { NoColDataFound } from "../../../../../NoColDataFound";
import { ColoredLinearProgress } from "../../../../../coloredProgressBar";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import { indigo, red } from "@mui/material/colors";
import { IconButton, Tooltip } from "@mui/material";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";

export const DocumentsTab = ({ applicantData, selectedSuitCase }) => {
  const [openSidebar, setSidebar] = useState(false);
  const [documents, setDocuments] = useState([]);
  const [isLoader, setLoader] = useState(true);
  const [isSubmitting, setSubmitting] = useState(false);
  const formData = new FormData();

  useEffect(() => {
    getListDocument();
  }, []);

  const toggleDrawer = (open) => {
    setSidebar(open);
  };

  const handelCancel = (applicantDocumentsId) => {
    let answer = window.confirm(
      "Are you sure you want to delete this Document ?"
    );

    if (answer) {
      Api(
        "DELETE",
        `Job/DeleteApplicantDocuments?companyId=${applicantData.companyId}&applicantId=${applicantData.applicantId}&jobId=${selectedSuitCase.jobId}&applicantDocumentsId=${applicantDocumentsId}`
      )
        .then((res) => {
          res.data.isSuccess == true
            ? toast.success(res.data.message)
            : toast.error(res.data.message);
          getListDocument();
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const getListDocument = () => {
    Api(
      "GET",
      `Job/ListApplicantDocuments?companyId=${applicantData.companyId}&applicantId=${applicantData.applicantId}&jobId=${selectedSuitCase.jobId}`
    )
      .then((res) => {
        if (res.data.length > 0) {
          setDocuments(res.data);
        } else {
          setDocuments([]);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoader(false);
      });
  };

  const handleDownload = (file, displayName) => {
    axios
      .get(file, {
        responseType: "blob",
      })
      .then((res) => {
        fileDownload(res.data, displayName);
      });
  };

  const handleSubmit = (selectedDocumentsLists) => {
    setSubmitting(true);
    formData.append("companyId", applicantData.companyId);
    formData.append("jobId", applicantData.jobId);
    formData.append("applicantId", applicantData.applicantId);

    selectedDocumentsLists.map((doc, index) => {
      if (
        doc.document !== "" &&
        doc.notes !== "" &&
        doc.documentTypeId !== ""
      ) {
        formData.append(
          "selectedDocumentsLists[" + index + "].documentTypeId",
          selectedDocumentsLists[index].documentTypeId
        );
        formData.append(
          "selectedDocumentsLists[" + index + "].document",
          selectedDocumentsLists[index].document
        );
        formData.append(
          "selectedDocumentsLists[" + index + "].notes",
          selectedDocumentsLists[index].notes
        );
      }
    });

    Api("POST", "Job/AddApplicantDocuments", formData, "multipart")
      .then((res) => {
        if (res.data.isSuccess == true) {
          toast.success(res.data.message);
          toggleDrawer(false);
          getListDocument();
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        toast.error(err.message);
        console.log(err);
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <div className="tab-container">
      {openSidebar && (
        <AddDocument
          openSidebar={openSidebar}
          toggleDrawer={toggleDrawer}
          handleSubmit={handleSubmit}
          applicantId={applicantData.applicantId}
          isSubmitting={isSubmitting}
        />
      )}

      {isLoader === true ? (
        <ColoredLinearProgress />
      ) : documents.length > 0 ? (
        <>
          <div className="notes-header-container p-16">
            <span className="heading-label">Documents({documents.length})</span>
            <ButtonUI
              p="8px"
              leftIcon={<AddIcon sx={{ height: "16px", width: "16px" }} />}
              onClick={() => toggleDrawer(true)}
            >
              Add
            </ButtonUI>
          </div>
          <div className="notes-cards-container p-0-16">
            {documents.map((doc) => (
              <div className="doucments-card" id={doc.applicantDocumentsId}>
                <div className="doc-detail">
                  <FilesIcon
                    stroke="#9EA0A5"
                    line="#fff"
                    width="32px"
                    height="32px"
                  />
                  <div className="file-name-label">
                    {doc.displayFileName ? doc.displayFileName : "No Name"}
                  </div>
                </div>
                <div className="doc-detail">
                  <div role="button" className="pointer">
                    <Tooltip title="Download">
                      <IconButton
                        size="small"
                        onClick={() => {
                          handleDownload(doc.document, doc.displayFileName);
                        }}
                      >
                        <FileDownloadOutlinedIcon
                          sx={{ color: indigo[500] }}
                          fontSize="small"
                        />
                      </IconButton>
                    </Tooltip>
                  </div>
                  {/* {doc.applicantDocumentsId !== "" && (
                    <Tooltip title="Delete">
                      <IconButton
                        onClick={() => handelCancel(doc.applicantDocumentsId)}
                      >
                        <CloseOutlinedIcon
                          sx={{ color: red[500] }}
                          fontSize="small"
                        />
                      </IconButton>
                    </Tooltip>
                  )} */}
                </div>
              </div>
            ))}
          </div>
        </>
      ) : (
        <>
          <div className="notes-header-container p-16">
            <span className="heading-label font-blue">
              Documents: ({documents.length})
            </span>
            <ButtonUI
              p="8px"
              leftIcon={<AddIcon sx={{ height: "16px", width: "16px" }} />}
              onClick={() => toggleDrawer(true)}
            >
              Add
            </ButtonUI>
          </div>
          <NoColDataFound />
        </>
      )}
    </div>
  );
};
