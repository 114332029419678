import { IconButton, Tooltip, createTheme } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import Loader from "../../../components/common/Loader";
import NoRecordsFound from "../../../components/common/NoRecordsFound";
import Api from "../../../helper/Api";
import { useSelector } from "react-redux";
import { StyledPagination } from "../../../components/ui/pagination/pagination";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import * as FileSaver from "file-saver";
import XLSX from "sheetjs-style";
import { indigo } from "@material-ui/core/colors";
import { Link } from "react-router-dom";
import LocalPrintshopOutlinedIcon from "@mui/icons-material/LocalPrintshopOutlined";
import jsPDF from "jspdf";
import "jspdf-autotable";
import moment from "moment";
import { ReportSortFilter } from "./reportSortFilter";

export const JobReport = () => {
  const [isLoader, setIsLoader] = useState(false);
  const [rows, setRows] = useState([]);
  const [pageNumber, setPage] = useState(1);
  const [jobExportData, setJobExportData] = useState([]);
  const { companyData } = useSelector((state) => state.companyDataReducer);
  const [totalPage, setTotalPage] = useState(1);
  const [companyOptions, setCompanyOptions] = useState([]);
  const [deptOptions, setDeptOptions] = useState([]);
  const [locationOptions, setLocationOptions] = useState([]);
  const [flag, setFlag] = useState(0);
  const [searchBy, setSearchBy] = useState("");
  const [dateFrom, setDateFrom] = useState(new Date().toISOString());
  const [dateTo, setDateTo] = useState(new Date().toISOString());
  const [dateDisplay, setDateDisplay] = useState("");

  const theme = createTheme({
    palette: {
      action: {
        hover: "#fff",
      },
    },
  });

  useEffect(() => {
    getJobReport();
    getJobExportData();
    getLocationOptions();
    getDepartmentOptions();
    getSubCompanies();
  }, [pageNumber]);

  const getLocationOptions = () => {
    Api("GET", `Facilities/GetAllFacilities?companyId=${companyData.companyId}`)
      .then((res) => {
        setLocationOptions(res.data.data);
      })
      .catch((err) => console.log(err));
  };

  const getSubCompanies = () => {
    Api(
      "GET",
      `companies/getcompany?pageLimit=${15}&parentCompanyId=${
        companyData.companyId
      }&companyHirerchy=${companyData.companyHirerchy}`
    )
      .then((response) => {
        response.data.data.length
          ? setCompanyOptions(response.data.data)
          : setCompanyOptions([]);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsLoader(false);
      });
  };

  const getDepartmentOptions = () => {
    Api(
      "GET",
      `Department/GetDepartmentList?companyId=${companyData.companyId}&companyHirerchy=${companyData.companyHirerchy}`
    )
      .then((res) => {
        setDeptOptions(res.data);
      })
      .catch((err) => console.log(err));
  };

  const getJobReport = (
    flagFilter = flag,
    elementId = searchBy,
    fromDate = dateFrom,
    toDate = dateTo,
    dtDisplay = dateDisplay
  ) => {
    setIsLoader(true);

    const query = `companyId=${companyData.companyId}&flag=${flagFilter}&searchBy=${elementId}&dateTo=${toDate}&dateFrom=${fromDate}&dateDispaly=${dtDisplay}&pageLimit=2&pageNumber=${pageNumber}&orderBy=desc&orderByDirection=createdAt`;
    Api("GET", `Report/JobsReport?${query}`)
      .then((res) => {
        setRows(res.data.data);
        setTotalPage(res.data.totalPage);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsLoader(false);
      });
  };

  const getJobExportData = () => {
    Api(
      "GET",
      `Report/JobsReportExport?companyId=${companyData.companyId}&flag=${flag}&searchBy=${searchBy}&dateTo=${dateTo}&dateFrom=${dateFrom}&dateDispaly=${dateDisplay}&pageLimit=0&pageNumber=0`
    ).then((res) => {
      setJobExportData(res.data.data);
    });
  };

  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const exportToCSV = (fileName) => {
    setIsLoader(true);
    Api("GET", `Report/JobsReportExport?companyId=${companyData.companyId}`)
      .then((res) => {
        const ws = XLSX.utils.json_to_sheet(res.data.data);
        const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, fileName + fileExtension);
        setIsLoader(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoader(false);
      });
  };

  // pagination
  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const Row = rows?.map((val, key) => {
    return (
      <tr key={key}>
        <td className="td_name">{val.jobNo}</td>
        <td>{val.jobTitle}</td>
        <td>{val.facility}</td>
        <td>{val.location}</td>
        <td>{val.schedule}</td>
        <td>{val.opened}</td>
        <td>{val.daysPosted}</td>
        <td>{val.currentStage}</td>
        <td>{val.replacing}</td>
        <Tooltip title={val.newHire.join(", ")}>
          <td>
            {val.newHire.join(", ").substring(0, 20) +
              (val.newHire.length > 0 ? "..." : "-")}
          </td>
        </Tooltip>
        <td>{val.startDate[0]?.substring(0, 10)}</td>
      </tr>
    );
  });

  const exportPDF = () => {
    var doc = new jsPDF("l", "mm", "a4");

    doc.setFontSize(10);

    doc.text(20, 20, moment().format("MM/DD/YY, HH:mm a"));
    doc.text(260, 20, "TalentATS");

    doc.setFontSize(22);
    doc.text(20, 30, "Jobs Report");

    const columns = [
      { title: "Job Num.", dataKey: "Job Num." },
      { title: "Job Title", dataKey: "Job Title" },
      { title: "Facility", dataKey: "Facility" },
      { title: "Location", dataKey: "Location" },
      { title: "Schedule", dataKey: "Schedule" },
      { title: "Opened", dataKey: "Opened" },
      { title: "Days Posted", dataKey: "Days Posted" },
      { title: "Current Stage", dataKey: "Current Stage" },
      { title: "Replacing", dataKey: "Replacing" },
      { title: "New Hire", dataKey: "New Hire" },
      { title: "Start Date", dataKey: "Start Date" },
    ];

    var options = {
      margin: {
        top: 20,
      },
      startY: 40,
    };

    jobExportData.map((j) => {
      j["New Hire"] = j["New Hire"]?.join(", ")?.substring(0, 10);
      j["Start Date"] = j["Start Date"]?.join(", ")?.substring(0, 10);
    });

    doc.setFontSize(10);
    doc.autoTable(columns, jobExportData, options);

    doc.save("jobReport.pdf");
  };

  const handleSortFilter = (
    flagFilter,
    elemId,
    toDate,
    fromDate,
    dtDisplay
  ) => {
    if (flagFilter === 1) {
      setFlag(flagFilter);
      setSearchBy(elemId);
      setDateTo(toDate);
      setDateFrom(fromDate);
      setDateDisplay(dtDisplay);
      getJobReport(flagFilter, elemId, fromDate, toDate, dtDisplay);
    } else {
      getJobReport(flagFilter, elemId);
    }
  };

  const handleDateFilters = () => {};

  return (
    <div>
      <div className="company_content">
        <div className="d-flex flex-column border-bottom">
          <div className="d-flex report-header">
            <div className="company_text">Jobs Report</div>
            {rows?.length > 0 && (
              <div className="d-flex">
                <ReportSortFilter
                  locationOptions={locationOptions}
                  deptOptions={deptOptions}
                  companyOptions={companyOptions}
                  handleSortFilter={handleSortFilter}
                  handleDateFilters={handleDateFilters}
                />
                <div className="d-flex align-items-center">
                  <Tooltip title="Download">
                    <IconButton onClick={() => exportToCSV("Job_Report")}>
                      <FileDownloadOutlinedIcon sx={{ color: indigo[500] }} />
                    </IconButton>
                  </Tooltip>
                </div>
                <div className="d-flex align-items-center">
                  <Tooltip title="Print PDF">
                    <IconButton onClick={() => exportPDF()}>
                      <LocalPrintshopOutlinedIcon sx={{ color: indigo[500] }} />
                    </IconButton>
                  </Tooltip>
                </div>
              </div>
            )}
          </div>
          <div className="top-url">
            <p>
              <Link to="/reports">Reports &gt; </Link>
              <Link to="/reports/job">
                <strong>Jobs Report</strong>
              </Link>
            </p>
          </div>
        </div>

        {isLoader ? (
          <Loader inShow={true} />
        ) : rows?.length > 0 ? (
          <>
            <div className="company_table" style={{ marginTop: 0 }}>
              <table>
                <thead>
                  <tr className="report-titles">
                    <td className="th_name">Job Num.</td>
                    <td>Job Title</td>
                    <td>Facility</td>
                    <td>Location</td>
                    <td>Schedule</td>
                    <td>Opened</td>
                    <td>Days Posted</td>
                    <td>Current Stage</td>
                    <td>Replacing</td>
                    <td>New Hire</td>
                    <td>Start Date</td>
                  </tr>
                </thead>
                <tbody>{Row}</tbody>
              </table>
            </div>
            <StyledPagination
              count={totalPage}
              page={pageNumber}
              variant="outlined"
              shape="rounded"
              onChange={handlePageChange}
            />
          </>
        ) : (
          <NoRecordsFound />
        )}
      </div>
    </div>
  );
};
