import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import "../../../assets/css/components/company-detail/_systemSettings.css";
import { toast } from "react-toastify";
import Api from "../../../helper/Api";
import { TimeSelector } from "../../ui/pickers/time";
import { ButtonUI } from "../../ui/button";

export function SystemSettings({ ...props }) {
  const [formErrors, setFormErrors] = useState({});
  const [formValues, setFormValues] = useState([]);
  const [startTime, setStartTime] = useState("2022-06-07 11:00:00");
  const [endTime, setEndTime] = useState("2022-06-07 11:00:00");
  const [isLoading, setLoading] = useState(false);
  const location = useLocation();

  useEffect(() => {
    Api(
      "GET",
      `SystemSettings/GetSystemSettings?companyId=${location.state.companyId}`
    )
      .then((response) => {
        if (response.data) {
          setFormValues(response.data.systemSettingDetailsViewModel);
          let demoDate = "2022-06-07T";

          let utcStartDate = new Date(
            demoDate +
              response.data.systemSettingDetailsViewModel.batchIntStartTime +
              "Z"
          );
          let utcStartTime = utcStartDate.toString();
          setStartTime(utcStartTime);

          let utcEndDate = new Date(
            demoDate +
              response.data.systemSettingDetailsViewModel.batchIntEndTime +
              "Z"
          );
          let utcEndTime = utcEndDate.toString();
          setEndTime(utcEndTime);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const storeSettings = (e) => {
    e.preventDefault();
    setLoading(true);
    setFormErrors(validate(formValues));
    if (Object.keys(validate(formValues)).length > 0) {
      return;
    }

    let sTime = new Date(startTime).toISOString();
    let eTime = new Date(endTime).toISOString();
    formValues.batchIntStartTime = sTime.substring(
      sTime.lastIndexOf("T") + 1,
      sTime.lastIndexOf(".")
    );
    formValues.batchIntEndTime = eTime.substring(
      eTime.lastIndexOf("T") + 1,
      eTime.lastIndexOf(".")
    );

    let data = {};
    data.companyId = location.state.companyId;
    data.systemSettingDetailsViewModel = formValues;

    Api("POST", `SystemSettings/SetSystemSettings`, data)
      .then((response) => {
        response.data.isSuccess == true
          ? toast.success(response.data.message)
          : toast.error(response.data.message);
        setFormValues(data.systemSettingDetailsViewModel);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const errors = {};
  const validate = (values) => {
    if (!values.videoIntExpDays) {
      errors.videoIntExpDays = "Video interview expiry days is required.";
    } else if (isNaN(values.videoIntExpDays)) {
      errors.videoIntExpDays = "Name must be a number!";
    }

    if (!values.renotifyApplicantVideoInt) {
      errors.renotifyApplicantVideoInt =
        "Renotify aaplicant video interview is required.";
    } else if (isNaN(values.renotifyApplicantVideoInt)) {
      errors.renotifyApplicantVideoInt =
        "Renotify aaplicant video interview must be a number!";
    }

    if (!values.offerExpDays) {
      errors.offerExpDays = "Offer expiry days is required.";
    } else if (isNaN(values.offerExpDays)) {
      errors.offerExpDays = "Offer expiry days must be a number!";
    }

    if (!values.offerLetterExpDays) {
      errors.offerLetterExpDays = "Offer letter expiry days is required.";
    } else if (isNaN(values.offerLetterExpDays)) {
      errors.offerLetterExpDays = "Offer letter expiry days must be a number!";
    }

    if (!values.batchIntScheduleIncrement) {
      errors.batchIntScheduleIncrement =
        "Batch interview schedule increment is required.";
    } else if (isNaN(values.batchIntScheduleIncrement)) {
      errors.batchIntScheduleIncrement =
        "Batch interview schedule increment must be a number!";
    }

    return errors;
  };

  return (
    <div>
      <div className="common-container users">
        <div className="inner-container">
          <div className="top-url">
            <p>
              <Link to="/companylist"> Companies &gt; </Link>
              <strong>System Settings</strong>
            </p>
          </div>
          <div className="manage-container jobs">
            <div className="manage-bg active">
              <div className="parent-container-d">
                <form onSubmit={storeSettings} className="h-100">
                  <div className="settings_card">
                    <div className="settings_grid">
                      <div className="heading">Video Int. Expire Days:</div>
                      <div className="content">
                        <span>
                          Used to calculate the default expiration date for a
                          video interview
                        </span>
                        <span>
                          <input
                            type="text"
                            className="textInput"
                            name="videoIntExpDays"
                            value={formValues.videoIntExpDays ?? " "}
                            maxLength="6"
                            size="5"
                            onChange={handleChange}
                          />
                        </span>
                      </div>
                      {formErrors.videoIntExpDays ? (
                        <span className="error_msg">
                          {formErrors.videoIntExpDays}
                        </span>
                      ) : null}
                    </div>
                    <div className="settings_grid">
                      <div className="heading">
                        Renotify Applicant Video Int.:
                      </div>
                      <div className="content">
                        <span>
                          Number of days after which an applicant will
                          re-notified of an active video interview.
                        </span>
                        <span>
                          <input
                            type="text"
                            className="textInput"
                            name="renotifyApplicantVideoInt"
                            value={formValues.renotifyApplicantVideoInt ?? " "}
                            maxLength="6"
                            size="5"
                            onChange={handleChange}
                          />
                        </span>
                      </div>
                      {formErrors.renotifyApplicantVideoInt ? (
                        <span className="error_msg">
                          {formErrors.renotifyApplicantVideoInt}
                        </span>
                      ) : null}
                    </div>
                    <div className="settings_grid">
                      <div className="heading">Offer Expire Days:</div>
                      <div className="content">
                        <span>
                          Used to calculate the default expiration date for a
                          job offer.
                        </span>
                        <span>
                          <input
                            type="text"
                            className="textInput"
                            name="offerExpDays"
                            value={formValues.offerExpDays ?? " "}
                            maxLength="6"
                            size="5"
                            onChange={handleChange}
                          />
                        </span>
                      </div>
                      {formErrors.offerExpDays ? (
                        <span className="error_msg">
                          {formErrors.offerExpDays}
                        </span>
                      ) : null}
                    </div>
                    <div className="settings_grid">
                      <div className="heading">Offer Letter Expire Days:</div>
                      <div className="content">
                        <span>
                          Used to calculate the default expiration date for an
                          offer letter.
                        </span>
                        <span>
                          <input
                            type="text"
                            className="textInput"
                            name="offerLetterExpDays"
                            value={formValues.offerLetterExpDays ?? " "}
                            maxLength="6"
                            size="5"
                            onChange={handleChange}
                          />
                        </span>
                      </div>
                      {formErrors.offerLetterExpDays ? (
                        <span className="error_msg">
                          {formErrors.offerLetterExpDays}
                        </span>
                      ) : null}
                    </div>
                    <div className="settings_grid">
                      <div className="heading">Batch Interview Start Time:</div>
                      <div className="content">
                        <span className="text">
                          Start time for batch interviews.
                        </span>
                        <span>
                          <TimeSelector
                            value={startTime}
                            setFunction={setStartTime}
                          />
                        </span>
                      </div>
                    </div>
                    <div className="settings_grid">
                      <div className="heading">Batch Interview End Time:</div>
                      <div className="content">
                        <span className="text">
                          End time for batch interviews.
                        </span>
                        <span>
                          <TimeSelector
                            value={endTime}
                            setFunction={setEndTime}
                          />
                        </span>
                      </div>
                    </div>
                    <div className="settings_grid">
                      <div className="heading">
                        Batch Interview Schedule Increment:
                      </div>
                      <div className="content">
                        <span>
                          Number of minutes between available batch interview
                          start times.
                        </span>
                        <span>
                          <input
                            type="text"
                            className="textInput"
                            name="batchIntScheduleIncrement"
                            value={formValues.batchIntScheduleIncrement ?? " "}
                            maxLength="6"
                            size="5"
                            onChange={handleChange}
                          />
                        </span>
                      </div>
                      {formErrors.batchIntScheduleIncrement ? (
                        <span className="error_msg">
                          {formErrors.batchIntScheduleIncrement}
                        </span>
                      ) : null}
                    </div>
                  </div>
                  <div className="save_settings">
                    <ButtonUI
                      p="9px"
                      width="80px"
                      height="40px"
                      onClick={() => storeSettings()}
                    >
                      {isLoading ? <div className="dot-pulse"></div> : "Save"}
                    </ButtonUI>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
