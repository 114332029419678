import moment from "moment";
import { Checkbox, FormControlLabel } from "@mui/material";
import { sendEmails, sendPhones, sendPreference } from "./constant";
import AddIcon from "@mui/icons-material/Add";
import { useState } from "react";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { IconButton, Tooltip } from "@mui/material";
import { red } from "@mui/material/colors";

export const SequenceMessages = ({ values, errors, styles }) => {
  const [messages, setMessages] = useState(values.sequenceMessages);

  const addDynamicMessage = () => {
    values.sequenceMessages = [...messages];
    values.sequenceMessages.push({
      messageOrder: 0,
      sendStartDate: new Date(),
      sendStartTime: new Date(),
      batchMessagePreference: 1,
      multipleEmails: 2,
      multipleCellPhones: 2,
      sendPreferences: 1,
      emailSubject: "",
      includeGreetings: true,
      messageContent: "",
      sendDaysAfterMessage: "",
      sendModes: 1,
    });
    setMessages([...values.sequenceMessages]);
  };

  const removeDynamicMessage = (index) => {
    const data = [...messages];
    data.splice(index, 1);
    setMessages(data);
    values.sequenceMessages = [...data];
  };

  const handleChange = (e, index) => {
    const { name, value } = e.target;
    setMessages(
      messages.map((message) =>
        messages.indexOf(message) === index
          ? { ...message, [name]: value }
          : message
      )
    );
    values.sequenceMessages = messages.map((message) =>
      messages.indexOf(message) === index
        ? { ...message, [name]: value }
        : message
    );
  };

  return (
    <div>
      <div className="text-center mt-10">
        <u>Sequence Messages</u>
      </div>
      {messages.map((m, index) => (
        <div key={"dmessage" + index} className="message-box">
          <div>
            <label className="d-flex justify-content-between align-items-center">
              <strong>Message #{index + 1}</strong>
              {messages.length > 1 && (
                <Tooltip title="Delete Message">
                  <IconButton onClick={() => removeDynamicMessage(index)}>
                    <DeleteOutlineOutlinedIcon sx={{ color: red[500] }} />
                  </IconButton>
                </Tooltip>
              )}
            </label>
            <p>
              <strong>Send: </strong> {moment(m.sendStartDate).format("L")}{" "}
              starting at {moment(m.sendStartTime).format("LT")}{" "}
            </p>
            <div className="mt-20">How do you want to send this message?</div>
          </div>
          <div>
            <FormControlLabel
              control={<Checkbox value={1} checked={m.sendModes} />}
              label="Email"
            />
            <FormControlLabel
              control={<Checkbox value={2} defaultChecked />}
              label="Text"
            />
            <FormControlLabel
              control={<Checkbox value={3} defaultChecked />}
              label="Both"
            />
            <p className="select-or mt-0 mb-10">(Select one or both)</p>
          </div>
          <div className="d-flex flex-column">
            <label style={styles.label}>
              If the recipient has multiple email addresses...
            </label>
            <div>
              <select
                className="w-100"
                name="multipleEmails"
                onChange={(e) => handleChange(e, index)}
                value={m.multipleEmails || ""}
              >
                <option value="">Select</option>
                {sendEmails.map((item) => (
                  <option key={item.id} value={item.value}>
                    {item.value}
                  </option>
                ))}
              </select>
              {errors.multipleEmail && (
                <div className="error-txt">{errors.multipleEmail}</div>
              )}
            </div>
          </div>
          <div className="d-flex flex-column">
            <label style={styles.label}>
              If the recipient has multiple cell phones...
            </label>
            <div>
              <select
                className="w-100"
                name="multipleCellPhones"
                onChange={(e) => handleChange(e, index)}
                value={m.multipleCellPhones || ""}
              >
                <option value="">Select</option>
                {sendPhones.map((item) => (
                  <option key={item.id} value={item.value}>
                    {item.value}
                  </option>
                ))}
              </select>
              {errors.multipleEmail && (
                <div className="error-txt">{errors.multipleEmail}</div>
              )}
            </div>
          </div>
          <div className="d-flex flex-column">
            <label style={styles.label}>
              If the recipient has both email and phone...
            </label>
            <div>
              <select
                className="w-100"
                name="sendPreferences"
                onChange={(e) => handleChange(e, index)}
                value={m.sendPreferences || ""}
              >
                <option value="">Select</option>
                {sendPreference.map((item) => (
                  <option key={item.id} value={item.id}>
                    {item.value}
                  </option>
                ))}
              </select>
              {errors.sendPreferences && (
                <div className="error-txt">{errors.sendPreferences}</div>
              )}
            </div>
          </div>
          <div className="d-flex flex-column">
            <label style={styles.label}>Email Subject</label>
            <input
              name="emailSubject"
              onChange={(e) => handleChange(e, index)}
              value={m.emailSubject || ""}
              style={styles.input}
              placeholder="Email Subject Here"
            />
            {errors.emailSubject && (
              <div className="error-txt">{errors.emailSubject}</div>
            )}
          </div>
          <div className="d-flex flex-column">
            <label style={styles.label}>Message Content</label>
            <textarea
              name="messageContent"
              onChange={(e) => handleChange(e, index)}
              value={m.messageContent || ""}
              style={styles.input}
              placeholder="Message Content Here"
            />
            {errors.messageContent && (
              <div className="error-txt">{errors.messageContent}</div>
            )}
          </div>
          {/* <div className="d-flex flex-column align-items-center">
            <FormControlLabel
              control={<Checkbox checked={m.includeGreetings} />}
              label="Include Greeting"
            />
            <p className="create-msg-content" onClick={createMsgContent}>
              Create Message Contents
            </p>
          </div> */}
        </div>
      ))}
      <div>
        <p
          className="add-msg blue-text pointer d-flex align-items-center"
          onClick={addDynamicMessage}
        >
          <AddIcon />
          <strong>Add Message</strong>
        </p>
      </div>
    </div>
  );
};
