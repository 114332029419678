import { DateSelector } from "../../../../../../ui/pickers/date";
import { AddEditBox } from "../../../../../addEditPopup/AddEditBox";

export const SendOfferLetterForm = ({
  handleSubmit,
  openForm,
  toggleForm,
  formValues,
  setFormValues,
  offerLetterId,
  applicantId,
  isSubmitting,
}) => {
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleDateChange = (newValue) => {
    setFormValues({ ...formValues, ["expireDate"]: newValue.toISOString() });
  };

  const save = (e) => {
    e.preventDefault();
    handleSubmit(offerLetterId);
  };

  return (
    <AddEditBox
      handleSubmit={save}
      openForm={openForm}
      toggleForm={toggleForm}
      title="Send Message"
      applicantId={applicantId}
      isSubmitting={isSubmitting}
    >
      <div className="forms-input">
        <label>Message to applicant:</label>
        <textarea
          placeholder="Message"
          name="applicantMessage"
          value={formValues.applicantMessage}
          onChange={handleChange}
        />
      </div>

      <div className="forms-input">
        <label>Expires:</label>
        {openForm && (
          <DateSelector
            value={formValues.expireDate}
            disablePast={true}
            handleDateChange={handleDateChange}
          />
        )}
      </div>
    </AddEditBox>
  );
};
