import { Tooltip } from "@mui/material";
import { ButtonUI } from ".";
import { EditIconOutlined } from "../../../icons/edit";

export const EditButton = ({ onClick }) => {
  return (
    <Tooltip title="Edit">
      <ButtonUI
        variant="outlined"
        fontSize="11px"
        borderColor="#39A571"
        color="#39A571"
        mr="6px"
        p="5px"
        mt="5px"
        width="60px"
        onClick={onClick}
        rightIcon={<EditIconOutlined />}
      >
        Edit
      </ButtonUI>
    </Tooltip>
  );
};
