import { IconButton } from "@mui/material";
import { ButtonUI } from "../../ui/button";
import CloseIcon from "@mui/icons-material/Close";
import { ColoredLinearProgress } from "../coloredProgressBar";
import "../../../assets/css/components/ui/_dot-loader.css";
import { red } from "@mui/material/colors";

export const AddEditBox = (props) => {
    return (
        <>
            <div className="mini-forms" id={"mini_form_" + props.applicantId}>
                <header className="header">
                    <span>{props.title}</span>
                    <IconButton
                        onClick={() => props.toggleForm(false)}
                        size="small"
                    >
                        <CloseIcon fontSize="small" sx={{ color: red[500] }} />
                    </IconButton>
                </header>

                {props.isLoading ? (
                    <ColoredLinearProgress />
                ) : (
                    <form>
                        <div>{props.children}</div>
                    </form>
                )}
                <div className="btnContainer mt-10">
                    {props.isSubmitting === true ? (
                        <ButtonUI p="10px" width="80px" m="4px">
                            <div className="dot-pulse"></div>
                        </ButtonUI>
                    ) : (
                        <ButtonUI p="10px" width="80px" m="4px" onClick={props.handleSubmit}>
                            Submit
                        </ButtonUI>
                    )}

                    <ButtonUI
                        p="10px"
                        variant="outlined"
                        className="header close"
                        onClick={() => props.toggleForm(false)}
                        disabled={props?.isSubmitting ?? false}
                    >
                        Cancel
                    </ButtonUI>
                </div>
            </div>
        </>
    );
};
