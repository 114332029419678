import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import AddIcon from "@mui/icons-material/Add";
import PropTypes from "prop-types";
import Api from "../../../helper/Api";
import { toast } from "react-toastify";
import Loader from "../../common/Loader";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "../../../assets/css/components/company-detail/_appointments.css";
import NoRecordsFound from "../../common/NoRecordsFound";
import { ButtonUI } from "../../ui/button";
import { EditDeleteMenu } from "../../ui/editDeleteMenu";
import { AddEditDocPack } from "./addEditDocPack";
import { AddEditDoc } from "./addEditDoc";
import { EditDeleteDocMenu } from "../../ui/editDeleteMenu/editDelDocMenu";
import { UploadDocument } from "./uploadDoc";
import { sortAtoZ } from "../../../helper/constants";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import { AlertBox, showSuccess } from "../../../helper/utils";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <div>{children}</div>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const CustomTab = styled(Tab)`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #333333;
  letter-spacing: normal;
  text-transform: capitalize;

  &.Mui-selected {
    font-weight: 700;
    color: #333;
  }
`;

export function Documents() {
  const [tabQuickChats, setTabQuickChats] = useState();
  const [showNoDoc, setShowNoDoc] = useState(true);
  const [editImageShow, setEditImageShow] = useState(false);
  const [showAddEditLabel, setAddEditLabel] = useState("");
  const [newEditorShow, setNewEditorShow] = useState(true);
  const [formValues, setFormValues] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [editMode, setEditMode] = useState(false);
  const [docList, setDocList] = useState([]);
  const [docPackList, setDocPackList] = useState([]);
  const [isLoader, setIsLoader] = useState(true);
  const [editor, setEditor] = useState("");
  const location = useLocation();
  const [formValuesChat, setFormValuesChat] = useState({});
  const [formErrorsChat, setFormErrorsChat] = useState({});
  const [openDocPackForm, setOpenDocPackForm] = useState(false);
  const [openDocForm, setOpenDocForm] = useState(false);
  const [value, setValue] = React.useState(0);
  const [openUploadDocForm, setOpenUploadDoc] = useState(false);
  const [isSubmitting, setSubmitting] = useState(false);
  const formData = new FormData();
  const errorsChat = {};
  const errors = {};

  useEffect(() => {
    const interval = setInterval(() => {
      getDocumentList();
    }, 3000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    getDocumentPackList();
    getDocumentList();
  }, []);

  const handleChangeTab = (event, newValue) => {
    newValue === 1 ? setTabQuickChats(true) : setTabQuickChats(false);
    setValue(newValue);
  };

  const geteditorVal = (val) => {
    fetch(val)
      .then((response) => {
        return response.text();
      })
      .then((html) => {
        setEditor(html);
      });
  };

  const getDocumentList = () => {
    Api(
      "GET",
      `Document/GetDocumentList?companyId=${location.state.companyId}&companyHirerchy=${location.state.companyHirerchy}&searchText&OrderBy=updatedAt&OrderbyDirection=desc`
    )
      .then((res) => {
        if (res.data.data) {
          setDocList(sortAtoZ(res.data.data, "documentTypeDisplayName"));
        }
      })
      .catch((err) => {
        toast.error(err);
      })
      .finally(() => setIsLoader(false));
  };

  const getDocumentPackList = () => {
    Api(
      "GET",
      `DocumentPack/GetDocumentPackList?companyId=${location.state.companyId}&companyHirerchy=${location.state.companyHirerchy}&searchText&OrderBy=updatedAt&OrderbyDirection=desc`
    )
      .then((res) => {
        if (res.data.data) {
          setDocPackList(sortAtoZ(res.data.data, "documentGroupDisplayName"));
        }
      })
      .catch((err) => {
        toast.error(err);
      });
  };

  // Submit function for Document tab forms
  const handleSubmit = (e) => {
    e.preventDefault();
    setFormErrors(validate(formValues));
    if (Object.keys(validate(formValues)).length > 0) {
      return;
    }

    const params = {
      companyId: location.state.companyId,
      name: formValues.docName,
      documentTypeId: formValues.doc_type,
      note: formValues.notesName,
      documentId: formValues.documentId,
    };

    setSubmitting(true);

    if (editMode) {
      Api("PUT", "Document/EditDocument", params, "multipart")
        .then((res) => {
          if (res.data.isSuccess === true) {
            toast.success(res.data.message);
            getDocumentList();
            delete formValues.docFileShow;
            delete formValues.htmldocShow;
            toggleDocDrawer("right", false);
          } else {
            toggleDocDrawer("right", true);
            toast.error(res.data.message);
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setSubmitting(false);
        });

      setNewEditorShow(true);
      setEditor("");
    } else {
      Api("POST", "Document/AddDocument", params, "multipart")
        .then((res) => {
          if (res.data.isSuccess === true) {
            window.open(`/addEditDoc/${window.btoa(res.data.id)}`, "_blank");
            toast.success(res.data.message);
            getDocumentList();
            toggleDocDrawer("right", false);
          } else {
            toast.error(res.data.message);
            toggleDocDrawer("right", true);
          }
        })
        .catch((err) => {
          toast.error(err);
        })
        .finally(() => {
          setSubmitting(false);
        });
    }
  };

  const validate = (values) => {
    if (!values.doc_type) {
      errors.doc_type = "Document Type is required.";
    }

    if (
      !values.htmldocShow &&
      !values.docFileShow &&
      !values.docFile &&
      showNoDoc
    ) {
      errors.docFile = "DocumentFile is required.";
    }

    if (!values.docName) {
      errors.docName = "Document Name is required.";
    }

    if (!values.notesName) {
      errors.notesName = "Notes is required.";
    } else if (!isNaN(values.notesName)) {
      errors.notesName = "Notes must be a text!";
    }

    return errors;
  };

  const handleSubmitChat = (data) => {
    setFormErrorsChat(validateChat(data));

    if (Object.keys(validateChat(data)).length > 0) {
      return;
    }

    const params = {
      companyId: location.state.companyId,
      name: data.doc_pack_name,
      documentUploadRequestsViewModel: data.uploadReqList,
      documentGroupId: data.pack_doc_type,
      documentId: data.checkedDocs,
      documentPackId: data.documentPackId ? data.documentPackId : null,
    };

    setIsLoader(true);
    if (editMode) {
      Api("PUT", "DocumentPack/EditDocumentPack", params)
        .then((res) => {
          if (res.data.isSuccess === true) {
            toast.success(res.data.message);
            getDocumentPackList();
            toggleDocPackDrawer("right", false);
          } else {
            toast.error(res.data.message);
          }
        })
        .catch((err) => {
          toast.error(err);
        })
        .finally(() => {
          setIsLoader(false);
        });
    } else {
      Api("POST", "DocumentPack/AddDocumentPack", params)
        .then((res) => {
          if (res.data.isSuccess === true) {
            toast.success(res.data.message);
            getDocumentPackList();
            toggleDocPackDrawer("right", false);
          } else {
            toast.error(res.data.message);
          }
        })
        .catch((err) => {
          toast.error(err);
        })
        .finally(() => {
          setIsLoader(false);
        });
    }
  };

  const addDataTableChat = (e) => {
    setAddEditLabel("Add Document Pack");
  };

  const validateChat = (values) => {
    if (!values.doc_pack_name) {
      errorsChat.doc_pack_name = "Document pack name is required.";
    }

    if (!values.pack_doc_type) {
      errorsChat.pack_doc_type = "Type is required.";
    }

    // if (checkedDocs.length === 0) {
    //   errorsChat.checked_docs = "Please select one document.";
    // }

    // if (uploadReqList.length > 0) {
    //   uploadReqList.map((req) =>
    //     req.instructions === ""
    //       ? (req.err_instructions = "Instructions is required.")
    //       : (req.err_instructions = "")
    //   );
    //   uploadReqList.map((req) =>
    //     req.name == ""
    //       ? (req.err_name = "Name is required.")
    //       : (req.err_name = "")
    //   );
    // }

    return errorsChat;
  };

  function handleEdit(dataId) {
    setEditMode(true);
    if (tabQuickChats) {
      setAddEditLabel("Edit Document Pack");
      const listItemValueChat = docPackList.filter((items) => {
        return items.documentPackId === dataId ? items : null;
      });

      setFormValuesChat({
        ["doc_pack_name"]: listItemValueChat[0].name,
        ["pack_doc_type"]: listItemValueChat[0].documentGroupId,
        ["documentPackId"]: listItemValueChat[0].documentPackId,
        ["checkedDocs"]: listItemValueChat[0].documentId,
        ["uploadReqList"]: listItemValueChat[0].documentUploadRequestsViewModel,
      });
      toggleDocPackDrawer("right", true);
    } else {
      setAddEditLabel("Edit Document");

      const listItemValue = docList.filter((items) => {
        return items.documentId === dataId ? items : null;
      });

      if (listItemValue[0].isUpload) {
        setEditImageShow(true);
        setShowNoDoc(true);
      } else {
        setNewEditorShow(false);
        setShowNoDoc(false);
      }

      fetch(listItemValue[0].htmlContent)
        .then((response) => {
          return response.text();
        })
        .then((html) => {
          setEditor(html);
        });

      setFormValues({
        ["docName"]: listItemValue[0].name,
        ["doc_type"]: listItemValue[0].documentTypeId,
        ["notesName"]: listItemValue[0].note,
        ["htmldocShow"]: listItemValue[0].isUpload
          ? null
          : listItemValue[0].htmlContent,
        ["docFileShow"]: listItemValue[0].isUpload
          ? listItemValue[0].htmlContent
          : null,
        ["docFile"]: null,
        ["docContent"]: null,
        ["documentId"]: dataId,
      });

      toggleDocDrawer("right", true);
      geteditorVal(listItemValue[0].htmlContent);
    }
  }

  function handleDelete(dataId) {
    // Delete data of row table - popup clicked delete
    AlertBox().then((res) => {
      if (!res.isConfirmed) return;

      setIsLoader(true);
      if (tabQuickChats) {
        Api(
          "DELETE",
          `DocumentPack/DeleteDocumentPack?documentPackId=${dataId}`
        )
          .then((response) => {
            if (response.data.isSuccess) {
              showSuccess("Deleted", response.data.message);
              getDocumentPackList();
            } else {
              toast.error(response.data.message);
            }
          })
          .catch((error) => {
            toast.error(error);
          })
          .finally(() => {
            setIsLoader(false);
          });
      } else {
        Api(
          "DELETE",
          `Document/DeleteDocument?documentId=${dataId}`,
          formValues
        )
          .then((response) => {
            if (response.data.isSuccess) {
              showSuccess("Deleted", response.data.message);
              getDocumentList();
              getDocumentPackList();
            } else {
              toast.error(response.data.message);
            }
          })
          .catch((error) => {
            toast.error(error);
          })
          .finally(() => {
            setIsLoader(false);
          });
      }
    });
  }

  const handleUpload = (selectedDocList) => {
    setSubmitting(true);
    formData.append("companyId", location.state.companyId);

    selectedDocList.map((doc, index) => {
      if (
        doc.document !== "" &&
        doc.notes !== "" &&
        doc.documentTypeId !== ""
      ) {
        formData.append(
          "documentList[" + index + "].documentTypeId",
          selectedDocList[index].documentTypeId
        );
        formData.append(
          "documentList[" + index + "].docName",
          selectedDocList[index].docName
        );
        formData.append(
          "documentList[" + index + "].uploadDocuments",
          selectedDocList[index].document
        );
        formData.append(
          "documentList[" + index + "].notes",
          selectedDocList[index].notes
        );
      }
    });

    Api("POST", "Document/AddUploadDocuments", formData, "multipart")
      .then((res) => {
        if (res.data.isSuccess === true) {
          toast.success(res.data.message);
          toggleUploadDocDrawer("right", false);
          getDocumentList();
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => setSubmitting(false));
  };

  const toggleDocPackDrawer = (anchor, open) => {
    setOpenDocPackForm({ ...openDocPackForm, [anchor]: open });
  };

  const toggleDocDrawer = (anchor, open) => {
    setOpenDocForm({ ...openDocForm, [anchor]: open });
  };

  const toggleUploadDocDrawer = (anchor, open) => {
    setOpenUploadDoc({ ...openUploadDocForm, [anchor]: open });
  };

  const openDocument = (data) => {
    // if (data.docS3URL !== "" || data.docS3URL !== null) {
    //   window.open(data.docS3URL, "_blank");
    // } else {
    window.open(`/doc-content/${window.btoa(data.documentId)}`, "_blank");
    // }
  };

  return (
    <div>
      {openDocForm && (
        <AddEditDoc
          toggleDrawer={toggleDocDrawer}
          openSidebar={openDocForm}
          handleSubmit={handleSubmit}
          formValues={formValues}
          setFormValues={setFormValues}
          formErrors={formErrors}
          isSubmitting={isSubmitting}
          showAddEditLabel={showAddEditLabel}
        />
      )}

      {openUploadDocForm && (
        <UploadDocument
          toggleDrawer={toggleUploadDocDrawer}
          openSidebar={openUploadDocForm}
          isSubmitting={isSubmitting}
          handleSubmit={handleUpload}
          formErrors={formErrors}
        />
      )}

      {openDocPackForm && (
        <AddEditDocPack
          openSidebar={openDocPackForm}
          handleSubmit={handleSubmitChat}
          toggleDrawer={toggleDocPackDrawer}
          formValuesChat={formValuesChat}
          setFormValuesChat={setFormValuesChat}
          formErrorsChat={formErrorsChat}
          showAddEditLabel={showAddEditLabel}
          docList={docList}
        />
      )}

      <div className="common-container users">
        <div className="inner-container documents">
          <div className="top-url">
            <p>
              <Link to="/companylist"> Companies &gt; </Link>
              <strong>Documents</strong>
            </p>
          </div>
          <div className="manage-container jobs quick">
            <div className="manage-bg active">
              <div className="parent-container-d">
                <div className="top-search">
                  <Tabs
                    value={value}
                    onChange={handleChangeTab}
                    aria-label="Quick notes tab"
                  >
                    <CustomTab label="Documents" {...a11yProps(0)} />
                    <CustomTab label="Documents Packs" {...a11yProps(1)} />
                  </Tabs>
                  {tabQuickChats ? (
                    <ButtonUI
                      p="12px"
                      leftIcon={<AddIcon fontSize="small" />}
                      onClick={() => (
                        addDataTableChat(),
                        toggleDocPackDrawer("right", true),
                        setEditMode(false),
                        setFormValuesChat({}),
                        setFormErrorsChat({})
                      )}
                    >
                      Add Document Pack
                    </ButtonUI>
                  ) : (
                    <div className="d-flex">
                      <ButtonUI
                        p="12px"
                        className="profile-img"
                        leftIcon={<FileUploadOutlinedIcon fontSize="small" />}
                        onClick={() => (
                          toggleUploadDocDrawer("right", true),
                          setFormValues({}),
                          setFormErrors({})
                        )}
                      >
                        Upload Documents
                      </ButtonUI>
                      <ButtonUI
                        p="12px"
                        ml="-10px"
                        leftIcon={<AddIcon fontSize="small" />}
                        onClick={() => (
                          setAddEditLabel("Create New Documents"),
                          setEditor(""),
                          toggleDocDrawer("right", true),
                          setNewEditorShow(true),
                          setEditMode(false),
                          setFormValues({}),
                          setFormErrors({}),
                          setShowNoDoc(false)
                        )}
                      >
                        Create New Documents
                      </ButtonUI>
                    </div>
                  )}
                </div>
                <div className="manage-checkboxes-none">
                  <TabPanel value={value} index={0}>
                    {isLoader ? (
                      <Loader inShow={true} />
                    ) : docList.length > 0 ? (
                      <>
                        <div className="cards-contain">
                          {docList.map((row) => (
                            <div className="cust-cards" key={row.documentId}>
                              <div className="date-sec">
                                <span>{row.documentTypeDisplayName}</span>
                                <div className="position-relative">
                                  {location.state.companyId === row.companyId && (
                                    <EditDeleteDocMenu
                                      id={row.documentId}
                                      onEdit={handleEdit}
                                      onDelete={handleDelete}
                                    />
                                  )}
                                </div>
                              </div>
                              <span
                                className="doc-name"
                                onClick={() => openDocument(row)}
                              >
                                <p>{row.name}</p>
                              </span>
                            </div>
                          ))}
                        </div>
                      </>
                    ) : (
                      <NoRecordsFound />
                    )}
                  </TabPanel>
                  <TabPanel value={value} index={1}>
                    {docPackList.length > 0 ? (
                      <>
                        <div className="cards-contain">
                          {docPackList.map((row) => (
                            <div
                              className="cust-cards"
                              key={row.documentPackId}
                            >
                              <div className="date-sec">
                                <span>{row.documentGroupDisplayName}</span>
                                <div className="position-relative">
                                  {location.state.companyId === row.companyId && (
                                    <EditDeleteMenu
                                      id={row.documentPackId}
                                      onEdit={handleEdit}
                                      onDelete={handleDelete}
                                    />
                                  )}
                                </div>
                              </div>
                              <div className="date-sec active">
                                <p>{row.name}</p>
                                <p>
                                  {row.documentId.length +
                                    row.documentUploadRequestsViewModel.length +
                                    " Docs"}
                                </p>
                              </div>
                            </div>
                          ))}
                        </div>
                      </>
                    ) : (
                      <NoRecordsFound />
                    )}
                  </TabPanel>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
