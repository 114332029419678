import { Checkbox, FormControlLabel } from "@mui/material";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Api from "../../../helper/Api";
import { AddEditPopup } from "../../common/addEditPopup/index";
export const AddEditDocPack = ({
  showAddEditLabel,
  openSidebar,
  toggleDrawer,
  handleSubmit,
  formValuesChat,
  setFormValuesChat,
  formErrorsChat,
  docList,
}) => {
  const [allDocTypes, setAllDocTypes] = useState([]);
  const [uploadReqList, setUploadReqList] = useState([]);
  const [checkedDocs, setCheckedDocs] = useState([]);
  const location = useLocation();

  useEffect(() => {
    getDocTypes();
  }, []);

  useEffect(() => {
    if (Object.keys(formValuesChat).length > 0 && openSidebar.right === true) {
      setCheckedDocs(formValuesChat.checkedDocs);
      setUploadReqList(formValuesChat.uploadReqList);
    } else if (
      Object.keys(formValuesChat).length === 0 &&
      openSidebar.right === true
    ) {
      setCheckedDocs([]);
      setUploadReqList([]);
    }
  }, [openSidebar.right]);

  // handle input change
  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...uploadReqList];

    if (name === "imageAllow") {
      list[index][name] = e.target.checked;
    } else {
      list[index][name] = value;
    }
    setUploadReqList(list);
  };

  // handle click event of the Remove button
  const handleRemoveClick = (index) => {
    const list = [...uploadReqList];
    list.splice(index, 1);
    setUploadReqList(list);
  };

  // handle click event of the Add button
  const handleAddClick = () => {
    setUploadReqList([
      ...uploadReqList,
      {
        name: "",
        instructions: "",
        imageAllow: false,
        companyId: location.state.companyId,
      },
    ]);
  };

  const handleChangeChat = (e) => {
    const { name, value } = e.target;
    setFormValuesChat({ ...formValuesChat, [name]: value });
  };

  //get document type dropdown
  const getDocTypes = () => {
    Api("GET", "Document/GetDocumentType?type=docgroup")
      .then((res) => {
        if (res.data) {
          setAllDocTypes(res.data);
        } else {
          setAllDocTypes([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const addDataCheck = (id) => {
    if (checkedDocs.includes(id)) {
      setCheckedDocs(checkedDocs.filter((docs) => docs !== id)); // pop
    } else {
      setCheckedDocs([...checkedDocs, id]); // or push
    }
  };

  const saveDocPack = (e) => {
    e.preventDefault();
    const data = {
      doc_pack_name: formValuesChat.doc_pack_name,
      documentPackId: formValuesChat?.documentPackId,
      pack_doc_type: formValuesChat.pack_doc_type,
      uploadReqList: uploadReqList,
      checkedDocs: checkedDocs,
    };

    setFormValuesChat(data);
    handleSubmit(data);
  };

  return (
    <AddEditPopup
      openSidebar={openSidebar}
      toggleDrawer={toggleDrawer}
      handleSubmit={saveDocPack}
      title={showAddEditLabel}
    >
      <div className="in-forms">
        <div className="forms-input">
          <label>Document Pack Name</label>
          <input
            type="text"
            placeholder="Enter a name"
            name="doc_pack_name"
            id="doc_pack_name"
            value={formValuesChat.doc_pack_name}
            onChange={handleChangeChat}
          />
          {formErrorsChat.doc_pack_name ? (
            <span className="error_msg">{formErrorsChat.doc_pack_name}</span>
          ) : null}
        </div>
        <div className="forms-input">
          <label>Type</label>
          <select
            name="pack_doc_type"
            id="pack_doc_type"
            value={formValuesChat.pack_doc_type}
            onChange={handleChangeChat}
          >
            <option value="none">Select</option>
            {allDocTypes.map((docs) => (
              <option key={docs.documentTypeId} value={docs.documentTypeId}>
                {docs.name}
              </option>
            ))}
          </select>
          {formErrorsChat.pack_doc_type ? (
            <span className="error_msg">{formErrorsChat.pack_doc_type}</span>
          ) : null}
        </div>
        <p className="select-doc">Select Documents</p>
        {formErrorsChat.checked_docs ? (
          <span className="error_msg">{formErrorsChat.checked_docs}</span>
        ) : null}
        {docList.map((items) => (
          <div className="select-doc-container" key={items.documentId}>
            <FormControlLabel
              control={
                <Checkbox
                  disableRipple
                  name="documents_data"
                  id={"docs" + items.documentId}
                  onChange={(e) => addDataCheck(items.documentId)}
                  checked={
                    checkedDocs.includes(items.documentId) ? true : false
                  }
                />
              }
              label={items.documentTypeDisplayName}
            />
            <p className="select-doc-desc">{items.name?.substring(0, 25)}</p>
          </div>
        ))}

        {uploadReqList.map((req, i) => (
          <div className="add-upload-container" key={i}>
            <div className="text-right">
              <img
                src={
                  require("../../../assets/images/add-upload-close.svg").default
                }
                alt="close-img"
                className="close-img"
                onClick={() => handleRemoveClick(i)}
              />
            </div>
            <div className="forms-input">
              <label>Name</label>
              <input
                type="text"
                placeholder="Enter a name"
                name="name"
                id="name"
                value={req.name}
                onChange={(e) => handleInputChange(e, i)}
              />
              {req.err_name ? (
                <span className="error_msg">{req.err_name}</span>
              ) : null}
            </div>
            <div className="forms-input">
              <label>Instructions</label>
              <textarea
                placeholder="Enter a instructions..."
                name="instructions"
                id="instructions"
                cols="20"
                rows="4"
                value={req.instructions}
                onChange={(e) => handleInputChange(e, i)}
              ></textarea>
              {req.err_instructions ? (
                <span className="error_msg">{req.err_instructions}</span>
              ) : null}
            </div>
            <div className="forms-input check-box">
              <FormControlLabel
                control={
                  <Checkbox
                    disableRipple
                    name="imageAllow"
                    onChange={(e) => handleInputChange(e, i)}
                    checked={req.imageAllow}
                  />
                }
                label="Allow Images Only"
              />
            </div>
          </div>
        ))}

        <p className="add-upload" onClick={() => handleAddClick()}>
          + Add Upload Request
        </p>
      </div>
    </AddEditPopup>
  );
};
