import {
  Badge,
  Divider,
  ListItemIcon,
  Menu,
  MenuItem,
  Tooltip,
} from "@mui/material";
import { userAsCompany } from "../../../helper/constants";
import React, { useEffect } from "react";
import { Box } from "@mui/system";
import { Link, useNavigate } from "react-router-dom";
import { NotificationIcon } from "../../../icons";
import BackgroundLetterAvatars from "../../common/layouts/BackgroundLetterAvatars";
import { ButtonUI } from "../button";
import Api from "../../../helper/Api";
import { useState } from "react";
import moment from "moment";
import { HubConnectionBuilder, LogLevel } from "@microsoft/signalr";
import { useSelector } from "react-redux";

export const NotificationMenu = (props) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();
  const [notifications, setNotifications] = useState([]);
  const { companyData } = useSelector((state) => state.companyDataReducer);
  const BASEURL = process.env.REACT_APP_BASE_URL;

  useEffect(async () => {
    var the_arr = BASEURL.split("/");
    the_arr.pop();
    the_arr.pop();
    var apiUrl = the_arr.join("/");

    const connect = new HubConnectionBuilder()
      .withUrl(`${apiUrl}/notifications`)
      .configureLogging(LogLevel.Information)
      .build();

    const data = [];

    connect.on("ReceiveNotification", (msg, username, email) => {
      if (data.length > 5) {
        data.pop();
      }

      data.unshift({
        msg: msg,
        email: email,
        username: username,
      });

      setNotifications(data);
    });

    await connect.start();
    getFeedConnection(connect.connection.connectionId);
  }, []);

  const getFeedConnection = (connId) => {
    const params = {
      companyId: companyData.companyId,
      connectionId: connId,
      userId: localStorage.getItem("user"),
    };

    Api("POST", `Notification/manageFeedConnection`, params).then();
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleProfile = () => {
    navigate("/settings");
  };

  return (
    <React.Fragment>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          textAlign: "center",
          width: "52px",
          height: "52px",
          borderRadius: "50%",
          "&:hover": {
            backgroundColor: "rgba(0, 0, 0, 0.04)",
          },
          "& span": {
            margin: "0 auto",
          },
        }}
      >
        <Tooltip title="Notifications">
          <Badge
            badgeContent={notifications?.length}
            overlap="circular"
            color="success"
            max={10}
          >
            <NotificationIcon
              onClick={handleClick}
              stroke={
                userAsCompany(window.location.hostname) ? "#3954A5" : "#fff"
              }
              width="22"
              height="22"
              className="icon search-icon"
            />
          </Badge>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 7,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        {notifications.length > 0 ? (
          notifications?.map((items, i) => (
            <MenuItem>
              <div className="notifi-block" key={items.id}>
                <ListItemIcon>
                  {/* <BackgroundLetterAvatars name={items.username} /> */}
                </ListItemIcon>
                <div className="notifi-msg">
                  <span>{items.username}</span> <label>## {items.email}</label>
                  <div
                    className="cards-heading"
                    dangerouslySetInnerHTML={{
                      __html: items.msg,
                    }}
                  ></div>
                  <label className="timing">
                    {moment(items.notificationDate).fromNow()}
                  </label>
                </div>
              </div>
              <Divider />
            </MenuItem>
          ))
        ) : (
          <>
            <MenuItem sx={{ margin: "auto" }}>
              <span>No Recent Notifications found !</span>
            </MenuItem>
            <Divider />
          </>
        )}
        <MenuItem>
          <Link to="/notification">
            <ButtonUI p="9px">See all Notifications</ButtonUI>
          </Link>
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
};
