import { useState } from "react";
import { AddEditBox } from "../../../../../addEditPopup/AddEditBox";

export const CancelDocPackForm = ({
  openForm,
  toggleForm,
  applicantId,
  cancleDocPack,
  isSubmitting,
}) => {
  const [cancelComment, setCancelComment] = useState();

  const handleChange = (e) => {
    setCancelComment(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    cancleDocPack(cancelComment);
  };

  return (
    <AddEditBox
      openForm={openForm}
      toggleForm={toggleForm}
      title="Cancel this Document pack ?"
      applicantId={applicantId}
      handleSubmit={handleSubmit}
      isSubmitting={isSubmitting}
    >
      <div className="forms-input">
        <label>Message to Applicant: </label>
        <textarea
          placeholder="Message"
          name="applicantMessage"
          value={cancelComment}
          onChange={handleChange}
        />
      </div>
    </AddEditBox>
  );
};
