export const UsersIcon = ({...props}) => {
    return (
        <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path 
                d="M12 16.875C14.0711 16.875 15.75 15.1961 15.75 13.125C15.75 11.0539 14.0711 9.375 12 9.375C9.92893 9.375 8.25 11.0539 8.25 13.125C8.25 15.1961 9.92893 16.875 12 16.875Z" 
                stroke="#C7C7D2" 
                strokeWidth="2" 
                strokeLinecap="round" 
                strokeLinejoin="round"
            />
            <path 
                d="M18.375 10.875C19.2484 10.8743 20.1098 11.0772 20.891 11.4677C21.6722 11.8583 22.3516 12.4256 22.8751 13.1246" 
                stroke="#C7C7D2" 
                strokeWidth="2" 
                strokeLinecap="round" 
                strokeLinejoin="round"
            />
            <path 
                d="M1.12451 13.1248C1.64805 12.4257 2.32742 11.8584 3.10863 11.4678C3.88985 11.0772 4.75138 10.8743 5.62478 10.875" 
                stroke="#C7C7D2" 
                strokeWidth="2" 
                strokeLinecap="round" 
                strokeLinejoin="round"
            />
            <path 
                d="M6.60303 20.2499C7.0957 19.238 7.86296 18.385 8.81722 17.7882C9.77148 17.1914 10.8743 16.875 11.9998 16.875C13.1253 16.875 14.2281 17.1914 15.1824 17.7882C16.1366 18.3849 16.9039 19.238 17.3966 20.2499" 
                stroke="#C7C7D2" 
                strokeWidth="2" 
                strokeLinecap="round" 
                strokeLinejoin="round"
            />
            <path 
                d="M5.62493 10.875C5.05571 10.8749 4.49828 10.7129 4.01771 10.4078C3.53714 10.1027 3.15328 9.66726 2.91095 9.1522C2.66861 8.63715 2.57781 8.06378 2.64914 7.49906C2.72047 6.93433 2.95099 6.40155 3.31378 5.96293C3.67658 5.52431 4.15667 5.19796 4.69799 5.02198C5.23932 4.84599 5.81954 4.82765 6.37091 4.96908C6.92227 5.11052 7.42202 5.40589 7.81179 5.82072C8.20157 6.23554 8.46528 6.7527 8.57215 7.31179" 
                stroke="#C7C7D2" 
                strokeWidth="2" 
                strokeLinecap="round" 
                strokeLinejoin="round"
            />
            <path 
                d="M15.4277 7.31137C15.5345 6.75219 15.7982 6.23494 16.188 5.82002C16.5778 5.40511 17.0776 5.10965 17.629 4.96816C18.1805 4.82667 18.7608 4.84499 19.3022 5.02098C19.8436 5.19696 20.3238 5.52335 20.6866 5.96203C21.0494 6.4007 21.28 6.93355 21.3513 7.49836C21.4226 8.06316 21.3318 8.6366 21.0894 9.15171C20.847 9.66683 20.4631 10.1023 19.9824 10.4074C19.5018 10.7125 18.9443 10.8745 18.375 10.8745" 
                stroke="#C7C7D2" 
                strokeWidth="2" 
                strokeLinecap="round" 
                strokeLinejoin="round"
            />
        </svg>
    )
}
