import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { JobCard } from "../../../common/cards/JobCard";
import { ButtonUI } from "../../../ui/button";
import Api from "../../../../helper/Api";
import { useDispatch } from "react-redux";
import { JOB_DATA_ACTIONS } from "../../../../redux/jobData/actions";
import { AddEditJob } from "../../addEditPopup/AddEditJob";
import { toast } from "react-toastify";
import { ProfileCard } from "../../cards/ProfileCard";
import { APPLICANT_DATA_ACTIONS } from "../../../../redux/applicantData/actions";
import { IconButton, Tooltip } from "@mui/material";
import { NoColDataFound } from "../../NoColDataFound";
import { AddApplicantForm } from "./content/addApplicant";
import CloseIcon from "@mui/icons-material/Close";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import {
  skeletonBaseColor,
  skeletonBorderRadius,
  skeletonHighLightColor,
} from "../../../../helper/constants";
import Repeat from "../../../../helper/utils";
import { SortDateApplicant } from "../../../../icons/sort/date";
import { ShowInActive } from "../../../../icons/inactive/show";
import { indigo, red } from "@mui/material/colors";
import flashIcon from "../../../../assets/images/flash_icon.svg";
import { SEQUENCE_DATA_ACTIONS } from "../../../../redux/sequenceData/actions";

const SEQ_ICON = {
  width: "17px",
  height: "17px",
  verticalAlign: "bottom",
};

const COLOR = {
  "": "",
  COMPLETE: "green",
  IN_PROGRESS: "yellow",
  NOT_STARTED: "pink",
};

export const JobSidebar = ({ jobData }) => {
  const [isLoader, setIsLoader] = useState(true);
  const [openTextbox, setOpenTextbox] = useState(false);
  const [openSidebar, setSidebar] = useState(false);
  const [formValues, setFormValues] = useState({});
  const [color, setColor] = useState("");
  const [jobProgressStatus, setJobProgressStatus] = useState("");
  const [count, setCount] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState("NEW_APPLICATION");
  const [openAddForm, setAddForm] = useState(false);
  const formData = new FormData();
  const [isSubmitting, setSubmitting] = useState(false);
  const [isSavingProgress, setIsSavingProgress] = useState(false);
  const [allApplicants, setAllApplicants] = useState([]);
  const dispatch = useDispatch();
  const [askForConfirmation, setAskForConfirmation] = useState(0);
  const [confirmData, setConfirmData] = useState([]);
  const [name, setName] = useState("");
  const [label, setLabel] = useState("New Application");
  const [isAligable, setAligible] = useState(true);
  const [isClosingTab, setIsClosingTab] = useState(false);
  const [isSort, setSort] = useState(false);
  const { jobsArray } = useSelector((state) => state.jobDataReducer);
  const [jobStatusDescription, setJobStatusDescription] = useState();
  const [inputDescription, setInputDescription] = useState("");
  const [displayedDescription, setDisplayedDescription] = useState(
    jobData.jobStatusDescription || ""
  );

  const { applicantsArray, isApplicantRefresh } = useSelector(
    (state) => state.applicantDataReducer
  );

  const userName = localStorage.getItem("userName");

  useEffect(() => {
    dispatch({
      type: JOB_DATA_ACTIONS.SET_JOB_CYCLE_STATUS,
      payload: "NEW_APPLICATION",
    });
    setJobProgressStatus(jobsArray[0]?.jobProgressStatus);
    setColor(COLOR[jobData?.jobProgressStatus]);
    getCount("ACTIVEHIRED");
    getAllApplicants("NEW_APPLICATION");
    setJobStatusDescription(jobsArray[0].jobProgressStatusDescription);
    setDisplayedDescription(jobsArray[0].jobProgressStatusDescription);
  }, []);

  useEffect(() => {
    if (isApplicantRefresh) {
      getCount("ACTIVEHIRED");
      getAllApplicants(selectedStatus);
      dispatch({ type: APPLICANT_DATA_ACTIONS.SET_APPLICANTS_REFRESH });
    }
  }, [isApplicantRefresh]);

  const handlejobApplicantStatus = (status, statusLabel) => {
    setLabel(statusLabel);
    setAllApplicants([]);
    setSelectedStatus(status);
    setIsLoader(true);
    // getCount("ACTIVEHIRED");
    getAllApplicants(status, isAligable);
  };

  const getAllApplicants = (status, isAligable = true) => {
    Api(
      "GET",
      `Job/GetApplicantListForJob?companyId=${jobData.companyId}&jobId=${jobData.jobId}&jobCycleStatus=${status}&isAligable=${isAligable}`
    )
      .then((res) => {
        setAllApplicants(res.data.data);
      })
      .finally(() => {
        setIsLoader(false);
      });
  };

  const getCount = (flag) => {
    Api(
      "GET",
      `Job/GetCount?jobId=${jobData.jobId}&companyId=${jobData.companyId}&flag=${flag}`
    ).then((res) => {
      setCount(res.data);
    });
  };

  // const saveProgressStatus = () => {
  //   const params = {
  //     jobId: jobData.jobId,
  //     companyId: jobData.companyId,
  //     jobProgressStatus: jobProgressStatus,
  //     jobProgressStatusDescription: jobStatusDescription,
  //   };
  //   setIsSavingProgress(true);
  //   Api("PUT", "Job/UpdateJobProgress", params)
  //     .then((res) => {
  //       if (res.data.isSuccess === true) {
  //         setOpenTextbox(false);
  //         // Find the index of the job in the jobsArray
  //         const index = jobsArray.findIndex(job => job.jobId === params.jobId);
  //         if (index !== -1) {
  //           // Create a copy of the jobsArray
  //           const updatedJobsArray = [...jobsArray];
  //           // Update the job object at the found index
  //           updatedJobsArray[index] = {
  //             ...updatedJobsArray[index],
  //             jobProgressStatus: jobProgressStatus,
  //             jobProgressStatusDescription: jobStatusDescription,
  //           };
  //           // Dispatch an action to update the jobsArray in the Redux store
  //           dispatch({
  //             type: JOB_DATA_ACTIONS.SET_JOBS_ARRAY,
  //             payload: updatedJobsArray,
  //           });
  //         }
  //       }
  //     })
  //     .finally(() => setIsSavingProgress(false));
  // };

  const saveProgressStatus = () => {
    const params = {
      jobId: jobData.jobId,
      companyId: jobData.companyId,
      jobProgressStatus: jobProgressStatus,
      jobProgressStatusDescription: jobStatusDescription,
    };
    setIsSavingProgress(true);
    Api("PUT", "Job/UpdateJobProgress", params)
      .then((res) => {
        if (res.data.isSuccess === true) {
          setOpenTextbox(false);
          // Find the index of the job in the jobsArray
          const index = jobsArray.findIndex(
            (job) => job.jobId === params.jobId
          );
          if (index !== -1) {
            // Create a copy of the jobsArray
            const updatedJobsArray = [...jobsArray];
            // Update the job object at the found index
            updatedJobsArray[index] = {
              ...updatedJobsArray[index],
              jobProgressStatus: jobProgressStatus,
              jobProgressStatusDescription: jobStatusDescription,
            };
            // Dispatch an action to update the jobsArray in the Redux store
            dispatch({ type: JOB_DATA_ACTIONS.SET_REFRESH });
            dispatch({
              type: JOB_DATA_ACTIONS.SET_JOBS_ARRAY,
              payload: updatedJobsArray,
            });
          }
          // Update the jobStatusDescription state only after successful API call
          setJobStatusDescription(jobStatusDescription);
        }
      })
      .finally(() => setIsSavingProgress(false));
  };

  const toggleDrawer = (anchor, open) => {
    if (open) {
      // Clear the jobStatusDescription when the form is opened
      setJobStatusDescription("");
    }
    setSidebar({ ...openSidebar, [anchor]: open });
  };

  const handleSubmit = (editor) => {
    setSubmitting(true);
    if (editor === undefined) {
      editor = formValues.description;
    }

    formValues.positions = formValues.positions ? formValues.positions : 0;

    const params = {
      jobCode: jobData.jobCode,
      jobId: jobData.jobId,
      name: formValues.name,
      description: editor,
      positions: formValues.positions,
      createdAt: jobData.createdAt,
      jobStatus: formValues.jobStatus,
      priority: formValues.priority,
    };

    dispatch({
      type: JOB_DATA_ACTIONS.SET_JOB_DATA,
      payload: params,
    });
    formValues.description = editor;

    setSubmitting(false);
    Api("PUT", "Job/EditJob", formValues)
      .then((res) => {
        if (res.data.isSuccess === true) {
          toast.success(res.data.message);
          toggleDrawer("right", false);
          dispatch({
            type: JOB_DATA_ACTIONS.SET_REFRESH,
            payload: {
              companyId: formValues?.companyId,
              jobId: formValues?.jobId,
              description: formValues?.description,
              priority: formValues?.priority,
              name: formValues?.name,
              jobNo: jobData?.jobNo,
              jobStatus: formValues?.jobStatus,
              jobProgressStatus: jobProgressStatus,
              jobProgressStatusDescription: jobStatusDescription,
              positions: formValues?.positions,
              facilityId: formValues?.facilityId,
              departmentId: formValues?.departmentId,
              createdAt: jobData?.createdAt,
              days: jobData?.days,
            },
          });
          setOpenTextbox(false);
          setDisplayedDescription(inputDescription);
        } else {
          toast.error(res.data.message);
        }
      })
      .finally(() => setSubmitting(false));
  };

  const showTextbox = (color, description) => {
    setColor(color);
    setOpenTextbox(true);
    setInputDescription(""); // Clear the textarea when showTextbox is called
  };

  const handleChange = (e) => {
    setInputDescription(e.target.value);
  };

  const editAction = (jobId) => {
    Api("GET", `Job/GetJobById?jobId=${jobId}`).then((res) => {
      setFormValues(res.data);
      toggleDrawer("right", true);
    });
  };

  const openApplicantDetails = (applicantData) => {
    if (applicantData.applicantList[0].jobCycleStatus === "NEW_APPLICATION") {
      Api(
        "PUT",
        `Job/UpdateCurrentStatus?companyId=${applicantData.companyId}&jobId=${applicantData.applicantList[0].jobId}&applicantId=${applicantData.applicantId}&status=REVIEWING`
      ).then((res) => {
        if (res.data.isSuccess === true) {
          applicantData.applicantList[0].jobCycleStatus = "REVIEWING";
        }
        dispatch({
          type: APPLICANT_DATA_ACTIONS.SET_APPLICANTS_REFRESH,
        });
      });
    }

    const record = {
      ...applicantData,
      applicantList: applicantData.applicantList.map((rec) => {
        if (rec.applicantId === applicantData.applicantId) {
          return {
            ...rec,
            isHotList: rec.isHotList,
          };
        }

        return rec;
      }),
    };
    const isPresent = applicantsArray.some((a) => {
      return a.applicantId === record.applicantId;
    });

    if (isPresent === false) {
      const data = [record, ...applicantsArray];
      dispatch({
        type: APPLICANT_DATA_ACTIONS.SET_APPLICANTS_ARRAY,
        payload: data,
      });
    }
  };

  const toggleAddForm = (anchor, open) => {
    setAddForm({ ...openAddForm, [anchor]: open });
  };

  const handleAddApplicant = () => {
    setSubmitting(true);
    formData.append("firstName", formValues?.firstName ?? "");
    formData.append("middleName", formValues?.middleName ?? "");
    formData.append("lastName", formValues?.lastName ?? "");
    formData.append("nickName", formValues?.nickName ?? "");
    formData.append("title", formValues?.title ?? "");
    formData.append("emailAddress", formValues?.emailAddress ?? "");
    formData.append("phoneNumber", formValues?.phoneNumber ?? "");
    formData.append("resume", formValues?.resume ?? "");
    formData.append("companyId", jobData.companyId);
    formData.append("jobId", jobData.jobId);
    if (askForConfirmation == 1) {
      formData.append("askForConfirmation", askForConfirmation);
    }
    formData.append("address.addressLineOne", formValues?.addressLineOne ?? "");
    formData.append("address.addressLineTwo", formValues?.addressLineTwo ?? "");
    formData.append("address.country", "");
    formData.append("address.city", formValues?.city ?? "");
    formData.append("address.state", formValues?.state ?? "");
    formData.append("address.zipCode", formValues?.zipCode ?? "");
    formData.append("source", formValues?.source ?? "");

    Api("POST", `Job/AddApplicant`, formData, "multipart")
      .then((res) => {
        if (res.data.isSuccess) {
          toast.success(res.data.message);
          toggleAddForm("right", false);
          setIsLoader(true);
          getAllApplicants("NEW_APPLICATION");
          getCount("ACTIVEHIRED");
        } else {
          // setAskForConfirmation(res.data.data.askForConfirmation);
          setName(formValues?.firstName + " " + formValues?.lastName);
          // setConfirmData(res.data.data.ApplicantData);
          toast.error(res.data.message);
        }
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const closeJobTab = (data) => {
    setIsClosingTab(true);
    const dataArr = [...jobsArray];

    dataArr.forEach((rec, index) => {
      if (rec.jobId === data.jobId) {
        dataArr.splice(index, 1);
      }
    });
    setTimeout(() => {
      dispatch({
        type: JOB_DATA_ACTIONS.SET_JOBS_ARRAY,
        payload: dataArr,
      });
      setIsClosingTab(false);
    }, 100);
  };

  const ApplicantCardSkeleton = () => {
    return (
      <Repeat numTimes={4}>
        {(index) => (
          <div className="g-card profile pointer" key={index}>
            <Skeleton count={1} height={40} width={40} circle={true} />

            <div className="profile-content">
              <div className="g-card-dark-header ml-20">
                <span>
                  <Skeleton width={100} height={18} />
                </span>
              </div>
              <div className="g-card-header-2 ml-20 mt-10">
                <Skeleton width={240} height={10} />
              </div>

              <div className="g-card-header-2 ml-20">
                <Skeleton width={240} height={10} />
              </div>
            </div>
          </div>
        )}
      </Repeat>
    );
  };

  const showInactiveApplicants = (flag) => {
    setAligible(flag);
    getCount(flag === false ? "NOTACTIVE" : "ACTIVEHIRED");
    getAllApplicants(selectedStatus, flag);
  };

  const sortByDate = () => {
    const recs = [...allApplicants];
    if (isSort) {
      const data = recs.sort(function (a, b) {
        return new Date(a["createdAt"]) - new Date(b["createdAt"]);
      });
      setAllApplicants(data);
      setSort(false);
    } else {
      const data = recs.sort(function (a, b) {
        return new Date(b["createdAt"]) - new Date(a["createdAt"]);
      });
      setAllApplicants(data);
      setSort(true);
    }
  };

  return (
    <div className="user-list" key={jobData.jobNo}>
      {openAddForm && (
        <AddApplicantForm
          openAddForm={openAddForm}
          toggleDrawer={toggleAddForm}
          handleAddApplicant={handleAddApplicant}
          formValues={formValues}
          setFormValues={setFormValues}
          isSubmitting={isSubmitting}
          askForConfirmation={askForConfirmation}
          confirmData={confirmData}
          name={name}
          jobId={jobData.jobId}
        />
      )}

      {openSidebar && (
        <AddEditJob
          title="Edit Job"
          openSidebar={openSidebar}
          toggleDrawer={toggleDrawer}
          formValues={formValues}
          setFormValues={setFormValues}
          handleSubmit={handleSubmit}
          isSubmitting={isSubmitting}
          setSubmitting={setSubmitting}
        />
      )}

      <div className="j-progress-status">
        <div className="d-flex justify-content-between align-items-center pb-20">
          <div className="j-status">
            <div>
              <Tooltip title="Not Started">
                <div
                  className={
                    color === "pink"
                      ? "light-pink pointer b-border"
                      : "light-pink pointer"
                  }
                  role="button"
                  onClick={() => (
                    showTextbox("pink", ""), setJobProgressStatus("NOT_STARTED")
                  )}
                ></div>
              </Tooltip>
              {!openTextbox &&
                jobProgressStatus === jobsArray[0].jobProgressStatus &&
                jobProgressStatus === "NOT_STARTED" && (
                  <div className="parent-container">
                    <span className="note-show">
                      {displayedDescription
                        ? `${userName} . - ${jobsArray[0]?.jobProgressStatusDescription}`
                        : ""}
                    </span>
                  </div>
                )}
            </div>

            <div>
              <Tooltip title="In Progress">
                <div
                  className={
                    color === "yellow"
                      ? "light-yellow pointer b-border"
                      : "light-yellow pointer"
                  }
                  role="button"
                  onClick={() => (
                    showTextbox(
                      "yellow",
                      jobsArray[0]?.jobProgressStatusDescription
                    ),
                    setJobProgressStatus("IN_PROGRESS")
                  )}
                ></div>
              </Tooltip>
              {!openTextbox &&
                jobProgressStatus === jobsArray[0].jobProgressStatus &&
                jobProgressStatus === "IN_PROGRESS" && (
                  <div className="parent-container">
                    <span className="note-show-inprogress">
                      {displayedDescription
                        ? `${userName} . - ${jobsArray[0]?.jobProgressStatusDescription}`
                        : ""}
                    </span>
                  </div>
                )}
            </div>

            <div>
              <Tooltip title="Complete">
                <div
                  className={
                    color === "green"
                      ? "light-green pointer b-border"
                      : "light-green pointer"
                  }
                  role="button"
                  onClick={() => (
                    showTextbox(
                      "green",
                      jobsArray[0]?.jobProgressStatusDescription
                    ),
                    setJobProgressStatus("COMPLETE")
                  )}
                ></div>
              </Tooltip>
              {!openTextbox &&
                jobProgressStatus === jobsArray[0].jobProgressStatus &&
                jobProgressStatus === "COMPLETE" && (
                  <div className="parent-container">
                    <span className="note-show-complete">
                      {displayedDescription
                        ? `${userName} . - ${jobsArray[0]?.jobProgressStatusDescription}`
                        : ""}
                    </span>
                  </div>
                )}
            </div>
          </div>

          {jobData.positions > 0 && jobData.jobStatus === "ACTIVE" && (
            <Tooltip title="Add Applicant">
              <IconButton
                size="small"
                onClick={() => (
                  toggleAddForm("right", true),
                  setFormValues({}),
                  setAskForConfirmation(0),
                  setName("")
                )}
              >
                <AddCircleOutlineOutlinedIcon sx={{ color: indigo[500] }} />
              </IconButton>
            </Tooltip>
          )}

          <Tooltip title="Close Job">
            <IconButton
              size="small"
              onClick={() => closeJobTab(jobData)}
              disabled={isClosingTab}
            >
              <CloseIcon sx={{ color: red[500] }} />
            </IconButton>
          </Tooltip>
        </div>
        {openTextbox ? (
          <div className="note-input">
            <div className="forms-input">
              <textarea
                type="text"
                id="note"
                placeholder="Note here.."
                value={inputDescription}
                onChange={handleChange}
              />
            </div>
            <div className="btn-container">
              <ButtonUI
                p="8px"
                className="mr-10"
                variant="outlined"
                onClick={
                  () => setOpenTextbox(false)
                  // , setJobStatusDescription("")
                }
                disabled={isSavingProgress}
              >
                Cancel
              </ButtonUI>
              <ButtonUI
                p="8px"
                pl="18px"
                onClick={saveProgressStatus}
                pr="18px"
                width="60px"
                disabled={isSavingProgress}
              >
                {!isSavingProgress ? (
                  "Save"
                ) : (
                  <div className="loader-spin"></div>
                )}
              </ButtonUI>
            </div>
          </div>
        ) : null}
      </div>

      <div className="js-card">
        <JobCard
          jobCode={jobData.jobNo}
          name={jobData.name}
          description={jobData.description}
          jobStatus={jobData.jobStatus}
          priority={jobData.priority}
          positions={jobData.positions}
          jobId={jobData.jobId}
          createdAt={jobData.createdAt}
          companyId={jobData.companyId}
          editAction={editAction}
        />

        <li className="item mb-10" style={{ padding: 0, width: "100%" }}>
          <Tooltip placement="top" title="New Application">
            <span
              className={
                selectedStatus === "NEW_APPLICATION"
                  ? "count pointer selected"
                  : "count pointer zero"
              }
              onClick={() =>
                handlejobApplicantStatus("NEW_APPLICATION", "New Application")
              }
              style={{ marginRight: 5 }}
            >
              {count["NEW_APPLICATION"]}
            </span>
          </Tooltip>
          <Tooltip placement="top" title="Reviewing">
            <span
              className={
                selectedStatus === "REVIEWING"
                  ? "count pointer selected"
                  : "count pointer zero"
              }
              onClick={() => handlejobApplicantStatus("REVIEWING", "Reviewing")}
              style={{ marginRight: 5 }}
            >
              {count["REVIEWING"]}
            </span>
          </Tooltip>
          <Tooltip placement="top" title="Video Interview">
            <span
              className={
                selectedStatus === "VIDEO_INTERVIEW"
                  ? "count pointer selected"
                  : "count pointer zero"
              }
              onClick={() =>
                handlejobApplicantStatus("VIDEO_INTERVIEW", "Video Interview")
              }
              style={{ marginRight: 5 }}
            >
              {count["VIDEO_INTERVIEW"]}
            </span>
          </Tooltip>
          <Tooltip placement="top" title="Interviewing">
            <span
              className={
                selectedStatus === "INTERVIEWING"
                  ? "count pointer selected"
                  : "count pointer zero"
              }
              onClick={() =>
                handlejobApplicantStatus("INTERVIEWING", "Interviewing")
              }
              style={{ marginRight: 5 }}
            >
              {count["INTERVIEWING"]}
            </span>
          </Tooltip>
          <Tooltip placement="top" title="Offering">
            <span
              className={
                selectedStatus === "OFFERING"
                  ? "count pointer selected"
                  : "count pointer zero"
              }
              onClick={() => handlejobApplicantStatus("OFFERING", "Offering")}
              style={{ marginRight: 5 }}
            >
              {count["OFFERING"]}
            </span>
          </Tooltip>
          <Tooltip placement="top" title="Offer Letter">
            <span
              className={
                selectedStatus === "OFFER_LETTER"
                  ? "count pointer selected"
                  : "count pointer zero"
              }
              onClick={() =>
                handlejobApplicantStatus("OFFER_LETTER", "Offer Letter")
              }
              style={{ marginRight: 5 }}
            >
              {count["OFFER_LETTER"]}
            </span>
          </Tooltip>
          <Tooltip placement="top" title="PreHire">
            <span
              className={
                selectedStatus === "PREHIRE"
                  ? "count pointer selected"
                  : "count pointer zero"
              }
              onClick={() => handlejobApplicantStatus("PREHIRE", "PreHire")}
              style={{ marginRight: 5 }}
            >
              {count["PREHIRE"]}
            </span>
          </Tooltip>
          <Tooltip placement="top" title="On Boarding">
            <span
              className={
                selectedStatus === "ONBOARDING"
                  ? "count pointer selected"
                  : "count pointer zero"
              }
              onClick={() =>
                handlejobApplicantStatus("ONBOARDING", "OnBoarding")
              }
              style={{ marginRight: 5 }}
            >
              {count["ONBOARDING"]}
            </span>
          </Tooltip>
          <Tooltip placement="top" title="Hire">
            <span
              className={
                selectedStatus === "HIRE"
                  ? "count pointer selected"
                  : "count pointer zero"
              }
              onClick={() => handlejobApplicantStatus("HIRE", "Hire")}
              style={{ marginRight: 5 }}
            >
              {count["HIRE"]}
            </span>
          </Tooltip>
        </li>
      </div>
      {isLoader === true ? (
        <SkeletonTheme
          borderRadius={skeletonBorderRadius}
          baseColor={skeletonBaseColor}
          highlightColor={skeletonHighLightColor}
        >
          <ApplicantCardSkeleton />
        </SkeletonTheme>
      ) : allApplicants.length > 0 ? (
        <div className="inner-h job-sidebar">
          <div>
            <div className="d-flex justify-content-between p-0-16 align-items-center border-bottom">
              <strong className="font-14">
                {label}{" "}
                <Tooltip
                  title="Create New Connect Sequence"
                  onClick={() =>
                    dispatch({
                      type: SEQUENCE_DATA_ACTIONS.SET_SEQ_DATA,
                      payload: [{ id: 1, data: "data" }],
                    })
                  }
                >
                  <img src={flashIcon} alt="flash-icon" style={SEQ_ICON} />
                </Tooltip>
              </strong>
              <div>
                <Tooltip title="Sort by Date">
                  <IconButton onClick={sortByDate}>
                    <SortDateApplicant
                      width="26"
                      height="26"
                      fill={isSort ? "#3954A5" : "#757575"}
                      stroke={isSort ? "#3954A5" : "#757575"}
                    />
                  </IconButton>
                </Tooltip>
                <Tooltip title={isAligable ? "Show  Inactive" : "Show Active"}>
                  <IconButton
                    onClick={() => showInactiveApplicants(!isAligable)}
                  >
                    <ShowInActive
                      width="26"
                      height="28"
                      fill={isAligable ? "#3954A5" : "#757575"}
                      stroke={isAligable ? "#3954A5" : "#757575"}
                    />
                  </IconButton>
                </Tooltip>
              </div>
            </div>
            <ul className="job_template_option_menu pointer">
              <div>
                {allApplicants.length > 0 &&
                  allApplicants.map((applicant) => (
                    <div key={Math.random()}>
                      <ProfileCard
                        key={applicant.applicantId}
                        applicantId={applicant.applicantId}
                        name={applicant.firstName + " " + applicant.lastName}
                        jobName={applicant.jobName}
                        jobId={applicant.jobId}
                        companyId={jobData.companyId}
                        jobCode={applicant.jobNo}
                        morePositions={applicant.morePositions}
                        createdAt={applicant.createdAt}
                        openApplicantDetails={openApplicantDetails}
                        checked={applicant.checked}
                        applicantList={allApplicants}
                        setApplicantList={setAllApplicants}
                        setAllChecked={true}
                        status={applicant.jobHireStatus}
                        applicant={applicant}
                      />
                    </div>
                  ))}
              </div>
            </ul>
          </div>
        </div>
      ) : (
        <>
          <div className="d-flex justify-content-between p-0-16 align-items-center border-bottom">
            <strong className="font-14">
              {label}{" "}
              {/* <Tooltip
                title="Create New Connect Sequence"
                onClick={() =>
                  dispatch({
                    type: SEQUENCE_DATA_ACTIONS.SET_SEQ_DATA,
                    payload: [{ id: 1, data: "data" }],
                  })
                }
              >
                <img src={flashIcon} alt="flash-icon" style={SEQ_ICON} />
              </Tooltip> */}
            </strong>
            <div className="d-flex" style={{ justifyContent: "flex-end" }}>
              <Tooltip title="Sort by Date">
                <IconButton onClick={sortByDate}>
                  <SortDateApplicant
                    width="26"
                    height="26"
                    fill={isSort ? "#3954A5" : "#757575"}
                    stroke={isSort ? "#3954A5" : "#757575"}
                  />
                </IconButton>
              </Tooltip>
              <Tooltip title={isAligable ? "Show  Inactive" : "Show Active"}>
                <IconButton onClick={() => showInactiveApplicants(!isAligable)}>
                  <ShowInActive
                    width="26"
                    height="28"
                    fill={isAligable ? "#3954A5" : "#757575"}
                    stroke={isAligable ? "#3954A5" : "#757575"}
                  />
                </IconButton>
              </Tooltip>
            </div>
          </div>
          <NoColDataFound />
        </>
      )}
    </div>
  );
};
