import { useState, useEffect } from "react";
import { CheckboxList } from "../../../../common/layouts/CheckboxList";
import Api from "../../../../../helper/Api";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import Loader from "../../../../common/Loader";
import { ColoredLinearProgress } from "../../../../common/coloredProgressBar";

export const JobTempBoards = ({ getJobTempById }) => {
  const [allData, setAllData] = useState([]);
  const [isCheckedAll, setAllChecked] = useState(false);
  const [isLoader, setIsLoader] = useState(false);
  const { companyData } = useSelector((state) => state.companyDataReducer);
  const hasSelectAll = true;
  const selectedData = useSelector(
    (state) => state.jobTemplateReducer.jobTemplateData.jobBoards
  );

  useEffect(() => {
    setIsLoader(true);
    Api(
      "GET",
      `JobBoard/GetJobBoardList?companyId=${companyData.companyId}&companyHirerchy=${companyData.companyHirerchy}&OrderBy=updatedAt&OrderbyDirection=desc`
    )
      .then((res) => {
        res.data.data.map((d) => {
          d.checked =
            selectedData.length > 0 && selectedData.includes(d.jobBoardId)
              ? true
              : false;
          d.id = d.jobBoardId;
        });
        setAllData(res.data.data);
        res.data.data.findIndex((item) => item.checked === false) === -1
          ? setAllChecked(false)
          : setAllChecked(true);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsLoader(false);
      });
  }, []);

  const handleSubmit = (data) => {
    setIsLoader(true);
    let selected = [];
    data.filter(function (record) {
      if (record.checked === true) {
        selected.push(record.jobBoardId);
      }
    });

    const params = {
      companyId: companyData.companyId,
      jobTemplateId: companyData.data.jobTemplateId,
      ids: selected,
      enumSourceType: 5, // Job Boards
    };

    Api("POST", "JobTemplate/SaveJobTemplateDetails", params)
      .then((res) => {
        if (res.data.isSuccess === true) {
          getJobTempById();
          toast.success(res.data.message);
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsLoader(false);
      });
  };

  const selectAll = () => {
    setAllChecked(!isCheckedAll);
    allData.map((d) => {
      d.checked = isCheckedAll;
    });
  };

  return (
    <div>
      <div className="top-header-user">
        <span className="main-name">Job Boards </span>
        <span className="blue-t pointer" onClick={() => selectAll()}>
          Select all
        </span>
      </div>
      {isLoader === true ? (
        <ColoredLinearProgress />
      ) : (
        <div className="main_content">
          <CheckboxList
            allData={allData}
            setAllData={setAllData}
            handleSubmit={handleSubmit}
            hasSelectAll={hasSelectAll}
            setAllChecked={setAllChecked}
          />
        </div>
      )}
    </div>
  );
};
